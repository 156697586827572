import React, { useEffect, useState } from "react";
import useSortData from "../../../hooks/useSortData";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styles from "./styles.module.scss";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import Roles from "../../../utils/api/Roles";
import { ROLE_ID_STUDENT, REQUESTED_STATUS_REJECT, REQUESTED_STATUS_ACCEPT, USER_STATUS_ACTIVE, USER_STATUS_BANNED } from "../../../constants/user";
import UserApi from "../../../utils/api/UserApi";
import { useNavigate } from "react-router-dom";
import cn from 'classnames';
import UsersBodyItem from "./UsersBodyItem";
import ConfirmModal from "../../../components/Modals/ConfirmModal/ConfirmModal";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import { toast } from "react-toastify";
import ToastMsg from "../../../components/ToastMsg/ToastMsg";
import BranchApi from "../../../utils/api/BranchApi";
import EmptyBlock from "../../../components/EmptyBlock/EmptyBlock";
import { useSelector } from "react-redux";
import { STUDENT_STATUS_ACTIVE, STUDENT_STATUS_DELETED, STUDENT_STATUS_PAUSED } from "../../../constants/courses";
import CourseStatusModal from "../../../components/Modals/CourseStatusModal/CourseStatusModal";
import SecondLoader from "../../../components/SecondLoader/SecondLoader";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const UsersTable = ({ columns, data, searchedValue, tableFor, changeUserStatus, loading, deleteUserFromTable, refetchCourseUsers, lastUserRef, orderBy, setOrderBy, order, setOrder }) => {
    
   const [clickedUserId, setClickedUserId] = useState(null);
   const [selectedRole, setSelectedRole] = useState({});

   const [clickedItem, setClickedItem] = useState(null);
   const [isChangeLoading, setIsChangeLoading] = useState(false);

   const t = useFindTranslationsByString();

    const navigate = useNavigate();
    const confirmModal = useHandleModal();
    const handleStatusModal = useHandleModal();

    const onOpenStatusModal = (item) => {
      handleStatusModal.open();
      setClickedItem(item)
   };

    const onSelectRole = (role) => {
      setSelectedRole(role);
   };

    const openConfirmModal = (userId) => {
      setClickedUserId(userId);
      confirmModal.open();
    }

    const onDeleteFromPlatform = async () => {
      setIsChangeLoading(true);

      const res = await new UserApi().updateUser(clickedUserId, {
         new_status: USER_STATUS_BANNED
      });

      if(res?.success?.message) {
         if(deleteUserFromTable) deleteUserFromTable(clickedUserId)
         changeUserStatus(clickedUserId, USER_STATUS_BANNED);
         refetchCourseUsers();
         toast(<ToastMsg text={res.success.message} />)
      } 

      if(res?.error?.message) toast(<ToastMsg text={res?.error?.message} isError/>)
      setIsChangeLoading(false);
      confirmModal.close();
    }

    const handleSort = (column) => {
      const columnMappings = {
         "team": { orderBy: "team", orderToggle: true },
         "progress": { orderBy: "progress", orderToggle: true },
         "name": { orderBy: "first_name", orderToggle: true },
         "department": { orderBy: "department", orderToggle: true },
         "position": { orderBy: "position", orderToggle: true },
         "was": { orderBy: "updated_at", orderToggle: true },
         "status": { orderBy: "user_course_status", orderToggle: true },
      };
   
      const columnMapping = columnMappings[column];
      if (columnMapping) {
         const { orderBy, orderToggle } = columnMapping;
         setOrderBy(orderBy || "");
         if (orderToggle) {
            setOrder(order === "asc" ? "desc" : "asc");
         }
      }
   };

   if(data?.length === 0 && !loading) {
      return <EmptyBlock />;
   }

   return (
      <div className={styles.table_container}>
         <table className={styles.table}>
            <thead className={styles.thead}>
               <tr>
                  {columns.map((column, index) => (
                     <th
                        key={column}
                        onClick={() => handleSort(column)}
                        className={styles.header}
                     >
                        <span>{t(column?.replace('_', ' '))}</span>
                        <div className={styles.arrow_wrapper}>
                           <span className={styles.arrow}></span>
                        </div>
                     </th>
                  ))}
                  {/* needed for making space for options */}
                  <th></th> 
               </tr>
            </thead>
            <tbody className={styles.tbody}>
            <TransitionGroup component={null}>
               {data?.map((row, index) => (
                  <CSSTransition key={`row-${index}`} classNames="fade" timeout={100}>
                  <>
                     <UsersBodyItem 
                        tableFor={tableFor} 
                        row={row} 
                        columns={columns} 
                        index={index}
                        openConfirmModal={openConfirmModal}
                        onOpenStatusModal={onOpenStatusModal}
                        lastItemRef={index === data?.length - 1 ? lastUserRef : null}
                     />
                  </>
                   
                  </CSSTransition>
               ))}
               </TransitionGroup>

            </tbody>
         </table>

         {data?.length === 0 && !loading && (
             <EmptyBlock />
         )}

         {loading && 
            <div className="default_loader_wrapper">
               <SecondLoader />
            </div>
         }

         <ConfirmModal
               confirmButtonText={'Block user'}
               onConfirm={onDeleteFromPlatform}
               onClose={confirmModal.close}
               isOpen={confirmModal.isActive}
               title={"You sure you want to block the user?"}
               isLoading={isChangeLoading}
            />
        
        <CourseStatusModal
            onClose={handleStatusModal.close}
            isOpen={handleStatusModal.isActive}
            item={clickedItem}
            refetchCourseUsers={refetchCourseUsers}
         />
      </div>
   );
};

export default UsersTable;
