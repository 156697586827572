import React, { useEffect, useState } from "react";
import useSortableAndFuzzyData from "../../../hooks/useSortableAndFuzzyData";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import styles from "./styles.module.scss";
import Roles from "../../../utils/api/Roles";
import {
   REQUESTED_STATUS_REJECT,
   REQUESTED_STATUS_ACCEPT,
   USER_STATUS_ACTIVE,
   USER_STATUS_BANNED,
} from "../../../constants/user";
import UserApi from "../../../utils/api/UserApi";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import StatisticsBodyItem from "./StatisticsBodyItem";
import ConfirmModal from "../../../components/Modals/ConfirmModal/ConfirmModal";
import { toast } from "react-toastify";
import ToastMsg from "../../../components/ToastMsg/ToastMsg";
import BranchApi from "../../../utils/api/BranchApi";
import EmptyBlock from "../../../components/EmptyBlock/EmptyBlock";
import { useSelector } from "react-redux";
import SecondLoader from "../../../components/SecondLoader/SecondLoader";

const StatisticsTable = ({
   columns,
   data,
   searchedValue,
   tableFor,
   loading,
   maxHeight,
   maxWordsLength = 19,
   lastElementRef,
   minWidth,
   rowClickable,
   t
}) => {
   const { sortedAndSearchedData, handleSort, performSearch } =
      useSortableAndFuzzyData(data, columns);

   const navigate = useNavigate();

   useEffect(() => {
      performSearch(searchedValue);
   }, [searchedValue, data]);

   if (
      sortedAndSearchedData?.length === 0 &&
      data?.length > 0 &&
      searchedValue?.length > 0
   ) {
      return <EmptyBlock />;
   }

   return (
      <div style={{ maxHeight }} className={styles.table_container}>
         <table className={cn(styles.table, minWidth ? styles.minWidth : '')}>
            <thead className={styles.thead}>
               <tr>
                  {columns?.map((column, index) => (
                     <th
                        key={column}
                        onClick={() => handleSort(column)}
                        className={cn(styles.header, 'noselect')}
                     >
                        <span>
                           {column === "percentage"
                              ? "%"
                              : t?.returnTranslation(column.replaceAll('_', ' '))
                           }
                        </span>
                        <div className={styles.arrow_wrapper}>
                           <span className={styles.arrow}></span>
                        </div>

                        { column === "user_score" && (tableFor === 'users' || tableFor === 'company') && 
                           <span className={styles.hint}>{t?.usersAverageScoreForAllCompletedCourses}</span>
                        }

                        { column === "course_score" && 
                           <span className={styles.hint}>{t?.averageScoreOfUsersThatHaveBeenPassedThisCourse}</span>
                        }
                     </th>
                  ))}
               </tr>
            </thead>
            <tbody className={styles.tbody}>
               <TransitionGroup component={null}>
                  {sortedAndSearchedData?.map((row, index) => (
                     <CSSTransition
                        key={`row-${index}`}
                        classNames="fade"
                        timeout={100}
                     >
                        <>
                           <StatisticsBodyItem
                              tableFor={tableFor}
                              row={row}
                              columns={columns}
                              index={index}
                              maxWordsLength={maxWordsLength}
                              rowClickable={rowClickable}
                              t={t}
                           />
                        </>
                     </CSSTransition>
                  ))}
               </TransitionGroup>
               <tr className={styles.triggerRefetch} ref={lastElementRef ? lastElementRef : null}></tr>
            </tbody>
         </table>

         {data?.length === 0 && <EmptyBlock />}
      </div>
   );
};

export default StatisticsTable;
