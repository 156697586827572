import React, { useEffect, useRef, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import MainLayout from "../../layouts/MainLayout/MainLayout";
import Input from "../../components/Input/Input";
import TextArea from "../../components/TextArea/TextArea";
import Button from "../../components/Button/Button";

import ProfileSidebar from "./ProfileSidebar/ProfileSidebar";
import ToastMsg from "../../components/ToastMsg/ToastMsg";
import ProfileSubheader from "./ProfileSubheader/ProfileSubheader";
import { useSelector } from "react-redux";
import BasicInfo from "./BasicInfo/BasicInfo";
import UserApi from "../../utils/api/UserApi";
import { useDispatch } from "react-redux";
import { setInfo } from "../../store/actions/user";
import moment from "moment";

import styles from "./styles.module.scss";
import cn from "classnames";
import PasswordInput from "../../components/PasswordInput/PasswordInput";
import { validateChangePassword, validateChangePasswordServer, validateChangeStrongPassword } from "../../utils/validationSchemas/validateChangePassword";
import PositionBlock from "./PositionBlock/PositionBlock";
import CertificatesSubheader from "../../components/CertificatesTab/CertificatesSubheader/CertificatesSubheader";
import Certificates from "../../components/CertificatesTab/Certificates/Certificates";
import CertificateModal from "../../components/Modals/CertificateModal/CertificateModal";
import useHandleModal from "../../hooks/dom/useHandleModal";
import CertificateApi from "../../utils/api/CertificateApi";
import { transformCertificateDate, transoformedResult } from "../../utils/coursesHelper";
import ActivityTab from "./ActivityTab/ActivityTab";
import AgencyApi from "../../utils/api/AgencyApi";
import { transformRoleText } from "../../utils/rolesHelper";
import Roles from "../../utils/api/Roles";
import TeamApi from "../../utils/api/TeamApi";
import useUserRole from "../../hooks/useUserRole";
import useGetAgencies from "../../hooks/api/useGetAgencies";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import useGetCertificates from "../../hooks/api/useGetCertificates";
import { TEAM_EXTRA_GROUP, TEAM_EXTRA_GROUP_TABLE } from "../../utils/api/serialization_groups";

import { BASIC_INFO_TAB, POSITION_TAB, ACTIVITY_TAB, CERTIFICATES_TAB,
CHANGE_PASSWORD_TAB } from '../../constants/user';
import useFindTranslationsByString from "../../hooks/useFindTranslationsByString";

const initialPassswordData = {
   old_password: "",
   new_password: "",
   confirm_new_password: "",
};

const ProfilePage = () => {
   const [isButtonDisabled, setIsButtonDisabled] = useState(false);
   const [avatar, setAvatar] = useState(null);

   const [relatedToYouTeams, setRelatedToYouTeams] = useState([]); 
   const [agenciesExtraInfo, setAgenciesExtraInfo] = useState([]); 
   const [yourTeams, setYourTeams] = useState([]); 
   const [teamsLoading, setTeamsLoading] = useState(false); 
   const [yourTeamsLoading, setYourTeamsLoading] = useState(false); 

   const [coursesLoading, setCoursesLoading] = useState(false); 
   const [curatorsCourses, setCuratorsCourses] = useState([]);

   const [activeTagsFilter, setActiveTagsFilter] = useState('');
   const [activeSortFilter, setActiveSortFilter] = useState('');
   const [orientation, setOrientation] = useState('grid');
   const [currentCertificate, setCurrentCertificate] = useState({});

   const currentCompanyLogo = useSelector((state) => state.sidebar.companyLogo);
   const currentCompanyName = useSelector((state) => state.sidebar.companyName);
   const currentCompanyId = useSelector((state) => state.sidebar.companyId);
   const certificateModal = useHandleModal();

   const [basicData, setBasicData] = useState();
   const [passwordData, setPasswordData] = useState(initialPassswordData);
   const [passwordErrors, setPasswordErrors] = useState(initialPassswordData);
   const navigate = useNavigate();
   const userId = useSelector(state => state?.user?.info?.id);

   const { userRoleId, userRoleName, filteredByCompanyRoles } = useUserRole(currentCompanyId);

   const params = useParams();
   const [activeElement, setActiveElement] = useState({ id: params.tab });

   const t = useFindTranslationsByString();

   const changeTab = (newTab) => {
      navigate(`/profile/${activeElement?.id}`);
    };

    useEffect(()=> {
      changeTab(activeElement);
    }, [activeElement])

   const dispatch = useDispatch();
   const userInfo = useSelector((state) => state.user.info);

   const isStrongPassword = filteredByCompanyRoles?.[0]?.branch?.company?.companySecurity?.strongPassword;

   const [selectedDepartment, setSelectedDepartment] = useState("");
   const [selectedPosition, setSelectedPosition] = useState("");

   const openCertificateModal = (certificate) => {
      setCurrentCertificate(certificate)
      certificateModal.open();
   }

   const onSelectDepartment = (department) => {
      setSelectedDepartment(department.name);
   };

   const onSelectPosition = (position) => {
      setSelectedPosition(position.name);
   };

   const handleListItemClick = (e, item) => {
      e.preventDefault();
      setActiveElement(item);
   };

   const initBasicData = () => {
      setBasicData({
         first_name: userInfo?.firstName || "",
         last_name: userInfo?.lastName || "",
         city: userInfo?.region || "",
         email: userInfo?.email || "",
         selected_time_zone: userInfo?.timezone || "",
         date: userInfo?.birthday ? moment(userInfo?.birthday).format("YYYY-MM-DD") : "",
         date_dd: userInfo?.birthday ? moment(userInfo?.birthday).format("D") : "",
         date_mm: userInfo?.birthday ? moment(userInfo?.birthday).format("M") : "",
         date_yyyy: userInfo?.birthday ? moment(userInfo?.birthday).format("YYYY") : "",
      });
   };

   const { certificates, isLoading: isCertLoading, totalCertificates, setCurrentPage: setCurrentCertPage, searchedValue, setSearchedValue, tags, setTags, order, setOrder, allCertificates } = useGetCertificates(userInfo?.id, currentCompanyId);

   const lastCertificateRef = useIntersectionObserver(
      () => {
        if (!isCertLoading && certificates?.length < totalCertificates) {
          if(certificates?.length === 0) {
            setCurrentCertPage(1);
          } else {
            setCurrentCertPage((prev) => prev + 1);
          }
        }
      },
      [isCertLoading, totalCertificates]
    );

   const changeUserPassword = async () => {
      if(isStrongPassword) {
         const error = validateChangeStrongPassword(passwordData, setPasswordErrors);
         if(error?.length) return toast(<ToastMsg text={error} isError /> );
      }

      if (!validateChangePassword(passwordData, setPasswordErrors)) {
         return toast(<ToastMsg text={"Fill in the required fields"} isError /> );
      }

      const res = await new UserApi().changeUserPassword(passwordData);

      if (res?.success) {
         toast(<ToastMsg text={res?.success?.message} />);
         setPasswordData(initialPassswordData);
         setPasswordErrors(initialPassswordData);
      }

      if (res?.error?.message) {
         validateChangePasswordServer(res?.error?.message, passwordData, setPasswordErrors)
         return toast(<ToastMsg text={res?.error?.message} isError />);
      }
   };

   const changeUserPosition = async () => {
      const data = {
         ...( selectedPosition.length ? {new_department: selectedDepartment} : {}),
         ...( selectedPosition.length ? {new_position: selectedPosition} : {})
      }

      const res = await new UserApi().changeYourUserData(data);

      if (res?.success) {
         toast(<ToastMsg text={res?.success?.message} />);
      }

      if (res?.error?.message) {
         return toast(<ToastMsg text={res?.error?.message} isError />);
      }
   };

   const changeUserData = async () => {
      if (!userInfo?.id)
         return toast(<ToastMsg text={"User not exist"} isError />);
      if(basicData.first_name.length === 0) return toast(<ToastMsg text={"First name cannot be empty"} isError/>)
         
      setIsButtonDisabled(true);

      const data = {
         new_first_name: basicData.first_name,
         new_last_name: basicData.last_name || '',
         ...(basicData.gender ? {new_gender: basicData.gender} : {}),
         ...(basicData.city ? { new_region: basicData.city } : {}),
         ...(basicData.date ? { new_birthday: basicData.date } : {}),
         ...(basicData.phone ? { new_phone_number: `+${basicData.phone}`} : {}),
         ...(basicData.email !== userInfo?.email && basicData.email?.length ? { new_email: basicData.email?.trim()} : {}),
      };

      const res = await new UserApi().changeYourUserData(data);

      if (res?.success?.data) {
         toast(<ToastMsg text={res?.success?.message} />);
         dispatch(setInfo({
            ...userInfo,
            firstName: res?.success?.data?.user?.firstName || "",
            lastName: res?.success?.data?.user?.lastName || "",
            ...(basicData.email !== userInfo?.email && basicData.email?.length ? { email: basicData.email?.trim()} : {}),
            ...(basicData.city ? { region: basicData.city } : {}),
            ...(basicData.date ? { birthday: basicData.date } : {}),
            ...(basicData.phone ? { phoneNumber: `+${basicData.phone}`} : {}),
         }));
      }

      if (res?.error?.message) {
         toast(<ToastMsg text={res?.error?.message} isError />);
      }
      setIsButtonDisabled(false);
   };
   
   const onSave = (e) => {
      e.preventDefault();
      if (activeElement?.id === BASIC_INFO_TAB) changeUserData();
      if (activeElement?.id === CHANGE_PASSWORD_TAB) changeUserPassword();
      if (activeElement?.id === POSITION_TAB) changeUserPosition();
   };
   
   const handlePasswordInputChange = (field, value) => {
      const updatedPasswordData = { ...passwordData };
      updatedPasswordData[field] = value;
      setPasswordData(updatedPasswordData);
      setPasswordErrors({ ...passwordErrors, [field]: "" });
   };

   const initDepartmentAndPosition = () => {
      setSelectedDepartment(userInfo?.department);
      setSelectedPosition(userInfo?.position);
   }

   useEffect(() => {
      initBasicData();
      initDepartmentAndPosition();
   }, [userInfo]);

   const role = transformRoleText(userRoleName, currentCompanyName) 

   const agencyManager = useGetAgencies(userId);

   const lastAgencyRef = useIntersectionObserver(
      () => {
        if (!agencyManager.isAgenciesLoading && agencyManager.agenciesList?.length < agencyManager.totalAgencies) {
          if(agencyManager.agenciesList?.length === 0) {
            agencyManager.setCurrentPage(1);
          } else {
            agencyManager.setCurrentPage((prev) => prev + 1);
          }
        }
      },
      [agencyManager.isAgenciesLoading, agencyManager.totalAgencies]
    );

    const getAgenciesExtraInfo = async () => {
      const data = {
         company_ids: mappedAgencies?.map(agency => agency?.id)
      }

      const res = await new AgencyApi().getAgencySpecificInfo(data);

      if(res?.success?.data) {
         setAgenciesExtraInfo(res?.success?.data)
      }
   }

   useEffect(()=> {
      if(agencyManager?.agenciesList?.length)   
         getAgenciesExtraInfo();
   }, [agencyManager?.agenciesList])

    const agenciesList = agencyManager?.agenciesList?.map(agency => {
      const matchingLink = agenciesExtraInfo?.find(a => a?.companyId === agency?.id);

      if (matchingLink) {
         return {
            ...agency,
            users: matchingLink?.usersWithSomeRoleInCompany,
            courses: matchingLink?.coursesInCompany,
         };
      } else {
         return agency;
      }
    })

    const mappedAgencies = agenciesList?.map((item) => ({
      id: item?.id,
      company_name: item?.name,
      courses: item?.courses || 0,
      participants: item?.users || 0,
      role: role
    }));

    const getRelatedToYouTeams = () => {
      setTeamsLoading(true);

      new Roles()
         .getTeamsWhereYouAreMentor(currentCompanyId, TEAM_EXTRA_GROUP_TABLE)
         .then((res) => {
            if (res?.success?.data) {
               const newData = res.success.data?.map((item) => ({
                  id: item?.id,
                  name: item?.name,
                  participants: item?.users?.length || 0,
                  role: 'Mentor',
               }));

               setRelatedToYouTeams(newData);
            } 
         })
         .finally(() => {
            setTeamsLoading(false);
         });
   };

    const getCuratorsCourses = () => {
      setCoursesLoading(true);

      new Roles()
         .getCoursesWhereYouAreCurator(currentCompanyId)
         .then((res) => {
            if (res?.success?.data) {
               const newData = res.success.data.map((item) => ({
                  id: item?.id,
                  folder_name: item?.folders[0]?.name || '-',
                  course_name: item?.name || '-',
                  course: item
               }));

               setCuratorsCourses(newData);
            } 
         })
         .finally(() => {
            setCoursesLoading(false);
         });
   };

   const getYourTeams = () => {
      setYourTeamsLoading(true);

      new Roles()
         .getYourTeams(TEAM_EXTRA_GROUP_TABLE)
         .then((res) => {
            if (res?.success?.data?.length) {
               setYourTeams(res.success.data.map((item) => ({
                  id: item?.id,
                  name: item?.name,
                  participants: item?.users?.length || 0,
                  role: 'Student',
               })));
            } 
         })
         .finally(() => {
            setYourTeamsLoading(false);
         });
   };

   const getTeams = async () => {
      await getRelatedToYouTeams();
      await getYourTeams();
   }

   useEffect(() => {
     if(currentCompanyId) {
         getTeams();
         getCuratorsCourses()
     }
    }, [currentCompanyId])

   return (
      <MainLayout>
         <main className={styles.settings_page}>
            <ProfileSubheader
               onSave={onSave}
               isButtonDisabled={isButtonDisabled}
            />

            <ProfileSidebar
               activeElement={activeElement}
               onMenuItemClick={handleListItemClick}
            />

            <div className={cn(styles.content, activeElement?.id === CERTIFICATES_TAB || activeElement?.id === ACTIVITY_TAB ? styles.hide : "")}>
               {activeElement?.id === BASIC_INFO_TAB && (
                  <BasicInfo
                     user={userInfo}
                     avatar={avatar}
                     setAvatar={setAvatar}
                     setBasicData={setBasicData}
                     basicData={basicData}
                  />
               )}

               {activeElement?.id === POSITION_TAB && (
                  <div className={styles.block}>
                     <h3 className={styles.title}>{t("Position")}</h3>
                     <PositionBlock 
                         setSelectedDepartment={setSelectedDepartment}
                         selectedDepartment={selectedDepartment}
                         setSelectedPosition={setSelectedPosition}
                         selectedPosition={selectedPosition}
                         onSelectDepartment={onSelectDepartment}
                         onSelectPosition={onSelectPosition}
                     />
                  </div>
               )}

               {activeElement?.id === CHANGE_PASSWORD_TAB && (
                  <div className={styles.block}>
                     <h3 className={styles.title}>{t("Change password")}</h3>
                     <div className={styles.row}>
                        <PasswordInput
                           isError={passwordErrors.old_password ? true : false}
                           value={passwordData?.old_password}
                           placeholder={t("Old password")}
                           onChangeValue={(e) =>
                              handlePasswordInputChange("old_password", e.target.value)
                           }
                        />
                     </div>
                     <div className={styles.row}>
                        <PasswordInput
                           isError={passwordErrors.new_password ? true : false}
                           value={passwordData.new_password}
                           placeholder={t("New password")}
                           onChangeValue={(e) =>
                              handlePasswordInputChange("new_password", e.target.value)
                           }
                        />
                     </div>
                     <div className={styles.row}>
                        <PasswordInput
                           isError={passwordErrors.confirm_new_password ? true : false}
                           value={passwordData.confirm_new_password}
                           placeholder={t("Confirm password")}
                           onChangeValue={(e) =>
                              handlePasswordInputChange("confirm_new_password", e.target.value)
                           }
                        />
                     </div>
                  </div>
               )}

               <CertificateModal
                  onClose={certificateModal.close}
                  isOpen={certificateModal.isActive}
                  certificate={currentCertificate}
               />

               {/* FOR FUTURE */}
               {/* {activeElement?.id === "SOCIAL_MEDIA_TAB && (
                  <div className={cn(styles.block, styles.social_media_block)}>
                     <h3 className={styles.title}>Social media</h3>
                     <p className={styles.subtitle}>Connect your account</p>
                     <SocialMediaList isConnecting />
                  </div>
               )} */}
            </div>

            {activeElement?.id === ACTIVITY_TAB && (
               <div className={styles.activityBlock}>
                 <ActivityTab 
                     relatedToYouTeams={[...relatedToYouTeams, ...yourTeams]}
                     teamsLoading={teamsLoading || yourTeamsLoading} 
                     companies={mappedAgencies}
                     lastAgencyRef={lastAgencyRef}
                     companiesLoading={agencyManager?.isAgenciesLoading}
                     courses={curatorsCourses}
                     cousesLoading={coursesLoading}
                  />
               </div>
            )}


            {activeElement?.id === CERTIFICATES_TAB && (
                  <div className={styles.certificateBlock}>
                    <h2 className={styles.title}>{t("Certificates")}</h2>

                     <CertificatesSubheader
                        certificates={certificates}
                        searchedValue={searchedValue}
                        setSearchedValue={setSearchedValue}
                        activeTagsFilter={tags}
                        setActiveTagsFilter={setTags}
                        activeSortFilter={order}
                        setActiveSortFilter={setOrder}
                        setOrientation={setOrientation}
                        orientation={orientation}
                        allCertificates={allCertificates}
                     />
                     <Certificates 
                        openCertificateModal={openCertificateModal} 
                        certificateLoading={isCertLoading}
                        certificates={certificates} 
                        lastItemRef={lastCertificateRef}
                        orientation={orientation}
                     />
                  </div>
               )}
         </main>
      </MainLayout>
   );
};

export default ProfilePage;
