import React from "react";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import styles from "./styles.module.scss";
import useFindTranslationsByString from "../../hooks/useFindTranslationsByString";

const HalfCircle = ({ progress, width, height, fontSize, color = "#fff" }) => {

   const t = useFindTranslationsByString();

   return (
      <div
         style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: "0",
            bottom: "0",
            left: "0",
            right: "0",
            margin: "auto",
            textAlign: "center",
         }}
         className={styles.wrapper}
      >
         <SemiCircleProgressBar
            percentage={+progress}
            strokeWidth={13}
            background={"rgba(62, 62, 65, 1)"}
            stroke={"rgba(176, 121, 240, 1)"}
         />

         <div className={styles.text}>
            <span>{progress ? Math.round(progress) : 0}%</span>
            <div className={styles.gray}>{t("Integration")}</div>
         </div>
      </div>
   );
};

export default HalfCircle;
