import React, { useEffect, useState } from "react";
import searchImg from "../../../assets/images/symbols/Search.svg";

import cn from "classnames";
import styles from "./styles.module.scss";
import DropdownWithImg from "../../../components/DropdownWithImg/DropdownWithImg";
import useMoveScroll from "../../../hooks/useMoveScroll";
import useUserRole from "../../../hooks/useUserRole";
import { useSelector } from "react-redux";
import { ROLE_ID_SUPER_ADMIN } from "../../../constants/user";

const StatisticsSubheader = ({
   leftText,
   leftSubText,
   searchedValue,
   setSearchedValue,
   companies,
   lastAgencyRef,
   activeFilter,
   setActiveFilter,
   teams,
   lastTeamRef,
   activeTeamFilter,
   setActiveTeamFilter,
   courses,
   activeCourseFilter,
   setActiveCourseFilter,
   isSmall,
   noCompanyFilter,
   tags,
   isCourses,
   activeTagsFilter,
   setActiveTagsFilter,
   noTeam,
   lastAllCourseRef,
   allCoursesSearchedValue,
   setAllCoursesSearchedValue,
   dropdownUserList,
   lastUserInDropdownRef,
   allUsersSearchedValue,
   setAllUsersSearchedValue,
   t
}) => {
   const currentCompanyId = useSelector(state => state.sidebar.companyId);
   const { userRoleId } = useUserRole(currentCompanyId)

   const {
      blockWrapperRef,
      handleMouseDown,
      handleMouseMove,
      handleMouseUp,
      moveLeft,
      moveRight,
      isDragging,
   } = useMoveScroll();

   const handleTagsChange = (item) => {
      if (activeTagsFilter?.find(tag => tag === item)) setActiveTagsFilter(activeTagsFilter?.filter(tag => tag !== item))
      else setActiveTagsFilter([...activeTagsFilter, item])
   }

   const displayedTags = () => {
      if (tags?.length) {
         return (
            <div
               onMouseDown={handleMouseDown}
               onMouseMove={handleMouseMove}
               onMouseUp={handleMouseUp}
               ref={blockWrapperRef}
               className={styles.tags_wrapper}
            >
               <button
                  onClick={() => setActiveTagsFilter([])}
                  className={cn(styles.tag)}
               >
                  {t?.allTags}
               </button>
               {tags?.map((item) => (
                  <button
                     className={cn(
                        styles.tag,
                        activeTagsFilter.includes(item) ? styles.active_tag : ''
                     )}
                     key={item}
                     onClick={() => handleTagsChange(item)}
                  >
                     {item}
                  </button>
               ))}
            </div>
         );
      }
   };

   return (
      <div
         className={cn(
            styles.table_subheader,
            isSmall ? styles.flexed_column : ""
         )}
      >
         <div className={styles.left}>
            <p className={styles.subtitle}>{leftText}</p>
            <p className={styles.gray}>{leftSubText}</p>
         </div>

         <div className={styles.right}>
            <div className={styles.search}>
               <img src={searchImg} alt="" />
               <input
                  placeholder={t?.search}
                  value={searchedValue}
                  onChange={(e) => setSearchedValue(e.target.value.trim())}
               />
            </div>

            {!isCourses && (
               <>
               {userRoleId && userRoleId === ROLE_ID_SUPER_ADMIN && !noCompanyFilter &&
                 <div>
                     <DropdownWithImg
                        data={companies}
                        placeholder={t?.selectCompany}
                        value={activeFilter}
                        onChange={(value) => setActiveFilter(value)}
                        maxFrontHeight={"30px"}
                        searchNeeded
                        lastItemRef={lastAgencyRef}
                     />
                  </div>
               }

               {noCompanyFilter &&
                 <div>
                     <DropdownWithImg
                        data={dropdownUserList}
                        placeholder={t?.selectUser}
                        value={activeFilter}
                        onChange={(value) => setActiveFilter(value)}
                        maxFrontHeight={"30px"}
                        searchNeeded
                        lastItemRef={lastUserInDropdownRef}
                        allCoursesSearchedValue={allUsersSearchedValue}
                        setAllCoursesSearchedValue={setAllUsersSearchedValue}
                     />
                  </div>
               }
                
                  {!isSmall && !noTeam && (
                     <div>
                        <DropdownWithImg
                           data={teams?.teamsList}
                           placeholder={t?.selectTeam}
                           value={activeTeamFilter}
                           onChange={(value) => setActiveTeamFilter(value)}
                           maxFrontHeight={"30px"}
                           searchNeeded
                           setAllCoursesSearchedValue={teams?.setSearchedValue}
                           allCoursesSearchedValue={teams?.searchedValue}
                           lastItemRef={lastTeamRef}
                        />
                     </div>
                  )}
                  {(isSmall || noCompanyFilter) && (
                     <div>
                        <DropdownWithImg
                           lastItemRef={lastAllCourseRef}
                           data={courses}
                           allCoursesSearchedValue={allCoursesSearchedValue}
                           setAllCoursesSearchedValue={setAllCoursesSearchedValue}
                           placeholder={t?.selectCourse}
                           value={activeCourseFilter}
                           onChange={(value) => setActiveCourseFilter(value)}
                           maxFrontHeight={"30px"}
                           searchNeeded
                        />
                     </div>
                  )}
               </>
            )}

            {displayedTags()}
         </div>
      </div>
   );
};

export default StatisticsSubheader;
