import React from "react";
import styles from "./styles.module.scss";
import cn from "classnames";
import {
   PALETTE_IMAGES_TYPE_SECOND,
   TEMPLATE_SECOND_STATIC_TEXT,
   TYPE_SECOND_IMAGE_POSTIONS,
   TYPE_SECOND_IMAGE_SIZE,
} from "../../../constants/courses";
import {
   getGridStylesSecondTemplateType,
   transformCertificateDate,
   transoformedResult,
} from "../../../utils/coursesHelper";

const CertificateTypeSecond = ({
   maxWidth,
   maxHeight,
   data,
   onClick,
   size,
   isActive,
   certificateRef
}) => {
   const {
      course_name,
      company_logo,
      company_name,
      color_palette,
      type,
      language,
      user_name,
      serial_number,
      result,
      end_date,
   } = data;

   const calcResult = +result
      ? transoformedResult(+result, language)
      : TEMPLATE_SECOND_STATIC_TEXT[language]?.result;

   const certificateDate = end_date
      ? transformCertificateDate(end_date, language)
      : TEMPLATE_SECOND_STATIC_TEXT[language]?.date;

   const gridStyles = getGridStylesSecondTemplateType(type, size);

   return (
      <div
         ref={certificateRef}
         className={cn(
            styles.certificate_template,
            onClick ? styles.hoverable : "",
            onClick ? "noselect" : "",
            onClick && isActive ? styles.active_template : "",
            data?.font_family ? styles[data?.font_family] : ""
         )}
         style={{
            maxWidth: maxWidth,
            maxHeight: maxHeight,
            backgroundImage: `url(${
               PALETTE_IMAGES_TYPE_SECOND[+color_palette - 1]
            })`,
            backgroundRepeat: "no-repeat",
            backgroundSize: TYPE_SECOND_IMAGE_SIZE[type],
            backgroundPosition: TYPE_SECOND_IMAGE_POSTIONS[type],
         }}
         onClick={onClick}
      >
         {type === "second_type_5" && (
            <div
               style={{
                  maxWidth: maxWidth,
                  maxHeight: maxHeight,
                  backgroundImage: `url(${
                     PALETTE_IMAGES_TYPE_SECOND[+color_palette - 1]
                  })`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "right 70%",
               }}
               className={styles.one_more_image}
            ></div>
         )}
         <div
            style={{
               ...gridStyles,
            }}
            className={cn(styles.wrapper, styles[`wrapper_${[size]}`])}
         >
            <div
               style={{
                  gridArea: "logoBlock",
               }}
               className={cn(
                  styles.logoBlock,
                  type === "second_type_5" ? styles.centered : ""
               )}
            >
               <img className={styles.logo} src={company_logo} alt="" />
               <span className={styles.companyName}>{company_name}</span>
            </div>
            <div
               style={{
                  gridArea: "courseAndResultsBlock",
               }}
               className={styles.courseAndResultsBlock}
            >
               <div className={styles.courseBlock}>
                  <span className={cn(styles.gray, styles.courseLabel)}>
                     {TEMPLATE_SECOND_STATIC_TEXT[language]?.courseLabel}
                  </span>
                  <span>{course_name}</span>
               </div>
               <div className={styles.resultsBlock}>
                  <span className={cn(styles.gray, styles.courseLabel)}>
                     {TEMPLATE_SECOND_STATIC_TEXT[language]?.resultLabel}
                  </span>
                  <span>
                     {calcResult ||
                        TEMPLATE_SECOND_STATIC_TEXT[language]?.result}
                  </span>
               </div>
            </div>

            <div
               className={cn(
                  styles.certificateBlock,
                  type === "second_type_5" ? styles.centered : ""
               )}
               style={{
                  gridArea: "certificateBlock",
               }}
            >
               <h2 className={styles.certificateLabel}>
                  {TEMPLATE_SECOND_STATIC_TEXT[language]?.certificateLabel}
               </h2>
               <h2 className={cn(styles.ofAchivements, styles.gray_big)}>
                  {TEMPLATE_SECOND_STATIC_TEXT[language]?.ofAchivements}
               </h2>
               <span className={cn(styles.gray, styles.serialNumber)}>
                  {serial_number || "№UD2047895487569854"}
               </span>
               <span className={styles.gray}>{certificateDate}</span>
            </div>
            <div
               className={styles.studentsBlock}
               style={{ gridArea: "studentsBlock" }}
            >
               <span className={cn(styles.gray_big, styles.studentLabel)}>
                  {TEMPLATE_SECOND_STATIC_TEXT[language]?.studentLabel}
               </span>
               <h2>{user_name || "Frances Swann"}</h2>
               <div className={cn(styles.gray, styles.congratsMsg)}>
                  {TEMPLATE_SECOND_STATIC_TEXT[language]?.congratsMsg}
               </div>
            </div>
         </div>
      </div>
   );
};

export default CertificateTypeSecond;
