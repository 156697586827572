import React, { useEffect, useState, useRef } from 'react';
import styles from './styles.module.scss'
import {
    ACCESS_TABS,
    STUDENTS_TAB,
    STUDENTS_TABLE_DATA,
    STUDENT_JUST_FIELDS,
    STUDENT_JUST_HEADERS,
    TEAM_TAB
} from "../../../constants/courses";
import cn from "classnames";
import Dropdown from "../../../components/Dropdown/Dropdown";
import cross from '../../../assets/images/symbols/cross_grey.svg';
import plus from '../../../assets/images/symbols/plus.svg';
import searchImg from '../../../assets/images/symbols/Search.svg';
import csvImg from '../../../assets/images/symbols/scv.svg';
import img from '../../../assets/images/Image_orange.png'
import Button from "../../../components/Button/Button";
import StudentsTable from "../StudentsTable/StudentsTable";
import {CSVLink} from 'react-csv';
import useHandleModal from '../../../hooks/dom/useHandleModal';

import TeamApi from '../../../utils/api/TeamApi';
import { ROLE_ID_STUDENT } from '../../../constants/user';
import UsersPageTable from '../UsersTable/UsersTable';
import SelectUsersAndTeamsModal from '../../../components/Modals/SelectUsersAndTeamsModal/SelectUsersAndTeamsModal';
import { mapUsersWithFilteredRoles, mapUsersWithNewLinks, returnTemporaryLinks } from '../../../utils/usersHelper';
import useFindTranslationsByString from '../../../hooks/useFindTranslationsByString';

const AccessToTheCourseTab = ({
                                  selectedUserList,
                                  setSelectedUserList,
                                  selectedTeamList,
                                  setSelectedTeamList,
                                  userList,
                                  lastModalUserRef,
                                  modalUserSearchedValue,
                                  setModalUserSearchedValue,
                                  modalTeamSearchedValue,
                                  setModaTeamrSearchedValue,
                                  changeAdminAsStudentStatus,
                                  teamList,
                                  courseUserList,
                                  fetchTeams,
                                  course,
                                  refetchData,
                                  loading,
                                  isModalUsersLoading,
                                  changeUserStatus,
                                  deleteUserFromTable,
                                  onGiveAccess,
                                  companyId,
                                  lastCourseUserRef,
                                  courseSearchedValue,
                                  setCourseSearchedValue,
                                  addAccessLoading,
                                  isUsersLoading,
                                  selectAllUsers,
                                  setSelectAllUsers,
                                  selectAllTeams,
                                  setSelectAllTeams,
                                  lastTeamRef,
                                  teamsLoading,
                                  fetchUsersOfCourse,
                                  orderBy,
                                  setOrderBy,
                                  order,
                                  setOrder
                              }) => {
    const [activeTab, setActiveTab] = useState(STUDENTS_TAB);
    const [searchValue, setSearchValue] = useState('');

    const t = useFindTranslationsByString();

    const selectUsersAndTeamsModal = useHandleModal();

    const csvLinkRef = useRef(null);

    const onClickTab = (value) => setActiveTab(value);

    const filterTeamList = (team) => !selectedTeamList?.some(item => item?.id === team?.id) && team?.name;

    const csvData = [
       STUDENT_JUST_HEADERS,
      ...courseUserList?.map(({ team, name, date, position, role, status, department, progress}) => [
         team,
         name,
         department,
         position,
         role,
         date,
         progress,
         status
      ]),
    ];

   useEffect(() => {
      setSearchValue('');
      setSelectedUserList([]);
   }, [activeTab]);

   useEffect(() => {
      setSearchValue('');
      setSelectedUserList([]);
   }, [activeTab]);

    return (
        <div translate="no" className={styles.accessToTheCourseTab}>

            <div className={styles.leftBlock}>
                <div className={styles.search_wrapper}>
                    <div className={styles.search}>
                        <img src={searchImg} alt={''}/>
                        <input
                            placeholder={t('Search')}
                            value={courseSearchedValue}
                            onChange={(e) => setCourseSearchedValue(e.target.value)}/>
                    </div>

               <Button 
                  onClick={()=> fetchUsersOfCourse(1, 1000).then(()=> csvLinkRef.current.link.click())}
                  title={t('Export to CSV')}
                  isBlack image={csvImg} 
                  maxWidth={'220px'}
                  disabled={loading}
               />

               <CSVLink ref={csvLinkRef} style={{ textDecoration: "none" }} data={csvData}/>

               <Button onClick={selectUsersAndTeamsModal?.open} title={t('Add access')} isBlack image={plus} maxWidth={'230px'}/>

                </div>

                  <div className={styles.table_wrapper}>
                     <UsersPageTable
                        key={loading}
                        columns={STUDENT_JUST_FIELDS}
                        data={courseUserList}
                        tableFor={"students"}
                        changeUserStatus={changeUserStatus}
                        deleteUserFromTable={deleteUserFromTable}
                        loading={loading}
                        refetchCourseUsers={refetchData}
                        lastUserRef={lastCourseUserRef}
                        orderBy={orderBy}
                        setOrderBy={setOrderBy}
                        order={order}
                        setOrder={setOrder}
                     />
                  </div>
            </div>

            <SelectUsersAndTeamsModal
                onClose={selectUsersAndTeamsModal.close}
                isOpen={selectUsersAndTeamsModal.isActive}
                setSelectedUsers={setSelectedUserList}
                selectedUsers={selectedUserList}
                searchedValue={modalUserSearchedValue}
                setSearchedValue={setModalUserSearchedValue}
                usersList={userList}
                onConfirm={onGiveAccess}
                teamList={teamList}
                isUsersLoading={isModalUsersLoading || isUsersLoading}
                lastUserRef={lastModalUserRef}
                setSelectedTeamList={setSelectedTeamList}
                selectedTeamList={selectedTeamList}
                changeAdminAsStudentStatus={changeAdminAsStudentStatus}
                selectAllUsers={selectAllUsers}
                setSelectAllUsers={setSelectAllUsers}
                selectAllTeams={selectAllTeams}
                setSelectAllTeams={setSelectAllTeams}
                lastTeamRef={lastTeamRef}
                teamsLoading={teamsLoading}
                teamSearchedValue={modalTeamSearchedValue}
                setTeamSearchedValue={setModaTeamrSearchedValue}
                isCourseAccessPage
            />
            
        </div>
    );
};

export default AccessToTheCourseTab;