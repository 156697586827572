import React, { useEffect, useRef, useState } from "react";
import MainLayout from "../../../layouts/MainLayout/MainLayout";
import KnowledgeCheckAPI from "../../../utils/api/KnowledgeCheckAPI";
import LessonApi from "../../../utils/api/LessonApi";
import { decodeSanitizedContent, decodeSanitizedInputValue, replaceIframesWithLiteYouTube, returnUpdatedContent, sortBySequence } from "../../../utils/coursesHelper";
import { ANSWER_STATUS_CHOSEN_AND_RIGHT, ANSWER_STATUS_CHOSEN_AND_WRONG, ANSWER_STATUS_NOT_CHOSEN_AND_RIGHT, ANSWER_STATUS_NOT_CHOSEN_AND_WRONG, QUESTION_IMAGES, QUESTION_NAMES, TEST_STATUS_APPROVED, TEST_STATUS_ON_REWORK, TEST_STATUS_REJECTED, testQuestionsList } from "../../../constants/tests";
import useAutoScrollFlexed from "../../../hooks/useAutoScrollFlexed";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import AnswersInfoModal from "../../../components/Modals/AnswersInfoModal/AnswersInfoModal";

import RightPanel from "./RightPanel/RightPanel";
import LeftPanel from "./LeftPanel/LeftPanel";
import CenterBlock from "./CenterBlock/CenterBlock";

import cn from "classnames";
import styles from "./styles.module.scss";
import { LESSON_TYPE_WITHOUT_TESTING_NUMBER, MAX_VIDEO_SIZE } from "../../../constants/courses";
import axios from "axios";
import { toast } from "react-toastify";
import ToastMsg from "../../../components/ToastMsg/ToastMsg";
import { COMPARE_ANSWER_CODE, QUESTIONS_THAT_NEED_EXTRA_CREATION, QUESTIONS_THAT_NEED_EXTRA_CREATION_PLUS_COMPARE, QUESTIONS_THAT_NOT_INCLUDED_IN_AUTO_CALCULATION, RATE_ANSWER_CODE, SEQUENCE_ANSWER_CODE, STARS_ANSWER_CODE, TRUE_FALSE_ANSWER_CODE } from "../../../utils/questionsHelper";
import { useParams } from "react-router-dom";

import { debounce } from "lodash";
import { useSelector } from "react-redux";
import EmptyBlock from "../../../components/EmptyBlock/EmptyBlock";
import { ROLE_ID_STUDENT } from "../../../constants/user";
import CourseApi from "../../../utils/api/CourseApi";
import UserApi from "../../../utils/api/UserApi";
import useUserRole from "../../../hooks/useUserRole";
import { HTTPS_REGEX } from "../../../constants/preLinks";
import { TEST_EXTRA_GROUP_ONE } from "../../../utils/api/serialization_groups";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const initialChartData = [
   ["Correct status", "percentage"],
   ["Correct", 0],
   ["Wrong", 5],
   ["Partially correct", 15],
];

const DELAY_BEFORE_SEND_REQUEST = 1000;

const KnowledgeCheck = () => {
   const params = useParams();

   const [isTheory, setIsTheory] = useState(false);
   const [dataLoading, setDataLoading] = useState(true);
   const [isEmodjiOpen, setIsEmodjiOpen] = useState(false);
   const [questionUpdateLoadingId, setQuestionUpdateLoadingId] = useState(null);
   const [chartData, setChartData] = useState(initialChartData);
   const [uploadFileProgress, setUploadFileProgress] = useState(0);
   const [fileName, setFileName] = useState('');

   const t = useFindTranslationsByString();

   const [testData, setTestData] = useState({});
   const [activeTestResult, setActiveTestResult] = useState(null);
   const [questions, setQuestions] = useState([]);

   const [course, setCourse] = useState(null);
   const [lessonContent, setLessonContent] = useState("");
   const [lessonName, setLessonName] = useState("");
   const [commentInputValue, setCommentInputValue] = useState("");

   const emodjiWrapperRef = useRef(null);
   const emodjiRef = useRef(null);
   const answersInfoModal = useHandleModal();

   const companyId = useSelector(state => state.sidebar.companyId);
   const userId = useSelector((state) => state?.user?.info?.id);
   const { userRoleId } = useUserRole(companyId);

   const { activeIndex, refs, scrolledBlockRef, handleClick } =
                           useAutoScrollFlexed(questions, isTheory);


   const openAnswersInfoModal = () => answersInfoModal.open();
   const onCloseEmodji = () => setIsEmodjiOpen(false);

   const setTestResult = async (resultStatus) => {
      const data =   {
         new_status: resultStatus,
         new_success_percentage: +chartData?.[1][1] || 50
      }

      const res = await new KnowledgeCheckAPI().updateTest(testData?.id, data);
      if(res?.success?.message) toast(<ToastMsg text={res?.success?.message} />);
      if(res?.error?.message) toast(<ToastMsg text={res?.error?.message} isError/>);
   }

   const updateCommentTest = async () => {
      if(testData?.commentTests?.[0]?.id) {
         const res = await new KnowledgeCheckAPI().updateTestComment(testData?.commentTests?.[0]?.id, { new_comment: commentInputValue });
      }
   }

   const debouncedUpdateCommentTest = debounce(updateCommentTest, DELAY_BEFORE_SEND_REQUEST); // Debounce for 1000 milliseconds (1 second)

   useEffect(() => {
     debouncedUpdateCommentTest();
 
     return () => {
       debouncedUpdateCommentTest.cancel(); 
     };
   }, [commentInputValue]);

   const handleTestResultClick = (result) => {
      setActiveTestResult(result);
      setTestResult(result)
   };

   const getTest = async () => {
      const res = await new KnowledgeCheckAPI().getOneTest(params?.id, TEST_EXTRA_GROUP_ONE);
      
      setDataLoading(true);

      if(res?.success?.data) {
         const test = res?.success?.data;
         const questions = test?.lesson?.questions;
         // we revese it to find the latest test question.
         const userTestQuestions = [...test?.userTestQuestions].reverse();

         setTestData(test); 

         const mapSequence = (ans, ansIdx, question) => {
            const isCorrect = ans?.answer?.content === question?.sequence?.[ansIdx];
               let status = 0;

               if (isCorrect) {
                  status = ANSWER_STATUS_CHOSEN_AND_RIGHT;
               } else {
                  status = ANSWER_STATUS_CHOSEN_AND_WRONG;
               }

            return  {
               ...ans, 
               content: ans?.answer.content,
               status
            }
         }

         const mapMostAnswers = (question, answer, answerIdx) => {
            const isAnswerChosen = userTestQuestions?.find(testQ => testQ.question?.id === question?.id)?.userAnswers?.some(userAnswer => userAnswer.answer?.id === answer?.id);

            if (question?.type === COMPARE_ANSWER_CODE) {
               const userTestQuestion = userTestQuestions?.find(testQ => testQ.question?.id === question?.id);
               const userAnswer = userTestQuestion?.userAnswers?.find(userAnswer => userAnswer.answer?.id === answer?.id);

               answer.isCorrect = userAnswer?.content === answer?.content;
             }

            const isCorrect = answer?.isCorrect;
        
            let status = 0; // Default status (chosen and right)
        
            if (isAnswerChosen) {
               if (isCorrect) {
                 status = ANSWER_STATUS_CHOSEN_AND_RIGHT;
               } else {
                 status = ANSWER_STATUS_CHOSEN_AND_WRONG;
               }
             } else if (!isAnswerChosen && isCorrect) {
               status = ANSWER_STATUS_NOT_CHOSEN_AND_RIGHT;
             } else if (!isAnswerChosen && !isCorrect) {
               status = ANSWER_STATUS_NOT_CHOSEN_AND_WRONG;
             }
        
            return {
              ...answer,
              content: 
                   QUESTIONS_THAT_NEED_EXTRA_CREATION_PLUS_COMPARE.includes(question?.type)
                     ?
                        (userTestQuestions?.find(testQ => testQ?.question?.id === question?.id)?.userAnswers
                        ?.find(userAnswer => userAnswer?.answer?.id === answer?.id)?.content || "")
                     : answer?.content,
              initialContent: decodeSanitizedInputValue(answer?.content),
              status: status,
            };
         }

         setQuestions(test?.lesson?.questions?.map((question, idx) => ({
                  ...question,
                  name: QUESTION_NAMES[question?.type],
                  image: QUESTION_IMAGES[question?.type],
                  result: userTestQuestions?.find(testQ => testQ.question?.id === question?.id)?.successPercentage || 0,
                  testQuestion: userTestQuestions?.find(testQ => testQ.question?.id === question?.id) || [],
                  ...(question?.type === COMPARE_ANSWER_CODE 
                        ? {answers: question?.answers.sort((a, b) => a?.matchingOption - b?.matchingOption)?.map((answer, answerIdx)=> mapMostAnswers(question, answer, answerIdx))} 
                        : {answers: question?.answers.map((answer, answerIdx)=> mapMostAnswers(question, answer, answerIdx))}
                     ),
                  ...(question?.type === SEQUENCE_ANSWER_CODE 
                     ? 
                     {sequenceAnswers: (userTestQuestions?.find(testQ => testQ.question?.id === question?.id)?.userAnswers || [])?.map((ans, ansIdx) => mapSequence(ans, ansIdx, question))} 
                     : {}),
                  status: question?.type === RATE_ANSWER_CODE || question?.type === STARS_ANSWER_CODE ? ANSWER_STATUS_CHOSEN_AND_RIGHT : userTestQuestions?.find(testQ => testQ.question?.id === question?.id)?.status || 0
               }))
               .map(question => ({
                  ...question,
                  content: decodeSanitizedInputValue(question?.content),
                  description: decodeSanitizedInputValue(question?.description),
                  title: decodeSanitizedInputValue(question?.title),
               }))
            )
      }

      setDataLoading(false);
   };

   const calculatePercentage = (count, total) => ((count / total) * 100);

   const updateChartData = () => {
      const filteredQuestions = questions?.filter(question => question.type !== STARS_ANSWER_CODE && question.type !== RATE_ANSWER_CODE && [0,1,2,3].includes(question?.status));
      const totalQuestionsCount = filteredQuestions.length;

      const resultCounts = questions.reduce(
         (accumulator, question) => {
           const result = question?.status;
           const questionType = question?.type;
       
           if (questionType !== STARS_ANSWER_CODE && questionType !== RATE_ANSWER_CODE && [0,1,2,3].includes(question?.status)) {
             if (result === TEST_STATUS_REJECTED) {
               accumulator.wrong++;
             } else if (result === TEST_STATUS_APPROVED) {
               accumulator.correct++;
             } else if (result === TEST_STATUS_ON_REWORK) {
               accumulator.partially_correct++;
             }
           }
       
           return accumulator;
         },
         { correct: 0, wrong: 0, partially_correct: 0 }
       );

      const newData = [
         ["Correct status", "percentage"],
         ["Correct", Math.round(calculatePercentage(resultCounts.correct, totalQuestionsCount))],
         ["Wrong", calculatePercentage(resultCounts.wrong, totalQuestionsCount)],
         ["Partially correct", calculatePercentage(resultCounts.partially_correct, totalQuestionsCount)],
      ];

      setChartData(newData);
   };

   const handleEmodji = ({ emoji }) => {
      setCommentInputValue(commentInputValue + emoji);
   };

   const createDefaultCommentTest = async() => {
      const res = await new KnowledgeCheckAPI().createTestComment(
         {
            user_test_id: testData?.id,
            comment: "Default text"
          });

      if(res?.success) {
         getTest();
      }
   }

   useEffect(()=> {
     if(testData?.commentTests?.length === 0) createDefaultCommentTest();
   }, [testData])

   useEffect(()=> {
     if(testData?.status) setActiveTestResult(testData?.status);
   }, [testData])

   useEffect(()=> {
    if(testData?.commentTests?.length) {
      setCommentInputValue(testData?.commentTests?.[0]?.comment)
    }
   }, [testData])

   const handleAutoSubmit = async (file, remove) => {
      if (file.size > MAX_VIDEO_SIZE) {
        remove();
        return toast(<ToastMsg text={'File size exceeds the maximum allowed size of 500 MB.'} isError />);
      }

      setFileName(file.name);

      const dataToGetUrl = [{
         comment_test_id: testData?.commentTests?.[0]?.id,
         filename: file.name 
      }]
         
      const res = await new KnowledgeCheckAPI().getPresignedUploadUrls(dataToGetUrl);

      const uploadUrl = res?.success?.data?.[0]?.success?.data?.upload_signed_url;
      const amazonFileUrl = res?.success?.data?.[0]?.success?.data?.file_signed_url;
      const amazonFileName = res?.success?.data?.[0]?.success?.data?.filename;

      const resUpload = await axios({
         method: 'PUT',
         url: uploadUrl,
         headers: {
           "Content-Type": file.type,
         },
         data: file,
         onUploadProgress: (progressEvent) => {
            const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            setUploadFileProgress(`${progress}%`)
            if(progress === 100) setUploadFileProgress('')
          },
       }).catch(err => {
         remove();
         toast(<ToastMsg text={err?.message || 'Something went wrong'} isError />)
       });

       if(resUpload) {
         const data = [
            {
              filename: amazonFileName
            }
          ]
         const addFilesRes = await new KnowledgeCheckAPI().addCommentFiles(testData?.commentTests?.[0]?.id, data);

         if(addFilesRes?.success) {
            toast(<ToastMsg text={'File has been added successfuly'}/>)
         }

         setFileName('');
       }

      remove();
    }

    const attachCommentFile = ({ meta, file, remove }, status, event) => {
      if(status === 'done') {
         handleAutoSubmit(file, remove);
      }
    }

    const addManualResult = (passedQuestion, resultStatus) => {
      setQuestions(prevQuestions => {
        return prevQuestions.map(question => {
          if (question.id === passedQuestion?.id) {
              question.status = resultStatus;
          }

          return question;
        });
      });

      updateUserTestQuestion(passedQuestion?.testQuestion?.id, resultStatus, passedQuestion?.id)
    };

    const updateUserTestQuestion = async (testQuestionId, status, questionId) => {
      setQuestionUpdateLoadingId(questionId);


      const res = await new KnowledgeCheckAPI().updateTestQuestion(testQuestionId, {
         new_status: status,
      });
      
      setQuestionUpdateLoadingId(null);
    }

   useEffect(() => {
      updateChartData();
   }, [questions]);

   useEffect(() => {
      if(questions) {
         const isOnlyAuto = questions.every(q => !QUESTIONS_THAT_NOT_INCLUDED_IN_AUTO_CALCULATION.includes(q.type))

         if(testData?.successPercentage && isOnlyAuto) {
            const newData = [
               ["Correct status", "percentage"],
               ["Correct", Math.round(testData?.successPercentage)],
               ["Wrong", 100 - testData?.successPercentage],
               ["Partially correct", 0],
            ];
      
            setChartData(newData);
         }
      }

   }, [questions]);
  
   useEffect(() => {
      getTest();
   }, []);

   const getOneLesson = async (id) => {
      const res = await new LessonApi().getOneLesson(id);
        if (res?.success?.data) {
           const decodedContent = decodeSanitizedContent(res?.success?.data?.content);
           const replacedWithLite = replaceIframesWithLiteYouTube(decodedContent || t('There are no data'));

           const content = await returnUpdatedContent(replacedWithLite, userId, testData?.lesson?.module?.course?.id);
   
           setLessonContent(content || replacedWithLite);
           setLessonName(res?.success?.data?.name);
        }
    }

   const getLessonsInModule = async (moduleId) => {
      const res = await new LessonApi().getLessonsInModule(moduleId);
      if(res?.success?.data) {
         const sortedLessons = res?.success?.data?.sort(sortBySequence);
         const currentIndex = sortedLessons.findIndex(
            (lesson) => lesson.id === testData?.lesson?.id
          );

          const previousLesson = sortedLessons
            .slice(0, currentIndex)
            .reverse()
            .find((lesson) => lesson.type === LESSON_TYPE_WITHOUT_TESTING_NUMBER);
  
            if(previousLesson?.id) {
                getOneLesson(previousLesson?.id);
            } else {
              setLessonContent(t('There are no data'));
            }
      }
   }

   useEffect(() => {
      if(testData?.lesson?.module?.id && userId) {
         getLessonsInModule(testData?.lesson?.module?.id)
      }
   }, [testData, userId]);

   const getCourse = async (id) => {
      const res = await new CourseApi().getCourseByID(id);
      if(res?.success?.data) {
         setCourse(res?.success?.data)
      }
   }

   useEffect(() => {
      if(testData?.lesson?.module?.course?.id) {
         getCourse(testData?.lesson?.module?.course?.id)
      }
   }, [testData]);

   // Hide the page from students and mentors
   if(userRoleId === ROLE_ID_STUDENT) {
      return <MainLayout><EmptyBlock/></MainLayout> 
   }

   return (
      <MainLayout>
         <div className={styles.knowledge_check_page}>
            <LeftPanel chartData={chartData} testData={testData} />

            <CenterBlock
               refs={refs}
               isTheory={isTheory}
               isEmodjiOpen={isEmodjiOpen}
               lessonContent={lessonContent}
               lessonName={lessonName}
               scrolledBlockRef={scrolledBlockRef}
               testQuestionsList={questions}
               openAnswersInfoModal={openAnswersInfoModal}
               commentInputValue={commentInputValue}
               setCommentInputValue={setCommentInputValue}
               emodjiRef={emodjiRef}
               setIsEmodjiOpen={setIsEmodjiOpen}
               emodjiWrapperRef={emodjiWrapperRef}
               handleEmodji={handleEmodji}
               attachCommentFile={attachCommentFile}
               uploadFileProgress={uploadFileProgress}
               fileName={fileName}
               addManualResult={addManualResult}
               handleTestResultClick={handleTestResultClick}
               activeTestResult={activeTestResult}
               questionUpdateLoadingId={questionUpdateLoadingId}
               dataLoading={dataLoading}
               testData={testData}
            />

            <RightPanel
               testQuestionsList={questions}
               isTheory={isTheory}
               setIsTheory={setIsTheory}
               activeIndex={activeIndex}
               handleClick={handleClick}
            />

            <AnswersInfoModal
               title={t("Types of answers")}
               onClose={answersInfoModal.close}
               isOpen={answersInfoModal.isActive}
            />
         </div>
      </MainLayout>
   );
};

export default KnowledgeCheck;
