import React, { useEffect } from "react";
import avatarPlaceholder from "../../../assets/images/avatar.svg";
import bag from "../../../assets/images/bag.png";
import navIcon from "../../../assets/images/nav.png";

import styles from "./styles.module.scss";
import cn from "classnames";
import CourseApi from "../../../utils/api/CourseApi";
import { useSelector } from "react-redux";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import HalfCircle from "../../../components/HalfCircle/HalfCircle";
import Circle from "../../../components/Circle/Circle";
import PieChartStatistics from "../../../components/PieChartStatistics/PieChartStatistics";
import StatisticsTable from "../StatisticsTable/StatisticsTable";
import StatisticsSubheader from "../StatisticsSubheader/StatisticsSubheader";
import {
   mapStatisticsUserCourses,
   STATISTICS_USER_COURSES_TABLE_HEADERS,
} from "../../../constants/statistics";
import Loader from "../../../components/Loader/Loader";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";


const StatisticsUserContent = ({
   statisticsUser,
   isSideBarFixed,
   userIntegrationPercentage,
   coursePassingInfo,
   userScore,
   coursesTableManager,
   lastUserCourseRef,
   orderBy,
   setOrderBy,
   order,
   setOrder,
   onProgressConfirm,
   progressRange
}) => {
   const companyName = useSelector((state) => state.sidebar.companyName);

   const t = useFindTranslationsByString();

   const mappedCourses = coursesTableManager?.data?.map(
      mapStatisticsUserCourses
   );

   const teamsInString = statisticsUser?.teams
      ?.map((team) => team.name)
      .join(",");

   const chartData = [
      ["Status", "value"],
      ["Complete", coursePassingInfo?.completed],
      ["Ongoing", coursePassingInfo?.ongoing],
      ["Not started", coursePassingInfo?.notStarted || (coursePassingInfo?.completed || coursePassingInfo?.ongoing ? 0 : 1)],
   ];

   return (
      <section className={styles.content}>
         <div className={styles.top_block}>
            <div
               className={cn(
                  styles.top_block__item,
                  styles.user_block,
                  isSideBarFixed ? styles.smaller_block : ""
               )}
            >
               <div className={styles.user_info}>
                  <img
                     src={statisticsUser?.avatar || avatarPlaceholder}
                     alt=""
                  />
                  <div
                     onClick={() =>
                        window.open(
                           `/users/profile/${statisticsUser?.id}`,
                           "_blank"
                        )
                     }
                     className={styles.name}
                  >
                     {statisticsUser?.name}
                  </div>
                  <div className={styles.email}>{statisticsUser?.email}</div>
               </div>
               <div className={styles.user_bottom_wrapper}>
                  <div className={styles.user_bottom_item}>
                     <div className={styles.company_left}>
                        <img src={navIcon} alt="" />
                        <CustomTooltip
                           id={companyName}
                           text={companyName}
                           limit={isSideBarFixed ? 12 : 15}
                        />
                     </div>
                     <div className={styles.gray}>{t("Company")}</div>
                  </div>

                  <div className={styles.user_bottom_item}>
                     <div className={styles.company_left}>
                        <img src={bag} alt="" />
                        <CustomTooltip
                           id={statisticsUser?.id}
                           text={teamsInString || "-"}
                           limit={isSideBarFixed ? 16 : 18}
                        />
                     </div>
                     <div className={styles.gray}>
                        {statisticsUser?.teams?.length > 1 ? t("Teams") : t("Team")}
                     </div>
                  </div>
               </div>
            </div>
            <div
               className={cn(
                  styles.top_block__item,
                  styles.user_block,
                  isSideBarFixed ? styles.smaller_block : ""
               )}
            >
               <div className={styles.block_title}>{t("Integration")}</div>
               <div className={styles.gray}>
                  {t("The ratio of completed courses to those assigned")}
               </div>
               <div className={styles.chart_container}>
                  <HalfCircle progress={userIntegrationPercentage} />
               </div>
            </div>
            <div
               className={cn(
                  styles.top_block__item,
                  styles.course_results_block,
                  isSideBarFixed ? styles.smaller_block : ""
               )}
            >
               <div className={styles.block_title}>{t("User score")}</div>
               <div className={styles.gray}>
                  {t("Average score for completed courses")}
               </div>
               <div className={styles.chart_container_circle}>
                  <PieChartStatistics chartData={chartData} score={userScore} />

                  <div className={styles.full_circle_right}>
                     <div className={styles.full_circle_right_item}>
                        <div className={styles.course_amount}>
                           {coursePassingInfo?.completed || 0} {t("courses")}
                        </div>
                        <div
                           className={cn(
                              styles.gray,
                              styles.course_item,
                              styles.completed
                           )}
                        >
                           {t("Complete")}
                        </div>
                     </div>
                     <div className={styles.full_circle_right_item}>
                        <div className={styles.course_amount}>
                           {coursePassingInfo?.ongoing || 0} {t("courses")}
                        </div>
                        <div
                           className={cn(
                              styles.gray,
                              styles.course_item,
                              styles.ongoing
                           )}
                        >
                           {t("Ongoing")}
                        </div>
                     </div>
                     <div className={styles.full_circle_right_item}>
                        <div className={styles.course_amount}>
                           {coursePassingInfo?.notStarted || 0} {t("courses")}
                        </div>
                        <div
                           className={cn(
                              styles.gray,
                              styles.course_item,
                              styles.not_started
                           )}
                        >
                           {t("Not started")}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div className={styles.table_wrapper}>
             <StatisticsSubheader
                  searchedValue={coursesTableManager?.searchedValue}
                  setSearchedValue={coursesTableManager?.setSearchedValue}
                  leftText={t("Assigned courses")}
                  leftSubText={`${coursesTableManager?.totalCount || 0} ${t("Courses in total")}`}
                  onProgressConfirm={onProgressConfirm}
                  progressRange={progressRange}
             />
               <StatisticsTable
                  data={mappedCourses}
                  columns={STATISTICS_USER_COURSES_TABLE_HEADERS}
                  searchedValue={coursesTableManager?.searchedValue}
                  maxHeight={"15vh"}
                  loading={coursesTableManager?.loading}
                  lastElementRef={lastUserCourseRef}
                  orderBy={orderBy}
                  setOrderBy={setOrderBy}
                  order={order}
                  setOrder={setOrder}
                  rowClickable
               />

         {coursesTableManager?.loading && coursesTableManager?.page === 1 && (
            <div className="default_loader_wrapper">
               <Loader size={"small"} />
            </div>
         )}
         </div>
      </section>
   );
};

export default StatisticsUserContent;
