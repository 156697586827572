import React, { useState } from "react";
import styles from "./styles.module.scss";
import emptyImg from "../../assets/images/empty_illustration.png";

import cn from "classnames";
import { DEFAULT_TEXT, returnTranslation } from "../../constants/languages";
import { useSelector } from "react-redux";

const EmptyBlock = ({ text, left, imgSmaller }) => {
   const translations = useSelector((state) => state.sidebar.translations);

   return (
      <div
         className={cn(
            styles.no_entity_block,
            imgSmaller ? styles.img_smaller : "",
            left ? styles.left : ""
         )}
      >
         <img src={emptyImg} alt={""} />
         <span>
            {returnTranslation(
               translations,
               text ? text : returnTranslation(translations, DEFAULT_TEXT?.common?.thereAreNoData)
            )}
         </span>
      </div>
   );
};

export default EmptyBlock;
