import React, { useEffect, useRef, useState } from "react";
import hat from "../../../assets/images/symbols/hat.svg";
import hatWhite from "../../../assets/images/symbols/hat_white.svg";
import dots from "../../../assets/images/symbols/three_dots.svg";
import dotsWhite from "../../../assets/images/symbols/three_dots_white.svg";
import plus from "../../../assets/images/symbols/plus.svg";
import empty_illustration from "../../../assets/images/empty_illustration.svg";

import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ModuleApi from "../../../utils/api/ModuleApi";
import LessonApi from "../../../utils/api/LessonApi";
import useOutsideClick from "../../../hooks/dom/useOutsideClick";
import Input from "../../../components/Input/Input";
import LessonItem from "../LessonItem/LessonItem";
import ToastMsg from "../../../components/ToastMsg/ToastMsg";
import {
   LESSON_TYPE_WITHOUT_TESTING_NUMBER,
   LESSON_TYPE_WITH_TESTING_NUMBER,
} from "../../../constants/courses";

import cn from "classnames";
import styles from "./styles.module.scss";
import { Draggable, Droppable } from "react-beautiful-dnd";
import DraggingArrows from "../../../components/DraggingArrows/DraggingArrows";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import ConfirmModal from "../../../components/Modals/ConfirmModal/ConfirmModal";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import Loader  from "../../../components/Loader/Loader";
import CourseApi from "../../../utils/api/CourseApi";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const ModuleItem = ({
   module,
   activeModule,
   index,
   onClickModule,
   activeElement,
   modules,
   defaultModules,
   onClickElement,
   fetchData,
   questionsDataFromInputs,
   isModuleDragging,
   questions,
   isDeleteMode,
   onSelectFilters,
   selectedLessons,
   openIsDeleteMode,
   editLanguage,
   course,
   modulesLoading,
   defaultCourseLanguage,
   fetchDefaultModulesData
}) => {

   const params = useParams();

   const t = useFindTranslationsByString();

   const confirmModal = useHandleModal();
   const moduleDotsRef = useRef(null);
   const moduleDropdownRef = useRef(null);
   const [isModuleOptionsOpen, setIsModuleOptionsOpen] = useState(false);
   const [createLoading, setCreateLoading] = useState(false);

   const [elementName, setElementName] = useState("");
   const [testName, setTestName] = useState("");
   const [moduleNewName, setModuleNewName] = useState(module?.name || "");

   useEffect(()=> {
      setModuleNewName(module?.name);
   }, [module])

   const [isAddElement, setIsAddElement] = useState(false); // lesson
   const [isAddElementTest, setIsAddElementTest] = useState(false); // test

   const [isRenameModule, setIsRenameModule] = useState(false);

   const onCloseModuleOptions = () => setIsModuleOptionsOpen(false);

   const onToggleModuleOptions = () => setIsModuleOptionsOpen(!isModuleOptionsOpen);

   useOutsideClick(moduleDropdownRef, onCloseModuleOptions, moduleDotsRef);

   const deleteModule = async () => {
      try {
         const res = await new ModuleApi().deleteModule(activeModule);

         if (res?.success) {
            fetchData(editLanguage?.code);
            onClickModule(0);
            onClickElement(null);
            new CourseApi().updateUsersProgressInCourse(params?.id);
         }
         if (res?.error) {
            return toast(<ToastMsg text={res?.error?.message} isError />);
         }
      } catch (error) {
         console.error("Error:", error);
      }
   };

   const updateModule = async (id) => {
      if(moduleNewName.length === 0 || moduleNewName === module?.name) return;

      const newModule = {
         new_name: moduleNewName.trim(),
         new_language: editLanguage?.code
      };

      try {
         const res = await new ModuleApi().updateModule(id, newModule);
         if (res?.success) {
            setIsRenameModule(false);
            fetchData(editLanguage?.code);
            fetchDefaultModulesData();
            return toast(<ToastMsg text={res?.success?.message} />);
         }
         if (res?.error) {
            setModuleNewName('');
            return toast(<ToastMsg text={res?.error?.message} isError />);
         }
      } catch (error) {
         console.error("Error:", error);
      }
   };

   const moduleNameChange = () => {
      setIsRenameModule(false);
      updateModule(module?.id)
   }

   const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
         moduleNameChange();
      }
    };

    const handleKeyDownCreateLesson = (event) => {
      if (event.key === 'Enter') {
        if (isAddElement) {
          createLesson(module.id, LESSON_TYPE_WITHOUT_TESTING_NUMBER, elementName);
          setIsAddElement(false);
          setElementName('');
        } else if (isAddElementTest) {
          createLesson(module.id, LESSON_TYPE_WITH_TESTING_NUMBER, testName);
          setIsAddElementTest(false);
          setTestName('');
        }
      }
    };

   const showChangeNameInput = () => {
      setIsRenameModule(true);
   }

   const createLesson = async (moduleID, type, lessonName) => {
      // type 1 === test
      // type 0 === lesson
      if (!params?.id) return;

      setCreateLoading(true);

      const lessonData = {
         name: `${lessonName.trim()}${editLanguage && editLanguage.code !== course.defaultLanguage ? '*' : ''}`,
         module_id: moduleID,
         type: type,
         content: 'Initial content',
         default_language: defaultCourseLanguage
      };

     const res = await new LessonApi().createLesson(lessonData);
       
      setElementName("");
      setTestName("")

      if (res?.success?.data) {
         if(editLanguage?.code !== course?.defaultLanguage) {
            const resUpdate = await new LessonApi().updateLesson(res?.success?.data?.lesson?.id, {
                new_name: lessonName,
                new_language: editLanguage?.code
             });
         }

        fetchDefaultModulesData();
        fetchData(editLanguage?.code).then(()=> setCreateLoading(false));

        if(type === LESSON_TYPE_WITHOUT_TESTING_NUMBER) {
         return new CourseApi().updateUsersProgressInCourse(params?.id);
        }
      }

      if (res?.error) {
         setCreateLoading(false)
         return toast(<ToastMsg text={res?.error?.message} isError />);
      }
   };

   const cancelLessonCreation = () => {
      setIsAddElement(false);
      setIsAddElementTest(false);
      setTestName('');
      setElementName('');
   }

   useEffect(() => {
      if(!activeModule) onClickModule(modules[0]?.id);
   }, []);

   return (
   <Droppable droppableId={`${module?.id}`}>
      {(provided, snapshot) => (
      <div {...provided.droppableProps} ref={provided.innerRef}>
         <div
            onClick={() => onClickModule(module?.id)}
            className={cn(
               styles.list_item,
               activeModule === module?.id ? styles.list_item_active : '',
               snapshot.isDraggingOver ? styles.overBlock  : ''
            )}
            key={module?.id}
         >  
            <div
               className={cn(
                  styles.list_item_header,
                  activeModule === module?.id ? styles.list_item_header_active : ''
               )}
            >
                  <span className={cn(
                     styles.list_item_header_title,
                     module?.name === defaultModules?.[index]?.name && editLanguage?.code !== course?.defaultLanguage ? styles.prevInput : ''
                  )}>
                     <DraggingArrows isDragging={isModuleDragging}/>
                     <div className={styles.module_number}>
                        {index + 1}. 
                     </div>
                     <span className={styles.module_name} onDoubleClick={showChangeNameInput}>
                        <CustomTooltip 
                           limit={11} 
                           delay={500}
                           position="bottom"
                           text={moduleNewName?.length ? moduleNewName : module?.name} 
                           id={`module_name_${module?.id}`} 
                        />
                     </span>
                  </span>
                  {isRenameModule && 
                        <input
                           className={styles.module_name_input}
                           value={moduleNewName}
                           onBlur={moduleNameChange}
                           onKeyDown={handleKeyDown}
                           onChange={(e) => setModuleNewName(e.target.value)}
                           autoFocus
                        />
                     }
               <div className={styles.dots_container}>
                  <img
                     src={activeModule === module?.id ? hatWhite : hat}
                     alt={""}
                  />
                  <span>{module.lessonsCount ? module.lessonsCount : 0}</span>
                  <div className={styles.dots_wrapper}>
                     <img
                        ref={moduleDotsRef}
                        onClick={onToggleModuleOptions}
                        src={dots}
                        alt={""}
                     />

                     {isModuleOptionsOpen && (
                        <div ref={moduleDropdownRef} className={styles.dropdown}>
                           <p
                              className={styles.options}
                              onClick={() => {
                                 confirmModal.open();
                                 onCloseModuleOptions();
                              }}
                           >
                              {t("Delete")}
                           </p>
                           <p
                              className={styles.options}
                              onClick={() => {
                                 setIsRenameModule(!isRenameModule);
                                 onCloseModuleOptions();
                              }}
                           >
                              {t("Rename")}
                           </p>
                           {!isDeleteMode &&
                              <p
                              className={styles.options}
                              onClick={(e) => {
                                 openIsDeleteMode();
                                 onCloseModuleOptions();
                              }}
                           >
                              {t("Select to delete")}
                           </p>
                           }
                        </div>
                     )}
                  </div>
               </div>
            </div>

            {activeModule === module?.id && (
               <div className={styles.elements}>
               {!module?.lessons?.length && 
               <div className={styles.empty_wrapper}>
                  <img src={empty_illustration} alt="" />
                  <p>{t("No lessons yet, please add one!")}</p>
               </div>
               }
            
                  {module?.lessons?.map((lesson, lessonIndex) => (
                     <Draggable
                        key={lesson.id}
                        draggableId={`${lesson.id}`}
                        index={lessonIndex}
                     >
                        {(provided, snapshot) => (
                        <div
                           className={styles.draggable_element}
                           ref={provided.innerRef}
                           {...provided.draggableProps}
                           {...provided.dragHandleProps}
                        >
                           <LessonItem
                              key={lesson?.id}
                              defaultCourseLanguage={defaultCourseLanguage}
                              lessonIndex={lessonIndex}
                              moduleIndex={index}
                              onClickElement={onClickElement}
                              activeElement={activeElement}
                              lesson={lesson}
                              defaultLessons={defaultModules[index]?.lessons}
                              isLessonDragging={snapshot.isDragging}
                              moduleId={module?.id}
                              fetchData={fetchData}
                              questions={questions}
                              questionsDataFromInputs={questionsDataFromInputs}
                              isDeleteMode={isDeleteMode}
                              onSelectFilters={onSelectFilters}
                              selectedLessons={selectedLessons}
                              openIsDeleteMode={openIsDeleteMode}
                              editLanguage={editLanguage}
                              course={course}
                           />
                        </div>
                        )}
                     </Draggable>
                  ))}
                  {provided.placeholder}
                  

                  {isAddElement && (
                     <Input
                        placeholder={t("Lesson name:")}
                        value={elementName}
                        onChangeValue={(e) => setElementName(e.target.value)}
                        onKeyDown={handleKeyDownCreateLesson}
                        autoFocus
                     />
                  )}
                  {isAddElementTest && (
                     <Input
                        placeholder={t("Test name")}
                        value={testName}
                        onChangeValue={(e) => setTestName(e.target.value)}
                        onKeyDown={handleKeyDownCreateLesson}
                        autoFocus
                     />
                  )}

            <div className={cn(
               styles.create_lesson_btns,
            )}>
               {!isAddElementTest && 
                  <div
                     className={cn(
                           styles.add, 
                           isAddElement ? styles.confirm_lesson_creation : ''
                        )}
                     onClick={() => {
                        setIsAddElement(!isAddElement);
                        if (isAddElement) {
                           createLesson(
                              module.id,
                              LESSON_TYPE_WITHOUT_TESTING_NUMBER,
                              elementName
                           );
                        }
                     }}
                  >
                     {!isAddElement ? (
                        <>
                           <img src={plus} alt={""} />
                           {t("Lesson")}
                        </>
                     ) : (
                        <span>{t("Confirm")}</span>
                     )}
                  </div>
               }

                  {isAddElementTest && 
                        <div
                           className={cn(
                                 styles.add,
                                 styles.cancel_lesson_creation,
                              )}
                           onClick={cancelLessonCreation}
                        >
                           {t("Cancel")}
                        </div>
                  }

                  {!isAddElement && 
                     <div
                        className={cn(
                              styles.add,
                              isAddElementTest ? styles.changed_flex_order : '',
                              isAddElementTest ? styles.confirm_lesson_creation : ''
                           )}
                        onClick={() => {
                           setIsAddElementTest(!isAddElementTest);
                           if (isAddElementTest) {
                              createLesson(
                                 module.id,
                                 LESSON_TYPE_WITH_TESTING_NUMBER,
                                 testName
                              );
                           }
                        }}
                     >
                        {!isAddElementTest ? (
                           <>
                              <img src={plus} alt={""} />
                              {t("Task")}
                           </>
                        ) : (
                           <span>{t("Confirm")}</span>
                        )}
                     </div>
                  }

                  { createLoading && 
                        <div className={styles.create_loader}>
                           <Loader size="small"/>
                        </div> 
                     }
                  
                  
                  {isAddElement && 
                        <div
                           className={cn(
                                 styles.add,
                                 styles.cancel_lesson_creation,
                              )}
                           onClick={cancelLessonCreation}
                        >
                           {t("Cancel")}
                        </div>
                  }
                  
                  </div>

               <ConfirmModal
                  isRemove
                  onConfirm={deleteModule}
                  onClose={confirmModal.close}
                  isOpen={confirmModal.isActive}
                  title={t("Are you sure?")}
                  subtitle={t("It will be deleted from all existed language versions of the course")}
               />
               </div>
            )}
         </div>
      </div>
      )}
   </Droppable>
   );
};

export default React.memo(ModuleItem);
