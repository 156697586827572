import React, { useState, useEffect } from 'react';
import AuthLayout from "../../layouts/AuthLayout/AuthLayout";
import styles from './styles.module.scss'
import authorizationBackgroundPerson from '../../assets/images/authorization-background-person.svg';
import Login from "../../components/Login";
import ForgetPassword from "../../components/ForgetPassword";
import { localStorageRemove } from "../../utils/localStorage";

export default function SignUpPage() {
    const [isForgetPassword, setIsForgetPassword] = useState(false);

    const onForgetPassword = () => setIsForgetPassword(true);
    const onLogin = () => setIsForgetPassword(false);

    useEffect(()=> {
      localStorageRemove('impersonateId');
    }, [])

    return (
        <AuthLayout>
            <main>
                <section className={styles.authorization_section}>
                    <div className={styles.authorization_section__container}>

                        {
                            isForgetPassword
                                ? <ForgetPassword onLogin={onLogin}/>
                                : <Login onForgetPassword={onForgetPassword}/>
                        }

                        <div className={styles.authorization_block__image_wrap}>
                            <img src={authorizationBackgroundPerson} width="460" height="539" alt="Person"/>
                        </div>
                    </div>
                </section>
            </main>
        </AuthLayout>
    );
};
;