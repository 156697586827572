import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import styles from "./styles.module.scss";
import Button from "../../Button/Button";
import Loader from "../../Loader/Loader";
import Input from "../../Input/Input";
import cn from 'classnames';
import Dropdown from "../../Dropdown/Dropdown";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const ChangeCourseLanguageModal = ({
   onClose,
   onConfirm,
   isOpen,
   title,
   subtitle,
   confirmButtonText,
   isLoading,
   dontShowLoader,
   maxHeight = "400px",
   maxWidth = "426px",
   icon,
   inputLabel,
   inputValue,
   setInputValue,
   isPrevInput,
   isError,
   isDropdown,
   languages,
   selectedItem,
   onSelect
}) => {

   const t = useFindTranslationsByString();

   return (
      <ModalLayout
         onClose={onClose}
         isOpen={isOpen}
         maxHeight={maxHeight}
         maxWidth={maxWidth}
         customPadding={'45px'}
         withCross
      >
         <div className={styles.confirm_wrapper}>
            <div className={styles.title}>
               {title}
               {subtitle && <div className={styles.info_msg}>{subtitle}</div>}
            </div>

            {isDropdown &&
              <Dropdown
                  data={languages}
                  field={'name'}
                  placeholder={t("Choose language")}
                  value={selectedItem.name}
                  onChange={onSelect}
                  maxHeight={'110px'}
               />
            }

           {!isDropdown &&
               <div className={cn(styles.input, isPrevInput ? styles.prevInput : '', isError ? styles.error : '')}>
                  <Input
                     placeholder={`${t("Title")}...`}
                     max={70}
                     withHeader
                     autoFocus
                     title={inputLabel}
                     value={inputValue}
                     onChangeValue={(event) => setInputValue(event?.target?.value )}
                  />
               </div>
            }

            <div className={styles.btns}>
               <Button
                  disabled={isLoading}
                  title={confirmButtonText ? confirmButtonText : t("Confirm")}
                  onClick={onConfirm}
               />
            </div>

            <div className="default_loader_wrapper">
               {isLoading && !dontShowLoader && <Loader size={"small"} />}
            </div>
         </div>
      </ModalLayout>
   );
};

export default ChangeCourseLanguageModal;
