import React, { useCallback, useEffect, useRef, useState } from "react";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import courseImg from "../../assets/images/course_thumbnail.png";
import cn from "classnames";
import shortLogo from "../../assets/images/favicon.png";
import styles from "./styles.module.scss";
import LineChart from "../../components/LineChart/LineChart";
import {
   CERTIFICATES_TABLE_HEADERS, COURSES_TABLE_HEADERS, DATE_TAB_DAYLY, DEFAULT_DEBOUNCE_DELAY,
   INTEGRATION_TABLE_HEADERS,
   QUESTIONS_TABLE_HEADERS, RATING_TABLE_HEADERS, STATISTICS_USERS_TABLE_HEADERS, STAT_TAB_COMPANY,
   STAT_TAB_COURSES, STAT_TAB_SCORE, STAT_TAB_INTEGRUM, STAT_TAB_PLATFORM, STAT_TAB_USERS, TESTS_TABLE_HEADERS,
   certificatesMap, companiesMap, integrationMap, questionsMap, statisticsUsersMap, testsMap } from "../../constants/statistics";
import AgencyApi from "../../utils/api/AgencyApi";
import StatisticsTable from "./StatisticsTable/StatisticsTable";
import StatisticsSubheader from "./StatisticsSubheader/StatisticsSubheader";
import StatisticsTabs from "./StatisticsTabs/StatisticsTabs";
import TeamApi from "../../utils/api/TeamApi";
import CourseApi from "../../utils/api/CourseApi";
import StatisticsApi from "../../utils/api/StatisticsApi";
import SecondLoader from "../../components/SecondLoader/SecondLoader";
import moment from "moment";
import { decodeSanitizedContent, decodeSanitizedInputValue, mapCoursesWithNewLinks, returnTemporaryCoursesLinks } from "../../utils/coursesHelper";
import debounce from 'lodash/debounce';
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import Loader from "../../components/Loader/Loader";
import { useSelector } from "react-redux";
import { mapAgenciesWithNewLinks, returnTemporaryCompaniesLinks } from "../../utils/usersHelper";
import useGetYourCoursesInCompany from "../../hooks/api/useGetYourCoursesInCompany";
import useGetAgencies from "../../hooks/api/useGetAgencies";
import useGetAllTeams from "../../hooks/api/useGetAllTeams";
import { ROLE_ID_SUPER_ADMIN } from "../../constants/user";
import useUserRole from "../../hooks/useUserRole";
import useGetAllUsersOfCompany from "../../hooks/api/useGetAllUsersOfCompany";
import useFindTranslations from "../../hooks/useFindTranlsations";

const initialFiltersData = {
   leftChartCompanyFilter: null,
   testsCompanyFilter: null,
   questionsCompanyFilter: null,
   ratingCompanyFilter: null,
   certificateCompanyFilter: null,
   integrationCompanyFilter: null,
}

const initialTeamFiltersData = {
   testsTeamFilter: null,
   questionsTeamFilter: null,
   ratingTeamFilter: null,
   certificateTeamFilter: null,
   integrationTeamFilter: null
}

const initialCourseFiltersData = {
   testsCourseFilter: null,
   questionsCourseFilter: null,
}
const initialTotalCount = {
   questions: 0,
   tests: 0,
   companies: 0,
   certificates: 0
}

const StatisticsPage = () => {

  const companyID = useSelector((state) => state.sidebar.companyId);
  const userId = useSelector(state => state?.user?.info?.id);
  const { userRoleId } = useUserRole(companyID);

  const t = useFindTranslations();

  const [questionsLoading, setQuestionsLoading] = useState(false);
  const [questionsPage, setQuestionsPage] = useState(1);
  const [tableTotalCount, setTableTotalCount] = useState(initialTotalCount);
  
  const [testsLoading, setTestsLoading] = useState(false);
  const [testsPage, setTestsPage] = useState(1);

  const [certificatesLoading, setCertificatesLoading] = useState(false);
  const [certificatesPage, setCertificatesPage] = useState(1);

  const [companyRatingLoading, setCompanyRatingLoading] = useState(false);
  const [companyRatingPage, setCompanyRatingPage] = useState(1);

   // Main tabs
   const [activeTab, setActiveTab] = useState(STAT_TAB_PLATFORM);
   const [graphLoading, setGraphLoading] = useState(true);

   // Charts tabs
   const [activeTabLeft, setActiveTabLeft] = useState(DATE_TAB_DAYLY);
   const [activeTabRight, setActiveTabRight] = useState(DATE_TAB_DAYLY);

   const [coursesStatisticsData, setCoursesStatisticsData] = useState([]);
   const [testsStatisticsData, setTestsStatisticsData] = useState([]);
   const [questionsStatisticsData, setQuestionsStatisticsData] = useState([]);
   const [certificatesStatisticsData, setCertificatesStatisticsData] = useState([]);
   const [companiesStatisticsData, setCompaniesStatisticsData] = useState([]);

   const [usersOnlineData, setUsersOnlineData] = useState({});
   const [usersRegistrationData, setUsersRegistrationData] = useState({});
   const [registrationDataTotal, setRegistrationDataTotal] = useState({});

   const [activeFilters, setActiveFilters] = useState(initialFiltersData);
   const [activeTeamFilters, setActiveTeamFilters] = useState(initialTeamFiltersData);
   const [activeCourseFilters, setActiveCourseFilters] = useState(initialCourseFiltersData);
   const [activeTagsFilter, setActiveTagsFilter] = useState([]);

   // Search
   const [certificatesSearchedValue, setCertificatesSearchedValue] =
      useState("");
   const [ratingSearchedValue, setRatingSearchedValue] = useState("");
   const [coursesSearchedValue, setCoursesSearchedValue] = useState("");
   const [testsSearchedValue, setTestsSearchedValue] = useState("");
   const [questionsSearchedValue, setQuestionsSearchedValue] = useState("");

   // Integration
   const [integrationLoading, setIntegrationLoading] = useState(false);
   const [integrationPage, setIntegrationPage] = useState(1);
   const [integrationData, setIntegrationData] = useState([]);
   const [integrationSearchedValue, setIntegrationSearchedValue] = useState('');

   // Users tab
   const [statisitcsUsersLoading, setStatisitcsUsersLoading] = useState(false);
   const [statisitcsUsersPage, setStatisitcsUsersPage] = useState(1);
   const [statisitcsUsersData, setStatisitcsUsersData] = useState([]);
   const [statisitcsUsersSearchedValue, setStatisitcsUsersSearchedValue] = useState('');

   const { setCurrentPage: setCurrentDropUserPage, totalUsers, 
      userList: dropdownUserList, 
      isUsersLoading: isDropdownUserLoading, 
      searchedValue: dropdownUserSearchedValue, 
      setSearchedValue: setDropdownUserSearchedValue 
   } = useGetAllUsersOfCompany(companyID);

   const lastUserInDropdownRef = useIntersectionObserver(
      () => {
      if (!isDropdownUserLoading && dropdownUserList?.length < totalUsers) {
         if(dropdownUserList?.length === 0) {
            setCurrentDropUserPage(1);
         } else {
            setCurrentDropUserPage((prev) => prev + 1);
         }
      }
      },
      [isDropdownUserLoading, totalUsers]
      );

   const onClickOnTab = (tab) => {
      setActiveTab(tab?.label);
   };

   const clickActiveTabLeft = (tab) => {
      setActiveTabLeft(tab?.label);
   };

   const clickActiveTabRight = (tab) => {
      setActiveTabRight(tab?.label);
   };

   const agencyManager = useGetAgencies(userId);

   const lastAgencyRef = useIntersectionObserver(
      () => {
        if (!agencyManager.isAgenciesLoading && agencyManager.agenciesList?.length < agencyManager.totalAgencies) {
          if(agencyManager.agenciesList?.length === 0) {
            agencyManager.setCurrentPage(1);
          } else {
            agencyManager.setCurrentPage((prev) => prev + 1);
          }
        }
      },
      [agencyManager.isAgenciesLoading, agencyManager.totalAgencies]
    );

   const allTeamsManager = useGetAllTeams();

   const lastTeamRef = useIntersectionObserver(
      () => {
        if (!allTeamsManager.isTeamsLoading && allTeamsManager.teamsList?.length < allTeamsManager.totalTeams) {
          if(allTeamsManager.teamsList?.length === 0) {
            allTeamsManager.setCurrentPage(1);
          } else {
            allTeamsManager.setCurrentPage((prev) => prev + 1);
          }
        }
      },
      [allTeamsManager.isTeamsLoading, allTeamsManager.totalTeams]
    );

   const tranformCourseImage = (course) => course?.image && !course.image.includes('static/media/') ? course?.image
      : courseImg;

    //  all available to your courses
    const { courseList: allCoursesList, isCoursesLoading: isAllCoursesListLoading, refetchCourses: refetchAllCourses, totalCourses: allCoursesTortal, setCurrentPage: setAllCoursesPage, searchedValue: allCoursesSearchedValue, setSearchedValue: setAllCoursesSearchedValue } = useGetYourCoursesInCompany(companyID);
 
    const lastAllCourseRef = useIntersectionObserver(
       () => {
         if (!isAllCoursesListLoading && allCoursesList?.length < allCoursesTortal) {
           if(allCoursesList?.length === 0) {
            setAllCoursesPage(1);
           } else {
            setAllCoursesPage((prev) => prev + 1);
           }
         }
       },
       [isAllCoursesListLoading, allCoursesTortal]
     );

   const fetchUsersOnlineData = async() => {
      const res = await new StatisticsApi().getAllUsersOnline();
      if(res?.success?.data) {
         setUsersOnlineData(res?.success?.data);
      }
   }

   const fetchUsersRegistrationData = async() => {
      setGraphLoading(true);
      const res = await new StatisticsApi().getAllUsersGrowth();
      if(res?.success?.data) {
         setUsersRegistrationData(res?.success?.data);
      }
      setGraphLoading(false);
   }

   const fetchCoursesData = async() => {
      const res = await new StatisticsApi().getCoursesStatistics(companyID);
      if(res?.success?.data?.course) {
         setCoursesStatisticsData(res?.success?.data?.course?.map(course => ({
            rating: course?.averageRating || '-',
            course_score: Math.round(course?.courseScore) || '-',
            course_name: course?.courseName,
            created: moment(course?.createdAt).format('YYYY-DD-MM HH:mm') || '-',
            last_modified: moment(course?.updatedAt).format('YYYY-DD-MM HH:mm') || '-',
            available: course?.usersAccessCount || 0,
            company: course?.companyName,
            rating_from_users: 0,
            tags: course?.tags || null,
            image: course?.courseImageUrl || ''
         })));
      }
   }

   const fetchTestsData = async(page) => {
      setTestsLoading(true);

      const res = await new StatisticsApi().getTestsStatistics(page ? page : testsPage, testsSearchedValue, activeFilters?.testsCompanyFilter?.id, activeCourseFilters?.testsCourseFilter?.id);
      if(res?.success?.data?.lesson) {
         const data = res?.success?.data?.lesson;

         if (page) {
            setTestsStatisticsData(data);
         } else {
            setTestsStatisticsData((prev) => [...prev, ...data]);
         }
         
         setTableTotalCount((prev)=> ({
            ...prev,
            tests: res?.success?.totalCount
         }))
      }

      if(res?.success?.data?.lesson?.length === 0) {
         setTestsPage(1);
      }

      if (res?.error?.message && page) {
         setTestsStatisticsData([]);
      }

     setTestsLoading(false);
   }

   const lastTestRef = useIntersectionObserver(
      () => {
        if (!testsLoading && testsStatisticsData?.length < tableTotalCount?.tests) {
          if(testsStatisticsData?.length === 0) {
            setTestsPage(1);
          } else {
            setTestsPage((prev) => prev + 1);
          }
        }
      },
      [testsLoading, tableTotalCount?.tests]
    );

   useEffect(()=> {
      if(testsPage > 1 && testsStatisticsData?.length <= tableTotalCount?.tests) {
         fetchTestsData();
      }
   }, [testsPage])

   const debouncedFetchTestsData = debounce(()=> fetchTestsData(1), DEFAULT_DEBOUNCE_DELAY); 

   useEffect(()=> {
      setTestsPage(1);
      debouncedFetchTestsData();
      return () => debouncedFetchTestsData.cancel();
   }, [testsSearchedValue, activeFilters?.testsCompanyFilter, activeCourseFilters?.testsCourseFilter])

   const fetchStatisticsUsersData = async(page) => {
      setStatisitcsUsersLoading(true);

      const res = await new StatisticsApi().getUsersStatistics(page ? page : statisitcsUsersPage, statisitcsUsersSearchedValue, companyID,activeCourseFilters?.usersCourseFilter?.id, activeTeamFilters?.usersTeamFilter?.id, activeFilters?.usersTabUser?.id);

      if(res?.success?.data?.user) {
         const data = res?.success?.data?.user;

         if (page) {
            setStatisitcsUsersData(data);
         } else {
            setStatisitcsUsersData((prev) => [...prev, ...data]);
         }
         
         setTableTotalCount((prev)=> ({
            ...prev,
            users: res?.success?.totalCount
         }))
      }

      if(res?.success?.data?.users?.length === 0) {
         setStatisitcsUsersPage(1);
      }

      if (res?.error?.message && page) {
         setStatisitcsUsersData([]);
      }

     setStatisitcsUsersLoading(false);
   }

   const lastStatisticsUserRef = useIntersectionObserver(
      () => {
        if (!statisitcsUsersLoading && statisitcsUsersData?.length < tableTotalCount?.users) {
          if(statisitcsUsersData?.length === 0) {
            setStatisitcsUsersPage(1);
          } else {
            setStatisitcsUsersPage((prev) => prev + 1);
          }
        }
      },
      [statisitcsUsersLoading, tableTotalCount?.users]
    );
    
   useEffect(()=> {
      if(statisitcsUsersPage > 1 && statisitcsUsersData?.length <= tableTotalCount?.users) {
         fetchStatisticsUsersData();
      }
   }, [statisitcsUsersPage])

   const debouncedFetchStatUsersData = debounce(()=> fetchStatisticsUsersData(1), DEFAULT_DEBOUNCE_DELAY); 

   useEffect(()=> {
      if(!companyID) return;

      setStatisitcsUsersPage(1);
      debouncedFetchStatUsersData();
      return () => debouncedFetchStatUsersData.cancel();
   }, [statisitcsUsersSearchedValue, activeCourseFilters?.usersCourseFilter, activeTeamFilters?.usersTeamFilter, activeFilters?.usersTabUser?.id])

   const fetchQuestionsData = async(page) => {
      setQuestionsLoading(true);

      const res = await new StatisticsApi().getQuestionsStatistics(page ? page : questionsPage, questionsSearchedValue, activeFilters?.questionsCompanyFilter?.id, activeCourseFilters?.questionsCourseFilter?.id);
      
      if(res?.success?.data?.question) {
         const data = res?.success?.data?.question;
         
         if (page) {
            setQuestionsStatisticsData(data);
         } else {
            setQuestionsStatisticsData((prev) => [...prev, ...data]);
         }

         setTableTotalCount((prev)=> ({
            ...prev,
            questions: res?.success?.totalCount
         }))
      } 

      if(res?.success?.data?.question?.length === 0) {
         setQuestionsPage(1);
      }

      if (res?.error?.message && page) {
         setQuestionsStatisticsData([]);
      }

      setQuestionsLoading(false);
   }

   const lastQuestionRef = useIntersectionObserver(
      () => {
        if (!questionsLoading && questionsStatisticsData?.length < tableTotalCount?.questions) {
          if(questionsStatisticsData?.length === 0) {
            setQuestionsPage(1);
          } else {
            setQuestionsPage((prev) => prev + 1);
          }
        }
      },
      [questionsLoading, tableTotalCount?.questions]
    );

   useEffect(()=> {
      if(questionsPage > 1 && questionsStatisticsData?.length <= tableTotalCount?.questions) {
         fetchQuestionsData();
      }
   }, [questionsPage])

   const debouncedFetchQuestionsData = debounce(()=> fetchQuestionsData(1), DEFAULT_DEBOUNCE_DELAY); 

   useEffect(()=> {
      debouncedFetchQuestionsData();
      setQuestionsPage(1);

      return () => debouncedFetchQuestionsData.cancel();
   }, [questionsSearchedValue, activeFilters?.questionsCompanyFilter, activeCourseFilters?.questionsCourseFilter])

   const fetchCertificatesData = async(page) => {
      setCertificatesLoading(true);
      
      const res = await new StatisticsApi().getCertificatesStatistics(
            page ? page : certificatesPage,
            certificatesSearchedValue, 
            activeFilters?.certificateCompanyFilter?.id,
            activeTeamFilters?.certificateTeamFilter?.id
         );

      if(res?.success?.data?.certificates) {
         const data = res?.success?.data?.certificates;

         if (page) {
            setCertificatesStatisticsData(data);
         } else {
            setCertificatesStatisticsData((prev) => [...prev, ...data]);
         }

         setTableTotalCount((prev)=> ({
            ...prev,
            certificates: res?.success?.totalCount
         }))
      }

      if(res?.success?.data?.certificates?.length === 0) {
         setCertificatesPage(1);
      }

      if (res?.error?.message && page) {
         setCertificatesStatisticsData([]);
      }

      setCertificatesLoading(false);
   }

   const lastCertificateRef = useIntersectionObserver(
      () => {
        if (!certificatesLoading && certificatesStatisticsData?.length < tableTotalCount?.certificates) {
          if(certificatesStatisticsData?.length === 0) {
            setCertificatesPage(1);
          } else {
            setCertificatesPage((prev) => prev + 1);
          }
        }
      },
      [certificatesLoading, tableTotalCount?.certificates]
    );

   const debouncedFetchCertificatesData = debounce(()=> fetchCertificatesData(1), DEFAULT_DEBOUNCE_DELAY); 

   useEffect(()=> {
      debouncedFetchCertificatesData();
      setCertificatesPage(1);
      return () => debouncedFetchQuestionsData.cancel();
   }, [certificatesSearchedValue, activeFilters?.certificateCompanyFilter])

   useEffect(()=> {
      if(certificatesPage > 1 && certificatesStatisticsData?.length <= tableTotalCount?.certificates) {
         fetchCertificatesData();
      }
   }, [certificatesPage])

   const fetchCompaniesStatisticsData = async(page) => {
      setCompanyRatingLoading(true);

      const res = await new StatisticsApi().getCompaniesStatistics(
         page ? page : companyRatingPage,
         ratingSearchedValue,
         activeFilters?.ratingCompanyFilter?.id,
         activeTeamFilters?.ratingTeamFilter?.id
      );

      if(res?.success?.data?.companies) {
         const data = res?.success?.data?.companies

         if (page) {
            setCompaniesStatisticsData(data);
         } else {
            setCompaniesStatisticsData((prev) => [...prev, ...data]);
         }

         setTableTotalCount((prev)=> ({
            ...prev,
            companies: res?.success?.totalCount
         }))
      }

      if(res?.success?.data?.companies?.length === 0) {
         setCompanyRatingPage(1);
      }

      if (res?.error?.message && page) {
         setCompaniesStatisticsData([]);
      }

      setCompanyRatingLoading(false);
   }

   const lastCompanyRef = useIntersectionObserver(
      () => {
        if (!companyRatingLoading && companiesStatisticsData?.length < tableTotalCount?.companies) {
          if(companiesStatisticsData?.length === 0) {
            setCompanyRatingPage(1);
          } else {
            setCompanyRatingPage((prev) => prev + 1);
          }
        }
      },
      [companyRatingLoading, tableTotalCount?.companies]
    );

   const debouncedFetchCompaniesData = debounce(()=> fetchCompaniesStatisticsData(1), DEFAULT_DEBOUNCE_DELAY);

   useEffect(()=> {
      debouncedFetchCompaniesData();
      setCompanyRatingPage(1);

      return () => debouncedFetchQuestionsData.cancel();
   }, [ratingSearchedValue, activeFilters?.ratingCompanyFilter, activeTeamFilters?.ratingTeamFilter])

   useEffect(()=> {
      if(companyRatingPage > 1 && companiesStatisticsData?.length <= tableTotalCount?.companies) {
         fetchCompaniesStatisticsData();
      }
   }, [companyRatingPage])

   const fetchIntegrationStatisticsData = async(page) => {
      setIntegrationLoading(true);

      const res = await new StatisticsApi().getIntegrationStatistics(
         page ? page : integrationPage,
         integrationSearchedValue,
         activeFilters?.integrationCompanyFilter?.id,
         activeTeamFilters?.integrationTeamFilter?.id,
      );

      if(res?.success?.data?.user) {
         const data = res?.success?.data?.user

         if (page) {
            setIntegrationData(data);
         } else {
            setIntegrationData((prev) => [...prev, ...data]);
         }

         setTableTotalCount((prev)=> ({
            ...prev,
            integration: res?.success?.totalCount
         }))
      }

      if(res?.success?.data?.user?.length === 0) {
         setIntegrationPage(1);
      }

      if (res?.error?.message && page) {
         setIntegrationData([]);
      }

      setIntegrationLoading(false);
   }

   const lastIntegrationRef = useIntersectionObserver(
      () => {
        if (!integrationLoading && integrationData?.length < tableTotalCount?.integration) {
          if(integrationData?.length === 0) {
            setIntegrationPage(1);
          } else {
            setIntegrationPage((prev) => prev + 1);
          }
        }
      },
      [integrationLoading, tableTotalCount?.integration]
    );

   const debouncedFetchIntegrationData = debounce(()=> fetchIntegrationStatisticsData(1), DEFAULT_DEBOUNCE_DELAY);

   useEffect(()=> {
      debouncedFetchIntegrationData();
      setIntegrationPage(1);

      return () => debouncedFetchIntegrationData.cancel();
   }, [integrationSearchedValue, activeFilters?.integrationCompanyFilter, activeTeamFilters?.integrationTeamFilter])

   useEffect(()=> {
      if(integrationPage > 1 && integrationData?.length <= tableTotalCount?.integration) {
         fetchIntegrationStatisticsData();
      }
   }, [integrationPage])

   const fetchUsersRegistrationDataForCompany = async(companyId) => {
      const res = await new StatisticsApi().getAllUsersGrowthForCompany(companyId);

      if(res?.success?.data) {
         setUsersRegistrationData(res?.success?.data);
      }
   }

   const fetchTotalData = async() => {
      const res = await new StatisticsApi().getTotalRegistrated();

      if(res?.success?.data) {
         setRegistrationDataTotal(res?.success?.data);
      }
   }

   useEffect(() => {
      fetchTotalData();
      fetchUsersOnlineData();
      fetchUsersRegistrationData();
      fetchCoursesData();
   }, []);

   useEffect(() => {
      if(userRoleId && companyID && +userRoleId !== ROLE_ID_SUPER_ADMIN) {
         setActiveFilters({...initialFiltersData, integrationCompanyFilter: {id: companyID}});
      } else {
         setActiveFilters(initialFiltersData);
      }
      setActiveTeamFilters(initialTeamFiltersData);
      setActiveCourseFilters(initialCourseFiltersData);
   }, [activeTab, userRoleId, companyID]);

   useEffect(() => {
      if(activeFilters?.leftChartCompanyFilter?.id) {
         fetchUsersRegistrationDataForCompany(activeFilters?.leftChartCompanyFilter?.id)
      }

      if(activeFilters?.leftChartCompanyFilter?.name === 'All') {
         fetchUsersRegistrationData()
      }
   }, [activeFilters?.leftChartCompanyFilter]);


   const currentTagsSet = Array.from(new Set(
      coursesStatisticsData?.reduce((allTags, course) => {
        const tagsArray = JSON.parse(course?.tags ?? "[]");
        return allTags.concat(tagsArray);
      }, []) ?? []
    ));

    const filteredCoursesByTags =  coursesStatisticsData?.filter((item) => {
      if (activeTagsFilter.length === 0) {
        return true; // No tag filter applied, show all courses
      }
      const tagsArray = JSON.parse(item?.tags ?? "[]");
      return tagsArray.some((tag) =>
        activeTagsFilter.includes(tag)
      );
    })

    const mappedQuestions = questionsStatisticsData?.map(questionsMap) || [];
    const mappedTests = testsStatisticsData?.map(testsMap) || [];
    const mappedCertificates = certificatesStatisticsData?.map(certificatesMap) || [];
    const mappedCompanies = companiesStatisticsData?.map(companiesMap) || [];
    const mappedIntegration = integrationData?.map(integrationMap) || [];
    const mappedUsers = statisitcsUsersData?.map(statisticsUsersMap) || [];

   return (
      <MainLayout>
         <main className={styles.statistics_page}>
            <h2 className="page_title">{t?.statistics}</h2>
            <StatisticsTabs t={t} activeTab={activeTab} clickOnTab={onClickOnTab} />

            {graphLoading && 
               <div className="default_loader_wrapper">
                  <SecondLoader />
               </div>
            }

            {activeTab === STAT_TAB_PLATFORM && (
               <div className={styles.graph_wrapper}>
                  <LineChart
                     key={"left"}
                     t={t}
                     currentAmount={registrationDataTotal?.total?.totalUsers || 0}
                     activeTab={activeTabLeft}
                     clickActiveTab={clickActiveTabLeft}
                     companies={agencyManager?.agenciesList}
                     lastAgencyRef={lastAgencyRef}
                     activeFilter={activeFilters?.leftChartCompanyFilter}
                     setActiveFilter={(value)=> setActiveFilters(prev => ({
                        ...prev,
                        leftChartCompanyFilter: value
                     }))}
                     data={usersRegistrationData}
                  />
                  <LineChart
                     key={"right"}
                     t={t}
                     isGreen
                     currentAmount={registrationDataTotal?.total?.totalActiveUsers || 0}
                     activeTab={activeTabRight}
                     clickActiveTab={clickActiveTabRight}
                     activeFilter={activeFilters?.rightChartCompanyFilter}
                     setActiveFilter={(value)=> setActiveFilters(prev => ({
                        ...prev,
                        rightChartCompanyFilter: value
                     }))}
                     data={usersOnlineData}
                  />
               </div>
            )}

            {activeTab === STAT_TAB_COURSES && (
               <div className={styles.grade_wrapper}>
                  <div className={styles.table_wrapper}>
                     <StatisticsSubheader
                        companies={agencyManager?.agenciesList}
                        lastAgencyRef={lastAgencyRef}
                        searchedValue={coursesSearchedValue}
                        setSearchedValue={setCoursesSearchedValue}
                        leftText={t?.countingCourses}
                        leftSubText={`${coursesStatisticsData?.length || 0} ${t?.coursesInTotal}`}
                        tags={currentTagsSet}
                        isCourses
                        activeTagsFilter={activeTagsFilter}
                        setActiveTagsFilter={setActiveTagsFilter}
                        t={t}
                     />
                     <StatisticsTable
                        data={filteredCoursesByTags}
                        columns={COURSES_TABLE_HEADERS}
                        maxHeight={"45vh"}
                        searchedValue={coursesSearchedValue}
                        t={t}
                     />
                  </div>

                  <div className={styles.two_tables_wrapper}>
                     <div className={cn(styles.table_wrapper, styles.two_tables_item)}>
                        <StatisticsSubheader
                           key={'questionsSubheader'}
                           companies={agencyManager?.agenciesList}
                           lastAgencyRef={lastAgencyRef}
                           courses={allCoursesList}
                           allCoursesSearchedValue={allCoursesSearchedValue}
                           setAllCoursesSearchedValue={setAllCoursesSearchedValue}
                           lastAllCourseRef={lastAllCourseRef}
                           activeCourseFilter={activeCourseFilters?.questionsCourseFilter}
                           setActiveCourseFilter={(value)=> setActiveCourseFilters(prev => ({
                              ...prev,
                              questionsCourseFilter: value
                           }))}
                           activeFilter={activeFilters?.questionsCompanyFilter}
                           setActiveFilter={(value)=> setActiveFilters(prev => ({
                              ...prev,
                              questionsCompanyFilter: value
                           }))}
                           setSearchedValue={setQuestionsSearchedValue}
                           leftText={t?.resultsOfTasksInTheTests}
                           isSmall
                           t={t}
                        />
                        <StatisticsTable
                           key={'questionsTable'}
                           data={mappedQuestions}
                           columns={QUESTIONS_TABLE_HEADERS}
                           maxHeight={"45vh"}
                           maxWordsLength={20}
                           lastElementRef={lastQuestionRef}
                           loading={questionsLoading}
                           t={t}
                        />

                        {questionsLoading && 
                           <div className={styles.loaderWrapper}>
                              <Loader size={"small"}/>
                           </div>
                        }
                     </div>
                     <div className={cn(styles.table_wrapper, styles.two_tables_item)}>
                        <StatisticsSubheader
                           key={'testsSubheader'}
                           allCoursesSearchedValue={allCoursesSearchedValue}
                           setAllCoursesSearchedValue={setAllCoursesSearchedValue}
                           courses={allCoursesList}
                           lastAllCourseRef={lastAllCourseRef}
                           activeCourseFilter={activeCourseFilters?.testsCourseFilter}
                           setActiveCourseFilter={(value)=> setActiveCourseFilters(prev => ({
                              ...prev,
                              testsCourseFilter: value
                           }))}
                           companies={agencyManager?.agenciesList}
                           lastAgencyRef={lastAgencyRef}
                           activeFilter={activeFilters?.testsCompanyFilter}
                           setActiveFilter={(value)=> setActiveFilters(prev => ({
                              ...prev,
                              testsCompanyFilter: value
                           }))}
                           setSearchedValue={setTestsSearchedValue}
                           leftText={t?.resultsOfTheTests}
                           isSmall
                           t={t}
                        />
                        <StatisticsTable
                           data={mappedTests}
                           columns={TESTS_TABLE_HEADERS}
                           maxHeight={"45vh"}
                           maxWordsLength={18}
                           key={'testsTable'}
                           lastElementRef={lastTestRef}
                           loading={testsLoading}
                           t={t}
                        />

                        {testsLoading && 
                           <div className={styles.loaderWrapper}>
                              <Loader size={"small"}/>
                           </div>
                        }
                     </div>

                  </div>
               </div>
            )}

            {activeTab === STAT_TAB_SCORE && (
               <div className={styles.grade_wrapper}>
                  <div className={styles.table_wrapper}>
                     <StatisticsSubheader
                        key={'certificatesSubheader'}
                        companies={agencyManager?.agenciesList}
                        lastAgencyRef={lastAgencyRef}
                        activeFilter={activeFilters?.certificateCompanyFilter}
                        setActiveFilter={(value)=> setActiveFilters(prev => ({
                           ...prev,
                           certificateCompanyFilter: value
                        }))}
                        setSearchedValue={setCertificatesSearchedValue}
                        noTeam
                        leftText={t?.numberOfCertificatesIssued}
                        leftSubText={`${tableTotalCount?.certificates || 0} ${t?.certificatesInTotal}`}
                        t={t}
                     />
                     <StatisticsTable
                        key={'certificatesTable'}
                        data={mappedCertificates}
                        columns={CERTIFICATES_TABLE_HEADERS}
                        loading={certificatesLoading}
                        maxHeight={"45vh"}
                        lastElementRef={lastCertificateRef}
                        t={t}
                     />

                     {certificatesLoading && 
                        <div className={styles.loaderWrapper}>
                           <Loader size={"small"}/>
                        </div>
                     }
                  </div>
               </div>
            )}

            {activeTab === STAT_TAB_COMPANY && 
                <div className={styles.table_wrapper}>
                <StatisticsSubheader
                   key={'ratingSubheader'}
                   companies={agencyManager?.agenciesList}
                   lastAgencyRef={lastAgencyRef}
                   activeFilter={activeFilters?.ratingCompanyFilter}
                   setActiveFilter={(value)=> setActiveFilters(prev => ({
                      ...prev,
                      ratingCompanyFilter: value
                   }))}
                   teams={allTeamsManager}
                   lastTeamRef={lastTeamRef}
                   activeTeamFilter={activeTeamFilters?.ratingTeamFilter}
                   setActiveTeamFilter={(value)=> setActiveTeamFilters(prev => ({
                      ...prev,
                      ratingTeamFilter: value
                   }))}
                   setSearchedValue={setRatingSearchedValue}
                   leftText={t?.successRatingAmongTheCompanies}
                   leftSubText={`${registrationDataTotal?.total?.totalUsers || 0} ${t?.usersInTotal}`}
                   t={t}
                />
                <StatisticsTable
                   key={'ratingTable'}
                   tableFor={'company'}
                   data={mappedCompanies}
                   columns={RATING_TABLE_HEADERS}
                   maxHeight={"45vh"}
                   loading={companyRatingLoading}
                   lastElementRef={lastCompanyRef}
                   t={t}
                />

                  {companyRatingLoading && 
                     <div className={styles.loaderWrapper}>
                        <Loader size={"small"}/>
                     </div>
                  }
             </div>
            }

            {activeTab === STAT_TAB_INTEGRUM && 
                <div className={styles.table_wrapper}>
                <StatisticsSubheader
                   key={'integrumSubheader'}
                   companies={agencyManager?.agenciesList}
                   lastAgencyRef={lastAgencyRef}
                   activeFilter={activeFilters?.integrationCompanyFilter}
                   setActiveFilter={(value)=> setActiveFilters(prev => ({
                      ...prev,
                      integrationCompanyFilter: value
                   }))}
                   activeTeamFilter={activeTeamFilters?.integrationTeamFilter}
                   setActiveTeamFilter={(value)=> setActiveTeamFilters(prev => ({
                      ...prev,
                      integrationTeamFilter: value
                   }))}
                   teams={allTeamsManager}
                   lastTeamRef={lastTeamRef}
                   setSearchedValue={setIntegrationSearchedValue}
                   leftText="Users rating"
                   leftSubText={`${tableTotalCount?.integration || 0} ${t?.usersInTotal}`}
                   t={t}
                />
                <StatisticsTable
                   key={'integrationTable'}
                   data={mappedIntegration}
                   columns={INTEGRATION_TABLE_HEADERS}
                   maxHeight={"45vh"}
                   loading={integrationLoading}
                   lastElementRef={lastIntegrationRef}
                   minWidth
                   t={t}
                />

                  {integrationLoading && 
                     <div className={styles.loaderWrapper}>
                        <Loader size={"small"}/>
                     </div>
                  }
             </div>
            }
            {activeTab === STAT_TAB_USERS && 
                <div className={styles.table_wrapper}>
                <StatisticsSubheader
                   key={'usersSubheader'}
                   courses={allCoursesList}
                   allCoursesSearchedValue={allCoursesSearchedValue}
                   setAllCoursesSearchedValue={setAllCoursesSearchedValue}
                   allUsersSearchedValue={dropdownUserSearchedValue}
                   setAllUsersSearchedValue={setDropdownUserSearchedValue}
                   lastAllCourseRef={lastAllCourseRef}
                   activeCourseFilter={activeCourseFilters?.usersCourseFilter}
                   setActiveCourseFilter={(value)=> setActiveCourseFilters(prev => ({
                              ...prev,
                              usersCourseFilter: value
                   }))}
                   activeTeamFilter={activeTeamFilters?.usersTeamFilter}
                   setActiveTeamFilter={(value)=> setActiveTeamFilters(prev => ({
                      ...prev,
                      usersTeamFilter: value
                   }))}
                   noCompanyFilter
                   dropdownUserList={dropdownUserList}
                   lastUserInDropdownRef={lastUserInDropdownRef}
                   activeFilter={activeFilters?.usersTabUser}
                   setActiveFilter={(value)=> setActiveFilters(prev => ({
                           ...prev,
                           usersTabUser: value
                     }))}
                   teams={allTeamsManager}
                   lastTeamRef={lastTeamRef}
                   setSearchedValue={setStatisitcsUsersSearchedValue}
                   leftText={t?.usersInCompany}
                   leftSubText={`${tableTotalCount?.users || 0} ${t?.usersInTotal}`}
                   t={t}
                />
                <StatisticsTable
                   key={'usersTable'}
                   data={mappedUsers}
                   rowClickable
                   columns={STATISTICS_USERS_TABLE_HEADERS}
                   maxHeight={"45vh"}
                   loading={false}
                   lastElementRef={lastStatisticsUserRef}
                   minWidth
                   tableFor={'users'}
                   t={t}
                />

                  {statisitcsUsersLoading && 
                     <div className={styles.loaderWrapper}>
                        <Loader size={"small"}/>
                     </div>
                  }
             </div>
            }
         </main>
      </MainLayout>
   );
};

export default StatisticsPage;
