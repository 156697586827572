import React from "react";
import styles from "./styles.module.scss";
import cn from "classnames";

const CustomSwitch = ({ leftText, rightText, maxWidth = "100%", isRight, setSwitch }) => {
   return (
      <div style={{ maxWidth }} className={styles.is_theory_btns}>
         <button
            className={cn(
               styles.is_theory_btn,
               !isRight ? styles.active_theory : ""
            )}
            onClick={() => setSwitch(false)}
         >
            {" "}
            {leftText}
         </button>
         <button
            className={cn(
               styles.is_theory_btn,
               isRight ? styles.active_theory : ""
            )}
            onClick={() => setSwitch(true)}
         >
            {" "}
            {rightText}
         </button>
         <div className={styles.active_indicator}>
            {!isRight ? leftText : rightText}
         </div>
      </div>
   );
};

export default CustomSwitch;
