import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import filtersImg from "../../assets/images/symbols/filters.svg";
import arrow from "../../assets/images/symbols/arrow.svg";
import checkboxInactive from "../../assets/images/notification_checkbox.svg";
import dots from "../../assets/images/symbols/three_dots.svg";

import cn from "classnames";
import useOutsideClick from "../../hooks/dom/useOutsideClick";
import { useSelector } from "react-redux";
import useMoveScroll from "../../hooks/useMoveScroll";
import LeftRightErrows from "../LeftRightArrows/LeftRightArrows";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import { v4 as uuidv4 } from "uuid";
import CustomStaticTooltip from "../CustomStaticTooltip/CustomStaticTooltip";
import Dropdown from "../Dropdown/Dropdown";
import DropdownWithImg from "../DropdownWithImg/DropdownWithImg";
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import { KNOWLEDGE_CHECK_DATE_OPTIONS } from "../../constants/user";
import useFindTranslations from "../../hooks/useFindTranlsations";

const ExtendedDateDropdown = ({
   title,
   sortFilter,
   dateFilter,
   setDateFilter,
   roleFilter,
   setRoleFilter,
   readFilter,
   setReadFilter,
   userBranchRoles,
   setSelectFilter,
   selectFilter,
   frontTimeFilterValue,
   setFrontTimeFilterValue
}) => {
   const [showOptions, setShowOptions] = useState(false);

   const optionRef = useRef(null);
   const dropDownRef = useRef(null);

   const closeOptions = () => setShowOptions(false);

   const t = useFindTranslations();

   useOutsideClick(dropDownRef, closeOptions, optionRef);

   const toggleOptions = (e) => {
      setShowOptions((prev) => !prev);
   };

   const handleFilterChange = (filterType) => {
      if (!setActiveSortFilter) return;

      if (activeSortFilter === filterType) {
         setActiveSortFilter("");
      } else {
         setActiveSortFilter(filterType);
      }
      toggleOptions();
   };

   const onClickOption = (option) => {
      setReadFilter(option);
      closeOptions();
   };

   const getDateRange = (label) => {
      const today = new Date();
      let startDate, endDate;

      switch (label) {
         case "for_today":
            startDate = endDate = formatDate(today);
            break;
         case "for_yesterday":
            const yesterday = new Date(today);
            yesterday.setDate(today.getDate() - 1);
            startDate = endDate = formatDate(yesterday);
            break;
         case "in_a_week":
            startDate = formatDate(
               new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000)
            );
            endDate = formatDate(today);
            break;
         case "in_a_month":
            startDate = formatDate(
               new Date(
                  today.getFullYear(),
                  today.getMonth() - 1,
                  today.getDate()
               )
            );
            endDate = formatDate(today);
            break;
         default:
            startDate = endDate = "";
      }

      return `${startDate} - ${endDate}`;
   };

   const formatDate = (date) => {
      if (!date) {
         return "";
      }

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
   };

   return (
      <div className={cn(styles.filtersBlock, "noselect")}>
         <CustomDropdown
            data={KNOWLEDGE_CHECK_DATE_OPTIONS}
            placeholder={t?.filter}
            value={dateFilter}
            maxFrontHeight40
            onChange={(value) => {
               setDateFilter(getDateRange(value.label));
               setFrontTimeFilterValue(value?.name);
            }}
            frontTimeFilterValue={frontTimeFilterValue}
            setFrontTimeFilterValue={setFrontTimeFilterValue}
            maxHeight={"400px"}
            img={filtersImg}
            isWithDateRange
            onChangeDate={(value) => setDateFilter(value)}
            dateFilter={dateFilter}
         />
      </div>
   );
};

export default ExtendedDateDropdown;
