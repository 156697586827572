import React, { useEffect, useMemo, useState } from "react";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import styles from "./styles.module.scss";
import arrow_right from "../../assets/images/arrow_right.svg";
import info from "../../assets/images/icon_info.svg";
import ongoing_courses_icon from "../../assets/images/certificates/ongoing_courses_icon.svg";
import completed_courses_icon from "../../assets/images/certificates/completed_courses_icon.svg";
import certificates_icon from "../../assets/images/certificates/certificates_icon.svg";
import pausedIcon from "../../assets/images/symbols/pause.svg";
import useHandleModal from "../../hooks/dom/useHandleModal";
import WelcomeModal from "../../components/Modals/WelcomeModal/WelcomeModal";
import CourseApi from "../../utils/api/CourseApi";
import LessonApi from "../../utils/api/LessonApi";
import ModuleApi from "../../utils/api/ModuleApi";
import UserAnswerApi from "../../utils/api/UserAnswerApi";
import { useSelector } from "react-redux";
import UniverseProgress from "../../components/UniverseProgress/UniverseProgress";
import Circle from "../../components/Circle/Circle";
import Planets from "../../components/Planets/Planets";
import courseImg from "../../assets/images/course_thumbnail.png";
import { COLOR_PRESETS, COURSE_STATUS_ACTIVE, COURSE_STATUS_DELETED, FOLDER_STATUS_ACTIVE, FOLDER_STATUS_DELETED, FOLDER_STATUS_HIDEN, levelsData } from "../../constants/courses";
import FolderBlock from "../../components/FolderBlock/FolderBlock";
import { useQuery } from "react-query";
import { pathList } from "../../routes/path";
import FolderApi from "../../utils/api/FolderApi";
import useMoveScroll from "../../hooks/useMoveScroll";
import LeftRightErrows from "../../components/LeftRightArrows/LeftRightArrows";
import CourseBlock from "../../components/CourseBlock/CourseBlock";
import cn from 'classnames';
import { ROLE_ID_STUDENT, ROLE_ID_SUPER_ADMIN } from "../../constants/user";
import { TEST_STATUS_APPROVED, TEST_STATUS_ON_REWORK, TEST_STATUS_REJECTED } from "../../constants/tests";
import KnowledgeCheckAPI from "../../utils/api/KnowledgeCheckAPI";
import { Link } from "react-router-dom";
import CustomTooltip from "../../components/CustomTooltip/CustomTooltip";
import { localStorageGet, localStorageSet } from "../../utils/localStorage";
import useChangeFolder from "../../hooks/useChangeFolder";
import useChangeCourse from "../../hooks/useChangeCourse";
import ConfirmModal from "../../components/Modals/ConfirmModal/ConfirmModal";
import { toast } from "react-toastify";
import ToastMsg from "../../components/ToastMsg/ToastMsg";
import StatisticsApi from "../../utils/api/StatisticsApi";
import useUserRole from "../../hooks/useUserRole";
import { returnTemporaryCoursesLinks } from "../../utils/coursesHelper";
import useGetYourCoursesInCompany from "../../hooks/api/useGetYourCoursesInCompany";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import Loader from "../../components/Loader/Loader";
import useGetFolders from "../../hooks/api/useGetFolders";
import useGetCoursesCount from "../../hooks/api/useGetCoursesCount";

import { useNavigate } from 'react-router-dom'; 
import CoursesInfoLineBlock from "../../components/CoursesInfoLineBlock/CoursesInfoLineBlock";
import { DEFAULT_TEXT, returnTranlation } from "../../constants/languages";
import useFindTranlsations from "../../hooks/useFindTranlsations";

const HomePage = () => {
   const userState = useSelector((state) => state.user);
   const [courses, setCourses] = useState([]);
   const [tests, setTests] = useState([]);
   const [coursesCompleted, setCoursesCompleted] = useState([]);
   const [coursesOngoing, setCoursesOngoing] = useState([]);
   const [currentLevel, setCurrentLevel] = useState(1);
   const [integrationPercentage, setIntegrationPercentage] = useState(undefined);
   const [coursesLinks, setCoursesLinks] = useState([]);

   const navigate = useNavigate();

   const onRedirectToEditFolderPage = (e, folder) => {
      e.stopPropagation();
      navigate(`/courses/folder/${folder?.id}/edit`);
   }

   const { blockWrapperRef, handleMouseDown, handleMouseMove, handleMouseUp, isDragging,   moveLeft, moveRight } = useMoveScroll();

   const isSideBarFixed = useSelector(state => state.sidebar.isFixed);
   const currentCompanyId = useSelector(state => state.sidebar.companyId);
   const userInfo = useSelector(state => state?.user?.info);
   const userId = useSelector(state => state?.user?.info?.id);
   const { userRoleId } = useUserRole(currentCompanyId)

   const { coursePassingInfo, userCertificatesCount } = useGetCoursesCount(currentCompanyId, userId, true);

   const isUserAsStudent = useSelector(state => state.sidebar.isUserAsStudent);

   const t = useFindTranlsations();

   const filterActiveCourses = (course) => course?.status === COURSE_STATUS_ACTIVE; 

   const { 
      blockWrapperRef: blockWrapperRefCourse, 
      handleMouseDown:handleMouseDownCourse, 
      handleMouseMove:handleMouseMoveCourse,
      handleMouseUp:handleMouseUpCourse,
      isDragging: isDraggingCourse, 
      moveLeft:moveLeftCourse, 
      moveRight:moveRightCourse 
   } = useMoveScroll();

   const initialMapCourses = (item) => ({
      id: item?.id,
      image: item?.image || courseImg,
      title: item?.name || '',
      description: item?.description || '',
      tagList: item?.tags || [],
      isInFolder: !!item?.folder?.id,
      folder: item?.folder || {},
      status: item?.status,
      createdAt: item?.createdAt,
      userCourses: item?.userCourses || [],
      percentageToReceiveCertificate: item?.percentageToReceiveCertificate || 0
   })

      const getUserOwnTests = async () => {
         const res = await new KnowledgeCheckAPI().getYourCheckedTests(currentCompanyId);

         if(res?.success?.data) {
            setTests(res?.success?.data);
         }
      }

      useEffect(()=> {
         getUserOwnTests();
      }, [])

      const { foldersList, isFoldersLoading, refetchFolders, totalFolders, setCurrentPage: setCurrentFolderPage, currentPage: currentFolderPage } = useGetFolders(currentCompanyId);

      const lastFolderRef = useIntersectionObserver(
         () => {
           if (!isFoldersLoading && foldersList?.length < totalFolders) {
             if(courseList?.length === 0) {
               setCurrentFolderPage(1);
             } else {
               setCurrentFolderPage((prev) => prev + 1);
             }
           }
         },
         [isFoldersLoading, totalFolders]
       );

      const { 
         onConfirmHideOpen,
         onConfirmDeleteOpen,
         confirmModal,
         confirmHideModal,
         isChangeLoading,
         onFolderDeleteToggle,
         onFolderHideToggle,
       } = useChangeFolder(refetchFolders);


   const isUserAsStudentAndNotSuperAdmin =  userRoleId === ROLE_ID_SUPER_ADMIN ? false : isUserAsStudent;

   const isExcludeCoursesWhereAdminAsStudent = userRoleId !== ROLE_ID_SUPER_ADMIN && userRoleId !== ROLE_ID_STUDENT;
   
   const { courseList, isCoursesLoading, refetchCourses, totalCourses, setCurrentPage } = useGetYourCoursesInCompany(currentCompanyId, isUserAsStudentAndNotSuperAdmin, null, null, isExcludeCoursesWhereAdminAsStudent);

       const lastCourseRef = useIntersectionObserver(
          () => {
            if (!isCoursesLoading && courseList?.length < totalCourses) {
              if(courseList?.length === 0) {
                setCurrentPage(1);
              } else {
                setCurrentPage((prev) => prev + 1);
              }
            }
          },
          [isCoursesLoading, totalCourses]
        );

      const {
         onConfirmPausedOpen,
         confirmPausedModal,
         onRedirectClickedCourse
      } = useChangeCourse(refetchCourses);

   const getYourIntegration = async () => {
      const res = await new StatisticsApi().getYourIntegration();
         
      if(res?.success?.data) {
         setIntegrationPercentage(Math.round(res?.success?.data?.[0].integration_percentage))
      }
   }

   useEffect(()=> {
      getYourIntegration();
   }, [])

   //  LEVEL COMMENT
   //  const clickPlanet = ({ level }) => setCurrentLevel(level);
   //  const selectedLevelData = levelsData.find(data => data.level === currentLevel);

   return (
      <MainLayout>
         <main className={styles.admin_page_body}>
            <section className={styles.progress_bord}>
               <div className={styles.left}>
                  <div className={styles.progress_card}>
                     <div
                        className={`${styles.progressbar_single} ${styles.left_planet_wrapper}`}
                     >
                        <div
                           className={`${styles.progress_level} ${
                              styles.planet_img
                           } ${styles[`progress_level_${currentLevel}`]}`}
                        >
                                {/* <span className={styles.progress_level__name}>{integrationPercentage}</span>
                                <span className={styles.progress_level__text}>Integration</span>
                              <Circle
                                 progress={integrationPercentage}
                                 width="85%"
                                 height="100%"
                              /> */}
                        </div>
                     </div>
             
                     <div>
                        <h2 className={styles.progress_card__title}>
                           {t?.hey}, {userState?.info?.firstName || t?.user}!
                        </h2>
                        <p className={styles.progress_card__text}>
                         {t?.recentTestsResults}   
                        </p>

                     {!!integrationPercentage && 
                        <div className={styles.integraion_line_wrapper}>
                           <span>{integrationPercentage}%</span>
                           <div className={styles.integraion_line}>
                              <div style={{width: `${integrationPercentage}%`}} className={styles.line}></div>
                           </div>
                           <img className={styles.hint_img} src={info} alt="" />

                           <div className={styles.hint}>
                              {t.coursesRatio}
                           </div>
                        </div>
                     }
                     </div>
                  </div>

                  <div className={styles.tests}>

                     {tests?.map(test => 
                        <Link key={test?.id} to={`/knowledge_check/info/${test?.id}`} className={styles.test_item}>
                            <div className={styles.test_results}>
                               <span className={styles.result_score}>{test?.successPercentage}%</span>
                               <Circle
                                  progress={test?.successPercentage}
                                  width="85%"
                                  height="100%"
                                  color={test?.status === TEST_STATUS_APPROVED ? '#36BA53' : (test?.status === TEST_STATUS_ON_REWORK ? 'yellow' : '#D64343')}
                               />
                            </div>
                            <div className={styles.test_info}>
                               <span>
                                 {test?.lesson?.name && test?.lesson?.name?.length > 17 ? `${test?.lesson?.name?.slice(0, 17)}...` : test?.lesson?.name}
                               </span>
                            </div>
                            <div>
                               <img src={arrow_right} alt="" />
                            </div>
                         </Link>
                        )}
                  </div>
               </div>

               <div className={cn(styles.right, isSideBarFixed ? styles.smaller : '')}>
                  <div className={styles.main_progress}>
                     <div className={styles.comets}>
                        <span
                           className={`${styles.comet} ${styles.comet_1}`}
                        ></span>
                        <span
                           className={`${styles.comet} ${styles.comet_2}`}
                        ></span>
                        <span
                           className={`${styles.comet} ${styles.comet_3}`}
                        ></span>
                        <span
                           className={`${styles.comet} ${styles.comet_4}`}
                        ></span>
                        <span
                           className={`${styles.comet} ${styles.comet_5}`}
                        ></span>
                        <span
                           className={`${styles.comet} ${styles.comet_6}`}
                        ></span>
                     </div>

                     <div className={styles.planets_wrapper}>
                        <Planets
                           setCurrentLevel={setCurrentLevel}
                           currentLevel={currentLevel}
                        />
                     </div>
                     {/* <span className={styles.main_progress__label}>{0}% Completed</span> */}
                  </div>

                  <div className={styles.courses_info_wrapper}>
                     <CoursesInfoLineBlock
                         numberOfItems={coursePassingInfo?.ongoing} 
                         link={pathList.courses.path} 
                         icon={ongoing_courses_icon}
                         title={t?.ongoing}
                             coursesText={t.courses}
                     />

                     <CoursesInfoLineBlock
                         numberOfItems={coursePassingInfo?.completed} 
                         link={`${pathList.courses.path}/1`}
                         icon={completed_courses_icon}
                         title={t?.completed}
                         coursesText={t.courses}
                     />

                     <CoursesInfoLineBlock 
                        numberOfItems={userCertificatesCount} 
                        link={'/profile/4'}
                        icon={certificates_icon}
                        title={t?.certificates}
                        coursesText={t.courses}
                     />
                  </div>

                  <div className={cn(styles.list, 'noselect')}>
                     <div className={styles.move_arrows}>
                        <p>{t.myFolders}</p>
                      <LeftRightErrows noBorder moveLeft={moveLeft} moveRight={moveRight} /> 
                     </div>

                     <div 
                         onMouseDown={handleMouseDown}
                         onMouseMove={handleMouseMove}
                         onMouseUp={handleMouseUp} 
                         ref={blockWrapperRef} 
                         className={cn(styles.list_wrapper)}
                     >
                        {foldersList?.map((item, key) => (
                           <FolderBlock
                              onRedirectToEditFolderPage={onRedirectToEditFolderPage}
                              isDragging={isDragging}
                              key={item?.id} 
                              refetchFolders={refetchFolders} 
                              folder={item} 
                              isHomePage
                              onConfirmDeleteOpen={onConfirmDeleteOpen}
                              onConfirmHideOpen={onConfirmHideOpen}
                              lastFolderRef={lastFolderRef}
                           />
                        ))}

                        {foldersList?.length === 0 && !isFoldersLoading &&
                           t?.myFolders
                        }


                        {isFoldersLoading && foldersList?.length > 0 && 
                           <div className={styles.folderLoaderWrapper}>
                              <Loader size={"small"}/>
                           </div>
                        }
                     </div>
                  </div>

                  <div className={styles.list}>
                     <div className={styles.move_arrows}>
                        <p>{t?.myCourses}</p>
                      <LeftRightErrows noBorder moveLeft={moveLeftCourse} moveRight={moveRightCourse} /> 
                     </div>

                     <div 
                         onMouseDown={handleMouseDownCourse}
                         onMouseMove={handleMouseMoveCourse}
                         onMouseUp={handleMouseUpCourse} 
                         ref={blockWrapperRefCourse} 
                         className={cn(styles.list_wrapper, styles.courses, 'noselect')}
                     >
                        {courseList?.map((item, key) => (
                           <CourseBlock 
                              lastCourseRef={lastCourseRef}
                              isHomePage
                              isDragging={isDraggingCourse}
                              refetchCourses={refetchCourses} 
                              key={key} 
                              course={item}
                              maxWidth={'270px'}
                              onConfirmPausedOpen={onConfirmPausedOpen}
                           />
                        ))}

                        {courseList?.length === 0 && !isCoursesLoading &&
                           t?.noAvailableCourses
                        }

                        {isCoursesLoading && 
                           <div className="default_loader_wrapper">
                              <Loader size={"small"}/>
                           </div>
                        }
                     </div>
                  </div>
                     
               </div>
            </section>
         </main>

         <ConfirmModal
               isRemove
               confirmButtonText={t?.deleteText}
               onConfirm={() => onFolderDeleteToggle(FOLDER_STATUS_DELETED)}
               onClose={confirmModal.close}
               isOpen={confirmModal.isActive}
               isLoading={isChangeLoading}
               title={t?.areYouSure}
            />

            <ConfirmModal
               confirmButtonText={t?.hideText}
               onConfirm={() => onFolderHideToggle(FOLDER_STATUS_HIDEN)}
               onClose={confirmHideModal.close}
               isOpen={confirmHideModal.isActive}
               isLoading={isChangeLoading}
               title={t?.areYouSure}
            />

         <ConfirmModal
               icon={pausedIcon}
               confirmButtonText={t?.openCourse}
               onConfirm={onRedirectClickedCourse}
               onClose={confirmPausedModal.close}
               isOpen={confirmPausedModal.isActive}
               title={t?.courseWasPaused}
               subtitle={`${t?.youCannotContinueLearning}.`}
            />
      </MainLayout>
   );
};

export default HomePage;
