import React, { useEffect, useRef, useState } from "react";
import useOutsideClick from "../../../hooks/dom/useOutsideClick";
import crossImg from "../../../assets/images/symbols/cross_grey.svg";
import filtersImg from "../../../assets/images/symbols/filters.svg";

import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import Button from "../../../components/Button/Button";
import Dropdown from "../../../components/Dropdown/Dropdown";
import RenameModal from "../../../components/Modals/RenameModal/RenameModal";
import { DEPARTMENTS } from "../../../constants/departmentsAndPositions";
import DropdownWithImg from "../../../components/DropdownWithImg/DropdownWithImg";
import useHandleModal from "../../../hooks/dom/useHandleModal";

import styles from "./styles.module.scss";
import cn from "classnames";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const StatisticsUsersFilter = ({
   maxHeight,
   onConfirm,
   selectedDepartment,
   setSelectedDepartment,
   coursesManager,
   lastAllCourseRef,
   activeCourseFilter,
   setActiveCourseFilter,
}) => {
   const [isOpen, setIsOpen] = useState(false);
   const [isDropdownInsideOpenD, setIsDropdownInsideOpenD] = useState(false);
   const [isDropdownInsideOpenC, setIsDropdownInsideOpenC] = useState(false);
   const filterRef = useRef();
   const dropdownRef = useRef();
   const inputRef = useRef();
   const newDepartmentModal = useHandleModal();

   const t = useFindTranslationsByString();

   const onClose = () => {
      setIsOpen(false);
   };

   const onToggle = (e) => {
      e.stopPropagation();
      setIsOpen(!isOpen);
      if(isOpen) {
         adjustDropdownHeight();
      }
   };

   const onOpenDepartmentModal = () => {
      newDepartmentModal.open();
      setSelectedDepartment("");
   };

   const onChangeValue = (val) => onChange(val);

   useOutsideClick(dropdownRef, onClose, inputRef);

   const checkDropdownStatusDepartDropdown = (isOpen) => {
      setIsDropdownInsideOpenD(isOpen);
   }

   const checkDropdownStatusCourseDropdown = (isOpen) => {
      setIsDropdownInsideOpenC(isOpen);
   }

   return (
      <div className={cn(styles.filter, isOpen ? styles.opened : '')} onClick={onToggle} ref={inputRef}>
         <div className={cn(styles.click_el, "noselect")}>
            <img src={filtersImg} alt="" />
         </div>

         {isOpen && (
            <div
               onClick={(e) => {
                  e.stopPropagation();
               }}
               style={{ maxHeight: maxHeight ? maxHeight : "" }}
               className={cn(styles.dropdown, isDropdownInsideOpenD ? styles.extraHeight : '', isDropdownInsideOpenC ? styles.extraHeightCourses : "")}
            >
               <div className={styles.dropdown_item}>
                  <Dropdown
                     data={DEPARTMENTS}
                     field={"name"}
                     placeholder={t("Select by department")}
                     value={selectedDepartment}
                     onChange={(item) => setSelectedDepartment(item?.name)}
                     maxHeight={"180px"}
                     searchNeeded
                     createBtnText={t("Create new department")}
                     onOpenCreateModal={onOpenDepartmentModal}
                     checkDropdownStatus={checkDropdownStatusDepartDropdown}
                  />
               </div>
               <div className={styles.dropdown_item}>
                  <DropdownWithImg
                     lastItemRef={lastAllCourseRef}
                     data={coursesManager.courseList}
                     allCoursesSearchedValue={coursesManager?.coursesSearchedValue}
                     setAllCoursesSearchedValue={coursesManager?.setCoursesSearchedValue}
                     placeholder={t("Select course")}
                     value={activeCourseFilter}
                     onChange={setActiveCourseFilter}
                     maxFrontHeight={"60px"}
                     frontColor
                     searchNeeded
                     maxHeight={'175px'}
                     checkDropdownStatus={checkDropdownStatusCourseDropdown}
                  />
               </div>

               <div className={styles.confirmBtn}>
                  <Button
                     disabled={selectedDepartment?.length === 0 && !activeCourseFilter}
                     title={t("Apply")}
                     onClick={(e) => {
                        onConfirm();
                        onClose();
                     }}
                  />
               </div>
            </div>
         )}

         <RenameModal
            key={t("New deparnment")}
            inputValue={selectedDepartment}
            setInputValue={setSelectedDepartment}
            onClose={newDepartmentModal.close}
            isOpen={newDepartmentModal.isActive}
            onConfirm={newDepartmentModal.close}
            title={t("Enter deparnment name")}
         />
      </div>
   );
};

export default StatisticsUsersFilter;
