import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import filtersImg from "../../../assets/images/symbols/filters.svg";
import themeImg from "../../../assets/images/symbols/theme.svg";
import deleteImg from "../../../assets/images/symbols/delete.svg";
import dateIcon from "../../../assets/images/date_icon.svg";
import languages_icon from "../../../assets/images/languages_icon.svg";
import eyeImg from "../../../assets/images/symbols/eye_crossed.svg";
import plusImg from "../../../assets/images/symbols/plus.svg";
import cross from "../../../assets/images/symbols/cross_grey.svg";
import arrow from "../../../assets/images/symbols/arrow.svg";
import left from "../../../assets/images/arrow_left.svg";
import right from "../../../assets/images/arrow_right.svg";
import searchImg from "../../../assets/images/symbols/Search.svg";
import completedIcon from "../../../assets/images/completed_icon.svg";
import failedIcon from "../../../assets/images/failed_icon.svg";
import icon_grid from "../../../assets/images/icon_grid.svg";
import icon_list from "../../../assets/images/icon_list.svg";
import icon_settings from "../../../assets/images/settings.svg";

import cn from "classnames";
import useOutsideClick from "../../../hooks/dom/useOutsideClick";
import useFindTranlsations from "../../../hooks/useFindTranlsations";
import { useSelector } from "react-redux";
import { PART_OF_MENTOR_NAME, ROLE_ID_STUDENT } from "../../../constants/user";
import useMoveScroll from "../../../hooks/useMoveScroll";
import LeftRightErrows from "../../../components/LeftRightArrows/LeftRightArrows";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import { v4 as uuidv4 } from "uuid";
import CustomStaticTooltip from "../../../components/CustomStaticTooltip/CustomStaticTooltip";
import useUserRole from "../../../hooks/useUserRole";
import DropdownWithCheckboxes from "../../../components/DropdownWithCheckboxes/DropdownWithCheckboxes";
import { COURSE_LANGUAGES_LIST } from "../../../constants/languages";

const coursesSortFiltersData = ['First new', 'Old first']

const FiltersBlock = ({
   title,
   onClickPlus,
   setShowHidden,
   isHidden,
   activeFilter,
   setActiveFilter,
   setActiveSortFilter,
   activeSortFilter,
   setActiveTagsFilter,
   activeTagsFilter,
   currentTags,
   isFolder,
   isFolders,
   searchWhat = 'Search folder',
   setStudentCourseFilter,
   studentCourseFilter,
   isCoursesOnCoursesPage = false,
   isCourses,
   byFolder,
   setByFolder,
   paginatedSearchedValue,
   setPaginatedSearchedValue,
   isFolderOnCoursesPage,
   foldersInListView,
   setFoldersInListView,
   createHint,
   isTopic,
   isPlusDropdown,
   onClickCreateTopic,
   onRedirectSettingsPage,
   combinedFilters,
   selectedLanguages,
   onSelectedLanguagesChange,
   clearExtendedFilters
}) => {

   const [showOptions, setShowOptions] = useState(false);
   const [dateFilterOpen, setDateFilterOpen] = useState(false);
   const [showFolderPlusOptions, setShowFolderPlusOptions] = useState(false);
   const [searchedValue, setSearchedValue] = useState('');
   const [showTagsOptions, setShowTagsOptions] = useState(false);
   const [tags, setTags] = useState(currentTags || []);


   const t = useFindTranlsations();

   const asStudent = useSelector(state => state.sidebar.isUserAsStudent)

   const { blockWrapperRef, handleMouseDown, handleMouseMove, handleMouseUp, moveLeft, moveRight,isDragging } = useMoveScroll();

   const currentCompanyId = useSelector(state => state.sidebar.companyId);
   const { userRoleId, userRoleName } = useUserRole(currentCompanyId)

   const optionRef = useRef(null);
   const dropDownRef = useRef(null);
   const dateFilterRef = useRef(null);

   // Options for adding either a course to folder or creating a topic.
   const plusFolderRef = useRef(null);
   const dropDownFolderRef = useRef(null);

   const optionTagRef = useRef(null);
   const dropDownTagRef = useRef(null);
   const openDateRef = useRef(null);

   const closeOptions = () => setShowOptions(false);
   const closeTagsOptions = () => setShowTagsOptions(false);
   const closeFolderPlusOptions = () => setShowFolderPlusOptions(false);
   const closeDateOptions = () => setDateFilterOpen(false);

   useOutsideClick(dropDownRef, closeOptions, optionRef);
   useOutsideClick(dropDownTagRef, closeTagsOptions, optionTagRef);
   useOutsideClick(dropDownFolderRef, closeFolderPlusOptions, plusFolderRef);
   useOutsideClick(dateFilterRef, closeDateOptions, openDateRef);

   const toggleOptions = (e) => {
      setShowOptions((prev) => !prev);
   };

   const toggleDateOptions = (e) => {
      setDateFilterOpen((prev) => !prev);
   };

   const toggleFolderPlusOptions = (e) => {
      setShowFolderPlusOptions((prev) => !prev);
   };

   const toggleTagsOptions = (e) => {
      setShowTagsOptions((prev) => !prev);
   };

   const toggleHidden = () => {
      if (!setActiveFilter) return;

      if (activeFilter === "hidden") {
         setActiveFilter("");
      } else {
         setActiveFilter("hidden");
      }
   };

   const toggleCompleted = () => {
      if (studentCourseFilter === "completed") {
         if(setStudentCourseFilter) setStudentCourseFilter("");
      } else {
         if(setStudentCourseFilter) setStudentCourseFilter("completed");
      }
   };

   const toggleFailed = () => {
      if (studentCourseFilter === "failed") {
        if(setStudentCourseFilter) setStudentCourseFilter("");
      } else {
        if(setStudentCourseFilter) setStudentCourseFilter("failed");
      }
   };

   const toggleDeleted = () => {
      if (!setActiveFilter) return;

      if (activeFilter === "deleted") {
         setActiveFilter("");
      } else {
         setActiveFilter("deleted");
      }
   };

   const handleFilterChange = (filterType) => {
      if (!setActiveSortFilter) return;

      if (activeSortFilter === filterType) {
         setActiveSortFilter("");
      } else {
         setActiveSortFilter(filterType);
      }
      toggleOptions();
   };
   
   const handleTagsChange = (tag) => {
      if (activeTagsFilter?.find(item => item === tag)) setActiveTagsFilter(activeTagsFilter?.filter(item => item !== tag))
      else setActiveTagsFilter([...activeTagsFilter, tag])
  }

   const handleFolderTopicsChange = (topic) => {
      if (activeTagsFilter?.find(item => item?.name === topic?.name)) setActiveTagsFilter(activeTagsFilter?.filter(item => item?.name !== topic?.name))
         else setActiveTagsFilter([...activeTagsFilter, topic])
  }

   useEffect(() => {
      if (currentTags?.length) {
        if (searchedValue) {
          const filteredTags = currentTags.filter(tag =>
            tag.toLowerCase().replace(/\s/g, '').includes(searchedValue.toLowerCase().replace(/\s/g, ''))
          );
          setTags(filteredTags);
        } else {
          setTags(currentTags);
        }
      }
    }, [currentTags, searchedValue]);

    const totalCharacterCount = currentTags?.reduce((accumulator, currentTag) => {
      return accumulator + currentTag.length;
    }, 0);

    const totalCharacterCountTopics = currentTags?.reduce((accumulator, currentTag) => {
      return accumulator + currentTag?.name?.length;
    }, 0);

    const isSettingsIconShow = title && userRoleId && !userRoleName?.includes(PART_OF_MENTOR_NAME) && userRoleId !== ROLE_ID_STUDENT && !asStudent

    const isActive = (item) => {
      if (activeTagsFilter && activeTagsFilter.length > 0) {
        if (typeof activeTagsFilter[0] === 'string') {
          return activeTagsFilter.includes(item);
        } else if (typeof activeTagsFilter[0] === 'object' && activeTagsFilter[0].id !== undefined) {
          return activeTagsFilter.some(tag => tag.id === item.id);
        }
      }
      return false;
    };

    const isExtendedFiltersOpen = coursesSortFiltersData.includes(activeSortFilter) || selectedLanguages?.length;

   return (
      <div className={styles.wrapper}>
         {(isTopic || isFolder)  && 
            <div className={styles.title}>
               <CustomTooltip id={title} limit={68} text={title}/>

               {isSettingsIconShow &&
                  <div 
                      className={styles.visit_settings_page} 
                      onClick={onRedirectSettingsPage}>
                         <CustomStaticTooltip 
                            id={uuidv4()} 
                            icon={icon_settings} 
                            hint={t?.goToSettingsPage}
                         />
                   </div>
               }
           
            </div>
         }
      <div className={cn(styles.filtersBlock, 'noselect')}>

         {isCourses && combinedFilters &&
            <div className={cn(
               styles.block_extended,
               coursesSortFiltersData.includes(activeSortFilter) || selectedLanguages?.length ? styles.activeFilter : '',
               showOptions ? styles.opened : ''
            )}>
            <div className={styles.filter_wrapper} ref={optionRef} onClick={toggleOptions}>

               <img src={filtersImg} alt={""} />
               {
                  isExtendedFiltersOpen ? t?.apply : t?.filter
               }

               {!!isExtendedFiltersOpen && 
                  <img onClick={clearExtendedFilters} className={styles.cross} src={cross} alt="" />
               }
               
               <img
                  className={cn(
                     styles.options_arrow,
                     showOptions ? styles.options_opened : ""
                  )}
                  src={arrow}
                  alt={""}
               />
            </div>

            {showOptions && (
               <div className={styles.option_date} ref={dropDownRef}>
                     <button onClick={(e)=> e.stopPropagation()} className={cn(styles.first_filter, styles.course_by_folder_filter)}>
                           <span onClick={(e)=> {
                              e.stopPropagation();
                              setByFolder(false);
                           }} className={!byFolder ? styles.active_first_filter : ''}>{t?.all}</span>
                           <span onClick={(e)=> {
                              e.stopPropagation();
                              setByFolder(true);
                           }} 
                           className={byFolder ? styles.active_first_filter : ''}>
                              {t?.byFolders}
                           </span>
                     </button>

                     {!byFolder && 
                        <DropdownWithCheckboxes
                           field={"name"}
                           maxHeight={"215px"}
                           data={COURSE_LANGUAGES_LIST}
                           placeholder={
                              selectedLanguages?.length
                                 ? selectedLanguages?.map((item) => item.name).join(", ")
                                 : t?.languages
                           }
                           dontCloseOnClick
                           onChange={onSelectedLanguagesChange}
                           selectedItems={selectedLanguages}
                           icon={languages_icon}
                        />
                     }
                    
                     <div className={styles.date_front} ref={dateFilterRef}>
                        <div className={cn(
                           styles.date_front_first,
                           dateFilterOpen ? styles.active_date : ""
                        )} ref={openDateRef} onClick={toggleDateOptions} >
                           <img className={styles.date_img} src={dateIcon} alt={""} />
                           {coursesSortFiltersData.includes(activeSortFilter) ? t?.returnTranslation(activeSortFilter) : t?.date}
                           <img
                              className={cn(
                                 styles.options_arrow,
                                 dateFilterOpen ? styles.options_opened : ""
                              )}
                              src={arrow}
                              alt={""}
                           />
                        </div>
                        
                        {dateFilterOpen && 
                           <div className={styles.date_filter_options}>
                              {coursesSortFiltersData.map(item => 
                                 <span className={activeSortFilter === item ? styles.active_option : ''} key={item} onClick={() => handleFilterChange(item)}>
                                       {t?.returnTranslation(item)}
                                 </span>
                              )}
                           </div>
                           }
                     </div>
               </div>
            )}
            </div>
         }

         {isFolders && 
            <button className={cn(styles.first_filter, isFolders ? styles.first_filter_folders : '')}>
              <span onClick={()=> setFoldersInListView(false)} className={!foldersInListView ? styles.active_first_filter : ''}>
                  <CustomStaticTooltip 
                     id={uuidv4()}
                     icon={icon_grid}
                     hint={t?.showAsGrid} 
                  />
              </span>
              <span onClick={()=> setFoldersInListView(true)} className={foldersInListView ? styles.active_first_filter : ''}>
                <CustomStaticTooltip 
                     id={uuidv4()}
                     icon={icon_list}
                     hint={t?.showAsList} 
                  />
              </span>
           </button>
         } 

         {!combinedFilters && 
            <div className={cn(
               styles.block,
               coursesSortFiltersData.includes(activeSortFilter) ? styles.activeFilter : '',
               showOptions ? styles.opened : ''
            )}>

            <div className={styles.filter_wrapper} ref={optionRef} onClick={toggleOptions}>
               <img src={filtersImg} alt={""} />
                  {coursesSortFiltersData.includes(activeSortFilter) 
                     ? t.returnTranslation(activeSortFilter) 
                     : t.filter
                  }
               <img
                  className={cn(
                     styles.options_arrow,
                     showOptions ? styles.options_opened : ""
                  )}
                  src={arrow}
                  alt={""}
               />
            </div>

            {showOptions && (
               <div className={styles.options} ref={dropDownRef}>
                  {coursesSortFiltersData.map(item => 
                      <span className={activeSortFilter === item ? styles.active_option : ''} key={item} onClick={() => handleFilterChange(item)}>
                      {t?.returnTranslation(item)}
                   </span>
                     )}
               </div>
            )}
         </div>
         }

         {(isCoursesOnCoursesPage && (userRoleId === ROLE_ID_STUDENT || asStudent)) && 
            <div className={styles.student_btns}>
              <button
                  onClick={toggleCompleted}
                  className={cn(
                     styles.block_medium,
                     studentCourseFilter === "completed" ? styles.activeFilter : ""
                  )}
               >
                  <img src={completedIcon} alt={""} />
                 {t?.completed}
               </button>
              <button
                  onClick={toggleFailed}
                  className={cn(
                     styles.block_medium,
                     studentCourseFilter === "failed" ? styles.activeFilter : ""
                  )}
               >
                  <img src={failedIcon} alt={""} />
                  {t?.failed}
               </button>
            </div>
         }

         {userRoleId && !userRoleName?.includes(PART_OF_MENTOR_NAME) && userRoleId !== ROLE_ID_STUDENT && !asStudent &&
            <>
               <button
                  onClick={toggleHidden}
                  className={cn(
                     styles.block_medium,
                     activeFilter === "hidden" ? styles.activeFilter : ""
                  )}
               >
                  <img src={eyeImg} alt={""} />
                  {t?.hidden}
               </button>

               <div
                  onClick={toggleDeleted}
                  className={cn(
                     styles.block_medium,
                     activeFilter === "deleted" ? styles.activeFilter : ""
                  )}
               >
                  <img src={deleteImg} alt={""} />
                  {t?.deletedText}
               </div>
            </>
         }

        
      {userRoleId && !userRoleName?.includes(PART_OF_MENTOR_NAME) && userRoleId !== ROLE_ID_STUDENT && !asStudent &&
         <div 
           ref={ isPlusDropdown ? plusFolderRef : null } 
           className={cn(styles.block_small, styles.plus)}
           onClick={() => {
            if(isPlusDropdown) { 
               toggleFolderPlusOptions();
            } else {
               onClickPlus()
            }
         }}>
            <CustomStaticTooltip 
                  id={uuidv4()}
                  icon={plusImg}
                  hint={createHint} 
               />

            { showFolderPlusOptions && (
               <div className={cn(styles.options, styles.folder_options)} ref={dropDownFolderRef}>
                  <span onClick={onClickCreateTopic}>{t?.createTopic}</span>
                  <span onClick={onClickPlus}>{t?.addCourses}</span>
               </div>
            )}
         </div>
      }

         <div className={styles.tags_block__wrapper}>
            <div className={styles.search}>
               <CustomStaticTooltip 
                  id={uuidv4()}
                  icon={searchImg}
                  hint={searchWhat === 'Search folder' ? t?.searchFolderName : t?.searchCourseNameTags} 
               />
               <input
                  placeholder={t?.returnTranslation(searchWhat)}
                  value={paginatedSearchedValue ? paginatedSearchedValue : searchedValue}
                  onChange={(e) => {
                     if(setPaginatedSearchedValue) {
                        setPaginatedSearchedValue(e.target.value)
                     } else {
                       setSearchedValue(e.target.value)
                     }
                  }}
               />
            </div>
            <div 
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp} 
                ref={blockWrapperRef} 
                className={styles.tags_wrapper}
                style={ { maxWidth:  isCourses ? '370px' : ''} }
            >
               <button
                     onClick={() => isFolderOnCoursesPage ? setActiveTagsFilter([]) : setActiveTagsFilter([])}
                     className={cn(styles.tag)}
                  >
                    {isFolderOnCoursesPage ? t?.allTopics : t?.allTags}  
                  </button>
               {tags?.map(item => 
                  <button className={cn(
                           styles.tag, 
                           isActive(item) ? styles.active_tag : ''
                        )
                     } key={isFolders ? item?.id : item} onClick={() => isFolderOnCoursesPage ? handleFolderTopicsChange(item) : handleTagsChange(item)}>
                     {isFolders ? item?.name : item}
                  </button>
                  )}
            </div>

            <div className={styles.arrows}>

               {!isFolders && totalCharacterCount > 30 &&
                  <LeftRightErrows moveLeft={moveLeft} moveRight={moveRight} />
               }
            
               {isFolders && totalCharacterCountTopics > 30 &&
                  <LeftRightErrows moveLeft={moveLeft} moveRight={moveRight} />
               }
            </div>

          
         </div>
      
      </div>
      </div>
   );
};

export default FiltersBlock;
