import userAvatar from "../../../../assets/images/avatar.svg";
import cousersCompleted from "../../../../assets/images/courses_completed.svg";
import foldersCompleted from "../../../../assets/images/folders_completed.svg";
import booksRead from "../../../../assets/images/books_read.svg";

import PieChart from "../../../../components/PieChart/PieChart";

import styles from "./styles.module.scss";
import cn from "classnames";
import CustomTooltip from "../../../../components/CustomTooltip/CustomTooltip";
import moment from "moment";
import { Link } from "react-router-dom";
import { parseIsoDurationStr } from "../../../../utils/coursesHelper";
import { PART_OF_MENTOR_NAME } from "../../../../constants/user";
import { useSelector } from "react-redux";
import useUserRole from "../../../../hooks/useUserRole";
import { useEffect, useState } from "react";
import { returnTemporaryLink } from "../../../../utils/usersHelper";
import useFindTranslationsByString from "../../../../hooks/useFindTranslationsByString";

const LeftPanel = ({ chartData, testData }) => {
   const firstName = testData?.user?.firstName;
   const lastName = testData?.user?.lastName;

   const t = useFindTranslationsByString();
   
   const { userRoleName } = useUserRole();
   const [updatedUserAvatar, setUpdatedUserAvatar] = useState('');

   const tipeSpentOnTest = parseIsoDurationStr(testData?.timeSpent || '')  || '';
   
   const studentName = `${firstName ? firstName : ''}${firstName && lastName ? ' ' : ''}${lastName ? lastName : ''}`;

   const getUserAvatar = async () => {
      const avatarLink = await returnTemporaryLink(testData?.user);
      setUpdatedUserAvatar(avatarLink?.[0]?.fileUrl || '');
   }

   useEffect(()=> {
      if(testData?.user) {
         getUserAvatar();
      }
   }, [testData])
   
   const studentAvatar = updatedUserAvatar ? updatedUserAvatar : userAvatar;
   const formattedDate = testData ? moment(testData.updatedAt).format("DD.MM.YY HH:mm") : null;

   const theCourse = testData?.lesson?.module?.course;

   const courseEditLink = `/courses/edit/${theCourse?.id}/default_lang/${theCourse?.defaultLanguage || 'en_US'}/${theCourse?.availableLanguages?.length > 1}`;

   const courseContentLink = `/courses/course/${theCourse?.id}/default_lang/${theCourse?.defaultLanguage || 'en_US'}/${theCourse?.availableLanguages?.length > 1}`;

   const linkToCourse = !userRoleName?.includes(PART_OF_MENTOR_NAME) ? courseEditLink : courseContentLink;

   return (
      <div className={styles.left_panel}>
         <div className={styles.chart_container}>
            <PieChart chartData={chartData} />
         </div>
         <div className={styles.user_profile}>
            <div className={styles.user_avatar}>
               <img src={studentAvatar} alt="avatar" />
               <span>{studentName || ''}</span>
            </div>
            
            <button className={styles.view_profile_btn}>
               <Link to={`/users/profile/${testData?.user?.id}`}>
                  {t("View profile")}
               </Link>
            </button>
         </div>
         {/* <div className={styles.completed_info}>
            FOR FUTURE
            <div className={styles.completed_info_item}>
               <div className={styles.completed_item_left}>
                  <img src={cousersCompleted} alt="" />
                  Courses completed:
               </div>
               <div>
                  0 / <span>15</span>
               </div>
            </div>
            <div className={styles.completed_info_item}>
               <div className={styles.completed_item_left}>
                  <img src={foldersCompleted} alt="" />
                  Folders completed:
               </div>
               <div>
                  0 / <span>10</span>
               </div>
            </div>
            <div className={styles.completed_info_item}>
               <div className={styles.completed_item_left}>
                  <img src={booksRead} alt="" />
                  Books read:
               </div>
               <div>
                  0 / <span>19</span>
               </div>
            </div>
         </div> */}

         <div className={styles.test_info}>
            <div className={styles.test_info_item}>
               <span>{t("Course")}:</span>
               <Link to={linkToCourse}>
                  <CustomTooltip 
                     text={testData?.lesson?.module?.course?.name || ''} 
                     id={testData?.id}
                     limit={22}
                  />
               </Link>
            </div>
            <div className={styles.test_info_item}>
               <span>{t("Task")}:</span>
               <CustomTooltip 
                  text={testData?.lesson?.name || ''} 
                  id={testData?.lesson?.id}
                  maxWidth={'290px'}
                  limit={22}
               />
            </div>
            <div className={styles.test_info_item}>
               <span>{t("Attempt")}:</span>
               <div>
                  {testData?.passAttemptsCounters?.length || 1}
               </div>
            </div>
            <div className={styles.test_info_item}>
               <span>{t("Run time")}:</span>
               <div>{tipeSpentOnTest}</div>
            </div>
            <div className={styles.test_info_item}>
               <span>{t("Done")}:</span>
               <div>{formattedDate || ''}</div>
            </div>
         </div>
      </div>
   );
};

export default LeftPanel;
