import React, {useEffect, useState} from 'react';
import {localStorageGet, localStorageRemove, localStorageSet} from "../../utils/localStorage";
import {useNavigate} from "react-router-dom";
import {pathList} from "../../routes/path";
import Header from "../../components/Header/Header";
import SideBar from "../../components/SideBar/SideBar";
import UserApi from "../../utils/api/UserApi";
import {useDispatch, useSelector} from "react-redux";
import {setInfo} from "../../store/actions/user";
import shortLogo from "../../assets/images/favicon.png";

import cn from 'classnames';
import styles from './styles.module.scss'
import { setCompanyInfo, setCompanyIcon, setCompanyName, setCompanyLanguages, setInfoLoading } from '../../store/actions/sidebar';
import {  PART_OF_MENTOR_NAME, ROLE_ID_STUDENT, ROLE_ID_SUPER_ADMIN } from '../../constants/user';
import { toast } from "react-toastify";
import ToastMsg from '../../components/ToastMsg/ToastMsg';
import useUserRole from '../../hooks/useUserRole';
import BranchApi from '../../utils/api/BranchApi';
import { returnTemporaryCompanyLink, returnTemporaryLink } from '../../utils/usersHelper';

const MainLayout = ({children, darkBg}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isSideBarFixed = useSelector(state => state.sidebar.isFixed);
    const userInfo = useSelector(state => state.user.info);
    const currentCompanyId = useSelector(state => state.sidebar.companyId);
    const availableLanguages = useSelector((state) => state.sidebar.availableLanguages);
    const { userRoleId, userRoleCompany, userRoleName } = useUserRole(currentCompanyId);
  
    const [userDataError, setUserDataError] = useState(false);
 
    const returnFirstCompanyBranch = async (companyId) => {
        const res = await new BranchApi().getCompanyBranches(companyId);
        if (res?.success?.data) { 
         return res?.success?.data[0] || {id: 1}
        }
     }

     const changeCurrentCompany = async (company) => {
         const data = {
            new_current_company_id: company?.id
         }

         const res = await new UserApi().changeCurrentSuperAdminCompany(data);
     }

    const fetchUserData = async () => {
      const res = await new UserApi().getYourUserData();

      if(!availableLanguages?.length) {
         dispatch(setInfoLoading(true));
      }
   
        if (res?.error?.message) {
            setUserDataError(true);
            return toast(<ToastMsg isError text={res.error.message || 'Something went wrong'} />);
        }

        if (res?.success?.data) {
         const restrictedAvatarLink = await returnTemporaryLink(res?.success?.data);

            dispatch(setInfo({
               ...res.success.data,
               avatar: restrictedAvatarLink?.[0]?.fileUrl || null
            }));

            let company;
            let branch;

            if (res.success.data.currentCompany) {
               company = res.success.data.currentCompany;
               branch = await returnFirstCompanyBranch(company?.id);
            } else {
                changeCurrentCompany(res.success.data.userBranchRoles[0]?.branch?.company)
                company = res.success.data.userBranchRoles[0]?.branch?.company;
                branch = res.success.data.userBranchRoles[0]?.branch || { id: 1 };
            }

            const updatedCompanyIcon = await returnTemporaryCompanyLink(res?.success?.data?.id, company);

            const dataAlreadyExists = currentCompanyId && res?.success?.data?.currentCompany && +currentCompanyId === res?.success?.data?.currentCompany?.id;

            if(dataAlreadyExists) {
               if(updatedCompanyIcon) {
                  dispatch(setCompanyName(company?.name));
                  dispatch(setCompanyLanguages(company?.availableLanguages));
                  dispatch(setCompanyIcon(updatedCompanyIcon?.[0]?.fileUrl));
                  dispatch(setInfoLoading(false));
               }
               return;
            }

            const companyIcon = updatedCompanyIcon?.[0]?.fileUrl;
            const logo = company?.companyLogo?.iconLogo ? companyIcon : shortLogo;

            dispatch(setCompanyLanguages(company?.availableLanguages));
            dispatch(setCompanyInfo(company.id, company.name, logo, branch?.id));
        }

        dispatch(setInfoLoading(false));
    }

    useEffect(() => {
        const token = localStorageGet("token", null);

        if (!!token) {
           fetchUserData();
        } else {
            localStorageRemove("token")

            if (![pathList.login.path, pathList.signup.path]?.some(item => location.pathname?.includes(item))) {
                navigate(pathList.login.path);
            }
        }
    }, [])

    const disableCopying = userRoleCompany?.companySecurity?.contentAndContextMenuDisabled  && (userRoleId === ROLE_ID_STUDENT 
                                   || !userRoleName?.includes(PART_OF_MENTOR_NAME))

    return (
        <div className={cn(styles.mainLayout, disableCopying ? "noselect" : "")}>
            <SideBar />
            <div className={cn(styles.content, isSideBarFixed && styles.sidebar_fixed)}>
                <Header/>

                <div className={cn(styles.children, darkBg && styles.darkBg)}>
                    {userDataError ? <div className={styles.error}>Something went wrong!</div> : children}
                </div>
            </div>

        </div>
    );
};

export default MainLayout;