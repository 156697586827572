import Base from "./Base";

export default class LessonApi extends Base {
    createLesson(data) {
      return super.post("api/v1/lesson", data);
    }

    deleteLesson(lessonID) {
      return super.delete(`api/v1/lesson/${lessonID}`);
    }

    deleteManyLessons(data) {
      return super.delete(`api/v1/lesson`, data);
    }

    updateLesson(lessonID, data) {
      return super.put(`api/v1/lesson/${lessonID}`, data);
    }

    changeLessonsSequence(data) {
      return super.put(`api/v1/lesson/change-sequences`, data);
    }
  
    getLessonsInModule(moduleID) {
        return super.get(`api/v1/module/${moduleID}/lesson`);
    }

    getOneLesson(lessonID, contentNeeded = true, signal, languageHeader) {
         //  The only place where content lesson exists.
         return super.get(`api/v1/lesson/${lessonID}`, null, signal, contentNeeded ? 'lesson:default+content' : '', languageHeader);
    }
    // Create/update/delete of questions in array
    processQuestions(data) {
      return super.post(`api/v1/question/process-many`, data);
    }

    getAllQuestionsInLesson(lessonID, serializationGroup, languageHeader) {
      return super.get(`api/v1/lesson/${lessonID}/question`, null, null, serializationGroup, languageHeader);
    }

    getAnswersInQuestion(questionID) {
      return super.get(`api/v1/question/${questionID}/answer`);
    }

    updateQuestionsSequence(data) {
      return super.put(`api/v1/question/change-display-sequences`, data);
    }

    updateAnswersSequence(data) {
      return super.put(`api/v1/answer/change-sequences`, data);
    }

    getPresignedQuestionFilesUploadUrls(data) {
      return super.post(`api/v1/question/get-aws-s3-presigned-upload-urls`, data)
    }

    getPresignedQuestionFileNoEntityCheck(data) {
      return super.post(`v1/question/get-aws-s3-presigned-upload-urls-no-entity-check`, data)
    }

    addFilesToLesson(lessonID, data) {
      return super.post(`api/v1/lesson/${lessonID}/add-many-lesson-filenames`, data);
    }

    addOneFileToLesson(lessonID, data) {
      return super.post(`api/v1/lesson/${lessonID}/add-lesson-file`, data);
    }

    getFiles(lessonID) {
      return super.get(`api/v1/lesson/${lessonID}/retrieve-lesson-files`);
    }

    getFile(lessonID, fileID) {
      return super.get(`api/v1/lesson/${lessonID}/retrieve-lesson-files/${fileID}`);
    }

    removeFiles(lessonID, data) {
      return super.delete(`api/v1/lesson/${lessonID}/remove-and-delete-lesson-files`, data);
    }

    removeOneFile(lessonID, fileID) {
      return super.delete(`api/v1/lesson/${lessonID}/remove-and-delete-lesson-files/${fileID}`);
    }

    getLessonVersions(lessonID) {
      return super.get(`api/v1/lesson/${lessonID}/versions`)
    }

    getPresignedUploadUrls(data) {
      return super.post(`api/v1/lesson/get-aws-s3-presigned-upload-urls`, data)
    }

    putUploadedFile(presignedUrl, data, config) {
      return super.put(presignedUrl, data, config)
    }

    getLastVisitedLesson(userID, courseID) {
      return super.get(`api/v1/user/${userID}/course/${courseID}/list-last-visited-lesson`);
   }

    getCompletedUserLesssonsInCourse(userID, courseID, languageHeader) {
      return super.get(`api/v1/lesson?limit=300&&filter[get_completed_lessons_by_user_id]=${userID}&filter[get_completed_lessons_by_course_id]=${courseID}`, null, null, null, languageHeader);
   }

   updateLastVisitedLesson(userID, courseID, data) {
      return super.put(`api/v1/user/${userID}/course/${courseID}/update-last-visited-lesson`, data);
   }
}