import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import { QueryClient, QueryClientProvider } from 'react-query';
import {configureStore} from './store';
import {Provider} from 'react-redux';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const store = configureStore();
const queryClient = new QueryClient();

ReactDOM.render(
    <Provider store={store}>
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
               <App/>
               <ToastContainer
                  theme={'colored'}
                  draggablePercent={60}
                  position={'top-right'}
                  closeButton={false}
               />
            </BrowserRouter>
        </QueryClientProvider>
    </Provider>,
    document.getElementById('root')
);