import { useState, useMemo } from "react";

const useSortData = (data) => {
   const [sortColumn, setSortColumn] = useState("");
   const [sortDirection, setSortDirection] = useState("");

   const handleSort = (column) => {
      if (sortColumn === column) {
         setSortDirection(sortDirection === "asc" ? "desc" : "asc");
      } else {
         setSortColumn(column);
         setSortDirection("asc");
      }
   };

   const sortedData = useMemo(() => {
      if (!sortColumn) return data;

      return [...data].sort((a, b) => {
         try {
            const aValue = a[sortColumn] ?? "";
            const bValue = b[sortColumn] ?? "";

            // Handle empty values by prioritizing non-empty values
            if (aValue === "" && bValue !== "") return 1;
            if (aValue !== "" && bValue === "") return -1;

            if (sortDirection === "asc") {
               if (typeof aValue === "string" && typeof bValue === "string") {
                  return aValue.localeCompare(bValue);
               } else {
                  return aValue - bValue;
               }
            } else {
               if (typeof aValue === "string" && typeof bValue === "string") {
                  return bValue.localeCompare(aValue);
               } else {
                  return bValue - aValue;
               }
            }
         } catch (error) {
            console.error("Error sorting data:", error);
            return 0;
         }
      });
   }, [data, sortColumn, sortDirection]);

   return {
      sortedData,
      handleSort,
      sortColumn,
      sortDirection,
   };
};

export default useSortData;
