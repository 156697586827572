import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import styles from "./styles.module.scss";
import Button from "../../Button/Button";
import plus from "../../../assets/images/plus.svg";
import downloadIcon from "../../../assets/images/file_upload.svg";
import uploadIcon from "../../../assets/images/upload.svg";
import Input from "../../Input/Input";
import { useEffect, useState } from "react";
import Dropdown from "../../Dropdown/Dropdown";
import DropdownUsers from "./DropdownUsers/DropdownUsers";
import TeamApi from "../../../utils/api/TeamApi";
import { toast } from "react-toastify";
import ToastMsg from "../../ToastMsg/ToastMsg";
import AgencyApi from "../../../utils/api/AgencyApi";
import { DEPARTMENTS, POSITIONS } from "../../../constants/departmentsAndPositions";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import RenameModal from "../RenameModal/RenameModal";
import { useSelector } from "react-redux";
import Loader from "../../Loader/Loader";
import PaginatedDropdown from "../../PaginatedDropdown/PaginatedDropdown";
import useGetActiveUsersOfCompany from "../../../hooks/api/useGetActiveUsersOfCompany";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const CreateTeamModal = ({ onClose, isOpen, fetchTeams, course, selectUserModal, selectedUsers,setSelectedUsers, onRemove }) => {
   const [teamName, setTeamName] = useState('');
   const [selectedMentor, setSelectedMentor] = useState({});

   const t = useFindTranslationsByString();

   const [selectedDepartment, setSelectedDepartment] = useState('');
   const [selectedPosition, setSelectedPosition] = useState('');
  
   const [isCreateLoading, setIsCreateLoading] = useState(false);
   const currentCompanyBranchId = useSelector(state => state.sidebar.currentCompanyBranchId);
   const currentCompanyId = useSelector(state => state.sidebar.companyId);

   const newDepartmentModal = useHandleModal();
   const newPositionModal = useHandleModal();
   
   const { setCurrentPage: setCurrentSpecialUsersPage,
      userList: specialUsersList, 
      setUserList: setSpecialUsersList, 
      isUsersLoading: isSpecialUsersLoading, 
      totalUsers: totalSpecialUsers, 
      searchedValue: specialUsersSearchedValue, 
      setSearchedValue: setSpecialUsersSearchedValue 
   } = useGetActiveUsersOfCompany(currentCompanyId, false, true, selectedDepartment, selectedPosition);

   const lastSpecialUserRef = useIntersectionObserver(
   () => {
   if (!isSpecialUsersLoading && specialUsersList?.length < totalSpecialUsers) {
      if(specialUsersList?.length === 0) {
         setCurrentSpecialUsersPage(1);
      } else {
         setCurrentSpecialUsersPage((prev) => prev + 1);
      }
   }
   },
   [isSpecialUsersLoading, totalSpecialUsers]
   );

   const onSelectDepartment = (department) => {
      setSelectedDepartment(department.name);
   }

   const onSelectPosition = (position) => {
      setSelectedPosition(position.name)
   }

   const onSelectMentor = (user) => {
      setSelectedMentor(user);
   };

   const onOpenDepartmentModal = () => {
      newDepartmentModal.open();
      setSelectedDepartment('');
   }

   const onOpenPositionModal = () => {
      newPositionModal.open();
      setSelectedPosition('');
   }

   const closeModal = () => {
      onClose();
      setSelectedDepartment('');
      setSelectedPosition('');
      setTeamName('');
      setSelectedMentor({});
      setSelectedUsers([]);
    }

   const changeMentor = async () => {
      if(!selectedMentor) return;
      
      const data = {
         new_mentor_id: selectedMentor?.id,
      }

      const res = await new TeamApi().updateTeam(params?.id, data);
      
      if(res?.success?.message) {
         toast(<ToastMsg text={res?.success?.message} />)
         fetchTeams();
      }

      if(res?.error?.message) {
         toast(<ToastMsg text={res?.error?.message} isError />)
      }
   }

   const fetchTeamCourses = async (team) => {
      const res = await new TeamApi().getAvailableCourses(team?.id);
      if(res?.success?.data) return res?.success?.data;
   };

   const onTeamCreate = async () => {
      if (selectedUsers.length === 0) {
        return toast(<ToastMsg text={"Add at least one student"} isError />);
      }

      if (teamName.length === 0) {
        return toast(<ToastMsg text={"Name of the team cannot be empty"} isError />);
      }

      setIsCreateLoading(true);

    
      const createTeamObj = {
        name: teamName,
        branch_id: currentCompanyBranchId ? currentCompanyBranchId : 1,
        ...(selectedMentor ? {mentor_id: selectedMentor?.id} : {}),
        ...(selectedDepartment ? {department: selectedDepartment } : {}),
        ...(selectedPosition ? {position: selectedPosition } : {})
      };
    
      const resTeamCreate = await new TeamApi().createTeam(createTeamObj);
      if (resTeamCreate?.error?.message) {
        setIsCreateLoading(false);
        return toast(<ToastMsg text={resTeamCreate?.error?.message} isError />);
      }

      const addUserPromises = selectedUsers.map(async (user) => {
        const resAddUsers = await new TeamApi().addUserToTeam(
          resTeamCreate?.success?.data?.team?.id,
          user.id,
        );
        return resAddUsers;
      });

      toast(<ToastMsg text={resTeamCreate?.success?.message} />);
      setIsCreateLoading(false);
      fetchTeams();
      closeModal();
    
      await Promise.all(addUserPromises); 
    };

   return (
      <ModalLayout
         onClose={closeModal}
         isOpen={isOpen}
         maxHeight={"505px"}
         maxWidth={"416px"}
         customBgColor={"#212224"}
         withCross
         visibleOverflow
      >
         <div className={styles.create_team_wrapper}>
            <div className={styles.title}>{t("Create")}</div>
            <div className={styles.inputs_wrapper}>
               <Input
                  value={teamName}
                  placeholder={t("Name")}
                  onChangeValue={(e)=> setTeamName(e.target.value)}
               />
               <Dropdown
                     data={DEPARTMENTS?.filter(d => d.id)}
                     field={"name"}
                     value={selectedDepartment}
                     placeholder={t("Department * Optionally")}
                     onChange={onSelectDepartment}
                     createBtnText={t("Create new department")}
                     maxHeight={"360px"}
                     onOpenCreateModal={onOpenDepartmentModal}
                  />

               <Dropdown
                  data={POSITIONS?.filter(d => d.id)}
                  maxHeight={"320px"}
                  field={"name"}
                  value={selectedPosition}
                  placeholder={t("Position * Optionally")}
                  onChange={onSelectPosition}
                  createBtnText={t("Create new position")}
                  onOpenCreateModal={onOpenPositionModal}
               />

                <PaginatedDropdown
                  searchNeeded
                  searchValue={specialUsersSearchedValue}
                  setSearchValue={setSpecialUsersSearchedValue}
                  maxHeight={'250px'}
                  data={specialUsersList}
                  lastItemRef={lastSpecialUserRef}
                  field={'name'}
                  placeholder={selectedMentor.name ? selectedMentor.name : t('Mentor')}
                  value={selectedMentor.name}
                  onChange={onSelectMentor}
                  isUsers
               />

               <DropdownUsers
                  placeholder={t('Choose')}
                  value={selectedUsers[0]?.email}
                  data={selectedUsers}
                  onClickPlus={selectUserModal.open}
                  onClickMinus={onRemove}
               />
            </div>
            <div className={styles.btns}>
               <Button title={t("Cancel")} isBlack onClick={closeModal} />
               <Button disabled={isCreateLoading}  title={t("Create")} onClick={onTeamCreate} />
            </div>

            <div className='default_loader_wrapper'>
               {isCreateLoading && 
                  <Loader size={"small"} />
               }
            </div>

            <RenameModal
               key={"New deparnment"}
               inputValue={selectedDepartment}
               setInputValue={setSelectedDepartment}
               onClose={newDepartmentModal.close}
               isOpen={newDepartmentModal.isActive}
               onConfirm={newDepartmentModal.close}
               title={t("Enter deparnment name")}
            />
            <RenameModal
               key={"New position"}
               inputValue={selectedPosition}
               setInputValue={setSelectedPosition}
               onClose={newPositionModal.close}
               isOpen={newPositionModal.isActive}
               onConfirm={newPositionModal.close}
               title={t("Enter position name")}
            />
         </div>
      </ModalLayout>
   );
};

export default CreateTeamModal;
