export const DEFAULT_LANG_LOCALE = {
   id: 1,
   name: "English",
   displayed_code: "en",
   code: "en_US",
};

export const COURSE_LANGUAGES_LIST = [
   { id: 1, name: "English", displayed_code: "en", code: "en_US" },
   { id: 2, name: "Ukrainian", displayed_code: "ua", code: "uk_UA" },
   { id: 3, name: "Russian", displayed_code: "ru", code: "ru_RU" },
   { id: 4, name: "Moldovan", displayed_code: "md", code: "mo_MD" },
   { id: 5, name: "Polish", displayed_code: "pl", code: "pl_PL" },
   { id: 6, name: "Romanian", displayed_code: "ro", code: "ro_RO" },
];

export const ALL_LANGUAGES_LIST = [
   { id: 1, name: "English", displayed_code: "en", code: "en_US" },
   { id: 2, name: "Ukrainian", displayed_code: "uk", code: "uk_UA" },
   { id: 3, name: "Russian", displayed_code: "ru", code: "ru_RU" },
   { id: 4, name: "Moldovan", displayed_code: "mo", code: "mo_MD" },
   { id: 5, name: "Polish", displayed_code: "pl", code: "pl_PL" },
   { id: 6, name: "Romanian", displayed_code: "ro", code: "ro_RO" },
   { id: 7, name: "Spanish", displayed_code: "es", code: "es_ES" },
   { id: 8, name: "Portuguese", displayed_code: "pt", code: "pt_PT" },
   { id: 9, name: "French", displayed_code: "fr", code: "fr_FR" },
   { id: 10, name: "German", displayed_code: "de", code: "de_DE" },
   { id: 11, name: "Italian", displayed_code: "it", code: "it_IT" },
   { id: 12, name: "Dutch", displayed_code: "nl", code: "nl_NL" },
   { id: 13, name: "Greek", displayed_code: "el", code: "el_GR" },
   { id: 14, name: "Swedish", displayed_code: "sv", code: "sv_SE" },
   { id: 15, name: "Danish", displayed_code: "da", code: "da_DK" },
   { id: 16, name: "Norwegian", displayed_code: "no", code: "no_NO" },
   { id: 17, name: "Finnish", displayed_code: "fi", code: "fi_FI" },
   { id: 18, name: "Hungarian", displayed_code: "hu", code: "hu_HU" },
   { id: 19, name: "Czech", displayed_code: "cs", code: "cs_CZ" },
   { id: 20, name: "Slovak", displayed_code: "sk", code: "sk_SK" },
   { id: 21, name: "Bulgarian", displayed_code: "bg", code: "bg_BG" },
   { id: 22, name: "Croatian", displayed_code: "hr", code: "hr_HR" },
   { id: 23, name: "Serbian", displayed_code: "sr", code: "sr_RS" },
   { id: 24, name: "Slovenian", displayed_code: "sl", code: "sl_SI" },
   { id: 25, name: "Lithuanian", displayed_code: "lt", code: "lt_LT" },
   { id: 26, name: "Latvian", displayed_code: "lv", code: "lv_LV" },
   { id: 27, name: "Estonian", displayed_code: "et", code: "et_EE" },
   { id: 28, name: "Icelandic", displayed_code: "is", code: "is_IS" },
   { id: 29, name: "Maltese", displayed_code: "mt", code: "mt_MT" },
   { id: 30, name: "Irish", displayed_code: "ga", code: "ga_IE" },
   { id: 31, name: "Welsh", displayed_code: "cy", code: "cy_GB" },
   { id: 32, name: "Catalan", displayed_code: "ca", code: "ca_ES" },
   { id: 33, name: "Basque", displayed_code: "eu", code: "eu_ES" },
   { id: 34, name: "Galician", displayed_code: "gl", code: "gl_ES" },
   { id: 35, name: "Breton", displayed_code: "br", code: "br_FR" },
   { id: 36, name: "Scottish Gaelic", displayed_code: "gd", code: "gd_GB" },
   { id: 37, name: "Albanian", displayed_code: "sq", code: "sq_AL" },
   { id: 38, name: "Mandarin Chinese", displayed_code: "zh", code: "zh_CN" },
   { id: 39, name: "Cantonese Chinese", displayed_code: "yue", code: "yue_HK" },
   { id: 40, name: "Tagalog", displayed_code: "tl", code: "tl_PH" },
   { id: 41, name: "Vietnamese", displayed_code: "vi", code: "vi_VN" },
   { id: 42, name: "Korean", displayed_code: "ko", code: "ko_KR" },
   { id: 43, name: "Japanese", displayed_code: "ja", code: "ja_JP" },
   { id: 44, name: "Arabic", displayed_code: "ar", code: "ar_SA" },
   { id: 45, name: "Hindi", displayed_code: "hi", code: "hi_IN" },
   { id: 46, name: "Urdu", displayed_code: "ur", code: "ur_PK" },
   { id: 47, name: "Gujarati", displayed_code: "gu", code: "gu_IN" },
   { id: 48, name: "Punjabi", displayed_code: "pa", code: "pa_IN" },
   { id: 49, name: "Bengali", displayed_code: "bn", code: "bn_BD" },
   { id: 50, name: "Tamil", displayed_code: "ta", code: "ta_IN" },
   { id: 51, name: "Telugu", displayed_code: "te", code: "te_IN" },
   { id: 52, name: "Persian", displayed_code: "fa", code: "fa_IR" },
   { id: 53, name: "Hebrew", displayed_code: "he", code: "he_IL" },
   { id: 54, name: "Swahili", displayed_code: "sw", code: "sw_KE" },
   { id: 55, name: "Amharic", displayed_code: "am", code: "am_ET" },
   { id: 56, name: "Somali", displayed_code: "so", code: "so_SO" },
   { id: 57, name: "Haitian Creole", displayed_code: "ht", code: "ht_HT" },
   {
      id: 58,
      name: "Portuguese (Brazilian)",
      displayed_code: "pt_BR",
      code: "pt_BR",
   },
   {
      id: 59,
      name: "French (Canadian)",
      displayed_code: "fr_CA",
      code: "fr_CA",
   },
   { id: 60, name: "Hmong", displayed_code: "hmn", code: "hmn_US" },
   { id: 61, name: "Navajo", displayed_code: "nv", code: "nv_US" },
   { id: 62, name: "Cherokee", displayed_code: "chr", code: "chr_US" },
];

export const TEXT_DOMAINS = [
   { id: 1, name: "All", label: "all" },
   { id: 2, name: "Authorization", label: "authorization" },
   { id: 3, name: "Home", label: "home" },
   { id: 4, name: "Courses and folders", label: "courses_and_folders" },
   { id: 5, name: "Course settings", label: "course_settings" },
   { id: 6, name: "Folder settings", label: "folder_settings" },
   { id: 7, name: "Course edit", label: "course_edit" },
   { id: 8, name: "Course content", label: "course_content" },
   { id: 9, name: "Knowledge check", label: "knowledge_check" },
   { id: 10, name: "Users rights", label: "user_rights" },
   {
      id: 11,
      name: "Profile from admin view",
      label: "profile_from_admin_view",
   },
   { id: 12, name: "Personal profile", label: "profile" },
   { id: 13, name: "Companies", label: "companies" },
   { id: 14, name: "Notifications", label: "notifications_front" },
   { id: 15, name: "Notification messages", label: "notifications_back" },
   { id: 17, name: "Modals", label: "modals" },
   { id: 18, name: "Common", label: "common" },
];

export const initialDomain = {
   id: 1,
   name: "All",
   label: "all",
};

export const defaultColumn = {
   name: "English (default)",
   label: "translatedValue",
   code: "en_US",
};

export const DEFAULT_TEXT = {
   authorization: {
      group: "authorization",
      email: "Your email",
      password: "Password (8+ characters)",
      stayLogged: "Stay logged",
      forgotPassword: "Forgot a password?",
      signIn: "Sign in",
      changedMind: "I changed my mind",
      goBack: "Go back",
      send: "Send",
   },
   home: {
      group: "home",
      greeting: "Hey",
      recentTestsResults: "Your most recent tests and results are below",
      coursesRatio: "The ratio of completed courses to those assigned",
      myFolders: "My folders",
      myCourses: "My courses",
   },
   courses_and_folders: {
      group: "courses_and_folders",
      showAsGrid: "Show as grid",
      showAsList: "Show as list",
      chooseCourses: "Choose courses",
      searchFolder: "Search folder",
      searchFolderName: "Search by folder name",
      searchCourseNameTags: "Search by course name or course tags",
      allTopics: "All topics",
      allTags: "All tags",
      byFolders: "By folders",
      all: "All",
      usersAccessCoursesFolder:
         "Users that have access to courses inside the folder",
      usersThatHaveAccessToCourse: "Users that have access to the course",
      usersAccessCoursesTopic:
         "Users that have access to courses inside the topic",
      coursesInsideFolder: "Courses inside the folder",
      coursesInsideTopic: "Courses inside the topic",
   },
   course_settings: {
      group: "course_settings",
      publishHideCourse: "Publish or hide the course",
      notOptimize:
         "Not optimize working talk conversation switch angel. Revision deck relaxation quarter last",
      customizeAppearanceCertificate:
         "Here you can customize the appearance of the certificate",
      congratulatoryTextCourseEnd:
         "Congratulatory text about the end of the course, which will appear in the course finale",
      limitCourseAvailability:
         "Limit the availability of the course to a certain date, or the number of days to complete it",
      thumbnailImage: "Thumbnail image",
      recommended: "Recommended",
      passSequence: "Pass sequence",
      linearMode:
         "Linear mode gives students access to the lesson in sequential order. Nonlinear mode - to all lessons at once",
      linear: "Linear",
      nonLinear: "Non linear",
      progressiveValidationLock: "Progressive Validation Lock",
      progressiveValidationLockDesc:
         "When 'Progressive Validation Lock' is set to 'yes,' it prohibits students from accessing the next test until the previous one they attempted has been marked as 'checked.'",
      averageGrade: "Average grade",
      passScore:
         "Set the score for all passed tests that students must achieve in order to pass the course",
      passAttemptsLimit: "Pass attempts limit",
      setPassAttemptsLimit:
         "Set the amount of attempts for all tests. When user fail last attempt he or she could continue the course only in case admin set the test to status 'on check'",
      issueCertificate: "Issue certificate",
      issueCertificateDesc:
         "Turn on this feature to provide official acknowledgment of achievements and qualifications for yourself or your users",
      detailedCourseCompletion: "Detailed course completion",
      courseCompletionDesc:
         "Student must successfully pass all tests within the course",
      showCourseScores: "Show course scores",
      customizeCertificate: "Customize certificate",
      duplicateInAnotherCompany: "Duplicate in another company",
      noCompaniesToDuplicate:
         "There are no companies to duplicate the course, add one!",
   },
   folder_settings: {
      group: "folder_settings",
      publishHideFolder: "Publish or hide the folder",
      organizeManageInformation:
         "Organize and manage information. The changes made will be reflected in the preview",
   },
   course_edit: {
      group: "course_edit",
      provideCommentsTask:
         "Here you can provide comments on the task, make an introduction, provide hints",
      multipleChoice: "Multiple choice",
      oneChoice: "One choice",
      trueFalse: "True/False",
      sequence: "Sequence",
      writtenAnswer: "Written answer",
      fileUpload: "File upload",
      videoAnswer: "Video answer",
      compare: "Compare",
      questionType: "Choose the type of question",
      contentAvailabilityDate:
         "The function allows you to make content available to students on a specific date",
      protectContent:
         "Enable protection of texts and pictures of the lesson from copying",
      passingScore: "Passing score",
      setPassingScore:
         "Set the score that students must achieve in order to pass the tests",
      shuffleAnswers: "Shuffle answers",
      shuffleQuestionsAnswers:
         "Shuffle questions and answers so they appear randomly",
      passAttempts: "Pass attempts",
      limitPassAttempts:
         "Limit the number of times students attempt an assignment",
      timer: "Timer",
      limitTestTime:
         "Limit the amount of time students can spend taking the test. When the time runs out, the number of earned points will be calculated automatically",
      pointsCalculationOptions:
         "Calculation of points in the presence of several options",
      pointsCalculationDescription:
         "Soft counting counts a certain number of points if not all correct answers are chosen. Hard does not count points if not all correct answers are selected",
      softCounting: "Soft",
      hardCounting: "Hard",
      showTestResults: "Show test results",
      showTestResultsDescription:
         "The student can see the correctness of his answers and where he made his mistake after passing the test",
      showScoreToStudent: "Show score to student",
      showScoreToStudentDescription:
         "The student will see the number of points he has scored after passing the test",
      welcomeMessage: "Welcome message",
      writeWelcomeText:
         "Write the text of the greeting that will be displayed before the start of the text",
      congratulatoryMessage: "Congratulatory message",
      writeCongratulatoryText:
         "Write a congratulatory text about the end of the test, which the student will see after the test is completed",
      taskSettings: "Task settings",
      lessonSettings: "Lesson settings",
   },
   course_content: {
      group: "course_content",
      welcomeMessage: "Welcome message",
      welcomeText:
         "Dear student, Welcome to the test. This test is designed to assess your understanding of the course material and your readiness for the upcoming challenges. Before you begin, please take a moment to read the following instructions:",
      testDurationUnlimited:
         "Test Duration: You will have unlimited time. If you close the test before submitting the results won't be saved",
      testDuration: "Test Duration: You will have",
      manageTimeText:
         "Make sure to manage your time wisely to answer all the questions. If you close the test before submitting the results won't be saved",
      timeForTest: "Time for test:",
      makeChoice: "Please make a choice",
      selectSeveralCorrectAnswers: "Please select several correct answers:",
      selectOneCorrectAnswer: "Please select 1 correct answer",
      matchCorrectOrder: "Please match the correct order",
      writeAnswerBelow: "Please write your answer below",
      typeAnswer: "Type your answer",
      uploadFileBelow: "Please upload the file below",
      uploadVideoBelow: "Please upload the video below",
      matchCorrectOrderPairs: "Please match the correct order of pairs",
      almostDone: "Almost done",
      testCompletionNotification:
         "As soon as an administrator checks your test, you will be notified of the result. You will also be able to find verified tests in the 'Knowledge Check' section",
      okBackToMain: "Ok, back to the main page",
      startTest: "Ready to start the test?",
      testWithdrawWarning:
         "Please note that if you withdraw from the test during the test, the result will be canceled",
      testResultsInProgress: "Test results are in progress:",
      testCheckedNotification:
         "Please wait until the test has been checked, you cannot move on to the next lesson. As soon as an administrator checks your test, you will be notified of the result. You will also be able to find verified tests in the 'Knowledge Check' section",
      userPerformanceAssessment:
         "Given that you're monitoring the user's progress in the course and have access to the course materials, you can navigate to the test page and assess the user's performance now",
      finishCourse: "Finish the course",
      previousLesson: "Previous lesson",
      nextLesson: "Next lesson",
      goToResultsPage: "Go to results page",
      courseFinished: "The course has been finished!",
      courseFinishedByUser: "The course has been finished by user!",
      testNotPassed: "The test was not passed yet",
   },
   knowledge_check: {
      group: "knowledge_check",
      automaticGrading: "Automatic grading for task",
      answerTypes: "Types of answers",
      correctAnswerChosen: "Correct answer chosen by the student",
      correctAnswerPoints:
         "The correct answer chosen by the student. Points are credited to",
      wrongAnswerChosen: "Wrong answer chosen by the student",
      wrongAnswerPoints:
         "The wrong answer chosen by the student. Points are not credited to",
      correctAnswerNotChosen: "Correct answer not chosen by the student",
      correctAnswerNotChosenPoints:
         "The correct answer is not chosen by the student. No points are awarded",
      questionCheckedAutomatically:
         "Question that was checked automatically by system",
      noAnswerFromUser: "No answer from user!",
      finalTestResult: "Final result of the test",
      testScoring: "Scoring of the test",
      studentScore: "Student score",
      assignmentCheckedAutomatically:
         "This assignment was checked automatically!",
   },
   knowledge_check: {
      group: "knowledge_check",
      automaticGradingForTask: "Automatic grading for task",
      typesOfAnswers: "Types of answers",
      correctAnswerChosenByStudent: "Correct answer chosen by the student",
      theCorrectAnswerChosenByStudentPointsCreditedTo:
         "The correct answer chosen by the student. Points are credited to",
      wrongAnswerChosenByStudent: "Wrong answer chosen by the student",
      theWrongAnswerChosenByStudentPointsNotCreditedTo:
         "The wrong answer chosen by the student. Points are not credited to",
      correctAnswerNotChosenByStudent:
         "Correct answer not chosen by the student",
      theCorrectAnswerNotChosenByStudentNoPointsAwarded:
         "The correct answer is not chosen by the student. No points are awarded",
      questionCheckedAutomaticallyBySystem:
         "Question that was checked automatically by system",
      noAnswerFromUser: "No answer from user!",
      finalResultOfTest: "Final result of the test",
      gradeForTheTask: "Grade for the task",
      rightPairs: "Right pairs",
   },
   statistics: {
      group: "statistics",
      usersRegisteredInAll: "Users registered in all",
      activeUsersInAll: "Active users in all",
      totalNumberOfUsers: "Total number of users",
      usersRegistered: "Users registered",
      usersWhoHaveBeenOnline: "Users who have been online",
      countingCourses: "Counting courses",
      coursesInTotal: "Courses in total",
      resultsOfTasksInTheTests: "Results of tasks in the tests",
      resultsOfTheTests: "Results of the tests",
      numberOfCertificatesIssued: "Number of certificates issued",
      certificatesInTotal: "Certificates in total",
      successRatingAmongTheCompanies: "Success rating among the companies",
      usersInTotal: "Users in total",
      usersInCompany: "Users in company",
      usersRating: "Users rating",
      usersAverageScoreForAllCompletedCourses:
         "User's average score for all completed courses",
      averageScoreOfUsersThatHaveBeenPassedThisCourse:
         "Average score of users that have been passed this course",
      averageScoreForCompletedCourses: "Average score for completed courses",
      averageScoreForAllCompletedTestsInACourse:
         "Average score for all completed tests in a course",
   },
   user_rights: {
      group: "user_rights",
      editRoleCapabilitiesAndPermissions:
         "Here you can edit role capabilities and their permissions",
      enterRoleName: "Enter role name",
      homework: "Homework",
      acceptedHomework: "Accepted homework",
      accessToAcceptedHomeworkSection:
         "Access to the accepted homework section with the ability to cancel the check",
      viewingLessons: "Viewing lessons",
      abilityToViewLessons: "The ability to view lessons",
      lessonModules: "Lesson modules",
      abilityToCustomizeAndConnectModules:
         "The ability to customize and connect the appearance of the modules",
      addingStudentToTeam: "Adding a student to the team",
      abilityToAddNewStudentManually:
         "The ability to add a new student manually or by list",
      showStudentContactDetails: "Show student contact details",
      abilityToViewStudentContactDetails:
         "The ability to view student contact details",
      abilityToAddAndRemoveUsers: "The ability to add and remove users",
      tags: "Tags",
      addingEditingDeletingTags: "Adding, editing, deleting tags",
      mainSettings: "Main settings",
      appearance: "Appearance",
      abilityToEditDescriptionAndPhoto:
         "The ability to edit the description and photo of the course",
      studentPermissions: "Student Permissions",
      editingStudentPermissionsSettings: "Editing student permissions settings",
      roleCapabilities: "Role Capabilities",
      editRightsSettingsForAllRoles: "Edit rights settings for all roles",
      addRole: "Add role",
   },
   profile_from_admin_view: {
      group: "profile_from_admin_view",
      editUserData: "Edit user data",
      blockTheUser: "Block the user",
      enterUserAccount: "Enter user account",
      accessToMaterials: "Access to materials",
   },
   profile: {
      group: "profile",
      configureYourAccount: "You can configure your account here",
      activity: "Activity",
      profileImage: "Profile image",
      uploadNewImage: "Upload new image",
      uploadNewFile: "Upload new file",
      dropFileHereToUpload: "Drop file here to upload or",
      deleteImage: "Delete image",
      personalInformation: "Personal Information",
      enterYourName: "Enter your name",
      enterYourLastName: "Enter your last name",
      enterYourEmail: "Enter your email",
      enterYourCity: "Enter your city",
      selectTimezone: "Select a timezone",
      contactInformation: "Contact information",
      locationInformation: "Location information",
      changePassword: "Change password",
      oldPassword: "Old password",
      newPassword: "New password",
      confirmPassword: "Confirm password",
   },
   companies: {
      group: "companies",
      companies: "Companies",
      switchCompany: "Switch company",
      createCompany: "Create company",
      nameOfCompany: "Name of company",
      uploadLogotypes: "Upload logotypes",
      security: "Security",
      disableContentSelection: "Disable content selection and context menu",
      studentsWillNotBeAbleToSelectContent:
         "Students will not be able to select content text using the cursor or keyboard. They will also not be able to right-click to see the context menu",
      strongPassword: "Strong password",
      pupilPasswordsMustBeAtLeast:
         "Pupil passwords must be at least 8 characters long. They should include uppercase and lowercase letters, numbers and special characters (e.g. !, ?, # or %)",
      termsOfUse: "Terms of use",
      checkTheTermsOfUseBox:
         "Check the 'I agree to the terms of use' box, without which registration will not be possible",
      usersAndRoles: "Users and roles",
      deleteFromCompany: "Delete from company",
      settingUpSectionsWithinAgencies: "Setting up sections within agencies",
   },
   notifications_front: {
      group: "notifications_front",
      markAsRead: "Mark as read",
      onlyRead: "Only read",
      onlyUnread: "Only unread",
      mute: "Mute",
      markAllAsRead: "Mark all as read",
      notifications: "Notifications",
   },
   modals: {
      group: "modals",
      startTheTest: "Start the test",
      congratulations: "Congratulations!",
      youHaveSuccessfullyCompletedTheTest:
         "You have successfully completed the test and are ready to move on to the next tasks",
      yourScore: "Your score",
      goToTheNextStep: "Go to the next step",
      notEnoughPoints: "Not enough points",
      tryAgain: "You have not scored enough points. Try again!",
      accessBlocked: "Access blocked",
      completeThePreviousSteps:
         "Complete the previous steps to unlock this stage",
      youSureYouWantLeaveTheTest: "You sure you want leave the test",
      unsuccessfulAttempt: "Unsuccessful attempt",
      youDidNotReceiveEnoughPoints:
         "Unfortunately, you did not receive enough points to successfully complete the course",
      wouldYouLikeToTryAgain: "Would you like to try again?",
      areYouSure: "Are you sure?",
      youWontBeAbleToRestoreIt: "You won't be able to restore it",
      uploadingFilesWillBeStopped: "Uploading files will be stopped.",
      youWillNotBeAbleToChangeYourChoice:
         "You will not be able to change your choice",
      statisticsAndProgress: "Statistics and progress",
      trackStatisticsAndProgress:
         "Track statistics and progress of course participants",
      newUsers: "New users",
      addParticipantsToTheCourse: "Add participants to the course at any time",
      answersToQueries: "Answers to queries",
      respondToRequests: "Respond to requests from course participants",
      versionOfTheCourse: "version of the course",
      courseTitleIn: "Course title in",
      dontForgetToChangeContent:
         "Don't forget to change all the content of the course to the selected language. If the version is not activated in the course settings yet you can activate it any moment there",
      chooseLanguageToTakeTheCourse:
         "You can choose the language in which you want to take the course",
      chooseLanguage: "Choose a language",
      chooseRole: "Choose role",
      participants: "Participants",
      selectTeamMentor: "Select team mentor",
      selectRole: "Select role",
      addTeamMentor: "Add team mentor",
      addMentor: "Add mentor",
      renameTeam: "Rename team",
      changeTeamName: "Change team name",
      coursesInTeam: "Courses in team",
      usersInTeam: "Users in team",
      changeUserInfo: "Change user info",
      changeRole: "Change role",
      asInitialUserRole: "As initial user role",
      asStudent: "As student",
      youCanAddAdminAsStudentOrAdmin:
         "You can add an admin as student or as admin",
      addingAnAdmin: "Adding an admin",
      uploadIconLogotype: "Upload Icon logotype",
      uploadLargeLogotype: "Upload large logotype",
      areYouSureYouWannaLeaveTheLesson:
         "Are you sure you wanna leave the lesson. Changes wasn't saved",
      itWillBeDeletedFromAllLanguages:
         "It will be deleted from all existed language versions of the course",
      courseWasPaused: "The course was paused",
      youCannotContinueLearning:
         "You cannot continue learning, but the lessons are available. If there is a deadline, it will be suspended",
      allTranslatedInformationWillBeLost:
         "All translated information will be lost!",
      deleteCourseFrom: "Delete course from",
      youSureYouWantToDeleteCourse: "You sure you want to delete course from",
      userCanChooseAndTakeCourse:
         "The user can choose and take a course in this language",
      userCannotSeeOrTakeCourse:
         "The user cannot see and cannot take the course in this language",
      chooseCourseStatusForUser: "Choose course status for user",
      chooseUserStatusOnCourse: "Choose user status 'on course'",
      userMayTakeTheTest: "The user may take the test",
      userCannotContinueLearning:
         "The user cannot continue learning, but the lessons are available to him. If there is a deadline, it will be suspended",
      userDoesNotSeeTheCourse:
         "The user does not see the course. His progress in the course will be deleted irretrievably",
      createUsers: "Create users",
      viewCertificate: "View certificate",
      youDidntPassAllTestsInCourse: "You didn't pass all tests in course!",
      toFinishTheCourseAllTestsShouldBePassed:
         "To finish the course all tests should be passed",
      youCannotContinueLearning:
         "You cannot continue learning, but completed lessons and finished tests are available. If there is a deadline, it will be suspended",
      renameTopic: "Rename topic",
      renameTeam: "Rename team",
      emailNotify: "Email notify",
      youCanCloseTheModalAndReopenIt:
         "You can close the modal and reopen it while you are editing the lesson. Uploading files will not be stopped or paused.",
      createSpecialUser: "Create a special user",
      createNewCourse: "Create new course",
      createNewFolder: "Create new folder",
      createNewTopic: "Create new topic",
      becomeTheCuratorOfThisCourse: "Become the curator of this course!",
      transitioningFromStudentToSpecialRole:
         "Since we are transitioning from the Student role to a Special role, all previous materials will remain available only in 'Student Mode,' which can be changed in the personal user profile. Any new courses added will be available for the user with the Special role.",
      noNotificationsToShowYet: "No notifications to show yet",
      youWillSeeUsefulInformationHereSoon:
         "You’ll see useful information here soon. Stay tuned!",
   },
   common: {
      group: "common",
      back: "Back",
      selectTeam: "Select team",
      role: "Role",
      admin: "Admin",
      mentor: "Mentor",
      curator: "Curator",
      student: "Student",
      noDepartment: "No department",
      noPosition: "No position",
      blocked: "Blocked",
      students: "Students",
      addTeam: "Add team",
      amount: "Amount",
      undefined: "Undefined",
      usersFromListProcessed: "users from the list have been processed!",
      enterAName: "Enter a name",
      enterEmail: "Enter email",
      enterANewTitle: "Enter a new title",
      first: "First",
      continue: "Continue",
      addStudent: "Add Student",
      addSpecialUser: "Add special user",
      addStudents: "Add students",
      addUsers: "Add users",
      uploadFromCSV: "Upload from CSV",
      previous: "Previous",
      sendInvite: "Send invite",
      fillInRequiredFields: "Fill in the required fields",
      from: "From",
      to: "To",
      apply: "Apply",
      active: "Active",
      paused: "Paused",
      deleteFromTheBin: "Delete from the bin",
      downloadAsPDF: "Download as PDF",
      downloadDone: "Download done",
      downloadAsPNG: "Download as PNG",
      next: "Next",
      checkRole: "Check role",
      averageScore: "Average score",
      thereAreNoUsers: "There are no users",
      thereAreNoData: "There are no data",
      rating: "Rating",
      created: "Created",
      lastModified: "Last modified",
      certificateId: "Certificate id",
      available: "Available",
      assignedCourses: "Assigned courses",
      completedCourses: "Completed courses",
      studentAmount: "Student amount",
      courseScore: "Course score",
      ratingFromUsers: "Rating from users",
      sunday: "Sunday",
      monday: "Monday",
      tuesday: "Tuesday",
      wednesday: "Wednesday",
      thursday: "Thursday",
      friday: "Friday",
      saturday: "Saturday",
      daily: "Daily",
      weekly: "Weekly",
      monthly: "Monthly",
      yearly: "Yearly",
      platform: "Platform",
      statistics: "Statistics",
      knowledgeCheck: "Knowledge check",
      usersRights: "Users rights",
      companies: "Companies",
      certificates: "Certificates",
      basicInfo: "Basic info",
      byPlatform: "By platform",
      byCourses: "By courses",
      byScore: "By score",
      byCompany: "By company",
      byUser: "By user",
      integration: "Integration",
      noComments: "No comments!",
      noFileWasAdded: "No file was added!",
      noVideoWasAddedByAdmin: "No video was added by an admin!",
      initialContent: "Initial content",
      correct: "Correct",
      attempt: "Attempt",
      runTime: "Run time",
      viewProfile: "View profile",
      question: "Question",
      close: "Close",
      awaitingChecking: "Awaiting checking",
      checked: "Checked",
      forToday: "For today",
      forYesterday: "For yesterday",
      inAWeek: "In a week",
      inAMonth: "In a month",
      selectDateRange: "Select date range",
      selectMainLanguage: "Select main language",
      selectAvailableLanguages: "Select available languages",
      selectDate: "Select date",
      selectAll: "Select all",
      selectLanguage: "Select language",
      selectCourse: "Select course",
      timeForCoursePassing: "Time for passing the course",
      copyTable: "Copy table",
      default: "Default",
      timeRemaining: "Time remaining:",
      finalResult: "Final result",
      navigation: "Navigation",
      finishAttempt: "Finish attempt",
      gotIt: "Got it",
      retry: "Retry",
      retakeCourse: "Retake course",
      nextTime: "Next time",
      later: "Later",
      createTopic: "Create topic",
      create: "Create",
      of: "of",
      upTo: "Up to",
      or: "or",
      true: "True",
      partially: "Partially",
      false: "False",
      addCourses: "Add courses",
      deleteFromFolder: "Delete from folder",
      deleteRole: "Delete role",
      tests: "tests",
      ongoing: "Ongoing",
      complete: "Complete",
      notStarted: "Not started",
      certificate: "Certificate",
      courses: "Courses",
      topicsAndCourses: "Topics and courses",
      topics: "Topics",
      folders: "Folders",
      delete: "Delete",
      signOut: "Sign out",
      deleteLesson: "Delete lesson",
      deleteAll: "Delete all",
      hide: "Hide",
      edit: "Edit",
      settings: "Settings",
      duplicate: "Duplicate",
      changeToVisible: "Change to visible",
      change: "Change",
      rename: "Rename",
      restore: "Restore",
      filter: "Filter",
      filters: "Filters",
      firstNew: "First new",
      oldFirst: "Old first",
      all: "All",
      hidden: "Hidden",
      deleted: "Deleted",
      completed: "Completed",
      failed: "Failed",
      onCheck: "On check",
      onRework: "On rework",
      reworkCourse: "Rework course",
      pass: "Pass",
      done: "Done",
      languages: "Languages",
      language: "Language",
      templates: "Templates",
      styles: "Styles",
      type: "Type",
      date: "Date",
      goToSettingsPage: "Go to settings page",
      publish: "Publish",
      basicSettings: "Basic settings",
      certificate: "Certificate",
      noAvailableCourses: "No available courses",
      endOfCourse: "End of course",
      accessToTheCourse: "Access to the course",
      accessToTheFolder: "Access to the folder",
      accessToTheTopic: "Access to the topic",
      title: "Title",
      courseTags: "Course tags",
      notFound: "Not found",
      selectSpecialUser: "Select special user",
      select: "Select",
      users: "Users",
      teams: "Teams",
      roles: "Roles",
      rights: "Rights",
      company: "Company",
      course: "Course",
      selectByDepartment: "Select by department",
      selectByPosition: "Select by position",
      selectByRole: "Select by role",
      selectByCompany: "Select by company",
      chooseAtLeastOneUserOrTeam: "Choose at least one user or team",
      createNewDepartment: "Create new department",
      departmentOptionally: "Department * Optionally",
      positionOptionally: "Position * Optionally",
      createNewPosition: "Create new position",
      newPosition: "New position",
      newDepartment: "New department",
      newName: "New name",
      colourPresets: "Colour presets",
      folderIcon: "Folder icon",
      folder: "Folder",
      folderName: "Folder name",
      courseName: "Course name",
      testName: "Test name",
      main: "Main",
      creator: "Creator",
      description: "Description",
      popupText: "Pop-up text",
      enterATitle: "Enter a title",
      enterADescription: "Enter a description",
      enterAComment: "Enter a comment",
      enterDepartmentName: "Enter department name",
      enterPositionName: "Enter position name",
      returnToYourAccount: "Return to your account",
      exportToCSV: "Export to CSV",
      addAccess: "Add access",
      addAccessToCompany: "Add access to the company",
      saveChanges: "Save changes",
      decline: "Decline",
      accept: "Accept",
      confirm: "Confirm",
      copied: "Copied",
      cancel: "Cancel",
      selected: "Selected",
      linkToTheCourse: "Link to the course",
      linkToTheFolder: "Link to the folder",
      search: "Search",
      preview: "Preview",
      team: "Team",
      name: "Name",
      surname: "Surname",
      email: "Email",
      department: "Department",
      position: "Position",
      was: "Was",
      progress: "Progress",
      status: "Status",
      endDate: "End date",
      passTime: "Pass time",
      openCourse: "Open course",
      theory: "Theory",
      structure: "Structure",
      moduleName: "Module name",
      test: "Test",
      score: "Score",
      result: "Result",
      profile: "Profile",
      blockUser: "Block user",
      unblockUser: "Unblock user",
      replaceImage: "Replace image",
      deleteImage: "Delete image",
      availableLanguage: "Available language",
      numberOfCourses: "Number of courses",
      numberOfUsers: "Number of users",
      viewAsStudent: "View as student",
      copyCourseLink: "Copy course link",
      defaultModule: "Default module",
      selectToDelete: "Select to delete",
      lesson: "Lesson",
      task: "Task",
      test: "Test",
      copyLesson: "Copy lesson",
      copy: "Copy",
      versions: "Versions",
      versionHistory: "Version history",
      introduction: "Introduction",
      uploadFiles: "Upload files",
      uploadFile: "Upload file",
      uploadAVideo: "Upload a video",
      uploadVideo: "Upload video",
      fileOptions: "File options",
      comments: "Comments",
      visibleOnlyToAdmins: "Visible only to admins",
      fileFormat: "File format",
      enterTheIntroductionOptionally: "Enter the introduction * Optionally",
      enterTheMessage: "Enter the message",
      enterTheComments: "Enter the comments",
      saveQuestions: "Save questions",
      clearAll: "Clear all",
      titleAndDescription: "Title and description",
      answers: "Answers",
      answer: "Answer",
      addAnswer: "Add answer",
      availabilityDate: "Availability date",
      protection: "Protection",
      hours: "hours",
      minutes: "minutes",
      mins: "mins",
      secs: "secs",
      defaultText: "Default text",
      dropNewQuestion: "Drop new question",
      allUsers: "All users",
      users: "Users",
      user: "User",
      userScore: "User score",
      markedAsStudent: "Marked as student",
      addAsStudent: "Add as student",
      wasNootOnline: "Was not online",
      noAccess: "No access",
      removeAccess: "Remove access",
      selectCompany: "Select company",
      selectUser: "Select user",
      addCompany: "Add company",
      add: "Add",
      module: "Module",
      somethingWentWrong: "Something went wrong",
      youAreOnCompany: "You are on this company",
      loading: "Loading",
      more: "More",
      choose: "Choose",
      selectTimezone: "Select a time zone",
      sections: "Sections",
      safetySettings: "Safety settings",
   },
};
 
export const returnTranslation = (items, text) => {
   const normalizedText = String(text)?.toLowerCase();
 
   const item = items?.find((item) =>
     item?.originalValue?.toLowerCase() === normalizedText
   );
 
   return item?.translatedValue ? item.translatedValue : text;
 };