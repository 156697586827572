import iconInfo from "../../../../assets/images/icon_info.svg";
import correctImg from "../../../../assets/images/symbols/check.svg";
import iconWrong from "../../../../assets/images/symbols/cross_white.svg";
import commentIcon from "../../../../assets/images/comment_icon.svg";
import fileIcon from "../../../../assets/images/file_icon.svg";
import rightErrow from "../../../../assets/images/arrow_right.svg";
import iconWrongRed from "../../../../assets/images/close.svg";
import partiallyCorrectIcon from "../../../../assets/images/partially_correct_icon.svg";
import correctImgGreen from "../../../../assets/images/correct_green.svg";
import compareImg from "../../../../assets/images/compare_icon.svg";

import CommonTypes from "../AnswersTypes/CommonTypes";
import { COMPARE_ANSWER_CODE, FILE_UPLOAD_ANSWER_CODE, MULTIPLE_CHOICE_ANSWER_CODE, ONE_CHOICE_ANSWER_CODE, RATE_ANSWER_CODE, SEQUENCE_ANSWER_CODE, STARS_ANSWER_CODE, TRUE_FALSE_ANSWER_CODE, VIDEO_ANSWER_CODE, WRITTEN_ANSWER_CODE } from "../../../../utils/questionsHelper";
import { commonTypes, typesThatNeededTips } from "../../../../constants/tests";

import CheckQuestionResult from "../CheckQuestionResult/CheckQuestionResult.js.js";
import WrittenAnswer from "../AnswersTypes/WrittenAnswer";
import FileAnswer from "../AnswersTypes/FileAnswer";
import SequenceAnswers from "../AnswersTypes/SequenceAnswers";
import CompareAnswer from "../AnswersTypes/CompareAnswers";
import RateAnswer from "../AnswersTypes/RateAnswer";
import StarsAnswer from "../AnswersTypes/StarsAnswer";
import TextArea from "../../../../components/TextArea/TextArea";

import styles from "./styles.module.scss";
import cn from "classnames";
import ReactPlayer from "react-player";
import Dropzone from "react-dropzone-uploader";
import CustomTooltip from "../../../../components/CustomTooltip/CustomTooltip";
import TestResultsBlock from "../../../../components/TestResultsBlock/TestResultsBlock";
import SecondLoader from "../../../../components/SecondLoader/SecondLoader.js";
import VideoFromUser from "../AnswersTypes/VideoFromUser.js";
import EmptyBlock from "../../../../components/EmptyBlock/EmptyBlock.js";
import useFindTranslationsByString from "../../../../hooks/useFindTranslationsByString.js";

const CenterBlock = ({
   lessonContent,
   lessonName,
   isTheory,
   testQuestionsList,
   openAnswersInfoModal,
   commentInputValue,
   fileName,
   addManualResult,
   chartData,
   testData,
   dataLoading
}) => {

   const t = useFindTranslationsByString();

   const renderLessonContent = () => {
      if (lessonContent?.length && isTheory) {
         return (
            <div className={cn(styles.lesson_wrapper, styles.center_block)}>
               <h3 className={styles.lesson_name}>{lessonName}</h3>
               <div dangerouslySetInnerHTML={{ __html: lessonContent }} />
            </div>
         );
      } else if (lessonContent?.length === 0 && isTheory) {
         return (
            <div className={cn(styles.lesson_wrapper, styles.center_block)}></div>
         );
      }
      if(!lessonContent && isTheory) return <EmptyBlock />
      return null;
   };

   const renderedLesssonContent = renderLessonContent();

   if(dataLoading) {
      return <div className={styles.emptyBlock}>
         <div className="default_loader_wrapper"><SecondLoader /></div>
      </div>
   }

   return (
      <>
        {renderedLesssonContent}

         {!isTheory && (
            <div className={styles.center_block}>
               {testQuestionsList.map((question, idx) => (
                  <div
                     className={styles.test_question_item}
                     key={question.id}
                  >
                     <div className={styles.small_title}>
                        {t("Question")} {idx + 1}. {t(question.name)}
                     </div>
                     <div className={styles.question_name}>
                        {question.title ? question?.title : question.content}
                     </div>

                     {question?.description && (
                        <div className={styles.description}>
                           <span>{question.description}</span>
                        </div>
                     )}

                     {question.type === VIDEO_ANSWER_CODE && 
                         <FileAnswer fileLink={question?.content} key={question.id} questionId={question?.id} isVideo courseId={testData?.lesson?.module?.course?.id} />
                     }

                     {question?.type === SEQUENCE_ANSWER_CODE && testData?.lesson?.isShowTestResult && (
                        <div className={styles.sequence_right_answers_wrapper}>
                           <div className={styles.small_title}>
                              <span>{t("Right pairs")}</span>
                           </div>
                           <div className={styles.sequence_right_answers}>
                              {question?.sequence?.map((answer, answerIdx) => (
                                 <div
                                    key={answerIdx}
                                    className={styles.sequence_right_answer}
                                 >
                                    {answerIdx + 1} <span> {answer} </span>
                                 </div>
                              ))}
                           </div>
                        </div>
                     )}

                     {question?.type === COMPARE_ANSWER_CODE && testData?.lesson?.isShowTestResult && (
                        <div className={styles.sequence_right_answers_wrapper}>
                           <div className={styles.small_title}>
                              <span>{t("Right pairs")}</span>
                           </div>
                           <div className={styles.sequence_right_answers}>
                              {question?.options?.map((option, optionIdx) => (
                                 <div
                                    key={optionIdx}
                                    className={styles.compare_right_wrapper}
                                 >
                                    <div className={styles.compare_left_side}> {option} </div>

                                    <div className={styles.compare_right_side}>
                                       <img src={compareImg} alt="" />
                                       {
                                          question?.answers?.find(
                                             (answer) =>
                                                answer.matchingOption ===
                                                optionIdx
                                          )?.initialContent
                                       }
                                    </div>
                                 </div>
                              ))}
                           </div>
                        </div>
                     )}

                     {typesThatNeededTips.includes(question?.type) && testData?.lesson?.isShowTestResult && (
                        <div className={styles.small_title}>
                           <span>
                              {t("Answers")}
                           </span>
                           <img
                              onClick={openAnswersInfoModal}
                              src={iconInfo}
                              alt=""
                           />
                        </div>
                     )}

                     {!typesThatNeededTips.includes(question?.type) && (
                        <div className={styles.small_title}>
                           <span>{t("Answers")}</span>
                        </div>
                     )}

                     {commonTypes.includes(question?.type) && (
                        <div className={styles.answers_block}>
                           {question?.answers?.map((answer, answerIdx) => (
                              testData?.lesson?.isShowTestResult ?
                                 <CommonTypes
                                    key={answerIdx}
                                    answer={answer}
                                    answerIdx={answerIdx}
                                    correctImg={correctImg}
                                    iconWrong={iconWrong}
                                    correctImgGreen={correctImgGreen}
                                    iconWrongRed={iconWrongRed}
                                 />
                              : null
                           ))}

                           <CheckQuestionResult
                              key={idx}
                              question={question}
                              correctImg={correctImg}
                              iconWrong={iconWrong}
                              partiallyCorrectIcon={partiallyCorrectIcon}
                           />
                        </div>
                     )}

                     {question.type === WRITTEN_ANSWER_CODE && (
                        <div className={styles.answers_block}>
                           {question?.answers?.map((answer, answerIdx) => (
                              <WrittenAnswer
                                 key={answerIdx}
                                 answer={answer}
                                 answerIdx={answerIdx}
                                 correctImg={correctImg}
                                 iconWrong={iconWrong}
                                 correctImgGreen={correctImgGreen}
                                 iconWrongRed={iconWrongRed}
                              />
                           ))}

                           <CheckQuestionResult
                              key={idx}
                              question={question}
                              correctImg={correctImg}
                              iconWrong={iconWrong}
                              manualChecked
                              partiallyCorrectIcon={partiallyCorrectIcon}
                           />
                        </div>
                     )}

                     {question.type === FILE_UPLOAD_ANSWER_CODE && (
                        <div className={styles.answers_block}>
                           <FileAnswer key={question?.id} fileLink={question?.answers?.[0]?.content} questionId={question?.id} />
                           
                           <CheckQuestionResult
                              key={idx}
                              question={question}
                              correctImg={correctImg}
                              iconWrong={iconWrong}
                              manualChecked
                              partiallyCorrectIcon={partiallyCorrectIcon}
                           />
                        </div>
                     )}

                     {question.type === VIDEO_ANSWER_CODE && (
                        <div className={styles.answers_block}>
                           <div className={styles.video_wrapper}>
                              <VideoFromUser question={question} courseId={testData?.lesson?.module?.course?.id}/>
                           </div>

                           <CheckQuestionResult
                              key={idx}
                              question={question}
                              correctImg={correctImg}
                              iconWrong={iconWrong}
                              manualChecked
                              partiallyCorrectIcon={partiallyCorrectIcon}
                           />
                        </div>
                     )}

                     {question.type === SEQUENCE_ANSWER_CODE && (
                        <div className={styles.answers_block}>
                           {question?.sequenceAnswers?.map((answer, answerIdx) => (
                              testData?.lesson?.isShowTestResult ? 
                                 <SequenceAnswers
                                    key={answerIdx}
                                    answer={answer}
                                    answerIdx={answerIdx}
                                    correctImg={correctImg}
                                    iconWrong={iconWrong}
                                    correctImgGreen={correctImgGreen}
                                    iconWrongRed={iconWrongRed}
                                 />
                              : null
                           ))}

                           <CheckQuestionResult
                              key={idx}
                              question={question}
                              correctImg={correctImg}
                              iconWrong={iconWrong}
                              partiallyCorrectIcon={partiallyCorrectIcon}
                           />
                        </div>
                     )}

                     {question.type === COMPARE_ANSWER_CODE && (
                        <div className={styles.answers_block}>
                           {question?.answers?.map((answer, answerIdx) => (
                              testData?.lesson?.isShowTestResult ? 
                                 <CompareAnswer
                                    key={answerIdx}
                                    answer={answer}
                                    question={question}
                                    answerIdx={answerIdx}
                                    compareImg={compareImg}
                                    correctImg={correctImg}
                                    iconWrong={iconWrong}
                                    correctImgGreen={correctImgGreen}
                                    iconWrongRed={iconWrongRed}
                                 />
                                 : null
                           ))}

                           <CheckQuestionResult
                              key={question.id}
                              question={question}
                              correctImg={correctImg}
                              iconWrong={iconWrong}
                              partiallyCorrectIcon={partiallyCorrectIcon}
                           />
                        </div>
                     )}

                     {question.type === RATE_ANSWER_CODE && (
                        <div className={styles.answers_block}>
                           <RateAnswer key={question.id} question={question} />
                           <CheckQuestionResult
                              key={idx}
                              question={question}
                              correctImg={correctImg}
                              iconWrong={iconWrong}
                              partiallyCorrectIcon={partiallyCorrectIcon}
                           />
                        </div>
                     )}

                     {question.type === STARS_ANSWER_CODE && (
                        <div className={styles.answers_block}>
                           <StarsAnswer key={question.id} question={question} total={question?.rating}/>
                           <CheckQuestionResult
                              key={idx}
                              question={question}
                              correctImg={correctImg}
                              iconWrong={iconWrong}
                              partiallyCorrectIcon={partiallyCorrectIcon}
                           />
                        </div>
                     )}

                  </div>
               ))}

               <TestResultsBlock 
                  status={+testData?.status} 
                  comment={testData?.commentTests?.[0]} 
                  score={chartData[1][1] || testData?.successPercentage} 
                  commentInputValue={commentInputValue} 
                  courseId={testData?.lesson?.module?.course?.id}
               />

            </div>
         )}
      </>
   );
};

export default CenterBlock;
