import React, { useEffect, useState } from "react";
import useSortableAndFuzzyData from "../../../hooks/useSortableAndFuzzyData";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import styles from "./styles.module.scss";
import {
   REQUESTED_STATUS_REJECT,
   REQUESTED_STATUS_ACCEPT,
   USER_STATUS_ACTIVE,
   USER_STATUS_BANNED,
} from "../../../constants/user";
import UserApi from "../../../utils/api/UserApi";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import StatisticsBodyItem from "./StatisticsBodyItem";
import { toast } from "react-toastify";
import ToastMsg from "../../../components/ToastMsg/ToastMsg";
import BranchApi from "../../../utils/api/BranchApi";
import EmptyBlock from "../../../components/EmptyBlock/EmptyBlock";
import { useSelector } from "react-redux";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const StatisticsTable = ({
   columns,
   data,
   searchedValue,
   tableFor,
   loading,
   maxHeight,
   maxWordsLength = 19,
   lastElementRef,
   minWidth,
   rowClickable,
   orderBy,
   setOrderBy,
   order,
   setOrder,
}) => {
   const navigate = useNavigate();

   const t = useFindTranslationsByString();

   const handleSort = (column) => {
      const columnMappings = {
         "user_score": { orderBy: "course_user_score", orderToggle: true },
         "progress": { orderBy: "progress", orderToggle: true },
         "course_name": { orderBy: "name", orderToggle: true },
         "#": { orderToggle: true }
      };
   
      const columnMapping = columnMappings[column];
      if (columnMapping) {
         const { orderBy, orderToggle } = columnMapping;
         setOrderBy(orderBy || "");
         if (orderToggle) {
            setOrder(order === "asc" ? "desc" : "asc");
         }
      }
   };
   

   return (
      <div style={{ maxHeight }} className={styles.table_container}>
         <table className={cn(styles.table, minWidth ? styles.minWidth : "")}>
            <thead className={styles.thead}>
               <tr>
                  {columns?.map((column, index) => (
                     <th
                        key={column}
                        onClick={() => handleSort(column)}
                        className={cn(styles.header, "noselect")}
                     >
                        <span>
                           {column === "percentage"
                              ? "%"
                              : t(column.replaceAll("_", " "))
                           }
                        </span>
                        <div className={styles.arrow_wrapper}>
                           <span className={styles.arrow}></span>
                        </div>

                        {column === "user_score" && (
                           <span className={styles.hint}>
                              {t("Average score for all completed tests in a course")}
                           </span>
                        )}
                     </th>
                  ))}
               </tr>
            </thead>
            <tbody className={styles.tbody}>
               <TransitionGroup component={null}>
                  {data?.map((row, index) => (
                     <CSSTransition
                        key={`row-${index}`}
                        classNames="fade"
                        timeout={100}
                     >
                        <>
                           <StatisticsBodyItem
                              tableFor={tableFor}
                              row={row}
                              columns={columns}
                              index={index}
                              maxWordsLength={maxWordsLength}
                              rowClickable={rowClickable}
                           />
                        </>
                     </CSSTransition>
                  ))}
               </TransitionGroup>
               <tr
                  className={styles.triggerRefetch}
                  ref={lastElementRef ? lastElementRef : null}
               ></tr>
            </tbody>
         </table>

         {data?.length === 0 && !loading && <EmptyBlock imgSmaller />}
      </div>
   );
};

export default StatisticsTable;
