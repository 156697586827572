import { useEffect, useState, useMemo } from "react";
import EmptyBlock from "../../../components/EmptyBlock/EmptyBlock";
import UniverseProgress from "../../../components/UniverseProgress/UniverseProgress";
import {
   COURSES_JUST_FIELDS,
   COURSES_JUST_HEADERS,
   COURSES_TABLE_HEADERS,
} from "../../../constants/courses";
import { DEFAULT_DATA_LIMIT, ROLE_ID_SUPER_ADMIN, TABS_USER_PROFILE_ADMIN_VIEW, TEAMS_HEADERS_LESS_INFO } from "../../../constants/user";
import CoursesTable from "../CoursesTable/CoursesTable";
import KnowledgeCheckSubheader from "../Subheader/KnowledgeCheckSubheader";
import Subheader from "../Subheader/Subheader";

import styles from "./styles.module.scss";
import cn from "classnames";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import BranchApi from "../../../utils/api/BranchApi";
import SelectCourseModal from "../../../components/Modals/SelectCourseModal/SelectCourseModal";
import UserApi from "../../../utils/api/UserApi";
import ToastMsg from "../../../components/ToastMsg/ToastMsg";
import { toast } from "react-toastify";
import TableBlock from "../../UserPage/TableBlock/TableBlock";
import UsersTestsTable from "../../KnowlegeCheckPage/UsersTestsTable/UsersTestsTable";
import { USERS_TESTS_COLUMN, USERS_TESTS_COLUMN_CHECKED, USER_TESTS_COLUMN_CHECKED, statusMap } from "../../../constants/tests";
import KnowledgeCheckAPI from "../../../utils/api/KnowledgeCheckAPI";
import { useSelector } from "react-redux";
import { filterActiveCourses, filterOnCheck, filterOther, parseIsoDurationStr, testsMap, transformCertificateDate, transoformedResult } from "../../../utils/coursesHelper";
import moment from "moment";
import CertificateApi from "../../../utils/api/CertificateApi";
import CertificateTemplate from "../../../components/CertificateTemplate/CertificateTemplate";
import CertificateModal from "../../../components/Modals/CertificateModal/CertificateModal";
import CertificatesSubheader from "../../../components/CertificatesTab/CertificatesSubheader/CertificatesSubheader";
import Certificates from "../../../components/CertificatesTab/Certificates/Certificates";
import useUserRole from "../../../hooks/useUserRole";
import { DEFAULT_DEBOUNCE_DELAY } from "../../../constants/statistics";
import debounce from 'lodash/debounce';
import { useParams } from 'react-router-dom';
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";
import CourseApi from "../../../utils/api/CourseApi";
import useGetCertificates from "../../../hooks/api/useGetCertificates";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const ProfileRight = ({
   user,
   activeTab,
   searchedValue,
   setSearchedValue,
   fetchUser,
   handleTabChange,
   courseTableData,
   isLoading,
   teamsTableData,
   lastCourseRef,
   refetchAllCoursesCourses,
   allCoursesApartFromUsers,
   allCoursesSearchedValue,
   setAllCoursesSearchedValue,
   isAllCoursesLoading,
   selectAll,
   setSelectAll,
   totalUserCourses,
   userCoursesSearchedValue,
   setUserCoursesSearchedValue,
   lastUserCourseRef,
   refetchUserCourses,
   getAllUsersCourses,
   isUserCoursesLoading
}) => {
   const [activeFilter, setActiveFilter] = useState('On check');
   const [activeTagsFilter, setActiveTagsFilter] = useState('');
   const [activeSortFilter, setActiveSortFilter] = useState('');
   const [orientation, setOrientation] = useState('grid');
   const [currentLevel, setCurrentLevel] = useState(2);
   const [isAddCourseLoading, setIsAddCourseLoading] = useState(false);
   const [selectedCoursesList, setSelectedCoursesList] = useState([]);
   const [currentCertificate, setCurrentCertificate] = useState({});
   const selectModal = useHandleModal();
   const certificateModal = useHandleModal();

   const t = useFindTranslationsByString();

   const params = useParams();

   const [tests, setTests] = useState([]);
   const [testsPage, setTestsPage] = useState(1);
   const [testsLoading, setTestsLoading] = useState(true);
   const [totalTests, setTotalTests] = useState(0);
   const [testSearchValue, setTestSearchValue] = useState('');

   const currentCompanyLogo = useSelector((state) => state.sidebar.companyLogo);
   const currentCompanyName = useSelector((state) => state.sidebar.companyName);
   const currentCompanyBranchId = useSelector((state) => state.sidebar.currentCompanyBranchId);
   
   const companyId = useSelector((state) => state.sidebar.companyId);
   const userInfo = useSelector(state => state?.user?.info);
   const { userRoleId } = useUserRole(companyId);

   const openCertificateModal = (certificate) => {
      setCurrentCertificate(certificate)
      certificateModal.open();
   }

   const { certificates, isLoading: isCertLoading, totalCertificates, setCurrentPage: setCurrentCertPage, searchedValue: certSearchedValue, setSearchedValue: setCertSearchedValue, tags, setTags, order, setOrder, allCertificates } = useGetCertificates(params?.id, companyId);

   const lastCertificateRef = useIntersectionObserver(
      () => {
        if (!isCertLoading && certificates?.length < totalCertificates) {
          if(certificates?.length === 0) {
            setCurrentCertPage(1);
          } else {
            setCurrentCertPage((prev) => prev + 1);
          }
        }
      },
      [isCertLoading, totalCertificates]
    );

    const fetchTests = async (page, limit = null) => {
      if(!companyId || !params?.id) return;

       setTestsLoading(true);
       const res = await new KnowledgeCheckAPI().getUserTestForOneUser(limit ? limit : DEFAULT_DATA_LIMIT, page ? page : testsPage, companyId, activeFilter === 'On check' ? 0 : 1, params?.id, testSearchValue);
 
       if(res?.success?.data) {
          if(page) {
             setTests(res?.success?.data);
          } else {
             setTests((prev)=> [...prev, ...res?.success?.data]);
          }
 
          setTotalTests(res?.success?.totalCount)
       }
 
       if(res?.success?.data?.length === 0) {
          setTestsPage(1);
       }
 
       if(res?.error?.message && page) {
          setTests([]);
       }
 
       setTestsLoading(false);
    }

    const lastTestRef = useIntersectionObserver(
       () => {
         if (!testsLoading && tests?.length < totalTests) {
           if(tests?.length === 0) {
             setTestsPage(1);
           } else {
             setTestsPage((prev) => prev + 1);
           }
         }
       },
       [testsLoading, totalTests]
     );
 
    useEffect(()=> {
       if(testsPage > 1 && tests?.length <= totalTests) {
          fetchTests();
       }
    }, [testsPage, companyId])

    const debouncedFetchTests = debounce(()=> fetchTests(1), DEFAULT_DEBOUNCE_DELAY); 

    useEffect(()=> {
      setTestsPage(1);
      debouncedFetchTests();

       return () => debouncedFetchTests.cancel();
    }, [testSearchValue, activeFilter, companyId])
 
    useEffect(()=> {
       setTestSearchValue('');
       setCertSearchedValue('');
    }, [activeTab])

   const handleFilterClick = (filter) => {
      setActiveFilter(filter);
   }

   const chunkArray = (array, chunkSize) => {
      const chunks = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        chunks.push(array.slice(i, i + chunkSize));
      }
      return chunks;
    };
    
    const onGiveAccessToCourses = async () => {
      const chunkSize = 25;
      const courseChunks = chunkArray(selectedCoursesList, chunkSize);
      const allAddUserPromises = [];
      
      setIsAddCourseLoading(true);

      let dataAmountProcceded = 0;
    
      try {
        for (const courseChunk of courseChunks) {
          const coursesToAdd = courseChunk?.map(course => ({
            course_id: course?.id,
            add: true
          }));

         const data = {
            course_data: coursesToAdd,
         }
    
          const res = await new UserApi().giveAccessToCourses(params?.id, data);

          if (res?.error?.message) {
            setSelectedCoursesList([]);
            return toast(<ToastMsg text={res?.error?.message || "Something went wrong"} isError />);
         } 

         if (res?.success?.message) { 
            dataAmountProcceded += res?.success?.data?.length || 0

            toast(<ToastMsg text={courseChunks?.length > 1 ? `${dataAmountProcceded} courses from list: ${res?.success?.message}` : res?.success?.message} />);
         }

          allAddUserPromises.push(res);
        }

        selectModal.close();
        setSelectedCoursesList([]);
        refetchUserCourses();
        refetchAllCoursesCourses();
      } catch (error) {
        console.error(`Error updating access for courses: ${error}`);
        throw error;
      } finally {
         setIsAddCourseLoading(false);
      }
    
      return allAddUserPromises;
    };

   const csvData = [
      COURSES_JUST_HEADERS,
      ...courseTableData?.map(({ folder, name, was, progress, status }) => [
         folder,
         name,
         was,
         progress,
         status,
      ]),
   ];

   const mappedTests = tests?.map(testsMap);

   const csvDataTests = [
      USER_TESTS_COLUMN_CHECKED,
      ...mappedTests?.map(({ course_name, test_name, end_date, pass_time, result }) => [
          course_name, test_name, end_date, pass_time, result
       ])
   ];

   return (
      <section className={styles.profile_right_section}>
         <UniverseProgress currentLevel={currentLevel} setCurrentLevel={setCurrentLevel} />
         <div className={styles.tab_navigation}>
            {TABS_USER_PROFILE_ADMIN_VIEW.map((tabItem) => (
               <button
                  key={tabItem}
                  className={cn(
                     styles.tab,
                     activeTab === tabItem && styles.activeTab
                  )}
                  onClick={() => handleTabChange(tabItem)}
               >
                  {t(tabItem)}
               </button>
            ))}
         </div>

         <div className={styles.content}>
            {activeTab === "Access to materials" && (
               <>
                  <Subheader
                     totalUserCourses={totalUserCourses}
                     key={"access_to_material"}
                     setSearchedValue={setUserCoursesSearchedValue}
                     searchedValue={userCoursesSearchedValue}
                     csvData={csvData}
                     selectModal={selectModal}
                     fetchData={getAllUsersCourses}
                     isDataLoading={isUserCoursesLoading}
                  />
                  
                  <CoursesTable
                     activeTab={activeTab}
                     data={courseTableData}
                     isLoading={isLoading}
                     refetchCourseUsers={()=> {
                        refetchUserCourses();
                        refetchAllCoursesCourses();
                        fetchUser();
                     }}
                     headers={COURSES_TABLE_HEADERS}
                     headersInArray={COURSES_JUST_FIELDS}
                     lastCourseRef={lastUserCourseRef}
                  />
               </>
            )}
            {activeTab === "Knowledge check" && (
               <>
                  <KnowledgeCheckSubheader
                     user={user}
                     key={"knowledge_check"}
                     setSearchedValue={setTestSearchValue}
                     searchedValue={testSearchValue}
                     activeFilter={activeFilter}
                     csvData={csvDataTests}
                     handleFilterClick={handleFilterClick}
                     fetchData={fetchTests}
                     isDataLoading={testsLoading}
                  />

                    <div className={styles.tests_wrapper}>
                        <UsersTestsTable
                           key={"knowledge_checked_table"}
                           data={mappedTests}
                           lastTestRef={lastTestRef}
                           columns={USER_TESTS_COLUMN_CHECKED}
                           t={{ returnTranslation: t }}
                        />
                     </div>
               </>
            )}

            {activeTab === "Teams" && (
               <div className={styles.teams_table}>
                  <TableBlock 
                     key={'teams_table'}
                     data={teamsTableData} 
                     headers={TEAMS_HEADERS_LESS_INFO}
                     tableFor={"teams"}
                     searchedValue={searchedValue} 
                     t={{ returnTranslation: t }}
                  />
               </div>
            )}

            {activeTab === 'Certificates' && 
               <>
                  <CertificatesSubheader
                        certificates={certificates}
                        searchedValue={certSearchedValue}
                        setSearchedValue={setCertSearchedValue}
                        activeTagsFilter={tags}
                        setActiveTagsFilter={setTags}
                        activeSortFilter={order}
                        setActiveSortFilter={setOrder}
                        setOrientation={setOrientation}
                        orientation={orientation}
                        allCertificates={allCertificates}
                     />
                  <Certificates 
                     openCertificateModal={openCertificateModal} 
                     certificateLoading={isCertLoading}
                     certificates={certificates} 
                     lastItemRef={lastCertificateRef}
                     orientation={orientation}
                  />
               </>
            }

         <SelectCourseModal
               oneStep
               onClose={selectModal.close}
               isOpen={selectModal.isActive}
               setSelectedCourses={setSelectedCoursesList}
               selectedCourses={selectedCoursesList}
               coursesList={allCoursesApartFromUsers}
               isDataLoading={isAddCourseLoading || isAllCoursesLoading}
               onAddCourse={onGiveAccessToCourses}
               lastCourseRef={lastCourseRef}
               searchedValue={allCoursesSearchedValue}
               setSearchedValue={setAllCoursesSearchedValue}
               selectAll={selectAll}
               setSelectAll={setSelectAll}
               coursesLoading={isAllCoursesLoading}
            />

            <CertificateModal
               onClose={certificateModal.close}
               isOpen={certificateModal.isActive}
               certificate={currentCertificate}
            />
           
         </div>
      </section>
   );
};

export default ProfileRight;
