import React, { useEffect, useState } from "react";

import shortLogo from "../../../assets/images/avatar.svg";

import styles from "./styles.module.scss";
import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import AgencyApi from "../../../utils/api/AgencyApi";
import ToastMsg from "../../../components/ToastMsg/ToastMsg";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader/Loader";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const AvatarUploadModal = ({ avatar, setRenderedIconFile, setAvatar, onSaveIcon, onClose, isActive, title, desc, iconEditModal, isLoading }) => {
   const [drag, setDrag] = useState(false);
   const [fileName, setFileName] = useState('');

   const t = useFindTranslationsByString();

   const fileLoadHandler = (e) => {
      e.preventDefault();

      setFileName(e.target.files[0]?.name);
      if(e.target.files[0]) setAvatar(e.target.files[0]);

      // for show icons straign away
      const reader = new FileReader();

      reader.onload = () => {
         setRenderedIconFile(reader.result);
      };
      
       if(e.target.files[0]) {
         reader.readAsDataURL(e.target.files[0]);
       }
   };

   const dragStartHandler = (e) => {
      e.preventDefault();
      setDrag(true);
   };

   const dragLeaveHandler = (e) => {
      e.preventDefault();
      setDrag(false);
   };

   const dropHandler = (e) => {
      e.preventDefault();
      
      const files = [...e.dataTransfer.files];

      setFileName(files[0]?.name)
      if(files[0]) setAvatar(files[0])

       // for show icons straign away
       const reader = new FileReader();
      
       reader.onload = () => {
          setRenderedIconFile(reader.result);
       };
        if(files[0]) {
          reader.readAsDataURL(files[0]);
        }
   };

   const onSave = () => {
      const size = 500 * 1024;

      if (avatar && avatar.size > size) {
         return toast(<ToastMsg text="Icon file size exceeds required size. Please select a smaller file." isError/>);
      }

      if (!avatar) {
         return toast(<ToastMsg text="Add an icon, please" isError/>);
      }

      onSaveIcon();
   }

   return (
      <ModalLayout
         isOpen={isActive}
         maxHeight={"400px"}
         maxWidth={"500px"}
         onClose={onClose}
         withCross
      >
         <div className={styles.popup_upload_container}>
            <div className={styles.settings_logo_header}>
               <span
                  className={`${styles.settings_box_icon} ${styles.settings_box_icon_favicon}`}
               />
               <div>
                  <p className={styles.settings_logo_title}>{title}</p>
                  <p className={styles.settings_logo_description}>{desc}</p>
               </div>
            </div>
            <div
               onDragStart={dragStartHandler}
               onDragOver={dragStartHandler}
               onDragLeave={dragLeaveHandler}
               onDrop={dropHandler}
               className={styles.upload_image_box}
            >
               <div
                  className={`${styles.drag_drop_image_box}
                              ${drag ? styles.drag : ""}`}
               >
                  <div className={styles.drag_drop_image_text}>
                     <label className={styles.drag_drop_image_button}>
                        <input onChange={fileLoadHandler} type="file" accept="image/*" />
                        {t("Upload file")}
                     </label>
                     {t('or')} Drag & Drop
                  </div>
                  <div>{fileName}</div>
               </div>
            </div>
            <div className={styles.popup_button_grid}>
               <button
                  onClick={onClose}
                  type="button"
                  className={styles.button_popup_cancel}
               >
                  {t('Cancel')}
               </button>
               <button
                type="button" 
                className={styles.button_popup_save}
                onClick={onSave}
                disabled={isLoading}
               >
                  {t('Save changes')}
               </button>
            </div>

            {isLoading && 
               <div className="default_loader_wrapper">
                  <Loader size={"small"} />
               </div>
            }
         </div>
         
      </ModalLayout>
   );
};

export default AvatarUploadModal;
