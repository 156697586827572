import React, { useEffect, useState } from "react";
import useSortData from "../../../hooks/useSortData";

import { CSSTransition, TransitionGroup } from "react-transition-group";
import styles from "./styles.module.scss";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import { Link, useNavigate } from "react-router-dom";
import { ROLE_ID_STUDENT } from "../../../constants/user";
import cn from 'classnames';
import EmptyBlock from "../../../components/EmptyBlock/EmptyBlock";
import CourseBlockDropdown from "../../../components/CourseBlockDropdown/CourseBlockDropdown";
import { useSelector } from "react-redux";

const UsersTestsTable = ({ columns, data, userRoleId, isTestsLoading, isTeam, lastTestRef, t }) => {
   const { sortedData, handleSort } = useSortData(data, columns);
   const navigate = useNavigate();
   const isUserAsStudent = useSelector(state => state.sidebar.isUserAsStudent);

   const [isHoverCourse, setIsHoverCourse] = useState(false);
   const [activeRow, setActiveRow] = useState({});

   const replaceSpaces = (str) => {
      return str.replace(" ", "_");
   };

   const onRowClick = (testId) => {
      const basePath = "/knowledge_check";
      let path = `${basePath}/check/${testId}`;
    
      if ((userRoleId && userRoleId === ROLE_ID_STUDENT) || isUserAsStudent) {
        path = `${basePath}/info/${testId}`;
      }
    
      window.open(path, '_blank', 'noopener,noreferrer');
    };

   const onMouseOverCourse = (data) => {
     setIsHoverCourse(true);
     setActiveRow(data);
   };

   if (sortedData?.length === 0 && data?.length > 0) {
      return <EmptyBlock />
   }

   const isCourseNameColumn = (column) => `${column}` === 'course_name';

   return (
      <div className={cn(styles.table_wrapper, isTeam ? styles.table_wrapperBigger : '')}>
         <table className={styles.table}>
            <thead className={styles.thead}>
               <tr>
                  {columns.map((column, index) => (
                     <th
                        key={column}
                        onClick={() => handleSort(column)}
                        className={styles.header}
                     >
                        <span>{t?.returnTranslation(column.replace("_", " "))}</span>
                        <div className={styles.arrow_wrapper}>
                           <span className={styles.arrow}></span>
                        </div>
                     </th>
                  ))}
               </tr>
            </thead>
            <tbody className={styles.tbody}>
               <TransitionGroup component={null}>
                  {sortedData?.map((row, index) => (
                     <CSSTransition
                        key={`row-${index}`}
                        classNames="fade"
                        timeout={100}
                     >
                        <>
                           <tr
                              ref={index === data?.length - 1 ? lastTestRef : null}
                              onClick={() => onRowClick(row.id)}
                              key={`row-${index}`}
                              className={index % 2 === 0 ? styles.rowOdd : ""}
                           >
                              {columns.map((column, columnIndex) => (
                                 <td 
                                    onMouseLeave={()=> setIsHoverCourse(false)}
                                    onMouseOver={()=> isCourseNameColumn(column) ? onMouseOverCourse(row) : null} key={`cell-${index}-${columnIndex}`}
                                    className={cn(isCourseNameColumn(column) && isHoverCourse && row?.id == activeRow?.id  ? styles.active : '')}
                                 >
                                    { isHoverCourse &&
                                       row?.id == activeRow?.id &&
                                       isCourseNameColumn(column) &&
                                       <div className={styles.dropdown_wrapper}>
                                          <div className={styles.course_dropdown}>
                                             <CourseBlockDropdown 
                                                course={activeRow?.lesson?.module?.course} 
                                             />
                                          </div>
                                       </div>
                                    }
                                    <div className={styles.cellContent}>
                                       <CustomTooltip
                                          id={row[column]}
                                          limit={30}
                                          text={row[column]}
                                       />
                                    </div>
                                 </td>
                              ))}
                           </tr>
                        </>
                     </CSSTransition>
                  ))}
               </TransitionGroup>
            </tbody>
         </table>

         {data?.length === 0 && !isTestsLoading && (
            <EmptyBlock />
         )}
      </div>
   );
};

export default UsersTestsTable;
