import React, { useEffect, useRef, useState } from 'react';
import styles from "./styles.module.scss";
import shortLogo from "../../assets/images/favicon.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { pathList } from "../../routes/path";
import { localStorageRemove, localStorageSet } from "../../utils/localStorage";
import { toggleSidebar, toggleSidebarFixed, setConfirmModalNeeded } from "../../store/actions/sidebar";
import { navLinksTop, navLinksBottom, companiesInfo } from './sideBarLinksMock';
import { PART_OF_MENTOR_NAME, ROLE_ID_STUDENT, ROLE_ID_SUPER_ADMIN, PART_OF_ADMIN_NAME } from '../../constants/user';
import CustomTooltip from '../CustomTooltip/CustomTooltip';
import ConfirmModal from '../Modals/ConfirmModal/ConfirmModal';
import cn from 'classnames';
import useUserRole from '../../hooks/useUserRole';
import useConfirm from '../../hooks/useConfirm';
import { DEFAULT_TEXT, returnTranslation } from '../../constants/languages';

const MAX_LENGTH = 9;
const MAX_LENGTH_ONE_WORD = 12;

const SideBar = () => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { handleLinkClick, redirectToPage, confirmModal } = useConfirm();

    const [settingsVisibility, setSettingsVisibility] = useState(false);

    const asStudent = useSelector(state => state.sidebar.isUserAsStudent);
    const isFixed = useSelector(state => state.sidebar.isFixed);
    const isSidebarOpen = useSelector(state => state.sidebar.isOpen);
    const currentCompanyLogo = useSelector(state => state.sidebar.companyLogo);
    const currentCompanyName = useSelector(state => state.sidebar.companyName);
    const currentCompanyId = useSelector(state => state.sidebar.companyId);

    const isUploadingFiles = useSelector(state => state?.sidebar?.confirmModalNeeded);
    const translations = useSelector(state => state?.sidebar?.translations);

    const { userRoleId, userRoleName } = useUserRole(currentCompanyId);

    const activeMenuClass = (path) => pathname?.includes(path) ? styles.active : '';
    const activeSettingsMenuClass = (path) => pathname?.includes(path) ? styles.activeSettings : '';

    const toggleSettingsVisibility = () => {
      setSettingsVisibility(!settingsVisibility);
    }

    useEffect(()=> {
      if(pathname?.includes(pathList.languagesPage.path)) {
         setSettingsVisibility(true);
      }
    }, [])
   
    const handleSidebarOpen = () => {
      if (!isFixed) {
        dispatch(toggleSidebar(true));
      }
    };

    const handleSidebarClose = () => {
      if (!isFixed) {
        dispatch(toggleSidebar(false));
      }
    };

    const handleSidebarFixed = () => {
        dispatch(toggleSidebarFixed(!isFixed));
        dispatch(toggleSidebar(true)); 
    };

    const onSignOut = () => {
      if (isUploadingFiles) {
         confirmModal.open();
     } else {
         localStorageSet('token', '');
         localStorageRemove('impersonateId');
         handleSidebarClose();
         navigate(pathList.login.path);
     }
  }

  useEffect(() => {
      const timeoutId = setTimeout(()=> {
         handleSidebarClose();
      }, 150)

      return ()=> {
         clearTimeout(timeoutId);
      }
   }, [pathList]);

  useEffect(() => {
      // If this condition works it means something wrong with sidebar. 
      // Sidebar should be always open if it is fixed.
      if(isFixed && !isSidebarOpen) {
         dispatch(toggleSidebar(true));
      }
   }, []);

   useEffect(() => {
      const handleBeforeUnload = (event) => {
          if (isUploadingFiles) {
              const confirmationMessage = 'Uploading is not finished. Are you sure you want to leave?';
              event.preventDefault();
              event.returnValue = confirmationMessage;
              return confirmationMessage;
          }
      };
  
      window.addEventListener('beforeunload', handleBeforeUnload);
  
      return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
      };
  }, [isUploadingFiles]);

   const getAbbreviatedName = () => {
      if(!currentCompanyName) return;

      const words = currentCompanyName?.split(' ');
      const abbreviation = words?.map(word => word.charAt(0)).join('');
  
      return words?.length > 1 ? abbreviation : currentCompanyName;
    };

   const isNameOneWord = () => {
      const words = currentCompanyName?.split(' ');
      return words?.length === 1;
    };

    const abbreviatedName = getAbbreviatedName();
    const displayText = currentCompanyName?.length > MAX_LENGTH ? abbreviatedName : currentCompanyName;

    const statisticsText = returnTranslation(translations, DEFAULT_TEXT.common.statistics);
    const signOutText = returnTranslation(translations, DEFAULT_TEXT.common.signOut);


    return (
         <aside
            className={`${styles.sidebar} ${isSidebarOpen ? styles.open : styles.close}`}
            onMouseEnter={handleSidebarOpen}
            onMouseLeave={handleSidebarClose}
          >
            {isSidebarOpen && 
               <span onClick={handleSidebarFixed} className={`${styles.fix_btn} ${isFixed && styles.not_fix_btn}`}></span>
            }

            <Link onClick={(e) => handleLinkClick(e, pathList.home.path)} to={pathList.home.path} className={cn(styles.logo, displayText?.length > MAX_LENGTH  && isNameOneWord() ? styles.lessFont : '')}>
               <img src={currentCompanyLogo && currentCompanyLogo !== 'undefined' ? currentCompanyLogo : shortLogo} width="90" height="24" alt={shortLogo}/> 
              {isSidebarOpen && <span><CustomTooltip id={'current company name'} text={displayText} noDots limit={isNameOneWord () ? MAX_LENGTH_ONE_WORD : MAX_LENGTH}/></span>}  
            </Link>

            <nav className={styles.navigation}>
                <div className={styles.navigation_top}>
                    <ul className={`${styles.navigation_list} ${styles.navigation_list_top}`}>
                     {navLinksTop?.map(link => {
                           const translatedTitle = returnTranslation(translations, link.title);

                           return (
                              <li key={link.title} className={styles.navigation_list_item}>
                                    <Link
                                       onClick={(e) => handleLinkClick(e, link.path)}
                                       to={link.path}
                                       className={`${styles.menu_link} ${styles.menu_link_icon} ${link.iconClass} ${activeMenuClass(link.path)}`}
                                    >
                                       {translatedTitle}
                                    </Link>
                              </li>
                           );
                        })}

                        {userRoleName?.includes(PART_OF_ADMIN_NAME) && !asStudent && 
                           <li key={'Statistics'} className={styles.navigation_list_item}>
                              <Link 
                                 onClick={(e) => handleLinkClick(e, pathList.statistics.path)}
                                 to={pathList.statistics.path} 
                                 className={`${styles.menu_link} ${styles.menu_link_icon} ${styles.menu_link_icon_statistics} ${activeMenuClass(pathList.statistics.path)}`}
                              >{statisticsText}</Link>
                           </li>
                        }
                    </ul>

                   {userRoleId && userRoleId !== ROLE_ID_STUDENT &&
                   !userRoleName?.includes(PART_OF_MENTOR_NAME) && !asStudent &&
                     <ul className={styles.navigation_list}>
                        {navLinksBottom.map(link => {
                        const translatedTitle = returnTranslation(translations, link.title);

                        if (link.path === pathList.usersRights.path && userRoleId !== ROLE_ID_SUPER_ADMIN) {
                           return null; 
                        }

                        return (
                           <li key={link.title} className={styles.navigation_list_item}>
                                 <Link
                                    onClick={(e) => handleLinkClick(e, link.path)}
                                    to={link.path}
                                    className={`${styles.menu_link} ${styles.menu_link_icon} ${link.iconClass} ${activeMenuClass(link.path)}`}
                                 >
                                    {translatedTitle}
                                 </Link>
                           </li>
                        );
                     })}

                     </ul>
                   }

                   { userRoleName?.includes(PART_OF_MENTOR_NAME) && !asStudent &&
                     <ul className={styles.navigation_list}>
                        <li key={navLinksBottom[0].title} className={styles.navigation_list_item}>
                           <Link
                              onClick={(e) => handleLinkClick(e, navLinksBottom[0].path)}
                              to={navLinksBottom[0].path}
                              className={`${styles.menu_link} ${styles.menu_link_icon} ${navLinksBottom[0].iconClass} ${activeMenuClass(
                                 navLinksBottom[0].path
                              )}`}
                           >
                              {returnTranslation(translations, navLinksBottom[0].title)}
                           </Link>
                        </li>
                     </ul>
                   }

                     <ul className={styles.navigation_list}>
                        <li className={styles.navigation_list_item}>
                           <Link
                              to={companiesInfo.path}
                              onClick={(e) => handleLinkClick(e, companiesInfo.path)}
                              className={`${styles.menu_link} ${styles.menu_link_icon} ${companiesInfo.iconClass} ${activeMenuClass(
                                 companiesInfo.path
                              )}`}
                           >
                              {returnTranslation(translations, companiesInfo.title)}
                           </Link>
                        </li>
                     </ul>

                     {userRoleId === ROLE_ID_SUPER_ADMIN && 
                        <ul className={styles.navigation_list}>
                         <li className={styles.navigation_list_item}>
                           <button
                              className={
                                 cn(
                                    styles.menu_link,
                                    styles.menu_link_icon,
                                    styles.settings,
                                    settingsVisibility ? styles.settings_open : "",
                                    activeSettingsMenuClass(pathList.languagesPage.path)
                                 )}
                              onClick={toggleSettingsVisibility}
                           >
                                 {returnTranslation(translations, DEFAULT_TEXT.common.settings)}
                           </button>

                           {settingsVisibility && 
                              <Link
                                 to={pathList.languagesPage.path}
                                 onClick={(e) => handleLinkClick(e, pathList.languagesPage.path)}
                                 className={`${styles.menu_link} ${styles.submenu_item} ${activeMenuClass(
                                    pathList.languagesPage.path
                                 )}`}
                              >
                                 <span>
                                   {returnTranslation(translations, DEFAULT_TEXT.common.languages)}
                                 </span>
                              </Link>
                           }
                         </li>
                      </ul>
                     }
                  
                </div>
                <button className={`${styles.menu_link} ${styles.menu_link_icon} ${styles.menu_link_icon_exit}`}
                        onClick={onSignOut}
                        type="button">
                    {signOutText}
                </button>
            </nav>

            <ConfirmModal
               confirmButtonText={'Confirm'}
               isOpen={confirmModal.isActive}
               onClose={confirmModal.close}
               isOpen={confirmModal.isActive}
               onConfirm={redirectToPage}
               maxHeight={'310px'}
               title={ "Are you sure you want to leave?"}
               subtitle={'Uploading files will be stopped.'}
            />
        </aside>
    );
};

export default SideBar;
