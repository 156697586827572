import React from "react";
import styles from "./styles.module.scss";
import TextareaAutosize from 'react-textarea-autosize';

const TextArea = ({
   placeholder,
   max,
   withEmodji,
   value = "",
   onChangeValue,
   withHeader,
   title,
   withCounter,
   height = 130,
   isResize,
   minRows,
   maxRows = 20,
   hasError,
   isGrayText,
   autoFocus,
   onBlur,
   onKeyDown,
   isLangsTable
}) => {
   return (
      <>
         {withHeader && (
            <div className={styles.header}>
               {title}
               {max && (
                  <span>
                     {value?.length}/{max?.toString()}
                  </span>
               )}
            </div>
         )}

         {isResize && (
            <TextareaAutosize
               style={{
                  width: "100%",
                  height: height,
                  background: "rgba(255, 255, 255, 0.05)",
                  color: isGrayText ? "gray" : "#fff",
                  resize: "vertical", 
                  fontSize: isLangsTable ?  "13.5px" : "",
                  padding: isLangsTable
                           ? '5px' 
                           : withEmodji 
                              ? '13px 35px' 
                              : '14px 16px', 
                  boxSizing: 'border-box',
                  borderRadius: '5px',
                  border: hasError 
                           ? '1px solid red' 
                           : isLangsTable 
                           ? '1px solid transparent' 
                           : '',
               }}
               autoFocus={autoFocus}
               onBlur={onBlur}
               minRows={minRows}
               maxRows={maxRows}
               className={styles.resize_area}
               placeholder={placeholder}
               value={value}
               onChange={onChangeValue}
               onKeyDown={onKeyDown}
            />
         )}

       
         {!isResize && (
            <div
               className={`${styles.inputWrapper} ${
                  withEmodji ? styles.withEmodji : ""
               }`}
               style={{ height: `${height}px` }}
            >
               <textarea
                  placeholder={placeholder}
                  maxLength={max}
                  value={value}
                  onChange={onChangeValue}
               />
               {withCounter && (
                  <span>
                     {value?.length}/{max?.toString()}
                  </span>
               )}
            </div>
         )}
      </>
   );
};

export default TextArea;
