import React, { useEffect, useState, useRef } from "react";
import searchImg from "../../../assets/images/symbols/Search.svg";
import csvImg from "../../../assets/images/symbols/scv.svg";
import plus from "../../../assets/images/symbols/plus.svg";
import courseIcon from "../../../assets/images/symbols/book.svg";
import folders from "../../../assets/images/menu/special-folders.svg";
import stars from "../../../assets/images/star.svg";

import Button from "../../../components/Button/Button";
import FilterRadio from "../../../components/FilterRadio/FilterRadio";

import styles from "./styles.module.scss";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import { CSVLink } from "react-csv";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const KnowledgeCheckSubheader = ({
   searchedValue,
   setSearchedValue,
   user,
   handleFilterClick,
   activeFilter,
   csvData,
   fetchData,
   isDataLoading
}) => {

   const t = useFindTranslationsByString();

   const csvLinkRef = useRef(null);

   return (
      <div className={styles.subheader}>
      
            <FilterRadio
               key={'On check'}
               title={t('On check')}
               active={activeFilter === 'On check'}
               onClick={() => handleFilterClick('On check')}
            />

            <FilterRadio
               key={'Checked'}
               title={t('Checked')}
               active={activeFilter === 'Checked'}
               onClick={() => handleFilterClick('Checked')}
            />
       
         <div className={styles.line}></div>
         <div className={styles.search_wrapper}>
            <div className={styles.search}>
               <img src={searchImg} alt={""} />
               <input
                  placeholder={t("Search")}
                  value={searchedValue}
                  onChange={(e) => setSearchedValue(e.target.value)}
               />
            </div>
            <Button 
               title={t('Export to CSV')} 
               isBlack
               image={csvImg}
               maxWidth={'180px'}
               disabled={isDataLoading}
               onClick={()=> fetchData(1, 1000).then(()=> csvLinkRef.current.link.click())}
            />

            <CSVLink ref={csvLinkRef} style={{ textDecoration: "none" }} data={csvData}/>
            
         </div>
      </div>
   );
};

export default KnowledgeCheckSubheader;
