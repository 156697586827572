import React, { useState } from "react";
import styles from "./styles.module.scss";
import {
   PROFILE_BASIC_SETTINGS_SUBMENU,
   PROFILE_SAFETY_SETTINGS_SUBMENU,
   ROLE_ID_STUDENT,
} from "../../../constants/user";

import cn from "classnames";
import useUserRole from "../../../hooks/useUserRole";
import { useSelector } from "react-redux";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const ProfileSidebar = ({ onMenuItemClick, activeElement }) => {
   const [submenuVisible, setSubmenuVisible] = useState(true);
   const [submenuSafetyVisible, setSubmenuSafetyVisible] = useState(false);

   const currentCompanyId = useSelector(state => state.sidebar.companyId);
   const { userRoleId } = useUserRole(currentCompanyId)

   const t = useFindTranslationsByString();

   const toggleSubmenuVisibility = () => {
      setSubmenuVisible(!submenuVisible);
   };

   const toggleSubmenuSafetyVisibility = () => {
      setSubmenuSafetyVisible(!submenuSafetyVisible);
   };

   return (
      <aside className={styles.page_navigation}>
         <ul className={styles.page_menu}>
            <li
               className={cn(
                  styles.page_menu_item,
                  submenuVisible ? styles.active : ""
               )}
            >
               <button
                  className={cn(
                     styles.icon_before,
                     styles.icon_before_settings,
                     styles.dropdown_button,
                     submenuVisible ? styles.active : ""
                  )}
                  onClick={toggleSubmenuVisibility}
               >
                  {t("Settings")}
               </button>
               <ul
                  className={cn(
                     styles.page_submenu,
                     submenuVisible ? "" : styles.page_menu_item_hide
                  )}
               >
                {PROFILE_BASIC_SETTINGS_SUBMENU.map((item) => {
                     if (userRoleId && userRoleId === ROLE_ID_STUDENT && item.el === "position") {
                        return null; // Don't render the "Position" tab for students
                     }

                     return (
                        <li key={item.id} className={styles.page_submenu_item}>
                           <a
                           className={cn(
                              item.id === activeElement?.id ? styles.activeTab : "",
                              styles.page_submenu_link
                           )}
                           href="#"
                           onClick={(e) => onMenuItemClick(e, item)}
                           >
                           {t(item.name)}
                           </a>
                        </li>
                     );
                  })}
               </ul>
            </li>

            <li
               className={cn(
                  styles.page_menu_item,
                  submenuSafetyVisible ? styles.active : ""
               )}
            >
               <button
                  className={cn(
                     styles.dropdown_button,
                     styles.icon_before,
                     styles.icon_before_safety,
                     submenuSafetyVisible ? styles.active : ""
                  )}
                  onClick={toggleSubmenuSafetyVisibility}
               >
                  {t("Safety settings")}
               </button>

               <ul
                  className={cn(
                     styles.page_submenu,
                     submenuSafetyVisible ? "" : styles.page_menu_item_hide
                  )}
               >
                  {PROFILE_SAFETY_SETTINGS_SUBMENU.map((item) => (
                     <li key={item.id} className={styles.page_submenu_item}>
                        <a
                           className={cn(
                              item.el === activeElement ? styles.activeTab : "",
                              styles.page_submenu_link
                           )}
                           href="#"
                           onClick={(e) => onMenuItemClick(e, item)}
                        >
                           {t(item.name)}
                        </a>
                     </li>
                  ))}
               </ul>
            </li>
         </ul>
      </aside>
   );
};

export default ProfileSidebar;
