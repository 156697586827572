import styles from "./styles.module.scss";
import logo from "../../assets/images/logo.svg";
import React from "react";
import {useNavigate} from "react-router-dom";
import {pathList} from "../../routes/path";
import {useState} from "react";
import {INIT_LOGIN_FORM} from "../../constants/init";
import {validateEmail} from "../../utils";
import {localStorageRemove, localStorageSet} from "../../utils/localStorage";
import AuthApi from "../../utils/api/AuthApi";
import {toast} from "react-toastify";
import ToastMsg from "../ToastMsg/ToastMsg";

import cn from 'classnames';
import Loader from "../Loader/Loader";
import useFindTranlsations from "../../hooks/useFindTranlsations";

export default function Login({onForgetPassword}) {
    const [formData, setFormData] = useState(INIT_LOGIN_FORM);
    const [isPasswordType, setIsPasswordType] = useState(false);
    const [isInvalidPassword, setIsInvalidPassword] = useState(false);
    const [isEntering, setIsEntering] = useState(false);
    const t = useFindTranlsations();

    const navigate = useNavigate();

    const isInvalidEmail = !validateEmail(formData?.email);

    const onChangeValue = (event) => setFormData({...formData, [event?.target?.name]: event?.target?.value});
    const onChangeChecked = (event) => setFormData({...formData, [event?.target?.name]: event?.target?.checked});
    const onSignUp = () => navigate(pathList.signup.path);
    const onSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsEntering(true);

        if (validateEmail(formData?.email) && formData?.password?.length) {
            const resLogin = await new AuthApi().login({
                username: formData?.email,
                password: formData?.password,
            });

            setIsEntering(false);

            if (resLogin?.error?.message) return toast(<ToastMsg text={resLogin?.error?.message} isError/>)

            if (resLogin?.success?.data?.accessToken) {
                localStorageSet('token', resLogin?.success?.data?.accessToken)
                navigate(pathList.home.path);
            }
        }

        setIsEntering(false);

        if (formData?.isRemember) localStorageSet('remember-email', formData.email);
        else localStorageRemove('remember-email');
    }

    return (
        <div className={styles.authorization_block}>
            <form onSubmit={onSubmit} className={styles.authorization_form} action="">
                <fieldset className={styles.authorization_form__fieldset}>
                    <p className={`${styles.input_wrap} ${formData?.email?.length ? (isInvalidEmail ? styles.invalid : styles.valid) : ''}`}>
                        {isInvalidEmail && <span className={styles.validation_message}>Uncorrect email</span>}
                        <input
                            className={styles.authorization_form__input}
                            type="email"
                            placeholder={t?.email}
                            name="email"
                            required
                            value={formData?.email}
                            onChange={onChangeValue}

                        />
                    </p>
                    <p className={`${styles.input_wrap} ${isInvalidPassword ? styles.invalid : ''}`}>
                        {isInvalidPassword && <span className={styles.validation_message}>Uncorrect password</span>}
                        <input
                            className={styles.authorization_form__input}
                            type={isPasswordType ? "text" : "password"}
                            placeholder={t?.password}
                            name="password"
                            required
                            value={formData?.password}
                            onChange={onChangeValue}
                        />
                        <button
                            type="button"
                            className={`${styles.input_password_btn} ${isPasswordType ? styles.hide : styles.show}`}
                            onClick={() => setIsPasswordType(!isPasswordType)}
                        />
                    </p>
                </fieldset>
                <div className={styles.login_row}>
                    <input
                        className={styles.authorization_form__agreement_checkbox}
                        type="checkbox"
                        id="register_agreement"
                        name="isRemember"
                        checked={formData?.isRemember}
                        onChange={onChangeChecked}
                    />
                    <label className={styles.authorization_form__agreement_label} htmlFor="register_agreement">
                        {t?.stayLogged}
                    </label>
                    <span className={styles.forget_password_link} onClick={onForgetPassword}>{t?.forgotPassword}</span>
                </div>
                <button disabled={isEntering} type="submit" className={cn(styles.authorization_form__submit, isEntering ? styles.button_disabled : '')}>
                  {isEntering ? <Loader size={"small"}/> : t?.signIn}
                </button>
            </form>

            {/* FOR FUTURE */}
            {/* <p className={styles.authorization_block__line}>or</p> */}

            {/* <SocialMediaList /> */}
            
            {/* <p className={styles.authorization_block__details}>
                Don't have an account yet?
                <span className={styles.authorization_block__link} onClick={onSignUp}>Sign up</span>
            </p> */}
        </div>
    )
}