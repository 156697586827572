import React, {useRef, useState} from 'react';
import styles from './styles.module.scss'
import useOutsideClick from "../../../../hooks/dom/useOutsideClick";
import Input from "../../../Input/Input";
import plus from '../../../../assets/images/symbols/plus.svg'
import line from '../../../../assets/images/symbols/line.svg'
import cn from "classnames";

const DropdownCourses = ({data, placeholder, onChange, value, title = null, onClickPlus, onClickMinus}) => {
    const [isOpen, setIsOpen] = useState(false)

    const dropdownRef = useRef()
    const inputRef = useRef()

    const onClose = () => setIsOpen(false)
    const onToggle = (e) => setIsOpen(!isOpen);
  
    // const onChangeValue = (val) => onChange(val)

    useOutsideClick(dropdownRef, onClose, inputRef)

    return (
        <>
            {title && <p className={styles.title}>{title}</p>}
            <div className={styles.dropdown_wrapper} ref={inputRef}>
                <Input
                    onClick={onToggle}
                    value={value}
                    placeholder={placeholder}
                    disabled={true}
                    customClass={isOpen ? styles.input_open : null}
                />
                <img src={plus} alt={''} onClick={onClickPlus}
                     className={cn(styles.arrow, isOpen && styles.arrow_open)}/>

               { data?.length > 0 && <div className={styles.overlayEl} onClick={onToggle}></div> }  
               { data?.length === 0 && <div className={styles.overlayEl} onClick={onClickPlus}></div> }  


                {isOpen && <div className={styles.dropdown}>
                    {data?.map((item, key) => <div
                        key={key}
                        onClick={() => onClickMinus(item?.id)}
                        className={cn(styles.dropdown_item, item?.id === value && styles.dropdown_item_active)}>
                        <img src={line} alt={''}/>
                        {item?.title}
                    </div>)}
                </div>}
            </div>
        </>
    );
};

export default DropdownCourses;