import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/MainLayout/MainLayout";

import TableBlock from "./TableBlock/TableBlock";
import Subheader from "./Subheader/Subheader";

import cn from "classnames";
import styles from "./styles.module.scss";
import UserApi from "../../utils/api/UserApi";
import { ALL_COURSES_IN_TEAM_TABLE_HEADERS, ALL_STUDENTS_IN_TEAM_TABLE_HEADERS, DEFAULT_DATA_LIMIT, PART_OF_MENTOR_NAME, ROLE_ID_GUEST, ROLE_ID_STUDENT, ROLE_ID_SUPER_ADMIN, USER_STATUS_ACTIVE } from "../../constants/user";
import moment from "moment";
import TeamApi from "../../utils/api/TeamApi";
import { useNavigate, useParams } from "react-router-dom";
import avatar from "../../assets/images/avatar.svg";
import books from "../../assets/images/team_info_book.svg";
import folders from "../../assets/images/team_info_folder.svg";
import profile from "../../assets/images/team_info_profile.svg";
import stars from "../../assets/images/team_info_star.svg";
import cross from '../../assets/images/symbols/cross_grey.svg'
import dots from "../../assets/images/symbols/three_dots_white.svg";
import useOutsideClick from "../../hooks/dom/useOutsideClick";
import useOptions from "../../hooks/useOptions";
import { toast } from "react-toastify";
import ToastMsg from "../../components/ToastMsg/ToastMsg";
import useHandleModal from "../../hooks/dom/useHandleModal";
import ConfirmModal from "../../components/Modals/ConfirmModal/ConfirmModal";
import RenameModal from "../../components/Modals/RenameModal/RenameModal";
import ChangeTeamMentorModal from "../../components/Modals/ChangeTeamMentorModal/ChangeTeamMentorModal";
import { mapUsersWithFilteredRoles, mapUsersWithNewLinks, returnTemporaryLinks, toggleMentorRights } from "../../utils/usersHelper";
import CourseApi from "../../utils/api/CourseApi";
import BranchApi from "../../utils/api/BranchApi";
import KnowledgeSubheader from "../KnowlegeCheckPage/KnowledgeSubheader/KnowledgeSubheader";
import UsersTestsTable from "../KnowlegeCheckPage/UsersTestsTable/UsersTestsTable";
import { TEST_STATUS_APPROVED, TEST_STATUS_ON_CHECK, TEST_STATUS_ON_REWORK, TEST_STATUS_REJECTED, USERS_TESTS_COLUMN, USERS_TESTS_DATA } from "../../constants/tests";
import { useSelector } from "react-redux";
import KnowledgeCheckAPI from "../../utils/api/KnowledgeCheckAPI";
import { parseIsoDurationStr, testsMap } from "../../utils/coursesHelper";

import { v4 as uuidv4 } from "uuid";
import CustomStaticTooltip from "../../components/CustomStaticTooltip/CustomStaticTooltip";
import SecondLoader from "../../components/SecondLoader/SecondLoader";
import { COURSE_STATUS_ACTIVE, COURSE_STATUS_HIDEN } from "../../constants/courses";
import useUserRole from "../../hooks/useUserRole";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import { DEFAULT_DEBOUNCE_DELAY } from "../../constants/statistics";
import debounce from 'lodash/debounce';
import { TEAM_EXTRA_GROUP, TEAM_EXTRA_GROUP_TABLE } from "../../utils/api/serialization_groups";
import useGetActiveUsersOfCompany from "../../hooks/api/useGetActiveUsersOfCompany";
import useGetCoursesOfUserOrTeam from "../../hooks/api/useGetCoursesOfUserOrTeam";
import useGetYourCoursesInCompany from "../../hooks/api/useGetYourCoursesInCompany";
import useGetAllUsersOfCompany from "../../hooks/api/useGetAllUsersOfCompany";
import useFindTranslationsByString from "../../hooks/useFindTranslationsByString";

const TeamPage = () => {
   const params = useParams();
   const navigate = useNavigate();

   const t = useFindTranslationsByString();

   const confirmModal = useHandleModal();
   const confirmModalAccess = useHandleModal();
   const renameModal = useHandleModal();
   const changeTeamMentorModal = useHandleModal();
   const selectUserModal = useHandleModal();
   const selectCourseModal = useHandleModal();

   const [frontTimeFilterValue, setFrontTimeFilterValue] = useState(null);
   const [activeTab, setActiveTab] = useState("students");

   // statuses in knowledge checked tab
   const [activeFilter, setActiveFilter] = useState({status: null});

   const [teamNameInput, setTeamNameInput] = useState("");

   const [searchedValue, setSearchedValue] = useState('');
   const [courseIdToRemove, setCourseIdToRemove] = useState(null);
   const [isAllTestsLoading, setIsAllTestsLoading] = useState(false);
   const [allCoursesListLoading, setAllCoursesListLoading] = useState(false);
   const [isAddLoading, setIsAddLoading] = useState(false);
   const [team, setTeam] = useState({});
   const [allCoursesInTeam, setAllCoursesInTeam] = useState([]);

   // userList is list with all users in the team
   const [userList, setUserList] = useState([]);

   const [isRemoveMentorLoading, setIsRemoveMentorLoading] = useState(false);
   const [isChangeLoading, setIsChangeLoading] = useState(false);
   const [isUsersLoading, setIsUsersLoading] = useState(false);

   const [studentsTableData, setStudentsTableData] = useState([]);
   const [teamsTableData, setTeamsTableData] = useState([]);
   const [selectedMentor, setSelectedMentor] = useState({});

   const [selectedUserList, setSelectedUserList] = useState([]);
   const [selectedCoursesList, setSelectedCoursesList] = useState([]);

   const [tests, setTests] = useState([]);
   const [testsPage, setTestsPage] = useState(1);
   const [testsLoading, setTestsLoading] = useState(true);
   const [totalTests, setTotalTests] = useState(0);
   const [testSearchValue, setTestSearchValue] = useState('');
   
   const currentCompanyId = useSelector(state => state.sidebar.companyId);
   const userId = useSelector(state => state?.user?.info?.id);
   const { userRoleName } = useUserRole(currentCompanyId)
   const [dateFilter, setDateFilter] = useState(null);

    const mapTeamCourses = ((course) => {
      return {
          id: course?.id,
          folder_name: course?.folder || '-',
          course_name: course.name || '',
      };
   })

    const mapTests = (test => ({
      ...test,
      student: test?.user?.firstName || test?.user?.lastName ? `${test?.user?.firstName || ''} ${test?.user?.lastName || ''}` : 'no name',
      course_name: test?.lesson?.module?.course?.name || '',
      test_name: test?.lesson?.name || '',
      // attempt: test?.passAttemptsCounters?.length  || 1,
      end_date: moment(test?.createdAt).format("MMMM DD, YYYY h:mm a"),
      pass_time: parseIsoDurationStr(test?.timeSpent || '')  || '',
   }))

    const fetchTests = async (page, limit = null) => {
      if(!currentCompanyId || !params?.id) return;

       setTestsLoading(true);
       const res = await new KnowledgeCheckAPI().getUsersTestFilteredByTeam(limit ? limit : DEFAULT_DATA_LIMIT, page ? page : testsPage, currentCompanyId, activeTab === 'knowledge_check' ? 0 : 1, params?.id, testSearchValue, dateFilter, activeFilter?.status);
 
       if(res?.success?.data) {
          if(page) {
             setTests(res?.success?.data);
          } else {
             setTests((prev)=> [...prev, ...res?.success?.data]);
          }
 
          setTotalTests(res?.success?.totalCount)
       }
 
       if(res?.success?.data?.length === 0) {
          setTestsPage(1);
       }
 
       if(res?.error?.message && page) {
          setTests([]);
       }
 
       setTestsLoading(false);
    }
 
    const lastTestRef = useIntersectionObserver(
       () => {
         if (!testsLoading && tests?.length < totalTests) {
           if(tests?.length === 0) {
             setTestsPage(1);
           } else {
             setTestsPage((prev) => prev + 1);
           }
         }
       },
       [testsLoading, totalTests]
     );
 
    useEffect(()=> {
       if(testsPage > 1 && tests?.length <= totalTests) {
          fetchTests();
       }
    }, [testsPage, currentCompanyId])
 
    const debouncedFetchTests = debounce(()=> fetchTests(1), DEFAULT_DEBOUNCE_DELAY); 

    useEffect(()=> {
      if(activeTab === 'knowledge_checked' || activeTab === 'knowledge_check') {
          setTestsPage(1);
          debouncedFetchTests();
      }
       return () => debouncedFetchTests.cancel();
    }, [testSearchValue, activeTab, currentCompanyId, dateFilter, activeFilter])
 
    useEffect(()=> {
       setTests([]);
       setTestsLoading(true);
       setTestSearchValue('');
    }, [activeTab])

   const { dropdownRef, dotsRef, isOptionsOpen, closeOptions, toggleOptions } =
      useOptions();

   const redirectToUserProfileAdminView = (userId) => {
      navigate(`/users/team/${team?.id}/profile/${userId}`);
   };

   const onSelectMentor = (user) => {
      setSelectedMentor(user);
   };

   const openConfirmModal = () => {
      closeOptions();
      confirmModal.open();
   };

   const openRenameModal = () => {
      closeOptions();
      renameModal.open();
   };

   const openChangeTeamMentorModal = () => {
      closeOptions();
      changeTeamMentorModal.open();
   };

   const initStudentsTableData = () => {
      const students = userList.map((user) => ({
         id: user?.id,
         name: user?.firstName || user?.lastName ? `${user?.firstName || ''} ${user?.lastName || ''}` : '-',
         email: user?.email ? user?.email : "-",
         position: user?.position || '-',
         was: user?.lastActivity
            ? moment(user?.lastActivity).format("MMM DD, YYYY h:mm a")
            : "Was not online",
         status: user?.status,
      }));

      setStudentsTableData(students);
   };

   const updateStudentsTableData = () => {
      if (userList?.length) {
         initStudentsTableData();
      }
   };

   const isTeams = true;

   //  Team courses (by default courses that have every team member)
   const { courseList: coursesList, isCoursesLoading, refetchCourses, totalCourses, setCurrentPage: setCurrentTeamCoursesPage, clearAllCourseFilters, searchedValue: сoursesSearchedValue, setSearchedValue: setCoursesSearchedValue, fetchCourses: fetchTeamCourses } = useGetCoursesOfUserOrTeam(currentCompanyId, params?.id, isTeams);

   const lastCourseRef = useIntersectionObserver(
      () => {
        if (!isCoursesLoading && coursesList?.length < totalCourses) {
          if(coursesList?.length === 0) {
            setCurrentTeamCoursesPage(1);
          } else {
            setCurrentTeamCoursesPage((prev) => prev + 1);
          }
        }
      },
      [isCoursesLoading, totalCourses]
    );

    const getAllTeamCoursesAtOnce = async () => {
      const res = await new CourseApi().getAllTeamCoursesAtOnce(params?.id, currentCompanyId);

      if(res?.success?.data) {
         setAllCoursesInTeam(res?.success?.data);
      }
    }

    const { courseList: allCoursesList, isCoursesLoading: isAllCoursesListLoading, refetchCourses: refetchAllCourses, totalCourses: allCoursesTortal, setCurrentPage: setAllCoursesPage, searchedValue: allCoursesSearchedValue, setSearchedValue: setAllCoursesSearchedValue, selectAll, setSelectAll, fetchCourses } = useGetYourCoursesInCompany(currentCompanyId);

    const lastAllCourseRef = useIntersectionObserver(
       () => {
         if (!isAllCoursesListLoading && allCoursesList?.length < allCoursesTortal) {
           if(allCoursesList?.length === 0) {
            setAllCoursesPage(1);
           } else {
            setAllCoursesPage((prev) => prev + 1);
           }
         }
       },
       [isAllCoursesListLoading, allCoursesTortal]
     );

   const { setCurrentPage: setCurrentSpecialUsersPage,
      userList: specialUsersList, 
      setUserList: setSpecialUsersList, 
      isUsersLoading: isSpecialUsersLoading, 
      totalUsers: totalSpecialUsers, 
      searchedValue: specialUsersSearchedValue, 
      setSearchedValue: setSpecialUsersSearchedValue,
      refetchUsers: refetchSpecialUsers
   } = useGetActiveUsersOfCompany(currentCompanyId, false, true);

      const lastSpecialUserRef = useIntersectionObserver(
      () => {
      if (!isSpecialUsersLoading && specialUsersList?.length < totalSpecialUsers) {
         if(specialUsersList?.length === 0) {
            setCurrentSpecialUsersPage(1);
         } else {
            setCurrentSpecialUsersPage((prev) => prev + 1);
         }
      }
      },
      [isSpecialUsersLoading, totalSpecialUsers]
      );

      const { setCurrentPage, totalUsers, 
         userList: teamMembers, 
         isUsersLoading: isMembersLoading, 
         searchedValue: teamMembersSearchedValue, 
         setSearchedValue: setTeamMembersSearchedValue 
      } = useGetAllUsersOfCompany(currentCompanyId);
   
      const lastStudentRef = useIntersectionObserver(
      () => {
      if (!isMembersLoading && teamMembers?.length < totalUsers) {
         if(teamMembers?.length === 0) {
            setCurrentPage(1);
         } else {
            setCurrentPage((prev) => prev + 1);
         }
      }
      },
      [isMembersLoading, totalUsers]
      );

   const fetchUsersInTeam = async () => {
      setIsUsersLoading(true);

      const res = await new TeamApi()
         .getOneTeam(params?.id, TEAM_EXTRA_GROUP_TABLE)
   
      if (res?.success?.data) {
         const links = await returnTemporaryLinks(res?.success?.data?.mentors?.filter(user => user?.avatar));

         const usersWithUpdatedLinks = res?.success?.data?.mentors?.map((user) => mapUsersWithNewLinks(user, links))?.map((user)=> mapUsersWithFilteredRoles(user, currentCompanyId));

         setUserList(res?.success?.data?.users);
         setTeam({
            ...res?.success?.data,
            mentors: usersWithUpdatedLinks
         });
      }

         setIsUsersLoading(false);
   };

   const chunkArray = (array, chunkSize) => {
      const chunks = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        chunks.push(array.slice(i, i + chunkSize));
      }
      return chunks;
    };
    
    const addUserToTeamInChunks = (selectedUserList, teamId, chunkSize = 35) => {
      const userChunks = chunkArray(selectedUserList, chunkSize);
      const allAddUserPromises = [];
    
      userChunks.forEach((chunk) => {
        const data = {
          user_ids: chunk.map(user => user?.id),
          add: true
        };
    
        const chunkPromise = new TeamApi().addUsersToTeam(teamId, data)
          .then(resAddUsers => resAddUsers)
          .catch(error => {
            console.error(`Error adding users in chunk: ${error}`);
            throw error;
          });
    
        allAddUserPromises.push(chunkPromise);
      });
    
      return allAddUserPromises;
    };

    const updateUserCourseAccessInChunks = async (passedUsers, chunkSize = 35) => {
      const courseChunks = chunkArray(allCoursesInTeam, chunkSize);
      const allAddUserPromises = [];
    
      setIsAddLoading(true);

      try {
        for (const courseChunk of courseChunks) {
          const usersData = {
            user_ids: passedUsers?.map(user => user?.id),
            course_ids: courseChunk?.map((item) => item?.id),
            status: COURSE_STATUS_ACTIVE,
            send_email: false,
            overwrite_user_course_active_or_paused_statuses: false
          };
    
          const res = await new CourseApi().addAcceessOfFewCoursesToFewUsers(usersData);
          allAddUserPromises.push(res);
        }
    
        refetchCourses();
        selectUserModal.close();
      } catch (error) {
        console.error(`Error updating access for courses: ${error}`);
        throw error;
      } finally {
        setIsAddLoading(false);
      }
    
      return allAddUserPromises;
    };

   const onAddUserToTeam = async () => {
      setIsAddLoading(true);
      const addUserPromises = addUserToTeamInChunks(selectedUserList, params?.id);

      const results = await Promise.allSettled(addUserPromises);
      const hasErrors = results.find((result) => !result?.value?.success);

      setIsAddLoading(false);

      const usersToUpdateCoursesStatus = results
         ?.map(r => r?.value?.success?.data)
         ?.flatMap(responseChunk => 
                responseChunk
            .filter(item => item.success && item.success.code === 200)
            .map(item => item.success.data.user)
         );

      if(usersToUpdateCoursesStatus?.length && allCoursesInTeam?.length) {
         await updateUserCourseAccessInChunks(usersToUpdateCoursesStatus);
      } else {
         selectUserModal.close();
      }

      setIsAddLoading(false);

      if (hasErrors?.value?.error) {
         setSelectedUserList([]);
         fetchUsersInTeam();
         return toast(<ToastMsg text={hasErrors?.value?.error?.message || "You are not allowed to do it"} isError />);
      } else {
         // when we add user to the teaam we add again existing courses to the team
         // in case of not doing it the courses won't appear in users account 
         // needed for giving the user courses of the team / since on server side we don't do it
         toast(<ToastMsg text={"Students have been added to the team!"} />);
         setSelectedUserList([]);
      }

      fetchUsersInTeam();
   };

   const onAddCourseToTeam = async () => {
      const chunkSize = 20;
      const courseChunks = chunkArray(selectedCoursesList, chunkSize);
      
      setIsAddLoading(true);
    
      try {
        let totalCoursesProcessed = 0;
    
        for (const courseChunk of courseChunks) {
          const coursesToAdd = courseChunk.map(course => ({
            course_id: course?.id,
            add: true
          }));
    
          const data = {
            course_data: coursesToAdd,
            send_email: false,
            overwrite_user_course_active_or_paused_statuses: false
          };
    
          const res = await new TeamApi().addCoursesToTeam(team?.id, data);
    
          if (res?.error?.message) {
            setSelectedCoursesList([]);
            return toast(<ToastMsg text={res?.error?.message || "Something went wrong"} isError />);
          }
    
          if (res?.success?.message) {
            totalCoursesProcessed += coursesToAdd.length;
            toast(
              <ToastMsg
                text={`Processed ${totalCoursesProcessed} courses: ${res?.success?.message}`}
              />
            );
          }
        }
    
        // Additionally add courses to mentors of the team after all chunks are processed
        const mentors = team?.mentors?.filter(user => user?.id !== Number(userId));
        if (mentors && mentors.length > 0) {
          updateUserCourseAccessInChunks(mentors)
        }
    
        // Cleanup and refresh actions
        setSelectedCoursesList([]);
        selectCourseModal.close();
        refetchCourses();
        getAllTeamCoursesAtOnce();
    
      } catch (error) {
        console.error(`Error updating access for courses: ${error}`);
        toast(<ToastMsg text={`Unexpected error: ${error.message}`} isError />);
      } finally {
        setIsAddLoading(false);
      }
    };

   const onRefreshCourseAccessToUsers = async (passedUsers) => {
      setIsAddLoading(true);
      const usersData = {
         user_ids: passedUsers?.map(user => user?.id),
         course_ids: allCoursesInTeam?.map((item) => item?.id),
         status: COURSE_STATUS_ACTIVE,
         send_email: false,
         overwrite_user_course_active_or_paused_statuses: false
      };

      const res = await new CourseApi().addAcceessOfFewCoursesToFewUsers(usersData);
      setIsAddLoading(false);
      refetchCourses();
      selectUserModal.close();
   };

   const onRemoveAccess = (courseId) => {
      if(!courseId) return;
      confirmModalAccess.open();
      setCourseIdToRemove(courseId);
   }

   const removeAccess = async () => {
      setIsChangeLoading(true);
      const data = { course_data: [{ course_id: courseIdToRemove, add: false }] }
      const res = await new TeamApi().addCoursesToTeam(team?.id, data);
      
      if(res?.error?.message) {
         toast(<ToastMsg text={res?.error?.message} isError />)
       }

      if(res?.success) {
         refetchAllCourses();
         refetchCourses();
         getAllTeamCoursesAtOnce();
      }

      confirmModalAccess.close();
      setIsChangeLoading(false);
   }

   const renameTeam = async () => {
      setIsAddLoading(true);
      const data = {
         new_name: teamNameInput,
      };

      const res = await new TeamApi().updateTeam(params?.id, data);

      if (res?.success?.message) {
         toast(<ToastMsg text={res?.success?.message} />);
         fetchUsersInTeam();
         renameModal.close();
         setTeamNameInput("");
      }

      if (res?.error?.message) {
         toast(<ToastMsg text={res?.error?.message} isError />);
      }

      setIsAddLoading(false);
   };

   const deleteTeam = async () => {
      setIsChangeLoading(true);
      const res = await new TeamApi().deleteTeam(params?.id);

      if (res?.success?.message) {
         toast(<ToastMsg text={res?.success?.message} />);
         navigate("/users");
      }

      if (res?.error?.message) {
         toast(<ToastMsg text={res?.error?.message} isError />);
      }
      setIsChangeLoading(false);
   };

   const removeMentor = async (clickedId) => {
      setIsRemoveMentorLoading(true);

      const res = await new TeamApi().editTeamMentors(params?.id, {
         mentor_data: [
            {
               user_id: clickedId,
               add: false
            }
         ]
      })

      fetchUsersInTeam();
      setIsRemoveMentorLoading(false);
   
      if (res?.error?.message) return toast(<ToastMsg text={res?.error?.message} isError/>)
    }

   const changeMentor = async () => {
      setIsAddLoading(true);
      const data = {
         new_mentor_id: selectedMentor?.id,
      };

      const res = await new TeamApi().updateTeam(params?.id, data);

      if (res?.success?.message) {
         toast(<ToastMsg text={res?.success?.message} />);
         changeTeamMentorModal.close();
         setSelectedMentor({});
         fetchUsersInTeam();

         const mentor = [{ id: selectedMentor?.id }];

         if(allCoursesInTeam?.length && selectedMentor?.id && selectedMentor?.id !== +userId) updateUserCourseAccessInChunks(mentor)
      }

      if (res?.error?.message) {
         toast(<ToastMsg text={res?.error?.message} isError />);
      }

      setIsAddLoading(false);
   };

   const handleTabChange = (tab) => {
      setActiveTab(tab);
   };

   useEffect(() => {
      if(currentCompanyId) {
         getAllTeamCoursesAtOnce();
         fetchUsersInTeam();
      } 
   }, [currentCompanyId]);

   useEffect(() => {
      setSearchedValue("");
   }, [activeTab]);

   useEffect(() => {
      updateStudentsTableData();
   }, [userList, activeTab]);

   const generateCsvDataStudents = () => {
      return [
         ALL_STUDENTS_IN_TEAM_TABLE_HEADERS,
         ...studentsTableData?.map(
            ({ name, email, position, was }) => 
            [ name, email, position, was ]
         ),
      ];
   };

   const generateCsvDataCourses = () => {
      return [
         ALL_COURSES_IN_TEAM_TABLE_HEADERS,
         ...coursesList?.map(mapTeamCourses)?.map(
            ({ folder_name, course_name }) => 
            [ folder_name, course_name ]
         ),
      ];
   };

   const mappedTests = tests?.map(testsMap)

   const generateCsvDataKnowledgeCheck = () => {
      return [
         USERS_TESTS_COLUMN,
         ...mappedTests?.map(({ student, course_name, test_name, end_date, pass_time}) => [
            student, course_name, test_name, end_date, pass_time,
            ])
      ];
   };

   const generateCsvDataKnowledgeChecked = () => {
      return [
         USERS_TESTS_COLUMN,
         ...mappedTests?.map(({ student, course_name, test_name, end_date, pass_time}) => [
             student, course_name, test_name, end_date, pass_time,
            ])
      ];
   };


   const mentorAvatar = (mentor) => {
      return mentor?.avatar ? mentor?.avatar : avatar;
   } ;

   return (
      <MainLayout darkBg>
         <section className={styles.top_section}>
            <h2>{team?.name}</h2>
            <div className={styles.top_section_right}>
               <div className={styles.user_info}>
                  
                  <div className={styles.mentors}>
                     {team?.mentors?.length > 0 ? (
                        team.mentors.map((mentor, index) => (
                           <div className={styles.mentor_item} key={index}>
                              <span className={styles.avatar_wrap}>
                                 <img
                                    className={styles.avatar_wrap_image}
                                    src={mentorAvatar(mentor)}
                                    alt=""
                                    width="40"
                                    height="40"
                                 />
                              </span>
                              <p>{mentor.firstName} {mentor.lastName}</p>
                              <button disabled={isRemoveMentorLoading} className={styles.removeMentor} onClick={()=> removeMentor(mentor?.id)}>
                                 <img src={cross} alt="" />
                              </button>
                           </div>
                        ))
                     ) : (
                        <p></p>
                     )}
                  </div>

                  <div className={styles.user_info_item}>
                     <img src={books} alt="books icon" />
                     <CustomStaticTooltip 
                        id={uuidv4()}
                        hint={t('Courses in team')} 
                        text={totalCourses || 0}
                     />
                  </div>
                  <div className={styles.user_info_item}>
                     <img src={profile} alt="profile icon" />
                     <CustomStaticTooltip 
                        id={uuidv4()}
                        hint={t('Users in team')} 
                        text={team?.users?.length}
                     />
                  </div>
                  {/* FOR FUTURE <div className={styles.user_info_item}>
                     <img src={folders} alt="folders icon" />
                     <span>1</span>
                  </div>
                  <div className={styles.user_info_item}>
                     <img src={stars} alt="stars icon" />
                     <span>91%</span>
                  </div> */}

                  {!userRoleName?.includes(PART_OF_MENTOR_NAME) && 
                     <div className={styles.teams_options}>
                        <img
                           ref={dotsRef}
                           onClick={() => toggleOptions()}
                           src={dots}
                           alt=""
                        />
                     </div>
                  }

                  {isOptionsOpen && (
                     <div ref={dropdownRef} className={styles.dropdown}>
                        <p className={styles.options} onClick={openRenameModal}>
                           {t("Rename team")}
                        </p>
                        <p
                           className={styles.options}
                           onClick={openChangeTeamMentorModal}
                        >
                           {t("Add mentor")}
                        </p>
                        <p
                           className={styles.options}
                           onClick={openConfirmModal}
                        >
                           {t("Delete team")}
                        </p>
                     </div>
                  )}
               </div>
            </div>
         </section>
         <main className={styles.users_page}>
            <div className={styles.loader_wrapper}>
               {isUsersLoading && <SecondLoader />}
            </div>
            <div className={styles.tab_navigation}>
               <button
                  className={cn(
                     styles.tab,
                     activeTab === "students" && styles.activeTab
                  )}
                  onClick={() => handleTabChange("students")}
               >
                  {t("Students")}
               </button>
               <button
                  className={cn(
                     styles.tab,
                     activeTab === "access" && styles.activeTab
                  )}
                  onClick={() => handleTabChange("access")}
               >
                  {t("Access")}
               </button>
               <button
                  className={cn(
                     styles.tab,
                     activeTab === "knowledge_check" && styles.activeTab
                  )}
                  onClick={() => handleTabChange("knowledge_check")}
               >
                  {t("Knowledge Check")}
               </button>
               <button
                  className={cn(
                     styles.tab,
                     activeTab === "knowledge_checked" && styles.activeTab
                  )}
                  onClick={() => handleTabChange("knowledge_checked")}
               >
                  {isAllTestsLoading ? `${t("Loading")}...` : t("Checked")}
                  
               </button>
            </div>

            {activeTab === "students" && (
               <>
                  <Subheader
                     key={"students"}
                     setSearchedValue={setSearchedValue}
                     searchedValue={searchedValue}
                     refetchData={fetchUsersInTeam}
                     csvData={generateCsvDataStudents()}
                     setSelectedUserList={setSelectedUserList}
                     selectedUserList={selectedUserList}
                     data={teamMembers}
                     lastStudentRef={lastStudentRef}
                     selectModal={selectUserModal}
                     onAddUserToTeam={onAddUserToTeam}
                     isAddLoading={isAddLoading}
                     studentSearchedValue={teamMembersSearchedValue}
                     setStudentSearchedValue={setTeamMembersSearchedValue}
                  />
                  <TableBlock
                     key={"users_in_team_table"}
                     data = {
                        studentsTableData?.filter(item => {
                          return (
                            item.name.toLowerCase().includes(searchedValue.toLowerCase()) ||
                            item.email.toLowerCase().includes(searchedValue.toLowerCase()) ||
                            item.position.toLowerCase().includes(searchedValue.toLowerCase())
                          );
                        })
                      }
                      
                     headers={ALL_STUDENTS_IN_TEAM_TABLE_HEADERS}
                     searchedValue={searchedValue}
                     refetchUsers={fetchUsersInTeam}
                     isUsersloading={isUsersLoading}
                     redirectToUserProfileAdminView={redirectToUserProfileAdminView}
                  />
               </>
            )}

            {activeTab === "access" && (
               <>
                  <Subheader
                     key={"access"}
                     setSearchedValue={setCoursesSearchedValue}
                     searchedValue={сoursesSearchedValue}
                     refetchData={fetchUsersInTeam}
                     fetchDataForCSV={fetchTeamCourses}
                     csvData={generateCsvDataCourses()}
                     selectedCoursesList={selectedCoursesList}
                     setSelectedCoursesList={setSelectedCoursesList}
                     data={allCoursesList}
                     selectModal={selectCourseModal}
                     isAccess
                     lastCourseRef={lastAllCourseRef}
                     allCoursesSearchedValue={allCoursesSearchedValue}
                     setAllCoursesSearchedValue={setAllCoursesSearchedValue}
                     onAddCourseToTeam={onAddCourseToTeam}
                     isAddLoading={isAddLoading}
                     selectAll={selectAll}
                     setSelectAll={setSelectAll}
                     isCoursesLoading={isAllCoursesListLoading || isCoursesLoading}
                  />
                  <TableBlock
                     key={"courses_in_team_table"}
                     data={coursesList?.map(mapTeamCourses)}
                     isAccess
                     lastCourseRef={lastCourseRef}
                     onRemoveAccess={onRemoveAccess}
                     headers={ALL_COURSES_IN_TEAM_TABLE_HEADERS}
                     searchedValue={сoursesSearchedValue}
                     refetchUsers={refetchCourses}
                     isUsersloading={isCoursesLoading}
                     allCoursesListLoading={allCoursesListLoading}
                  />
               </>
            )}

            {activeTab === "knowledge_check" && (
               <div className={styles.knowledge_check}>
                 <KnowledgeSubheader
                     key={"knowledge_check"}
                     setSearchedValue={setTestSearchValue}
                     searchedValue={testSearchValue}
                     csvData={generateCsvDataKnowledgeCheck()}
                     dateFilter={dateFilter}
                     isDataLoading={testsLoading}
                     fetchTests={fetchTests}
                     setDateFilter={setDateFilter}
                     isTeamPage
                     setFrontTimeFilterValue={setFrontTimeFilterValue}
                     frontTimeFilterValue={frontTimeFilterValue}
                  />
                   <UsersTestsTable
                     lastTestRef={lastTestRef}
                     key={"knowledge_check_table"}
                     data={mappedTests}
                     columns={USERS_TESTS_COLUMN}
                     isTestsLoading={testsLoading}
                     isTeam
                     t={{returnTranslation: t}}
                  />
               </div>
            )}

            {activeTab === "knowledge_checked" && (
               <div className={styles.knowledge_check}>
                 <KnowledgeSubheader
                     key={"knowledge_checked"}
                     setSearchedValue={setTestSearchValue}
                     searchedValue={testSearchValue}
                     csvData={generateCsvDataKnowledgeChecked()}
                     dateFilter={dateFilter}
                     setDateFilter={setDateFilter}
                     checked
                     activeFilter={activeFilter}
                     setActiveFilter={setActiveFilter}
                     isTeamPage
                     fetchTests={fetchTests}
                     isDataLoading={testsLoading}
                  />
                  <UsersTestsTable
                     lastTestRef={lastTestRef}
                     key={"knowledge_checked_table"}
                     data={mappedTests}
                     columns={USERS_TESTS_COLUMN}
                     isTestsLoading={testsLoading}
                     isTeam
                     t={{returnTranslation: t}}
                  />
               </div>
            )}

            <ConfirmModal
               isRemove
               confirmButtonText={t("Delete")}
               onConfirm={deleteTeam}
               onClose={confirmModal.close}
               isOpen={confirmModal.isActive}
               title={t("Are you sure?")}
               isLoading={isChangeLoading}
            />

            <ConfirmModal
               confirmButtonText={t("Remove access")}
               onConfirm={removeAccess}
               onClose={confirmModalAccess.close}
               isOpen={confirmModalAccess.isActive}
               title={t("Are you sure?")}
               isLoading={isChangeLoading}
            />

            <RenameModal
               onConfirm={renameTeam}
               onClose={renameModal.close}
               isOpen={renameModal.isActive}
               inputValue={teamNameInput}
               setInputValue={setTeamNameInput}
               title={t("Change team name")}
               isLoading={isAddLoading}
            />

            <ChangeTeamMentorModal
               onConfirm={changeMentor}
               onClose={()=> {
                  changeTeamMentorModal.close();
                  setSelectedMentor({})
               }}
               isOpen={changeTeamMentorModal.isActive}
               teamMembers={specialUsersList}
               lastSpecialUserRef={lastSpecialUserRef}
               onSelectMentor={onSelectMentor}
               title={t("Add team mentor")}
               isLoading={isAddLoading}
               selectedMentor={selectedMentor}
            />
         </main>
      </MainLayout>
   );
};

export default TeamPage;
