import React, {useEffect, useState} from 'react';
import styles from './styles.module.scss'
import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import searchIcon from '../../../assets/images/symbols/Search.svg'
import notFound from '../../../assets/images/symbols/nothing_found.svg'
import Button from "../../Button/Button";
import Checkbox from "../../Checkbox/Checkbox";
import Loader from '../../Loader/Loader';
import cn from 'classnames';
import useFindTranlsations from '../../../hooks/useFindTranlsations';

const SelectCourseModal = ({isOpen, onClose, setSelectedCourses, selectedCourses, coursesList, onAddCourse, oneStep, isDataLoading, lastCourseRef, searchedValue, setSearchedValue, selectAll, setSelectAll, coursesLoading}) => {
    const [search, setSearch] = useState('');
    const t = useFindTranlsations();

    const onSelectFilters = (course) => {
        if (selectedCourses?.find(item => item?.id === course?.id)) setSelectedCourses(selectedCourses?.filter(item => item?.id !== course?.id))
        else setSelectedCourses([...selectedCourses, course])
    }

    const filterBySearch = (list) => list?.filter(item => search?.length ? item?.title?.toLowerCase()?.includes(search?.toLowerCase()) : true); 

    const closeModal = () => {
      onClose();
      if(oneStep) setSelectedCourses([]);
    }

    const toggleSelectAll = () => {
      setSelectAll(!selectAll);
      if(selectAll) setSelectedCourses([]);
   };

  useEffect(()=> {
   if(coursesLoading) return;

   if (selectAll) {
      setSelectedCourses(coursesList);
   } else {
      setSelectedCourses(selectedCourses);
   }
  }, [selectAll, coursesLoading])

  useEffect(()=> {
    return () => {
      setSearch('');
      setSelectedCourses([]);
   }
  }, [])

  const searchedData = search ? filterBySearch(coursesList) : coursesList

    return (
        <ModalLayout
            isOpen={isOpen}
            onClose={closeModal}
            maxHeight={'550px'}
            maxWidth={'416px'}
            withCross
        >
            <p className={styles.title}>{t?.selectCourse}</p>
            <p className={styles.selected}>{t?.selected}: <span>{selectedCourses?.length}</span></p>
            <div className={styles.search}>
                <img src={searchIcon} alt={''}/>
                <input
                    placeholder={t?.search}
                    value={ searchedValue ? searchedValue : search}
                    onChange={(event) => {
                        if(setSearchedValue) {
                           setSearchedValue(event?.target?.value)
                        } else {
                           setSearch(event?.target?.value)
                        }
                    }}
                />
            </div>

            {searchedData?.length > 0 && 
               <div className={cn(styles.selectAllButton, 'noselect')} onClick={toggleSelectAll}>
                  <Checkbox isChecked={selectAll} isGrey />
                  <span>{t?.selectAll}</span>
               </div>
            }

            {
                !!searchedData?.length
                    ? <div className={styles.list}>
                        {searchedData?.map((item, key) =>
                            <div ref={coursesList?.length - 1 === key && lastCourseRef ? lastCourseRef : null} key={key} onClick={() => onSelectFilters(item)} className={styles.list_item}>
                                <Checkbox isChecked={selectedCourses?.some(sItem => sItem?.id === item?.id)} isGrey/>
                                {item?.title}
                            </div>)}
                    </div>
                    : <div className={styles.notFound}>
                        <img src={notFound} alt={''}/>
                        {t?.thereAreNoData}
                    </div>
            }

            <Button disabled={isDataLoading || !selectedCourses?.length} title={t?.next} onClick={onAddCourse ? onAddCourse : closeModal}/>

            {isDataLoading && 
               <div className='default_loader_wrapper'>
                  <Loader size={'small'}/>
               </div>
            }

        </ModalLayout>
    );
};

export default SelectCourseModal;