import Input from "../../../components/Input/Input";
import Button from "../../../components/Button/Button";
import { COLOR_PRESETS } from "../../../constants/courses";
import { FOLDER_ICONS_LIST } from "../../../constants/folders";
import { useParams } from "react-router-dom";

import styles from "./styles.module.scss";
import cn from "classnames";

const BasicSettingsTab = ({ data, setData, t }) => {
   const params = useParams();
   
   return (
      <div translate="no" className={styles.basicSettingsTab}>
         <div className={styles.block}>
            <Input
               placeholder={`${t?.enterATitle}...`}
               max={40}
               withHeader
               title={t?.title}
               value={data?.new_name}
               onChangeValue={(event) =>
                  setData({ ...data, new_name: event?.target?.value })
               }
            />
         </div>
         {!params?.topicId && (
            <>
               <div className={styles.block}>
                  <div className={styles.colors}>
                     <div className={styles.subtitle}>{t?.colourPresets}</div>
                     <div className={styles.colors_list}>
                        {COLOR_PRESETS?.map((item, key) => (
                           <div
                              key={key}
                              onClick={() => {
                                 setData({
                                    ...data,
                                    new_color: item?.id,
                                    hexColor: item?.hexColor,
                                 });
                              }}
                              className={cn(
                                 styles.colors_list_item,
                                 +data?.new_color === item?.id &&
                                    styles.colors_list_item_active
                              )}
                           >
                              <div style={{ background: item.color }}></div>
                           </div>
                        ))}
                     </div>
                  </div>
               </div>

               <div className={styles.block}>
                  <div className={styles.subtitle}>{t?.folderIcon}</div>
                  <div className={styles.icons_list}>
                     {FOLDER_ICONS_LIST?.map((item) => (
                        <item.Icon
                           key={item?.id}
                           className={cn(
                              styles.icon,
                              item?.name === data?.new_icon ? styles.active : ""
                           )}
                           fill={"rgba(147, 148, 149, 1)"}
                           onClick={() => {
                              setData({
                                 ...data,
                                 new_icon: item?.name,
                              });
                           }}
                        />
                     ))}
                  </div>
               </div>
            </>
         )}
      </div>
   );
};

export default BasicSettingsTab;
