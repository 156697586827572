import { DEFAULT_LANG_LOCALE } from "../../constants/languages";
import { localStorageGet, localStorageSet } from "../../utils/localStorage";
import { TOGGLE_SIDEBAR, SET_INFO_LOADING, TOGGLE_SIDEBAR_FIXED, SET_CHOSEN_LANGUAGE, SET_COMPANY_INFO, SET_COMPANY_ICON, SET_COMPANY_NAME, SET_USER_AS_ADMIN, SET_CURATOR_MODAL_HASH, SET_IS_CONFIRM_MODAL_NEEDED, SET_COMPANY_AVAILABLE_LANGUAGES, SET_USER_LANGUAGE, SET_COMPANY_APP_TRANSLATION } from "../constants/sidebar";

// Get initial state from local storage
const initialState = {
  isOpen: localStorage.getItem("sidebarState") === "true",
  isFixed: localStorage.getItem("sidebarFixedState") === "true",
  companyName: localStorage.getItem("companyName") || "Default",
  companyLogo: localStorage.getItem("companyLogo") || "",
  companyId: localStorage.getItem("companyId") || 1,
  locale: JSON.parse(localStorage.getItem("locale")) || '',
  currentCompanyBranchId: localStorage.getItem("currentCompanyBranchId") || 1,
  isUserAsStudent: localStorage.getItem("isUserAsStudent") === 'true' || false,
  curatorModalHash: null,
  confirmModalNeeded: false,
  translations: localStorageGet("translations", []),
  availableLanguages: [],
  loading: true,
  chosenLanguage: localStorageGet('chosenLanguage', DEFAULT_LANG_LOCALE)
};

export default function sidebarReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      // Save state in local storage
      localStorage.setItem("sidebarState", action.isOpen.toString());

      return {
        ...state,
        isOpen: action.isOpen,
      };
    case TOGGLE_SIDEBAR_FIXED:
      // Save state in local storage
      localStorage.setItem("sidebarFixedState", action.isFixed.toString());

      return {
        ...state,
        isFixed: action.isFixed,
      };
      case SET_COMPANY_INFO:
      // Save company info in local storage
      localStorage.setItem("companyName", action.companyName);
      localStorage.setItem("companyLogo", action.companyLogo);
      localStorage.setItem("companyId", action.companyId);
      localStorage.setItem("currentCompanyBranchId", action.currentCompanyBranchId);

      return {
         ...state,
         companyName: action.companyName,
         companyLogo: action.companyLogo,
         companyId: action.companyId,
         currentCompanyBranchId: action.currentCompanyBranchId,
      };
      case SET_COMPANY_ICON:
      localStorage.setItem("companyLogo", action.companyLogo);

      return {
         ...state,
         companyLogo: action.companyLogo,
      };
      case SET_COMPANY_NAME:
      localStorage.setItem("companyName", action.companyName);
      return {
         ...state,
         companyName: action.companyName,
      };
      case SET_COMPANY_AVAILABLE_LANGUAGES:
      return {
         ...state,
         availableLanguages: action.langs,
      };
      case SET_INFO_LOADING:
      return {
         ...state,
         loading: action.loading,
      };
      case SET_USER_LANGUAGE:
      localStorage.setItem("locale", action.locale);
      return {
         ...state,
         locale: action.locale,
      };
      case SET_COMPANY_APP_TRANSLATION:
      localStorage.setItem("translations", JSON.stringify(action.translations));
      return {
         ...state,
         translations: action.translations,
      };
      case SET_CHOSEN_LANGUAGE:
      localStorageSet("chosenLanguage", action.chosenLanguage);

      return {
         ...state,
         chosenLanguage: action.chosenLanguage,
      };
      case SET_USER_AS_ADMIN:
      localStorage.setItem("isUserAsStudent", action.isUserAsStudent);

      return {
         ...state,
         isUserAsStudent: action.isUserAsStudent,
      };
      case SET_CURATOR_MODAL_HASH:
      return {
         ...state,
         curatorModalHash: action.curatorModalHash,
      };
      case SET_IS_CONFIRM_MODAL_NEEDED:
      return {
         ...state,
         confirmModalNeeded: action.confirmModalNeeded,
      };
    default:
      return state;
  }
}
