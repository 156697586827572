import React, { useEffect, useState } from "react";

import shortLogo from "../../../assets/images/avatar.svg";

import styles from "./styles.module.scss";
import AgencyApi from "../../../utils/api/AgencyApi";
import ToastMsg from "../../../components/ToastMsg/ToastMsg";
import { toast } from "react-toastify";
import UserApi from "../../../utils/api/UserApi";
import cn from 'classnames';
import Loader from "../../../components/Loader/Loader";
import { useDispatch } from "react-redux";
import { setInfo } from "../../../store/actions/user";
import { useSelector } from "react-redux";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const LogoBlock = ({ avatar, setAvatar, uploadAvatarModal, renderedIconFile, setRenderefIcon }) => {
   const [isChangeIconLoading, setIsChangeIconLoading] = useState(false);
   const userInfo = useSelector(state => state?.user?.info);
   const dispatch = useDispatch();

   const t = useFindTranslationsByString();
 
   const deleteFavicon = async () => {
      setIsChangeIconLoading(true);
      new UserApi().deleteUserAvatar().then((res) => {
         if (res?.success) {
            setAvatar(shortLogo);
            setRenderefIcon('');
            dispatch(setInfo({
               ...userInfo,
               avatar: null
            }));
            return toast(<ToastMsg text={res?.success?.message} />);
         }
         if (res?.error) {
            return toast(<ToastMsg text={res?.error?.message} isError />);
         }
      }).finally(()=> setIsChangeIconLoading(false));
   };

   const openUploadModal = () => {
      uploadAvatarModal.open();
   };

   return (
      <div className={styles.settings_box}>
         <p className={styles.setting_box_name}>{t("Profile image")}</p>
         <div className={styles.settings_favicon}>
          
            <div className={styles.logo_body}>
               <div className={styles.settings_logo_header}>
                  <span
                     className={`${styles.settings_box_icon} ${styles.settings_box_icon_favicon}`}
                  />
                  <div className={styles.settings_logo_header_text}>
                     <p className={styles.settings_logo_title}>
                        {t("Upload Icon logotype")}
                     </p>
                     <p className={styles.settings_logo_description}>
                        {t("Recommended")}: 500 KB & 400x400px (PNG {t("or")} JPG)
                     </p>
                  </div>
               </div>

               <div className={styles.logo_wrapper}>
                  <div className={styles.logo_wrapper_second}>
                     {renderedIconFile && (
                        <img
                           src={renderedIconFile}
                           width="64"
                           height="64"
                           alt="Selected Favicon"
                        />
                     )}

                     {!renderedIconFile && 
                        <img src={shortLogo} width="64" height="64" alt="logo" />
                     }
                  </div>
            </div>

               <div className={styles.logo_small_buttons}>
                  <button onClick={openUploadModal} type="button" className={styles.button_upload_image}>
                     {t("Upload new image")}
                  </button>
                  <button
                     disabled={!renderedIconFile || isChangeIconLoading ? true : false}
                     onClick={deleteFavicon}
                     type="button"
                     className={cn(!renderedIconFile || isChangeIconLoading ? styles.button_disabled : '', styles.button_delete_image)}
                  >
                     {t("Delete image")}
                  </button>
               </div>

               {isChangeIconLoading && 
                  <div className="default_loader_wrapper">
                     <Loader size={"small"} />
                  </div>
               }
            </div>
         </div>
      </div>
   );
};

export default LogoBlock;
