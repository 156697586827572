import Base from "./Base";

export default class CertificateApi extends Base {
   getUserCertificates(
      limit,
      page,
      userID,
      companyID,
      searchedValue,
      tags,
      order
   ) {
      let url = `api/v1/certificate?limit=${limit}&page=${page}&filter[company]=${companyID}&filter[user]=${userID}`;

      if (searchedValue) {
         url += `&filter[search]=${searchedValue}`;
      }

      if (tags?.length > 0) {
         const tagsString = tags.map((tag) => `'${tag}'`).join(", ");
         url += `&filter[tags]=[${tagsString}]`;
      }

      if (order) {
         url += `&order=${order === "Old first" ? "asc" : "desc"}`;
      }

      return super.get(url);
   }

   getUserCertificatesCount(companyID, userID) {
      return super.get(
         `api/v1/certificate?limit=1&filter[company]=${companyID}&filter[user]=${userID}&order=desc`
      );
   }

   getCertificateTemplates() {
      return super.get(`api/v1/certificate-template`);
   }

   createCertificate(data) {
      return super.post(`api/v1/certificate`, data);
   }

   createCertificateTemplate(data) {
      return super.post(`api/v1/certificate-template`, data);
   }

   updateCertificateTemplate(templateID, data) {
      return super.put(`api/v1/certificate-template/${templateID}`, data);
   }
}
