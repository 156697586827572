import statisticsIcon from '../assets/images/curator/statistics.svg';
import newUsersIcon from '../assets/images/curator/new_users.svg';
import queriesIcon from '../assets/images/curator/queries.svg';
import { transformRoleText } from '../utils/rolesHelper';
import { sortRoles } from '../utils/usersHelper';

export const USER_GENDER = {
    'man': 1,
    'woman': 2,
    'other': 3,
}

export const KNOWLEDGE_CHECK_DATE_OPTIONS = [
   { name: "For today", label: "for_today" },
   { name: "For yesterday", label: "for_yesterday" },
   { name: "In a week", label: "in_a_week" },
   { name: "In a month", label: "in_a_month" },
]

export const DEFAULT_ROLES = [
   {name: 'All', label: 'all'},
   {name: 'Guest', label: 'guest'},
   {name: 'Student', label: 'student'},
   {name: 'Curator', label: 'curator'},
   {name: 'Mentor', label: 'mentor'},
   {name: 'Admin', label: 'admin'},
]

export const DEFAULT_DATA_LIMIT = 10;
export const SMALLER_DATA_LIMIT = 8;
export const BIGGER_DATA_LIMIT = 1000;
export const NOTIFICATION_API_CALL_INTERVAL = 120_000;

export const USER_STATUS_UNVERIFIED = 0; 
export const USER_STATUS_ACTIVE = 1; // is set when user is registered
export const USER_STATUS_INACTIVE = 2;
export const USER_STATUS_BANNED = 3;

export const ROLE_ID_SUPER_ADMIN = 1;
export const ROLE_ID_STUDENT = 2;
export const ROLE_ID_GUEST = 3;
export const ROLE_ID_ADMIN = 4;
export const ROLE_ID_MENTOR = 9;

export const BRANCH_ROLE_STUDENT = [
   {
       role: {
          id: ROLE_ID_STUDENT,
          name: 'ROLE_STUDENT'
       }
   }
 ]

export const PART_OF_MENTOR_NAME = 'MENTOR';
export const PART_OF_ADMIN_NAME = 'ADMIN';
export const PART_OF_CURATOR_NAME = 'CURATOR';

export const TABS_USER_PROFILE_ADMIN_VIEW = [
   "Access to materials",
   // "Surveys",
   "Knowledge check",   
   "Certificates",
   // "Statistics",
   // "Applications",
   "Teams"
 ];

export const requestStatusMapping = {
   1: 'waiting',
   2: 'accepted',
   3: 'declined',
 }

 export const REQUESTED_STATUS_REJECT = 3;
 export const REQUESTED_STATUS_ACCEPT = 2;
 export const REQUESTED_STATUS_PENDING = 1;

export const ROLES = [
   'ROLE_SUPER_ADMIN',
   'ROLE_STUDENT', 
   'ROLE_ADMIN', 
   'ROLE_EDITOR', 
   'ROLE_TEACHER',
   'ROLE_ANALYTIC',
   'ROLE_CURATOR'
];

 export const ALL_STUDENTS_TABLE_HEADERS = [
   "name",
   'email',
   "department",
   "position",
   "role",
   "was",
 ];

 export const PROFILE_TEAMS_TABLE_HEADERS = [
   "name",
   'participants',
   "role",
 ];

 export const PROFILE_CURATOR_TABLE_HEADERS = [
   "folder_name",
   'course_name',
 ];

 export const PROFILE_COMPANIES_TABLE_HEADERS = [
   "company_name",
   'courses',
   'participants',
   "role",
 ];

 export const ALL_STUDENTS_IN_TEAM_TABLE_HEADERS = [
   "name",
   "email",
   "position",
   "was",
 ];

 export const ALL_COURSES_IN_TEAM_TABLE_HEADERS = [
   "folder_name",
   "course_name",
 ];

 export const REQUESTED_STUDENTS_TABLE_HEADERS = [
   "name",
   "email",
   "position",
   "role",
   "was",
   "request"
 ];

 export const TEAMS_HEADERS = [
   "team",
   "department",
   "position",
   "amount",
 ];

 export const TEAMS_HEADERS_LESS_INFO = [
   "team",
   "department",
   "position",
 ];

 export const usersRightsData = [
   {
     name: "isHomework",
     title: "Homework",
     text: "The possibility of checking homework",
   },
   {
     name: "isAcceptedHomework",
     title: "Accepted homework",
     text: "Access to the accepted homework section with the ability to cancel the check",
   },
   {
     name: "isViewingLessons",
     title: "Viewing lessons",
     text: "The ability to view lessons",
   },
   {
     name: "isLessonModules",
     title: "Lesson modules",
     text: "The ability to customize and connect the appearance of the modules",
   },
   {
     name: "isAddingStudent",
     title: "Adding a student to the team",
     text: "The ability to add a new student manually or by list",
   },
   {
     name: "isShowDetails",
     title: "Show student contact details",
     text: "The ability to view student contact details",
   },
   {
     name: "isAddRoles",
     title: "Users",
     text: "The ability to add and remove users",
   },
   // {
   //   name: "isStatistics",
   //   title: "Statistics",
   //   text: "The ability to view analytical reports on student and employee performance",
   // },
   {
     name: "isTags",
     title: "Tags",
     text: "Adding, editing, deleting tags",
   },
 ];

 export const usersRightsDataFeedback = [
   {
     name: "isViewingRequests",
     title: "Viewing requests",
     text: "The ability to view requests from users",
   },
   {
     name: "isRespondToRequest",
     title: "Respond to requests",
     text: "The ability to respond to requests from users",
   },
 ];

 export const usersRightsDataSettings = [
   {
     name: "isAppearance",
     title: "Appearance",
     text: "The ability to edit the description and photo of the course",
   },
   {
     name: "isStudentPermissions",
     title: "Student Permissions",
     text: "Editing student permissions settings",
   },
   {
     name: "isRoleCapabilities",
     title: "Role Capabilities",
     text: "Edit rights settings for all roles",
   },
 ];

 // label should be the same as inputsData in UsersRight page
 export const pageStaticPermissionsInfo = [
   {permission_ids: [133], name: 'Homework', label: 'isHomework'},
   {permission_ids: [130, 132], name: 'Accepted homework', label: 'isAcceptedHomework'},
   {permission_ids: [75, 76], name: 'Viewing Lessons' , label: 'isViewingLessons'},
   {permission_ids: [67], name: 'Lesson modules' , label: 'isLessonModules'},
   {permission_ids: [119, 109], name: 'Adding a student' , label: 'isAddingStudent'},
   {permission_ids: [141, 9], name: 'Show student contact details' , label: 'isShowDetails'},
   {permission_ids: [41, 43, 129], name: 'Role' , label: 'isAddRoles'},
   // {permission_ids: [TODO], name: 'Statistics' , label: 'isStatistics'},
   // {permission_ids: [TODO], name: 'Viewing requests' , label: 'isViewingRequests'},
   // {permission_ids: [TODO], name: 'Respond to requests' , label: 'isRespondToRequest'},
   {permission_ids: [140], name: 'Tags' , label: 'isTags'},
   {permission_ids: [65, 118], name: 'Appearance' , label: 'isAppearance'},
   {permission_ids: [82, 83], name: 'Student Permissions' , label: 'isStudentPermissions'},
   {permission_ids: [84, 85, 138], name: 'Role Capabilities' , label: 'isRoleCapabilities'},
]

export const BASIC_INFO_TAB = '1';
export const POSITION_TAB = '2';
export const ACTIVITY_TAB = '3';
export const CERTIFICATES_TAB = '4';
export const CHANGE_PASSWORD_TAB = '5';

export const PROFILE_BASIC_SETTINGS_SUBMENU = [
   {
      id: BASIC_INFO_TAB,
      name: "Basic info",
      el: "basic_info",
   },
   {
      id: POSITION_TAB,
      name: "Position",
      el: "position",
   },
   {
      id: ACTIVITY_TAB,
      name: "Activity",
      el: "activity",
   },
   {
      id: CERTIFICATES_TAB,
      name: "Certificates",
      el: "certificates",
   },
]

export const PROFILE_SAFETY_SETTINGS_SUBMENU = [
   {
      id: CHANGE_PASSWORD_TAB,
      name: "Change password",
      el: "change_password",
   },
   // {
   //    id: 2,
   //    name: "Social media",
   //    el: "social_media",
   // }
]

export const MALE = 1;
export const FEMALE = 2;
export const OTHER_GENDER = 3;

export const GENDERS = [
   {type: MALE, name: 'Male'},
   {type: FEMALE, name: 'Female'},
   {type: OTHER_GENDER, name: 'Not specified'},
]

export const CURATOR_MODAL_LIST = [
   {id: 1, title: 'Statistics and progress', desc: 'Track statistics and progress of course participants', icon: statisticsIcon },
   {id: 2, title: 'New users', desc: 'Add participants to the course at any time', icon: newUsersIcon },
   {id: 3, title: 'Answers to queries', desc: 'Respond to requests from course participants', icon: queriesIcon },
]

export const returnCompanyFirstRoleName = (roles, currentCompanyId) => {
   const superAdminRole = roles?.filter(role => role?.role?.id === ROLE_ID_SUPER_ADMIN);
   const filteredByCompanyRoles = roles?.filter(role => role?.branch?.company?.id === +currentCompanyId)?.sort(sortRoles);

   return superAdminRole?.[0]?.role?.name || filteredByCompanyRoles?.[0]?.role?.name;
}

// !! that's excluded companies
const returnFilteredCompanyFirstRoleName = (roles, currentCompanyId) => {
   const superAdminRole = roles?.filter(role => role?.role?.id === ROLE_ID_SUPER_ADMIN);
   const filteredByCompanyRoles = roles?.filter(role => role?.branch?.company?.id !== currentCompanyId)?.sort(sortRoles);
   
   return superAdminRole?.[0]?.role?.name || filteredByCompanyRoles?.[0]?.role?.name;
}

const returnFilteredCompanyFirstCompanyName = (roles, currentCompanyId) => {
   const superAdminRole = roles?.filter(role => role?.role?.id === ROLE_ID_SUPER_ADMIN);
   const filteredByCompanyRoles = roles?.filter(role => role?.branch?.company?.id !== currentCompanyId);

   return superAdminRole?.[0]?.branch?.company?.name || filteredByCompanyRoles?.[0]?.branch?.company?.name;
}

export const mappedExcludedUsers = (user, currentCompanyId) => ({
   id: user?.id,
   role: transformRoleText(returnFilteredCompanyFirstRoleName(user?.userBranchRoles, currentCompanyId), returnFilteredCompanyFirstCompanyName(user?.userBranchRoles, currentCompanyId)) || 'Guest',
   name: user?.firstName || user?.lastName ? `${user.firstName || ''} ${user.lastName || ''}` : user.email,
   avatar: user?.avatar,
   department: user?.department,
   position: user?.position
})