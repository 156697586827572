import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";

import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import FiltersBlock from "./FiltersBlock/FiltersBlock";
import CourseBlock from "../../components/CourseBlock/CourseBlock";
import useHandleModal from "../../hooks/dom/useHandleModal";
import AddCourseModal from "../../components/Modals/AddCourseModal/AddCourseModal";
import FolderApi from "../../utils/api/FolderApi";
import { COURSE_STATUS_ACTIVE, COURSE_STATUS_DELETED, COURSE_STATUS_HIDEN, FOLDER_STATUS_ACTIVE, FOLDER_STATUS_DELETED, FOLDER_STATUS_HIDEN } from "../../constants/courses";
import SelectCourseModal from "../../components/Modals/SelectCourseModal/SelectCourseModal";
import AddFolderModal from "../../components/Modals/AddFolderModal/AddFolderModal";
import { useSelector } from "react-redux";
import CourseApi from "../../utils/api/CourseApi";
import { toast } from "react-toastify";
import ToastMsg from "../../components/ToastMsg/ToastMsg";
import { filterActiveCourses, mapCoursesWithNewLinks, returnTemporaryCoursesLinks } from "../../utils/coursesHelper";
import { ROLE_ID_STUDENT, ROLE_ID_SUPER_ADMIN } from "../../constants/user";
import { useNavigate } from "react-router-dom";
import pausedIcon from "../../assets/images/symbols/pause.svg";

import styles from "./styles.module.scss";
import cn from 'classnames';
import EmptyBlock from "../../components/EmptyBlock/EmptyBlock";
import SelectAgencyModal from "../../components/Modals/SelectAgencyModal/SelectAgencyModal";
import useDuplicateAgencies from '../../hooks/dom/useDuplicateAgencies';
import useChangeCourse from "../../hooks/useChangeCourse";
import useChangeFolder from "../../hooks/useChangeFolder";
import ConfirmModal from "../../components/Modals/ConfirmModal/ConfirmModal";
import useUserRole from "../../hooks/useUserRole";
import useGetYourCoursesInCompany from "../../hooks/api/useGetYourCoursesInCompany";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import useGetAgencies from "../../hooks/api/useGetAgencies";
import useGetTopics from "../../hooks/api/userGetTopics";
import useGetEntetiesForUpdateFolder from "../../hooks/api/useGetEntetiesForUpdateFolder";
import TopicBlock from "../../components/TopicBlock/TopicBlock";
import useFindTranslations from "../../hooks/useFindTranlsations";

const FolderPage = () => {
   const params = useParams();
   const handleAddCourseModal = useHandleModal();
   const handleAddTopicCourseModal = useHandleModal();
   const handleCreateTopicModal = useHandleModal();
   const confirmDeleteFromFolderModal = useHandleModal();

   const [folder, setFolder] = useState({});
   const [clickdedCourse, setClickdedCourse] = useState({});
   const [selectedCourses, setSelectedCourses] = useState([]);
   const [folderLoading, setFolderLoading] = useState(false);
   const [isAddLoading, setIsAddLoading] = useState(false);

   const currentCompanyId = useSelector(state => state.sidebar.companyId);
   const { userRoleId } = useUserRole(currentCompanyId)
   const userInfo = useSelector(state => state?.user?.info);
   const isUserAsStudent = useSelector(state => state.sidebar.isUserAsStudent);
   const userId = useSelector(state => state?.user?.info?.id);

   const t = useFindTranslations();

    // Entities for updating access for users
    const updateFolderManager = useGetEntetiesForUpdateFolder(params?.id, currentCompanyId);

   const navigate = useNavigate();

   const [selectedTopicCourses, setSelectedTopicCourses] = useState([]);

   const onRemoveSelectedTopicCourses = (id) => setSelectedTopicCourses(selectedTopicCourses?.filter(item => item?.id !== id));

   const [allTags, setAllTags] = useState([]);

  const getAllTags = async () => {
     const res = await new CourseApi().getAllTags();
     if(res?.success?.data) {
        setAllTags(res?.success?.data);
     } 
  }

  useEffect(()=> {
     getAllTags();
  }, [])

   const { 
      onDuplicateAgencies,
      onOpenDuplicateModal,
      duplicateModal,
      setSelectedAgencies, 
      selectedAgencies,
      isAgenciesDuplicateLoading,
      setIsAgenciesDuplicateLoading
   } = useDuplicateAgencies();

   const agencyManager = useGetAgencies(userId);

   const lastAgencyRef = useIntersectionObserver(
      () => {
        if (!agencyManager.isAgenciesLoading && agencyManager.agenciesList?.length < agencyManager.totalAgencies) {
          if(agencyManager.agenciesList?.length === 0) {
            agencyManager.setCurrentPage(1);
          } else {
            agencyManager.setCurrentPage((prev) => prev + 1);
          }
        }
      },
      [agencyManager.isAgenciesLoading, agencyManager.totalAgencies]
    );

   const getFolder = async (loding = false) => {
      if(!loding) setFolderLoading(true);
      const res = await new FolderApi().getOneFolder(params?.id);

      if (res?.success?.data) {
         setFolder(res?.success?.data);
      } 

      if(!loding) setFolderLoading(false)
   };

   const isUserAsStudentAndNotSuperAdmin =  userRoleId === ROLE_ID_SUPER_ADMIN ? false : isUserAsStudent;

   const isExcludeCoursesWhereAdminAsStudent = userRoleId !== ROLE_ID_SUPER_ADMIN && userRoleId !== ROLE_ID_STUDENT;

   // all your courses apart from that one in folder
   const { courseList, isCoursesLoading, refetchCourses, totalCourses, setCurrentPage, currentPage, clearAllCourseFilters, searchedValue, setSearchedValue, selectAll,
      setSelectAll } = useGetYourCoursesInCompany(currentCompanyId, isUserAsStudentAndNotSuperAdmin, false, params.id, isExcludeCoursesWhereAdminAsStudent);

   const lastCourseRef = useIntersectionObserver(
      () => {
        if (!isCoursesLoading && courseList?.length < totalCourses) {
          if(courseList?.length === 0) {
            setCurrentPage(1);
          } else {
            setCurrentPage((prev) => prev + 1);
          }
        }
      },
      [isCoursesLoading, totalCourses]
    );

   // All courses in folder!
   const coursesManager = useGetYourCoursesInCompany(currentCompanyId, isUserAsStudentAndNotSuperAdmin, params.id, null, isExcludeCoursesWhereAdminAsStudent);

   const lastCourseInFolderRef = useIntersectionObserver(
      () => {
        if (!coursesManager.isCoursesLoading && coursesManager.courseList?.length < coursesManager.totalCourses) {
          if(coursesManager.courseList?.length === 0) {
            coursesManager.setCurrentPage(1);
          } else {
            coursesManager.setCurrentPage((prev) => prev + 1);
          }
        }
      },
      [coursesManager.isCoursesLoading, coursesManager.totalCourses]
    );

    const refetchFullCourses = () => {
      refetchCourses();
      coursesManager.refetchCourses();
    }

    //  Folder
    const onRedirectToEditFolderPage = (e, folder) => {
      e.stopPropagation();
      navigate(`/courses/folder/${params?.id}/edit`);
    }

     // Topic
     const onRedirectToEditTopicPage = (e, topic) => {
         e.stopPropagation();
         navigate(`/courses/folder/${params?.id}/topic/${topic?.id}/edit`);
     }

      const { foldersList, isFoldersLoading, refetchFolders, totalFolders, setCurrentPage: setCurrentFolderPage, currentPage: currentFolderPage, activeFolderNameFilter, setActiveFolderNameFilter } = useGetTopics(currentCompanyId, coursesManager.searchedValue, coursesManager.activeCourseSortFilter, coursesManager.activeCourseFilter, params?.id);

      const lastFolderRef = useIntersectionObserver(
         () => {
         if (!isFoldersLoading && foldersList?.length < totalFolders) {
            if(foldersList?.length === 0) {
               setCurrentFolderPage(1);
            } else {
               setCurrentFolderPage((prev) => prev + 1);
            }
         }
         },
         [isFoldersLoading, totalFolders]
      );

    const { 
         isChangeLoading,
         setIsChangeLoading,
         confirmModalToogleShowStatus,
         confirmHideModal,
         confirmDeleteModal,
         retakeModal,
         onCourseChangeStatus,
         onClose,
         onConfirmOpenStatus,
         changeCourseStatusToActive,
         onConfirmOpenRestore,
         confirmModalRestore,
         onConfirmOpenHide,
         onRetakeCourse,
         onRetakeModalOpen,
         onConfirmOpenDelete,
         deleteCourse,
         onConfirmPausedOpen,
         confirmPausedModal,
         onRedirectClickedCourse
   } = useChangeCourse(refetchFullCourses)

   const createFolderSuccessCallback = () => {
      refetchFolders();
      coursesManager.setSearchedValue('')
      handleCreateTopicModal.close();
  }

   const { 
      onFolderHideToggle,
      onConfirmDeleteOpen,
      onFolderDeleteToggle,
      onConfirmOpenChangeHidenStatus,
      deleteFolder,
      confirmModalReturnBack,
      onConfirmOpenRestore: onConfirmOpenRestoreF,
      confirmDeleteModal: confirmDeleteModalF,
      confirmModal: confirmModalF,
      confirmHideModal: confirmHideModalF,
      confirmModalRestore: confirmModalRestoreF,
      isChangeLoading: isChangeLoadingF,
      onConfirmHideOpen: onConfirmHideOpenF,
      onConfirmOpenDelete: onConfirmOpenDeleteF,
    } = useChangeFolder(refetchFolders);

   const onOpenDeleteFromFolder = (course) => {
      onClose();
      setClickdedCourse(course);
      confirmDeleteFromFolderModal.open();
   };

   const onAddCourse = async () => {
      setIsAddLoading(true);
      const res = await new FolderApi().addCoursesToFolder(folder.id, {courses: selectedCourses?.map(item => item?.id)});

      if(res?.success?.message) {
         toast(<ToastMsg text={res?.success?.message}/>);
         getFolder(true);
         refetchFullCourses();
         handleAddCourseModal.close();
         updateFolderManager.onUpdateFolderMaterialsForUsers(selectedCourses, true);
         updateFolderManager.refetchAll();
         setSelectedCourses([]);
      }

      if(res?.error?.message) {
        toast(<ToastMsg text={res?.error?.message} isError />);
      }

      setIsAddLoading(false);
   }

   useEffect(() => {
      getFolder();

      return ()=> {
         setFolder({})
      }
   }, []);

    const onCourseDeleteFromFolder = async () => {
      setIsChangeLoading(true);
      const res = await new FolderApi().removeCourseFromFolder(folder?.id, clickdedCourse?.id);
      
      confirmDeleteFromFolderModal.close();

      setIsChangeLoading(false);
      
      if (res?.error?.message) {
         return toast(<ToastMsg text={res?.error?.message} isError />);
      }

      if (res?.success?.message) {
         toast(<ToastMsg text={res?.success?.message} />);
         refetchFullCourses();

         updateFolderManager.onUpdateFolderMaterialsForUsers(clickdedCourse?.id, false);
         updateFolderManager.refetchAll();
      }
   };

   const courseListWithoutThatOneInFolder = courseList?.filter(course =>
      course?.folders?.every(folder => folder?.id !== +params?.id)
  );

   return (
      <MainLayout>
         <div className={styles.coursesPage}>
            <FiltersBlock 
                  title={folder?.name}
                  onRedirectSettingsPage={onRedirectToEditFolderPage}
                  isFolder
                  setActiveFilter={coursesManager?.setActiveCourseFilter} 
                  activeFilter={coursesManager?.activeCourseFilter}
                  activeTagsFilter={coursesManager?.activeCourseTagsFilter}
                  setActiveTagsFilter={coursesManager?.setActiveCourseTagsFilter}
                  activeSortFilter={coursesManager?.activeCourseSortFilter}
                  setActiveSortFilter={coursesManager?.setActiveCourseSortFilter}
                  onClickPlus={handleAddCourseModal.open}
                  onClickCreateTopic={handleCreateTopicModal.open}
                  searchWhat={"Search"}
                  isPlusDropdown
                  currentTags={allTags}
                  paginatedSearchedValue={coursesManager?.searchedValue}
                  setPaginatedSearchedValue={coursesManager?.setSearchedValue}
               />

            <div className={cn(styles.topics_list, styles.list, foldersList?.length > 5 ? styles.overflow_auto : '')}>
            {isFoldersLoading && foldersList?.length === 0 ? (
               <div className={styles.w_full}>
                  {Array(4).fill(null).map((item, idx) => (
                     <div key={idx} className={styles.sceletonCourse_wrapper}>
                        <div key={idx} className={cn(styles.sceletonCourse)}>
                        </div>
                        <div className={styles.loadingTitleCourse}></div>  
                        <div className={styles.loadingTitleCourse2}></div>  
                     </div>
                  ))}
               </div>
               ) : (
                  foldersList?.map((item, key) => (
                     <TopicBlock
                        onRedirectToEditTopicPage={onRedirectToEditTopicPage}
                        lastTopicRef={ key === foldersList?.length - 1 ? lastFolderRef : null }
                        key={key}
                        folder={folder}
                        topic={item}
                        activeFilter={coursesManager?.activeCourseFilter}
                        onConfirmDeleteOpen={onConfirmDeleteOpen}
                        onConfirmHideOpen={onConfirmHideOpenF}
                        onConfirmOpenRestore={onConfirmOpenRestoreF}
                        onConfirmOpenDelete={onConfirmOpenDeleteF}
                        onConfirmOpenChangeHidenStatus={onConfirmOpenChangeHidenStatus}
                     />
                  ))
               )}
            </div>

            <div className={styles.list}>
            {coursesManager?.isCoursesLoading && coursesManager?.courseList?.length === 0 ? (
               <div className={styles.w_full}>
                  {Array(4).fill(null).map((item, idx) => (
                     <div key={idx} className={styles.sceletonCourse_wrapper}>
                        <div key={idx} className={cn(styles.sceletonCourse)}>
                        </div>
                        <div className={styles.loadingTitleCourse}></div>  
                        <div className={styles.loadingTitleCourse2}></div>  
                     </div>
                  ))}
               </div>
               ) : (
                  coursesManager?.courseList?.map((item, key) => (
                     <CourseBlock
                        refetchCourses={()=> {
                           getFolder(true);
                           refetchCourses();
                        }}
                        lastCourseRef={lastCourseInFolderRef}
                        key={key}
                        course={item}
                        activeCourseFilter={coursesManager?.activeCourseFilter}
                        folder={folder}
                        onOpenDuplicateModal={onOpenDuplicateModal}
                        onConfirmOpenStatus={onConfirmOpenStatus}
                        onConfirmOpenRestore={onConfirmOpenRestore}
                        onConfirmOpenHide={onConfirmOpenHide}
                        onRetakeModalOpen={onRetakeModalOpen}
                        onConfirmOpenDelete={onConfirmOpenDelete}
                        onOpenDeleteFromFolder={onOpenDeleteFromFolder}
                        onConfirmPausedOpen={onConfirmPausedOpen}
                     />
                  ))
               )}

               {(coursesManager?.courseList?.length === 0 && foldersList?.length === 0) && !coursesManager?.isCoursesLoading && !folderLoading &&
                    <EmptyBlock left />
               }
            </div>

            {/* Topics */}
            <ConfirmModal
               isRemove
               confirmButtonText={t?.deleteFromBin}
               onConfirm={deleteFolder}
               onClose={confirmDeleteModalF.close}
               isOpen={confirmDeleteModalF.isActive}
               isLoading={isChangeLoadingF}
               title={t?.areYouSure}
               subtitle={t?.youWillNotBeAbleToChangeYourChoice}
            /> 

            <ConfirmModal
               isRemove
               confirmButtonText={t?.deleteText}
               onConfirm={() => onFolderDeleteToggle(FOLDER_STATUS_DELETED)}
               onClose={confirmModalF.close}
               isOpen={confirmModalF.isActive}
               isLoading={isChangeLoadingF}
               title={t?.areYouSure}
            />

            <ConfirmModal
               confirmButtonText={t?.hideText}
               onConfirm={() => onFolderHideToggle(FOLDER_STATUS_HIDEN)}
               onClose={confirmHideModalF.close}
               isOpen={confirmHideModalF.isActive}
               isLoading={isChangeLoadingF}
               title={t?.areYouSure}
            />

            <ConfirmModal
               confirmButtonText={t?.restore}
               onConfirm={() => onFolderDeleteToggle(FOLDER_STATUS_ACTIVE)}
               onClose={confirmModalRestoreF.close}
               isOpen={confirmModalRestoreF.isActive}
               isLoading={isChangeLoadingF}
               title={t?.areYouSure}
            />

            <ConfirmModal
               confirmButtonText={t?.confirm}
               onConfirm={() => onFolderHideToggle(FOLDER_STATUS_ACTIVE)}
               onClose={confirmModalReturnBack.close}
               isOpen={confirmModalReturnBack.isActive}
               isLoading={isChangeLoadingF}
               title={t?.areYouSure}
            />

            {/* Create a topic */}
            <AddFolderModal
                isTopic
                selectedCourses={selectedTopicCourses}
                setSelectedCourses={setSelectedTopicCourses}
                onOpenSelectCourseModal={()=>{
                  handleAddTopicCourseModal.open();
                  handleCreateTopicModal.close();
                  setSearchedValue('');
                }}
                isOpen={handleCreateTopicModal.isActive}
                onClose={()=>  {
                  handleCreateTopicModal.close();
                  setSearchedValue('');
               }}
                createSuccessCallback={createFolderSuccessCallback}
                coursesList={courseList}
                onRemove={onRemoveSelectedTopicCourses}
                extraUsers={updateFolderManager?.users}
            />

            <SelectCourseModal
                onClose={()=> {
                  handleAddTopicCourseModal.close();
                  handleCreateTopicModal.open();
                }}
                isOpen={handleAddTopicCourseModal.isActive}
                selectedCourses={selectedTopicCourses}
                setSelectedCourses={setSelectedTopicCourses}
                coursesList={courseList}
                lastCourseRef={lastCourseRef}
                selectAll={selectAll}
                setSelectAll={setSelectAll}
                coursesLoading={isCoursesLoading}
                searchedValue={searchedValue}
                setSearchedValue={setSearchedValue}
            />

            {/* Courses */}

             <SelectCourseModal
                onClose={()=> {
                  handleAddCourseModal.close();
                  setSelectedCourses([]);
                  setSelectAll(false);
               }}
                isOpen={handleAddCourseModal.isActive}
                setSelectedCourses={setSelectedCourses}
                selectedCourses={selectedCourses}
                onAddCourse={onAddCourse}
                isDataLoading={isAddLoading || isCoursesLoading}
                coursesList={courseListWithoutThatOneInFolder}
                lastCourseRef={lastCourseRef}
                searchedValue={searchedValue}
                setSearchedValue={setSearchedValue}
                selectAll={selectAll}
                setSelectAll={setSelectAll}
                coursesLoading={isCoursesLoading}
            />

            <SelectAgencyModal
                onClose={()=> {
                  setSelectedAgencies([]);
                  duplicateModal.close();
               }}
                isOpen={duplicateModal.isActive}
                selectedAgencies={selectedAgencies}
                setSelectedAgencies={setSelectedAgencies}
                agenciesList={agencyManager.agenciesList}
                onAddAgencies={onDuplicateAgencies}
                refetch={refetchCourses}
                agencyManager={agencyManager}
                isDataLoading={agencyManager.isAgenciesLoading || isAgenciesDuplicateLoading}
            />

         <ConfirmModal
               confirmButtonText={t?.confirm}
               onConfirm={()=> changeCourseStatusToActive(confirmModalToogleShowStatus)}
               onClose={confirmModalToogleShowStatus.close}
               isOpen={confirmModalToogleShowStatus.isActive}
               isLoading={isChangeLoading}
               title={t?.areYouSure}
            />

            <ConfirmModal
               confirmButtonText={t?.restore}
               onConfirm={()=> changeCourseStatusToActive(confirmModalRestore)}
               onClose={confirmModalRestore.close}
               isOpen={confirmModalRestore.isActive}
               isLoading={isChangeLoading}
               title={t?.areYouSure}
            />

            <ConfirmModal
               confirmButtonText={t?.confirm}
               onConfirm={()=> onCourseChangeStatus(COURSE_STATUS_HIDEN)}
               onClose={confirmHideModal.close}
               isOpen={confirmHideModal.isActive}
               isLoading={isChangeLoading}
               title={t?.areYouSure}
            />
             <ConfirmModal
               confirmButtonText={t?.retake}
               onConfirm={onRetakeCourse}
               onClose={retakeModal.close}
               isOpen={retakeModal.isActive}
               title={t?.areYouSure}
            />
            <ConfirmModal
               icon={pausedIcon}
               confirmButtonText={t?.openCourse}
               onConfirm={onRedirectClickedCourse}
               onClose={confirmPausedModal.close}
               isOpen={confirmPausedModal.isActive}
               title={t?.courseWasPaused}
               subtitle={t?.youCannotContinueLearning}
            />
             <ConfirmModal
               isRemove
               confirmButtonText={t?.deleteFromTheBin}
               onConfirm={deleteCourse}
               onClose={confirmDeleteModal.close}
               isOpen={confirmDeleteModal.isActive}
               isLoading={isChangeLoading}
               title={t?.areYouSure}
               subtitle={t?.youWillNotBeAbleToChangeYourChoice}
            />
            <ConfirmModal
               isRemove
               confirmButtonText={t?.delete}
               onConfirm={onCourseDeleteFromFolder}
               onClose={confirmDeleteFromFolderModal.close}
               isOpen={confirmDeleteFromFolderModal.isActive}
               isLoading={isChangeLoading}
               title={t?.areYouSure}
            />
         </div>
      </MainLayout>
   );
};

export default FolderPage;
