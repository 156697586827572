import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import useOutsideClick from "../../hooks/dom/useOutsideClick";
import Input from "../Input/Input";
import arrow from "../../assets/images/symbols/arrow.svg";
import plus from "../../assets/images/symbols/plus.svg";
import arrowWhite from "../../assets/images/symbols/arrow_white.svg";
import avatarPlaceholder from "../../assets/images/avatar.svg";
import serchIcon from "../../assets/images/symbols/Search.svg";
import cn from "classnames";
import { useSelector } from "react-redux";
import { transformRoleText } from "../../utils/rolesHelper";

const PaginatedDropdown = ({
   data,
   placeholder,
   onChange,
   value,
   field = null,
   title = null,
   isWhiteBlack = false,
   createBtnText,
   onOpenCreateModal,
   searchNeeded,
   maxHeight,
   isError,
   isUsers,
   lastItemRef,
   searchValue,
   setSearchValue,
}) => {
   const [isOpen, setIsOpen] = useState(false);
   const currentCompanyId = useSelector((state) => state.sidebar.companyId);
   const currentCompanyName = useSelector((state) => state.sidebar.companyName);
  
   const dropdownRef = useRef();
   const inputRef = useRef();
   const inputRefTop = useRef();

   const onClose = () => setIsOpen(false);
   const onToggle = () => setIsOpen(!isOpen);
   const onChangeValue = (val) => onChange(val);

   useOutsideClick(dropdownRef, onClose, inputRef);

   useEffect(() => {
      if (!isOpen && setSearchValue) setSearchValue("");
      if (isOpen) inputRefTop.current.focus();
   }, [isOpen]);

   return (
      <>
         {title && <p className={styles.title}>{title}</p>}
         <div
            className={cn(
               styles.dropdown_wrapper,
               value?.length && searchNeeded ? styles.value : ""
            )}
            onClick={onToggle}
            ref={inputRef}
         >
            {(searchNeeded || createBtnText) && isOpen && (
               <img className={styles.search} src={serchIcon} alt="" />
            )}
            {createBtnText || searchNeeded ? (
               <Input
                  isDefaultColor
                  placeholder={value || placeholder}
                  ref={inputRefTop}
                  customClass={cn(
                     isOpen ? styles.input_open : null,
                     isOpen && (searchNeeded || createBtnText)
                        ? styles.padding_left
                        : ""
                  )}
                  disabled={!isOpen}
                  value={searchValue}
                  onChangeValue={(e) => setSearchValue(e.target.value)}
                  isWhiteBlack={isWhiteBlack}
                  isError={isError ? styles.error : ""}
               />
            ) : (
               <Input
                  placeholder={placeholder}
                  ref={inputRefTop}
                  customClass={isOpen ? styles.input_open : null}
                  disabled={true}
                  value={value}
                  onChangeValue={(e) => setSearchValue(e.target.value)}
                  isWhiteBlack={isWhiteBlack}
                  isError={isError ? styles.error : ""}
               />
            )}

            <div className={styles.click_el}></div>

            <img
               src={isWhiteBlack ? arrowWhite : arrow}
               alt={""}
               className={cn(styles.arrow, isOpen && styles.arrow_open)}
            />

            {isOpen && (
               <div
                  style={{ maxHeight: maxHeight ? maxHeight : "300px" }}
                  className={cn(
                     styles.dropdown,
                     isWhiteBlack && styles.dropdown_isWhiteBlack,
                     createBtnText && styles.with_create_btn
                  )}
               >
                  {data?.map((item, key) => {
                     const classNameActive = field
                        ? cn(
                             styles.dropdown_item,
                             item?.id === value?.id &&
                                styles.dropdown_item_active
                          )
                        : cn(
                             styles.dropdown_item,
                             item === value && styles.dropdown_item_active
                          );

                     if (isUsers) {
                        return (
                           <div
                              ref={
                                 key === data?.length - 1 ? lastItemRef : null
                              }
                              key={key}
                              onClick={() => onChangeValue(item)}
                              className={classNameActive}
                           >
                              <div className={styles.user_info_wrapper}>
                                 <img
                                    src={`${
                                       item.avatar
                                          ? item.avatar
                                          : avatarPlaceholder
                                    }`}
                                    alt=""
                                 />
                                 <div className={styles.right}>
                                    <p>{field ? item[field] : item}</p>
                                    <span>
                                    {transformRoleText(
                                       (item?.userBranchRoles
                                          ?.filter(
                                             (role) =>
                                                role?.branch?.company?.id ===
                                                +currentCompanyId
                                          )?.[0]
                                          ?.role?.name) || "-", currentCompanyName
                                    )}
                                    </span>
                                 </div>
                              </div>
                           </div>
                        );
                     }

                     return (
                        <div
                           ref={key === data?.length - 1 ? lastItemRef : null}
                           key={key}
                           onClick={() => onChangeValue(item)}
                           className={classNameActive}
                        >
                           {field ? item[field] : item}
                        </div>
                     );
                  })}
                  {!data?.length && (
                     <div className={styles.dropdown_item}>No options</div>
                  )}
                  {createBtnText && isOpen && (
                     <div
                        onClick={onOpenCreateModal}
                        className={styles.create_btn}
                     >
                        <img className={styles.plus} src={plus} alt="" />
                        {createBtnText}
                     </div>
                  )}
               </div>
            )}
         </div>
      </>
   );
};

export default PaginatedDropdown;
