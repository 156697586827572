import React, { useEffect, useState, useRef } from "react";
import searchImg from "../../../assets/images/symbols/Search.svg";
import csvImg from "../../../assets/images/symbols/scv.svg";
import plus from "../../../assets/images/symbols/plus.svg";

import Button from "../../../components/Button/Button";
import FilterRadio from "../../../components/FilterRadio/FilterRadio";

import styles from "./styles.module.scss";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import CreateStudentsModal from "../../../components/Modals/CreateStudentsModal/CreateStudentsModal";
import { CSVLink } from "react-csv";
import CreateSpecialUserModal from "../../../components/Modals/CreateSpecialUserModal/CreateSpecialUserModal";

const UsersSubheader = ({
   isAdmins,
   isBlocked,
   searchedValue,
   setSearchedValue,
   refetchData,
   csvData,
   addUserToList,
   fetchUsers,
   isDataLoading,
   t
}) => {

   const csvLinkRef = useRef(null);

   const createStudentModal = useHandleModal();
   const createSpecialUserModal = useHandleModal();

   return (
      <div className={styles.subheader}>
         <div className={styles.line}></div>
         <div className={styles.search_wrapper}>
            <div className={styles.search}>
               <img src={searchImg} alt={""} />
               <input
                  placeholder={t?.search}
                  value={searchedValue}
                  onChange={(e) => setSearchedValue(e.target.value)}
               />
            </div>
               <Button
                  title={t?.exportToCSV}
                  image={csvImg}
                  isBlack
                  disabled={isDataLoading}
                  maxWidth={"180px"}
                  onClick={()=> fetchUsers(1, 1000).then(()=> csvLinkRef.current.link.click())}
               />
            <CSVLink ref={csvLinkRef} style={{ textDecoration: "none" }} data={csvData}/>

            {!isAdmins && !isBlocked && (
               <Button
                  title={t?.addStudent}
                  image={plus}
                  maxWidth={"220px"}
                  onClick={createStudentModal.open}
               />
            )}
            {isAdmins && (
               <Button
                  title={t?.addSpecialUser}
                  image={plus}
                  maxWidth={"220px"}
                  onClick={createSpecialUserModal.open}
               />
            )}
         </div>

         <CreateStudentsModal
            onClose={createStudentModal.close}
            isOpen={createStudentModal.isActive}
            refetchData={refetchData}
            addUserToList={addUserToList}
         />

         <CreateSpecialUserModal
            onClose={createSpecialUserModal.close}
            isOpen={createSpecialUserModal.isActive}
            refetchData={refetchData}
            addUserToList={addUserToList}
         />
      </div>
   );
};

export default UsersSubheader;
