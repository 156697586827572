import React from 'react';
import styles from './styles.module.scss'
import cn from "classnames";

const Checkbox = ({  
                      type,
                      title,
                      setIsChecked,
                      isChecked,
                      onClickCallback = null,
                      isRound,
                      isGrey,
                      withWhiteBorder,
                      extraMargin
                  }) => {

    const onToggleChecked = () => {
        if (onClickCallback) {
            onClickCallback()
            if (isChecked) return
        }
        if (setIsChecked) setIsChecked(!isChecked)
    }

    return (
        <div className={cn(styles.checkbox,
            isRound && styles.checkbox_isRound,
            isRound && extraMargin && styles.extraMargin,
            isGrey && styles.checkbox_isGrey,
            withWhiteBorder && styles.checkbox_withWhiteBorder
        )}
             onClick={onToggleChecked}
        >
            <input type="checkbox" checked={isChecked} onChange={onToggleChecked}/>
            <span>{title}</span>
        </div>
    );
};

export default Checkbox;