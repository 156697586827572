import { useState, useEffect } from "react";
import { debounce } from "lodash";
import { DEFAULT_DEBOUNCE_DELAY } from "../../constants/statistics";
import { BIGGER_DATA_LIMIT, DEFAULT_DATA_LIMIT, SMALLER_DATA_LIMIT } from "../../constants/user";
import CourseApi from "../../utils/api/CourseApi";
import {
   mapCoursesWithNewLinks,
   returnTemporaryCoursesLinks,
} from "../../utils/coursesHelper";
import courseImg from "../../assets/images/course_thumbnail.png";
import { useSelector } from "react-redux";
import UserApi from "../../utils/api/UserApi";

const initialMapCourses = (item) => ({
   id: item?.id,
   image: item?.image || courseImg,
   logo: item?.image || courseImg,
   title: item?.name || "",
   name: item?.name || "",
   description: item?.description || "",
   tagList: item?.tags || [],
   isInFolder: !!item?.folder?.id,
   folder: item?.folder || {},
   status: item?.status,
   createdAt: item?.createdAt,
   userCourseResults: item?.userCourseResults || [],
   percentageToReceiveCertificate: item?.percentageToReceiveCertificate || 0,
   users: 0,
});

const useGetYourCoursesInCompany = (
   companyId,
   isAdminAsStudent = false,
   folderIncludedCoursesId,
   folderExcludedCoursesId,
   isExcludeCoursesWhereAdminAsStudent,
   serializationGroup
) => {
   const [isCoursesLoading, setIsCoursesLoading] = useState(true);
   const [selectAll, setSelectAll] = useState(false);
   const [searchedValue, setSearchedValue] = useState("");
   const [coursesList, setCoursesList] = useState([]);
   const [totalCourses, setTotalCourses] = useState(0);
   const [currentPage, setCurrentPage] = useState(1);
   const userId = useSelector(state => state?.user?.info?.id);
   const [coursesLinks, setCoursesLinks] = useState([]);
   const [coursesUsersInfo, setCoursesUsersInfo] = useState([]);
   const [coursesProgress, setCoursesProgress] = useState([]);

   const abortController = new AbortController();
   const signal = abortController.signal;

   // is completed / is failed
   const [studentCourseFilter, setStudentCourseFilter] = useState("");
   // status of the course
   const [activeCourseFilter, setActiveCourseFilter] = useState("");
   const [selectedLanguages, setSelectedLanguages] = useState([])

   const [activeCourseSortFilter, setActiveCourseSortFilter] = useState("");
   const [activeCourseTagsFilter, setActiveCourseTagsFilter] = useState([]);

   const clearAllCourseFilters = () => {
      if (activeCourseTagsFilter?.length) setActiveCourseTagsFilter([]);
      if (activeCourseSortFilter?.length) setActiveCourseSortFilter("");
      if (searchedValue?.length) searchedValue("");
      if (activeCourseFilter?.length) setActiveCourseFilter("");
   };

   const clearExtendedFilters = (e) => {
      e.stopPropagation();
      
      setSelectedLanguages([]);
      setActiveCourseSortFilter('');
   }

   const onSelectedLanguagesChange = (item) => {
      if (selectedLanguages.some((lang) => lang.id === item.id)) {
         setSelectedLanguages(
            selectedLanguages.filter((lang) => lang.id !== item.id)
         );
      } else {
         setSelectedLanguages([...selectedLanguages, item]);
      }
   };

   const getUserProgressOnCourse = async () => {
      if(!courseList?.length) return;
      const lastTenCourses = courseList.slice(-10);

      const data = {
         progress_data: lastTenCourses?.map(course => ({
             user_id: userId,
             course_id: course?.id
         }))
     };
     
      const res = await new UserApi().getUserProgressOnCourses(data);
      const courses = res?.success?.data?.filter(res => res?.success?.data)?.map(item => item?.success?.data);


      if(courses?.length) {
         setCoursesProgress([...coursesProgress, ...courses]);
      }
    }

   const fetchCourses = async (page, limit) => {
      setIsCoursesLoading(true);

      try {
         const res = await new CourseApi().getYourCourses({
            limit: limit ? limit : (selectAll ? BIGGER_DATA_LIMIT : DEFAULT_DATA_LIMIT),
            page: page ? page : currentPage,
            order: activeCourseSortFilter === "Old first" ? "asc" : "desc",
            tags: activeCourseTagsFilter,
            translations: selectedLanguages,
            completedStatus: studentCourseFilter,
            status: activeCourseFilter,
            companyId,
            searchedValue,
            isAdminAsStudent,
            serializationGroup,
            folderIncludedCoursesId,
            isExcludeCoursesWhereAdminAsStudent,
            signal
         });

         if (res?.success?.data) {
            const courses = res?.success?.data || [];

            if (page) {
               setCoursesList(courses);
            } else {
               setCoursesList((prev) => [...prev, ...courses]);
            }

            setTotalCourses(res?.success?.totalCount);
         }

         if (res?.success?.data?.length === 0) {
            setCurrentPage(1);
         }

         if (res?.error?.message && page) {
            setCoursesList([]);
         }
      } catch (error) {
         console.error("Error fetching courses:", error);
      } finally {
         setIsCoursesLoading(false);
      }
   };

   const getCoursesLinks = async () => {
      const lastTenCourses = coursesList?.slice(-10); 
  
      if (lastTenCourses.length === 0) {
          return; 
      }
  
      const filteredCourses = lastTenCourses.filter(course => 
          course?.image && !course?.image?.includes('/static/media/')
      );
  
      const links = await returnTemporaryCoursesLinks(userId || 1, filteredCourses);
  
      setCoursesLinks([...coursesLinks, ...links]);
  };
  
   const getCoursesUsersInfo = async () => {
      const lastTenCourses = coursesList?.slice(-10); 
  
      if (lastTenCourses.length === 0) {
          return; 
      }

      const dataToSend = {
         course_ids: lastTenCourses?.map(course => course?.id)
       }

      const dataToSendForStatuses = {
         user_ids: [+userId],
         course_ids: lastTenCourses?.map(course => course?.id)
       }

      const resStatuses = await new UserApi().getYourCoursesStatuses(dataToSendForStatuses);
      const res = await new CourseApi().getCoursesUsersInfo(dataToSend);

      if(res?.success?.data) {
         const infoData = res?.success?.data || [];
         const statusesData = resStatuses?.success?.data || [];

         const combinedData = infoData?.map(item => {
            const matchingData = statusesData.find(data => data?.course?.id === item?.courseId);
        
            if (matchingData) {
                return {
                    ...item,
                    userStatus: matchingData?.status
                };
            } else {
                return {
                    ...item,
                    userStatus: null
                };
            }
        })

         setCoursesUsersInfo([...coursesUsersInfo, ...combinedData]);
      }
  };

   useEffect(()=> {
      if(coursesList?.length && userId) {
         getCoursesLinks();
         getUserProgressOnCourse();
         getCoursesUsersInfo();
      }
   }, [coursesList, userId])

   const updatedCourseList = coursesList?.map(course => {
      const matchingData = coursesProgress.find(data => data?.userCourseProgress?.course?.id === course?.id);

      if (matchingData) {
          return {
              ...course,
              progress: matchingData?.userCourseProgress?.progress || 0,
              result: matchingData?.userCourseResult?.result ? matchingData?.userCourseResult?.result : null
          };
      } else {
          return {
              ...course,
              progress: null,
              result: null
          };
      }
  });

   const courseListWithImages = updatedCourseList?.map(course => {
      const matchingLink = coursesLinks?.find(link => link?.fileName === course?.image);

      if (matchingLink) {
         return {
            ...course,
            image: matchingLink.fileUrl || courseImg,
            logo: matchingLink.fileUrl || courseImg,
            title: course?.name || "",
         };
      } else {
         return {
            ...course,
            image: courseImg,
            logo: courseImg,
            title: course?.name || "",
         };
      }
    })

    const courseList = courseListWithImages?.map(course => {
      const matchingLink = coursesUsersInfo?.find(courseInfo => courseInfo?.courseId === course?.id);

      if (matchingLink) {
         return {
            ...course,
            users: matchingLink?.usersWithActiveCourseCount + matchingLink?.usersWithPausedCourseCount,
            userStatus: matchingLink?.userStatus
         };
      } else {
         return course;
      }
    })

   const debouncedFetchCourses = debounce(
      () => fetchCourses(1),
      DEFAULT_DEBOUNCE_DELAY
   );

   const refetchCourses = () => {
      debouncedFetchCourses();
      setCurrentPage(1);
   };

   useEffect(() => {
      if (currentPage > 1 && courseList?.length <= totalCourses) {
         fetchCourses();
      }
   }, [currentPage]);

   useEffect(()=> {
      return () => {
         abortController.abort();
         setCoursesList([]);
         setCoursesLinks([]);
      }
   }, [])

   useEffect(() => {
      if (companyId && userId) {
         setCurrentPage(1);
         debouncedFetchCourses();
      }

      return () => debouncedFetchCourses.cancel();
   }, [
      searchedValue,
      studentCourseFilter,
      activeCourseSortFilter,
      activeCourseTagsFilter,
      selectedLanguages,
      activeCourseFilter,
      companyId,
      userId
   ]);

   useEffect(() => {
      if (selectAll && courseList?.length !== totalCourses && courseList?.length < BIGGER_DATA_LIMIT) {
         setCurrentPage(1);
         debouncedFetchCourses();
      }

      return () => debouncedFetchCourses.cancel();
   }, [selectAll]);

   return {
      isCoursesLoading,
      courseList,
      totalCourses,
      currentPage,
      fetchCourses,
      debouncedFetchCourses,
      setCurrentPage,
      searchedValue,
      setSearchedValue,
      setTotalCourses,
      refetchCourses,
      activeCourseSortFilter,
      setActiveCourseSortFilter,
      activeCourseTagsFilter,
      setActiveCourseTagsFilter,
      clearAllCourseFilters,
      activeCourseFilter,
      setActiveCourseFilter,
      studentCourseFilter,
      setStudentCourseFilter,
      selectAll,
      setSelectAll,
      selectedLanguages,
      onSelectedLanguagesChange,
      clearExtendedFilters
   };
};

export default useGetYourCoursesInCompany;
