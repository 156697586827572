import React from "react";
import dots from "../../../assets/images/symbols/three_dots_white.svg";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import useOptions from "../../../hooks/useOptions";
import { v4 as uuidv4 } from "uuid";
import styles from "./styles.module.scss";
import arrow from "../../../assets/images/symbols/Arrow - Down.svg";
import statusActiveImg from "../../../assets/images/symbols/arrow_green.svg";
import statusPausedImg from "../../../assets/images/paused_status.svg";
import statusDeletedImg from "../../../assets/images/deleted_status.svg";

import cn from "classnames";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ROLE_ID_SUPER_ADMIN } from "../../../constants/user";
import {
   STUDENT_STATUS_ACTIVE,
   STUDENT_STATUS_DELETED,
   STUDENT_STATUS_PAUSED,
} from "../../../constants/courses";
import useUserRole from "../../../hooks/useUserRole";
import ProgressLine from "../../../components/ProgressLine/ProgressLine";

const UsersBodyItem = ({
   row,
   columns,
   index,
   tableFor,
   openConfirmModal,
   onOpenStatusModal,
   lastItemRef,
   t
}) => {
   const { dropdownRef, dotsRef, isOptionsOpen, closeOptions, toggleOptions } =
      useOptions();

   const companyId = useSelector(state => state.sidebar.companyId);
   const { userRoleId } = useUserRole(companyId);

   const navigate = useNavigate();

   const redirectToUserProfileAdminView = (id) => {
      navigate(`/users/profile/${id}`);
   };

   const defineIcon = (status) => {
      if (status === STUDENT_STATUS_ACTIVE) {
         return statusActiveImg;
      } else if (status === STUDENT_STATUS_PAUSED) {
         return statusPausedImg;
      } else if (status === STUDENT_STATUS_DELETED) {
         return statusDeletedImg;
      } else {
         return statusActiveImg;
      }
   };

   return (
      <tr
         ref={lastItemRef}
         key={`row-${index}`}
         className={cn(index % 2 === 0 ? styles.rowOdd : "")}
      >
         {columns.map((column, columnIndex) => (
            <React.Fragment key={`cell-${index}-${columnIndex}`}>
               {column === "team" && (
                  <td className={styles.table_data}>
                     <div className={styles.table_data_depart}>
                        <CustomTooltip id={row.id} text={row?.team} limit={8} />
                     </div>
                  </td>
               )}
               {column !== "status" && column !== "team" &&  column !== 'progress' && (
                  <td>
                     <div className={styles.cellContent}>
                        <CustomTooltip
                           id={row[column]}
                           limit={20}
                           text={row[column] !== "waiting" ? row[column] : ""}
                        />
                     </div>
                  </td>
               )}

               {column === "status" && (
                  <td className={styles.cellContent}>
                     <div
                        className={styles.actions}
                        onClick={() => onOpenStatusModal(row)}
                     >
                        <img
                           src={defineIcon(row?.status_on_course)}
                           alt="icon"
                        />
                        <img src={arrow} alt="" />
                     </div>
                  </td>
               )}

            {column === "progress" && (
               <td className={styles.cellContent}>
                  <ProgressLine progress={row?.progress} />
               </td>
               )
            }
            </React.Fragment>
         ))}

         {tableFor !== "requests" && tableFor !== "teams" && (
            <td className={styles.option_wrapper}>
               <img
                  ref={dotsRef}
                  onClick={() => toggleOptions()}
                  src={dots}
                  alt={"options"}
               />
              
               {isOptionsOpen &&
                  (tableFor === "students" || tableFor === "roles") && (
                     <div ref={dropdownRef} className={styles.dropdown}>
                        <p
                           className={styles.options}
                           onClick={() =>
                              redirectToUserProfileAdminView(row?.id)
                           }
                        >
                           {t?.profile}
                        </p>

                        <p
                           className={styles.options}
                           onClick={() => {
                              closeOptions();
                              if (openConfirmModal) openConfirmModal(row.id);
                           }}
                        >
                           {t?.removeAccess}
                        </p>
                     </div>
                  )}
            </td>
         )}
      </tr>
   );
};

export default UsersBodyItem;
