import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import useOutsideClick from "../../hooks/dom/useOutsideClick";
import Input from "../Input/Input";
import arrow from "../../assets/images/symbols/arrow.svg";
import plus from "../../assets/images/symbols/plus.svg";
import crossImg from "../../assets/images/symbols/cross_grey.svg";
import arrowWhite from "../../assets/images/symbols/arrow_white.svg";
import avatarPlaceholder from "../../assets/images/avatar.svg";
import serchIcon from "../../assets/images/symbols/Search.svg";
import cn from "classnames";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import Button from "../Button/Button";
import Checkbox from "../Checkbox/Checkbox";
import notFound from "../../assets/images/symbols/nothing_found.svg";
import { transformRoleText } from "../../utils/rolesHelper";
import { useSelector } from "react-redux";
import useFindTranslations from "../../hooks/useFindTranlsations";

const FewItemsDropdown = ({
   data,
   placeholder,
   onChange,
   value,
   field = null,
   title = null,
   isWhiteBlack = false,
   createBtnText,
   onOpenCreateModal,
   searchNeeded,
   maxHeight,
   isError,
   lastItemRef,
   searchValue,
   setSearchValue,
   selectedItems,
   setSelectedItems,
   onConfirm,
}) => {
   const [isOpen, setIsOpen] = useState(false);
   const companyName = useSelector((state) => state.sidebar.companyName);
   const dropdownRef = useRef();
   const inputRef = useRef();
   const inputRefTop = useRef();

   const t = useFindTranslations();

   const onClose = () => {
      setIsOpen(false);
      setSearchValue('');
   };
   const onToggle = (e) => {
      e.stopPropagation();
      setIsOpen(!isOpen);
   };
   const onChangeValue = (val) => onChange(val);

   useOutsideClick(dropdownRef, onClose, inputRef);

   useEffect(() => {
      if (isOpen) inputRefTop.current.focus();
   }, [isOpen]);

   const firstSelectedItems = selectedItems?.slice(0, 3);
   let croppedPlaceholder = "";
   
   if (firstSelectedItems?.length > 0) {
       const names = firstSelectedItems?.map(item => item.name);
       croppedPlaceholder = names.join(', ');
       if (croppedPlaceholder?.length > 20) {
           croppedPlaceholder = croppedPlaceholder?.substring(0, 20) + "...";
       }
   }

   return (
      <>
         {title && <p className={styles.title}>{title}</p>}
         <div
            className={cn(
               styles.dropdown_wrapper,
               value?.length && searchNeeded ? styles.value : ""
            )}
            onClick={onToggle}
            ref={inputRef}
         >
            {searchNeeded && isOpen && (
               <img className={styles.search} src={serchIcon} alt="" />
            )}
            {value?.avatar && !isOpen && (
               <img
                  className={cn(styles.search, styles.logo)}
                  src={value?.avatar}
                  alt=""
               />
            )}

            {!!selectedItems?.length && (
               <img
                  onClick={(e) => {
                     e.stopPropagation();
                     setSelectedItems([]);
                     if (selectedItems?.length) onConfirm();
                  }}
                  className={styles.cross_img}
                  src={crossImg}
                  alt=""
               />
            )}

            <div className={styles.inputWrapper}>
               <Input
                  placeholder={croppedPlaceholder || placeholder}
                  ref={inputRefTop}
                  customClass={cn(
                     styles.input,
                     !isOpen && !value?.name ? styles.pl_0 : ""
                  )}
                  disabled={!isOpen}
                  value={searchValue}
                  onChangeValue={(e) => {
                     setSearchValue(e?.target?.value);
                  }}
                  isWhiteBlack={isWhiteBlack}
                  isError={isError ? styles.error : ""}
               />
            </div>

            <div className={styles.click_el}></div>

            <img
               src={isWhiteBlack ? arrowWhite : arrow}
               alt={""}
               className={cn(styles.arrow, isOpen && styles.arrow_open)}
            />

            {isOpen && (
               <div
                  onClick={(e) => {
                     e.stopPropagation();
                  }}
                  style={{ maxHeight: maxHeight ? maxHeight : "400px" }}
                  className={cn(
                     styles.dropdown,
                     isWhiteBlack && styles.dropdown_isWhiteBlack,
                     createBtnText && styles.with_create_btn
                  )}
               >
                  <p className={styles.selected}>
                     {t?.selected}: <span>{selectedItems?.length}</span>
                  </p>
                  {!!data?.length ? (
                     <div className={styles.list}>
                        {data?.map((item, key) => (
                           <p
                              ref={
                                 data?.length - 1 === key ? lastItemRef : null
                              }
                              key={key}
                              onClick={(e) => {
                                 e.stopPropagation();
                                 onChangeValue(item);
                              }}
                              className={styles.list_item}
                           >
                              <Checkbox
                                 isChecked={selectedItems?.some(
                                    (sItem) => sItem?.id === item?.id
                                 )}
                                 isGrey
                              />
                              <img
                                 src={`${
                                    item.avatar
                                       ? item.avatar
                                       : avatarPlaceholder
                                 }`}
                                 alt=""
                              />
                              <div className={styles.info}>
                                 <CustomTooltip
                                    text={item?.name}
                                    id={item?.id}
                                    limit={20}
                                 />

                                 <span className={styles.role}>
                                    {transformRoleText(
                                       item?.userBranchRoles[0]?.role?.name,
                                       companyName
                                    )}
                                 </span>
                              </div>
                           </p>
                        ))}
                     </div>
                  ) : (
                     <div className={styles.notFound}>
                        <img src={notFound} alt="" />
                        {t?.thereAreNoData}
                     </div>
                  )}

                  <div className={styles.confirmBtn}>
                     <Button
                        disabled={selectedItems?.length === 0}
                        title={t?.confirm}
                        onClick={(e) => {
                           onConfirm();
                           onClose();
                        }}
                     />
                  </div>
               </div>
            )}
         </div>
      </>
   );
};

export default FewItemsDropdown;
