import React, {useEffect, useState} from 'react';
import styles from './styles.module.scss'
import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import searchIcon from '../../../assets/images/symbols/Search.svg';
import exclMark from '../../../assets/images/icon_info.svg';
import avatarPlaceholder from '../../../assets/images/avatar.svg';
import notFound from '../../../assets/images/symbols/nothing_found.svg';
import Button from "../../Button/Button";
import Checkbox from "../../Checkbox/Checkbox";
import Dropdown from '../../Dropdown/Dropdown';
import { DEPARTMENTS, POSITIONS } from '../../../constants/departmentsAndPositions';
import Loader from "../../Loader/Loader";
import useFindTranslationsByString from '../../../hooks/useFindTranslationsByString';

const SelectUserModal = ({isOpen, onClose, onConfirm, setSelectedUsers, selectedUsers, usersList, oneStep, lastUserRef = null, userSearchValue, setUserSearchValue, hint, isAddUsersToTeam, isDataLoading }) => {
    
    const [departments, setDepartments] = useState([]);
    const [positions, setPositions] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState('');
    const [selectedPosition, setSelectedPosition] = useState('');

    const t = useFindTranslationsByString();

    const onSelectDepartment = (department) => {
      setSelectedDepartment(department.name);
      setSelectedUsers(prev => {
         const newUsersToAdd = usersList.filter(user => user.department === department.name && !prev.some(prevUser => prevUser.id === user.id));
         return [...prev, ...newUsersToAdd];
      });
    };

    const onSelectPosition = (position) => {
      setSelectedPosition(position.name);
      setSelectedUsers(prev => {
         const newUsersToAdd = usersList.filter(user => user.position === position.name && !prev.some(prevUser => prevUser.id === user.id));
         return [...prev, ...newUsersToAdd];
      });
    }

    const initDepartmentsAndPositionsList = () => {
      const departments = [];
      const positions = [];

      usersList.forEach(user => {
         if (user.department && !departments.some(department => department.name === user.department)) {
           departments.push({ id: user.id, name: user.department });
         }
         if (user.position && !positions.some(position => position.name === user.position)) {
            positions.push({ id: user.id, name: user.position });
         }
       });
    
      setDepartments(departments);
      setPositions(positions);
    }

    useEffect(()=> {
      initDepartmentsAndPositionsList();
    }, [usersList])

    const onSelectFilters = (course) => {
        if (selectedUsers?.find(item => item?.id === course?.id)) setSelectedUsers(selectedUsers?.filter(item => item?.id !== course?.id))
        else setSelectedUsers([...selectedUsers, course])
    }

    const closeModal = () => {
      onClose();
      setSelectedDepartment('');
      setSelectedPosition('');
      if(oneStep) setSelectedUsers([]);
    }

    return (
        <ModalLayout
            isOpen={isOpen}
            onClose={closeModal}
            maxHeight={'600px'}
            maxWidth={'500px'}
            withCross
        >
            <div className={styles.title}>{t("Select students")} 
               {hint && 
                  <div className={styles.exp_mark}>
                     <span className={styles.hint}>{t(hint)} </span>
                     <img src={exclMark} alt="" />
                  </div>
               }
            </div>
            <div className='default_loader_wrapper'>
               {isDataLoading && 
                  <Loader size={"small"} />
               }
            </div>
            <p className={styles.selected}>{t("Selected")} : <span>{selectedUsers?.length}</span></p>
            <div className={styles.search}>
                <img src={searchIcon} alt={''}/>
                <input
                    placeholder={t('Search')}
                    value={userSearchValue}
                    onChange={(event) => setUserSearchValue(event?.target?.value)}
                />
            </div>

         <div className={styles.dropdowns}>
            <div className={styles.dropdown}>

               <Dropdown
                  data={departments}
                  field={"name"}
                  value={selectedDepartment}
                  placeholder={t("Select by department")}
                  onChange={onSelectDepartment}
                  maxHeight={"220px"}
                  searchNeeded
               />
            </div>
            <div className={styles.dropdown}>
               <Dropdown
                  data={positions}
                  maxHeight={"180px"}
                  field={"name"}
                  value={selectedPosition}
                  placeholder={t("Select by Position")}
                  onChange={onSelectPosition}
                  searchNeeded
               />
               </div>
         </div>

            {
                !!usersList?.length
                    ? <div className={styles.list}>
                        {usersList?.map((item, key) =>
                            <div ref={ usersList?.length - 1 === key ? lastUserRef : null} key={key} onClick={() => onSelectFilters(item)} className={styles.list_item}>
                                <Checkbox isChecked={selectedUsers?.some(sItem => sItem?.id === item?.id)} isGrey/>
                                <img
                                 src={`${item.avatar ? item.avatar : avatarPlaceholder}`}
                                 alt=""
                                 />
                                {item?.firstName || 'no name'} {item?.lastName}
                            </div>)}
                    </div>
                    : <div className={styles.notFound}>
                        <img src={notFound} alt={''}/>
                        {t("Not found")} 
                    </div>
            }

            <Button disabled={selectedUsers.length === 0 || isDataLoading} title={t('Confirm')} onClick={onConfirm ? ()=> {
               if(!isAddUsersToTeam) 
                  closeModal();
               onConfirm();
            }  : onClose}/>

        </ModalLayout>
    );
};

export default SelectUserModal;