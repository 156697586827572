import React from "react";
import styles from "./styles.module.scss";
import cn from "classnames";

const CustomTooltip = ({
   text,
   limit = 17,
   id,
   className,
   maxWidth = "750px",
   position = "top",
   extraMarginLeft,
   noDots,
   delay,
   isWidthMoreThanParent
}) => {
   return (
      <span
         className={cn(
            styles.text,
            className,
            styles.tooltip_wrapper,
            text?.length > limit ? styles.cursor_default : ""
         )}
      >
         {text?.length > limit
            ? `${text?.slice(0, limit)}${noDots ? "" : "..."}`
            : text}

         {text?.length > limit && (
            <div
               style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: isWidthMoreThanParent ? maxWidth : '',
                  maxWidth: maxWidth,
                  position: 'absolute',
                  zIndex: 1000,
                  top: position === 'top' ? 'auto' : '95%',
                  bottom: position === 'top' ? '95%' : 'auto',
                  left: extraMarginLeft ? '70px' : '0',
                  marginLeft: extraMarginLeft ? '70px' : '0',
               }}
               className={cn(
                  styles.hiddenTitle,
                  extraMarginLeft ? styles.ml_70 : ""
               )}
            >
               {text || ""}
            </div>
         )}
      </span>
   );
};

export default CustomTooltip;
