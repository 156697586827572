import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import filtersImg from "../../../assets/images/symbols/filters.svg";
import arrow from "../../../assets/images/symbols/arrow.svg";
import checkboxInactive from "../../../assets/images/notification_checkbox.svg";
import dots from "../../../assets/images/symbols/three_dots.svg";

import cn from "classnames";
import useOutsideClick from "../../../hooks/dom/useOutsideClick";
import { useSelector } from "react-redux";
import { PART_OF_MENTOR_NAME, ROLE_ID_STUDENT } from "../../../constants/user";
import useMoveScroll from "../../../hooks/useMoveScroll";
import LeftRightErrows from "../../../components/LeftRightArrows/LeftRightArrows";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import { v4 as uuidv4 } from "uuid";
import CustomStaticTooltip from "../../../components/CustomStaticTooltip/CustomStaticTooltip";
import Dropdown from "../../Dropdown/Dropdown";
import DropdownWithImg from "../../DropdownWithImg/DropdownWithImg";
import CustomDropdown from "../../CustomDropdown/CustomDropdown";
import {
   checkBoxFiltersData,
   optionsFiltersData,
   rolesFiltersData,
   sortFiltersData,
} from "../../../constants/notifications";
import { transformRoleText } from "../../../utils/rolesHelper";
import useFindTranslations from "../../../hooks/useFindTranlsations";

const FiltersBlock = ({
   title,
   sortFilter,
   setSortFilter,
   dateFilter,
   setDateFilter,
   roleFilter,
   setRoleFilter,
   readFilter,
   setReadFilter,
   userBranchRoles,
   setSelectFilter,
   selectFilter
}) => {
   const [showOptions, setShowOptions] = useState(false);

   const optionRef = useRef(null);
   const dropDownRef = useRef(null);

   const t = useFindTranslations()

   const closeOptions = () => setShowOptions(false);

   useOutsideClick(dropDownRef, closeOptions, optionRef);

   const toggleOptions = (e) => {
      setShowOptions((prev) => !prev);
   };

   const handleFilterChange = (filterType) => {
      if (!setActiveSortFilter) return;

      if (activeSortFilter === filterType) {
         setActiveSortFilter("");
      } else {
         setActiveSortFilter(filterType);
      }
      toggleOptions();
   };

   const onClickOption = (option) => {
      setReadFilter(option);
      closeOptions();
   };

   const transformedRoles = userBranchRoles
      ?.filter(role => {
         const roleName = role?.role?.name.toLowerCase();
         return roleName.includes('mentor') || roleName.includes('student') || roleName.includes('curator') || roleName.includes('role_admin');
      })
      .map(role => ({
         name: transformRoleText(role?.role?.name, role?.branch?.company?.name),
         roleId: role?.role?.id
      }));

   return (
      <div className={cn(styles.filtersBlock, "noselect")}>
         <CustomDropdown
            data={sortFiltersData}
            placeholder={t?.filter}
            value={dateFilter ? dateFilter : sortFilter?.name || t?.filter}
            onChange={(value) => setSortFilter(value)}
            maxFrontHeight={"30px"}
            img={filtersImg}
            isWithDate
            onChangeDate={(value) => setDateFilter(value)}
            dateFilter={dateFilter}
         />
        {userBranchRoles?.length > 1 && 
            <CustomDropdown
               placeholder={t?.roles}
               data={transformedRoles}
               value={roleFilter?.name || t?.roles}
               onChange={(value) => setRoleFilter(value)}
               maxFrontHeight={"30px"}
            />
        }

         <CustomDropdown
            data={checkBoxFiltersData}
            placeholder={""}
            value={selectFilter?.name === 'Select' ? selectFilter : ''}
            onChange={(value) => setSelectFilter(value)}
            maxFrontHeight={"30px"}
            noLeftRightPadding
            img={checkboxInactive}
         />

         <div className={styles.options_wrapper}>
            <div
               className={styles.dots}
               onClick={toggleOptions}
               ref={optionRef}
            >
               <img src={dots} alt="" />
            </div>

            {showOptions && (
               <div ref={dropDownRef} className={styles.dropdown}>
                  {optionsFiltersData?.map((option) => (
                     <p
                        key={option.name}
                        className={styles.options}
                        onClick={() => onClickOption(option)}
                     >
                        {t?.returnTranslation(option?.name)}
                     </p>
                  ))}
               </div>
            )}
         </div>
      </div>
   );
};

export default FiltersBlock;
