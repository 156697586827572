import styles from "./styles.module.scss";
import cn from "classnames";
import {
   PALETTE_IMAGES_TYPE_FIRST,
   TEMPLATE_FIRST_STATIC_TEXT,
} from "../../../constants/courses";
import {
   getGridStylesFirstTemplateType,
   transformCertificateDate,
   transoformedResult,
} from "../../../utils/coursesHelper";

const templatesWithLessImage = [
   "first_type_6",
   "first_type_7",
   "first_type_8",
   "first_type_9",
   "first_type_10",
];

const CertificateTypeFirst = ({
   maxWidth,
   maxHeight,
   data,
   onClick,
   size,
   isActive,
   certificateRef
}) => {
   const {
      course_name,
      company_logo,
      company_name,
      color_palette,
      type,
      language,
      user_name,
      result,
      serial_number,
      end_date,
   } = data;

   const calcResult = +result
      ? transoformedResult(+result, language)
      : TEMPLATE_FIRST_STATIC_TEXT[language].result;

   const certificateDate = end_date
      ? transformCertificateDate(end_date, language)
      : TEMPLATE_FIRST_STATIC_TEXT[language]?.date

   const gridStyles = getGridStylesFirstTemplateType(type, size);

   return (
      <div
         ref={certificateRef}
         className={cn(
            styles.certificate_template,
            onClick ? styles.hoverable : "",
            onClick ? "noselect" : "",
            onClick && isActive ? styles.active_template : "",
            data?.font_family ? styles[data?.font_family] : ""
         )}
         style={{
            maxWidth: maxWidth,
            maxHeight: maxHeight,
            backgroundImage: `url(${
               PALETTE_IMAGES_TYPE_FIRST[+color_palette - 1]
            })`,
            backgroundRepeat: "no-repeat",
            backgroundSize: templatesWithLessImage.includes(type)
               ? "45% 100%"
               : "56% 100%",
            backgroundPosition: "right top",
         }}
         onClick={onClick}
      >
         <div
            style={{
               ...gridStyles,
               gridTemplateColumns: templatesWithLessImage.includes(type)
                  ? "auto 40%"
                  : "",
            }}
            className={cn(styles.wrapper, styles[`wrapper_${[size]}`])}
         >
            <div
               style={{
                  gridArea: "logoBlock",
               }}
               className={styles.logoBlock}
            >
               <img className={styles.logo} src={company_logo} alt="" />
               <span className={styles.companyName}>{company_name}</span>
            </div>
            <div
               className={styles.courseBlock}
               style={{ gridArea: "courseBlock" }}
            >
               <span className={styles.gray}>
                  {TEMPLATE_FIRST_STATIC_TEXT[language]?.courseLabel}
               </span>
               <span>{course_name}</span>
            </div>
            <div
               className={styles.resultsBlock}
               style={{ gridArea: "resultsBlock" }}
            >
               <span className={styles.gray}>
                  {TEMPLATE_FIRST_STATIC_TEXT[language]?.resultLabel}
               </span>
               <span>
                  {calcResult || TEMPLATE_FIRST_STATIC_TEXT[language]?.result}
               </span>
            </div>
            <div
               className={styles.certificateBlock}
               style={{
                  gridArea: "certificateBlock",
               }}
            >
               <h2 className={styles.certificateLabel}>
                  {TEMPLATE_FIRST_STATIC_TEXT[language]?.certificateLabel}
               </h2>
               <span className={cn(styles.gray, styles.serialNumber)}>
                {serial_number || "№UD2047895487569854"}   
               </span>
               <span className={styles.gray}>
                  {certificateDate}
               </span>
            </div>
            <div
               className={styles.studentsBlock}
               style={{ gridArea: "studentsBlock" }}
            >
               <span className={cn(styles.gray, styles.studentLabel)}>
                  {TEMPLATE_FIRST_STATIC_TEXT[language]?.studentLabel}
               </span>
               <h2 className={styles.studentName}>{user_name || "Frances Swan"}</h2>
            </div>
         </div>
      </div>
   );
};

export default CertificateTypeFirst;
