import React, { useEffect, useState } from "react";

import TableBlock from "../TableBlock/TableBlock";
import {
   PROFILE_TEAMS_TABLE_HEADERS,
   PROFILE_CURATOR_TABLE_HEADERS,
   PROFILE_COMPANIES_TABLE_HEADERS,
   ROLE_ID_STUDENT,
} from "../../../constants/user";
import { useSelector } from "react-redux";

import styles from "./styles.module.scss";
import cn from "classnames";
import useUserRole from "../../../hooks/useUserRole";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const ActivityTab = ({ companies, companiesLoading, courses, coursesLoading, relatedToYouTeams, teamsLoading, lastAgencyRef}) => {
   const [activeTab, setActiveTab] = useState("teams");

   const t = useFindTranslationsByString();
   
   const companyId = useSelector(state => state.sidebar.companyId);
   const { userRoleId } = useUserRole(companyId);

   const handleTabChange = (tab) => {
      setActiveTab(tab);
   };

   return (
      <div className={styles.activity_tab_wrapper}>
         <div className={styles.tab_navigation}>
            <button
               className={cn(
                  styles.tab,
                  activeTab === "teams" && styles.activeTab
               )}
               onClick={() => handleTabChange("teams")}
            >
               {t("Teams")}
            </button>
            { userRoleId && userRoleId !== ROLE_ID_STUDENT && 
               <button
                  className={cn(
                     styles.tab,
                     activeTab === "curator" && styles.activeTab
                  )}
                  onClick={() => handleTabChange("curator")}
               >
                  {t("Curator")}
               </button>
            }
           
            <button
               className={cn(
                  styles.tab,
                  activeTab === "companies" && styles.activeTab
               )}
               onClick={() => handleTabChange("companies")}
            >
               {t("Companies")}
            </button>
         </div>

         {activeTab === "teams" && (
            <TableBlock
               key={relatedToYouTeams}
               tableFor={"teams"}
               data={relatedToYouTeams}
               headers={PROFILE_TEAMS_TABLE_HEADERS}
               loading={teamsLoading}
            />
         )}
         {activeTab === "curator" && (
            <TableBlock
               key={"curator"}
               tableFor={"curator"}
               data={courses}
               headers={PROFILE_CURATOR_TABLE_HEADERS}
               loading={coursesLoading}
            />
         )}
         {activeTab === "companies" && (
            <TableBlock
               tableFor={"companies"}
               data={companies}
               headers={PROFILE_COMPANIES_TABLE_HEADERS}
               loading={companiesLoading}
               lastAgencyRef={lastAgencyRef}
            />
         )}
      </div>
   );
};

export default ActivityTab;
