import { useState, useEffect, useRef } from "react";
import { debounce } from "lodash";
import UserApi from "../../utils/api/UserApi";
import { mapUsersWithFilteredRoles, mapUsersWithNewLinks, returnTemporaryLinks } from "../../utils/usersHelper";
import { DEFAULT_DEBOUNCE_DELAY } from "../../constants/statistics";
import { BIGGER_DATA_LIMIT, DEFAULT_DATA_LIMIT } from "../../constants/user";

const useGetAllUsersOfCompany = (companyId, isStudents = false, isSpecialUsers = false, selectedDepartment, selectedPosition, courseId, serializationGroup, triggerCFetch) => {
   const [isUsersLoading, setIsUsersLoading] = useState(true);
   const [searchedValue, setSearchedValue] = useState("");
   const [userList, setUserList] = useState([]);
   const [totalUsers, setTotalUsers] = useState(0);
   const [currentPage, setCurrentPage] = useState(1);
   const [selectAll, setSelectAll] = useState(false);

   const previousUsersRequest = useRef(null);

   const fetchUsers = async (page) => {
      setIsUsersLoading(true);

      try {
         const res = await new UserApi().getAllUsersOfCompany(
            page ? page : currentPage,
            selectAll ? BIGGER_DATA_LIMIT : DEFAULT_DATA_LIMIT,
            companyId,
            searchedValue,
            isStudents,
            isSpecialUsers,
            selectedDepartment,
            selectedPosition,
            courseId,
            serializationGroup,
         );

         const controller = new AbortController();
         if(previousUsersRequest?.current) {
            previousUsersRequest.current.abort();
         } 

         previousUsersRequest.current = controller;
         const signal = controller.signal;

         if (res?.success?.data) {
            const users = res?.success?.data || [];

            const links = await returnTemporaryLinks(users?.filter(user => user?.avatar));
            const usersWithUpdatedLinks = users?.map((user) => mapUsersWithNewLinks(user, links))?.map((user)=> mapUsersWithFilteredRoles(user, companyId));
            
            if (page) {
               setUserList(usersWithUpdatedLinks);
            } else {
               setUserList((prev) => [...prev, ...usersWithUpdatedLinks]);
            }

            setTotalUsers(res?.success?.totalCount);
         }

         if (res?.success?.data?.length === 0) {
            setCurrentPage(1);
         }

         if (res?.error?.message && page) {
            setUserList([]);
         }
      } catch (error) {
         console.error("Error fetching users:", error);
      } finally {
         setIsUsersLoading(false);
      }
   };

   const debouncedFetchUsers = debounce(
      () => fetchUsers(1),
      DEFAULT_DEBOUNCE_DELAY
   );

   const refetchUsers = () => {
      setCurrentPage(1);
      debouncedFetchUsers();
   }

   useEffect(() => {
      if (currentPage > 1 && userList?.length <= totalUsers) {
         fetchUsers();
      }
   }, [currentPage]);

   useEffect(()=> {
      return ()=> {
         setSearchedValue('')
         setUserList([])
      }
   }, [])

   useEffect(() => {
      if (companyId) {
         setCurrentPage(1);
         debouncedFetchUsers();
      }

      return () => debouncedFetchUsers.cancel();
   }, [searchedValue, companyId, triggerCFetch]);

   useEffect(() => {
      if (selectAll && userList?.length !== totalUsers && userList?.length < BIGGER_DATA_LIMIT) {
         setCurrentPage(1);
         debouncedFetchUsers();
      }

      return () => debouncedFetchUsers.cancel();
   }, [selectAll]);

   return {
      isUsersLoading,
      userList,
      setUserList,
      totalUsers,
      currentPage,
      fetchUsers,
      refetchUsers,
      setCurrentPage,
      searchedValue,
      setSearchedValue,
      selectAll,
      setSelectAll
   };
};

export default useGetAllUsersOfCompany;
