import React, {useEffect, useRef, useState} from 'react';
import star from '../../assets/images/symbols/star.svg';
import clock from '../../assets/images/symbols/clock.svg'
import eye from '../../assets/images/symbols/eye_gray.svg'
import courseImg from "../../assets/images/course_thumbnail.png";
import passedImg from "../../assets/images/passed_img.svg";
import failedImg from "../../assets/images/failed_img.svg";
import dots from '../../assets/images/symbols/three_dots.svg'
import pauseImg from "../../assets/images/course_pause.svg";
import useOutsideClick from "../../hooks/dom/useOutsideClick";
import {Link, useNavigate} from "react-router-dom";
import CourseApi from '../../utils/api/CourseApi';
import { v4 as uuidv4 } from "uuid";
import { COURSE_STATUS_DELETED, COURSE_STATUS_HIDEN } from '../../constants/courses';

import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { ROLE_ID_STUDENT, PART_OF_MENTOR_NAME, ROLE_ID_SUPER_ADMIN } from '../../constants/user';
import FolderApi from '../../utils/api/FolderApi';
import cn from 'classnames';
import CustomStaticTooltip from '../CustomStaticTooltip/CustomStaticTooltip';
import UserApi from '../../utils/api/UserApi';
import useUserRole from '../../hooks/useUserRole';
import { DEFAULT_TEXT, returnTranslation } from '../../constants/languages';

const CourseBlock = ({ course, refetchCourses, activeCourseFilter, folder, isDragging = false, isHomePage, onOpenDuplicateModal, studentCourseFilter,
onConfirmOpenStatus, onConfirmOpenRestore, onConfirmOpenHide, onRetakeModalOpen, onConfirmOpenDelete, onConfirmOpen, onOpenDeleteFromFolder, lastCourseRef, maxWidth, onConfirmPausedOpen}) => {
   const [isOpen, setIsOpen] = useState(false);
   const [isChangeLoading, setIsChangeLoading] = useState(false);
   const asStudent = useSelector(state => state.sidebar.isUserAsStudent);

   const userId = useSelector(state => state?.user?.info?.id);
   const currentCompanyId = useSelector(state => state.sidebar.companyId);
   const translations = useSelector((state) => state.sidebar.translations);
   const { userRoleId, userRoleName } = useUserRole(currentCompanyId)
 
   const dotsRef = useRef();
   const dropdownRef = useRef();
   const navigate = useNavigate();

   const onToggle = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsOpen(!isOpen);
   };
   const onClose = () => setIsOpen(false);

   const settingsLink = `/courses/settings/${course?.id}`;
   const contentLink = `/courses/course/${course?.id}/default_lang/${course?.defaultLanguage || 'en_US'}/${course?.availableLanguages?.length > 1}`;
   const editLink = `/courses/edit/${course?.id}/default_lang/${course?.defaultLanguage || 'en_US'}/${course?.availableLanguages?.length > 1}`;

   const onSettings = (e) => {
      e.preventDefault();
      e.stopPropagation();
      navigate(settingsLink)
   };

   const onContent = () => {
      if(isDragging) return;
      navigate(contentLink);
   } 

   const onEdit = (e) => {
      e.preventDefault();
      e.stopPropagation();
      if(isDragging) return;
      navigate(editLink);
   } 

   const onOpenDuplicate = (e) => {
      e.preventDefault();
      e.stopPropagation();
      if(onOpenDuplicateModal) onOpenDuplicateModal(course);
      onClose();
   }
   
   useOutsideClick(dropdownRef, onClose, dotsRef);

   const courseImage = course?.image && !course.image.includes('static/media/')
      ? course?.image
      : courseImg;

   const findYourCourseResult = (course) => {
      return course?.user?.id === userId;
   };

   const progressCondition = ((studentCourseFilter?.length === 0 || !studentCourseFilter || isHomePage) && course?.progress) ? true : false;

   const editLinkCondition = userRoleId !== ROLE_ID_STUDENT && !userRoleName?.includes(PART_OF_MENTOR_NAME) && !asStudent;

   const hideText = returnTranslation(translations, DEFAULT_TEXT.common.hide);
   const deleteText = returnTranslation(translations, DEFAULT_TEXT.common.delete);
   const restoreText = returnTranslation(translations, DEFAULT_TEXT.common.restore);
   const paused = returnTranslation(translations, DEFAULT_TEXT.common.paused);
   const changeToVisible = returnTranslation(translations, DEFAULT_TEXT.common.changeToVisible);
   const duplicate = returnTranslation(translations, DEFAULT_TEXT.common.duplicate);
   const settingsText = returnTranslation(translations, DEFAULT_TEXT.common.settings);
   const editText = returnTranslation(translations, DEFAULT_TEXT.common.edit);
   const deleteFromFolderText = returnTranslation(translations, DEFAULT_TEXT.common.deleteFromFolder);
   const timeForCoursePassing = returnTranslation(translations, DEFAULT_TEXT.common.timeForCoursePassing);
   const hoursText = returnTranslation(translations, DEFAULT_TEXT.common.hours);
   const usersThatHaveAccessToCourse = returnTranslation(translations, DEFAULT_TEXT.courses_and_folders.usersThatHaveAccessToCourse);
   const openCourse = returnTranslation(translations, DEFAULT_TEXT.common.openCourse);
   const reworkCourse = returnTranslation(translations, DEFAULT_TEXT.common.reworkCourse);

    return (
      <>
      <Link to={editLinkCondition ? editLink : contentLink} className={styles.wrapper} style={{maxWidth}} ref={lastCourseRef ? lastCourseRef : null} onClick={(e)=> {
          e.preventDefault();
         
         if(editLinkCondition) {
            onEdit(e);
         } else {
            if((userRoleId === ROLE_ID_STUDENT || asStudent) && studentCourseFilter?.length) {
               onRetakeModalOpen(course, e)
            } else if (course?.userStatus === COURSE_STATUS_HIDEN && !studentCourseFilter?.length) {
               onConfirmPausedOpen(course, e)
            } else {
               onContent(e);
            }
         }
      }} 
      className={cn(styles.courseBlock, studentCourseFilter?.length > 0 ? styles.studentsCompletedCourse : '', course?.status === COURSE_STATUS_DELETED || course.status === COURSE_STATUS_HIDEN ? styles.hidden_layout : '')}
      >
         <div 
            style={{ '--course-open': `"${openCourse}"`, '--course-rework': `"${reworkCourse}` }}
            className={cn(
                  styles.image_wrapper, 
                  isHomePage ? styles.home_image_wrapper : '', 
                  studentCourseFilter?.length ? styles.image_wrapperComplete : '', 
                  course?.userStatus === COURSE_STATUS_HIDEN ? styles.pausedCourse : '')
               }>
            <img src={courseImage} alt={''} className={styles.image}/>

            {studentCourseFilter?.length > 0 && 
               <div className={styles.result}>
                  <img src={studentCourseFilter === 'failed' ? failedImg : passedImg } alt="" />
                  <span>
                  {course?.result || 0} / 100
                  </span>
               </div>
            }
         </div>

         {course?.userStatus === COURSE_STATUS_HIDEN && 
              <div className={styles.pauseWrapper}>
                <img src={pauseImg} alt="" />
                <span>{paused}</span>
              </div>
         }

            <div className={styles.content}>
               {userRoleId !== ROLE_ID_STUDENT && !userRoleName?.includes(PART_OF_MENTOR_NAME) && userRoleId && !asStudent && (
                  <div className={styles.dots} onClick={onToggle} ref={dotsRef}>
                     <img src={dots} alt="" />
                  </div>
               )}

                {isOpen && <div onClick={(e)=> e.stopPropagation()} ref={dropdownRef} className={styles.dropdown}>

                  {activeCourseFilter === 'hidden' && 
                     <p onClick={(e)=> onConfirmOpenStatus(course, e)}>{changeToVisible}</p>
                  }

                  {activeCourseFilter === 'deleted' && 
                     <>
                        <p onClick={(e)=> onConfirmOpenRestore(course, e)}>{restoreText}</p>
                        <p onClick={(e)=> onConfirmOpenDelete(course, e)}>{deleteText}</p>
                     </>
                  }

                    <p onClick={onSettings}>{settingsText}</p>
                    <p onClick={onEdit}>{editText}</p>

                  {activeCourseFilter !== 'hidden' && !isHomePage  && 
                     <p onClick={(e)=> onConfirmOpenHide(course, e)}>{hideText}</p>
                  }

                  {activeCourseFilter !== 'deleted' && !isHomePage  && 
                     <p onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if(folder) {
                           onOpenDeleteFromFolder(course, e)
                        } else {
                           onConfirmOpen(course, e)
                        }
                     }}>
                        {folder ? deleteFromFolderText : deleteText}</p>
                  }

                  {activeCourseFilter !== 'deleted' && !isHomePage && userRoleId === ROLE_ID_SUPER_ADMIN &&
                     <p onClick={onOpenDuplicate}>{duplicate}</p>
                  }
                </div>}

                <p className={styles.title}>{course?.title ? course?.title : course?.name}</p>
                {
                    !!course?.tagList?.length && !isHomePage
                    && <div className={styles.row}>
                        {course?.tagList?.map((item, key) => (
                            <span key={key}>{item}</span>
                        ))}
                    </div>
                }

                {
                    !!course?.tags?.length && !isHomePage
                    && <div className={styles.row}>
                        {course?.tags?.map((item, key) => (
                            <span key={key}>{item}</span>
                        ))}
                    </div>
                }

                <p className={styles.text}>{course?.description}</p>

               {progressCondition &&
                 <div className={styles.result_line}>
                  <span>
                     {course?.progress || '0'}%
                     </span>
                     <div className={styles.result_line_wrapper}>
                        <span style={{width: `${course?.progress || 0}%`}} className={cn(styles.line, styles.purple_line )}></span>
                     </div>
                  </div>
               }

                {studentCourseFilter?.length > 0 && 
                     <div className={styles.result_line}>
                       <span>
                        {course?.result || '0'}%
                        </span>
                        <div className={styles.result_line_wrapper}>
                           <span style={{width: `${course?.result || 0}%`}} className={cn(styles.line, studentCourseFilter === 'failed' ? styles.red_line : styles.greenline )}></span>
                        </div>
                     </div>
                  }

                <div className={styles.info}>
                    <div className={styles.info_item}>
                        <img src={clock} alt={''}/>
                        <CustomStaticTooltip 
                           id={uuidv4()}
                           hint={timeForCoursePassing} 
                           text={`12 ${hoursText}`}
                        />
                    </div>

                    {/* <div className={styles.info_item}>
                        <img src={star} alt={''}/>
                        <CustomStaticTooltip 
                           id={uuidv4()}
                           hint={'In progress'} 
                           text={'4.7'}
                        />
                    </div> */}

                    {userRoleId && userRoleId !== ROLE_ID_STUDENT && !!course?.users &&
                      <div className={styles.info_item}>
                        <img src={eye} alt={''}/>
                        <CustomStaticTooltip 
                           id={uuidv4()}
                           hint={usersThatHaveAccessToCourse} 
                           text={course?.users || 0}
                        />
                     </div>
                    }
                   
                </div>
            </div>
        </Link>
        </>
    );
};

export default CourseBlock;