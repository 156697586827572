import { COURSE_STATUS_ACTIVE, COURSE_STATUS_DELETED, COURSE_STATUS_HIDEN, FOLDER_STATUS_ACTIVE } from "../../constants/courses";
import { BIGGER_DATA_LIMIT } from "../../constants/user";
import Base from "./Base";

export default class CourseApi extends Base {
   
   getYourCourses({ 
         limit, 
         page,
         companyId,
         order,
         status,
         isAdminAsStudent,
         tags,
         searchedValue,
         completedStatus,
         folderIncludedCoursesId,
         isExcludeCoursesWhereAdminAsStudent,
         signal,
         serializationGroup,
         translations
      }) {
      let url = `api/v1/course?limit=${limit}&page=${page}&filter[company]=${companyId}`;

      if (searchedValue) {
         url += `&filter[search]=${searchedValue}`;
      }

      url += `&filter[available_courses]=1`;

      // Exclude by default that courses where you are admin as student.
      if(isAdminAsStudent) {
         url += `&filter[get_courses_when_you_are_admin_as_student]=${1}`;
      } else if(isExcludeCoursesWhereAdminAsStudent) {
         url += `&filter[get_courses_when_you_are_admin_as_student]=${0}`;
      }

      if (tags?.length > 0) {
         const tagsString = tags.map(tag => `'${tag}'`).join(', '); 

         url += `&filter[tags]=[${tagsString}]`;
      }

      if (translations?.length > 0) {
         const transString = translations.map(trans => `'${trans?.code}'`).join(', '); 
         url += `&filter[translations]=[${transString}]`;
      }

      if(completedStatus === 'completed') {
         url += `&filter[your_completed_courses]=${1}`;
      }

      if(completedStatus === 'failed') {
         url += `&filter[is_failed_courses_when_user_course_result_less_than_course_percentage_to_receive_certificate_and_when_completed_user_course_status_is_true]=${1}`;
      }

      if(folderIncludedCoursesId) {
         url += `&filter[include_courses_in_folder]=${folderIncludedCoursesId}`;
      }

      if(status === 'deleted') {
         url += `&filter[status]=${COURSE_STATUS_DELETED}`;
      }

      if(status === 'hidden') {
         url += `&filter[status]=${COURSE_STATUS_HIDEN}`;
      }

      if(!status) {
         url += `&filter[status]=${COURSE_STATUS_ACTIVE}`;
      }

      if (order) {
         url += `&order=${order}`;
      }

      if (!order) {
         url += `&order=desc`;
      }

      return super.get(url, null, signal);
   } 

   getAllCourseTranslatedLocales () {
      
   }

   getAllCourseTranslatedLocales (courseId) {
      let url = `api/v1/course/${courseId}/list-locales`;
      
      return super.get(url)
   }
   
   getAllTeamCoursesAtOnce (teamId, companyId) {
      let url = `api/v1/course?limit=${BIGGER_DATA_LIMIT}&page=${1}&filter[available_courses_of_team]=${teamId}&filter[company]=${companyId}`;
      
      return super.get(url)
   }

   getCoursesOfUserOrTeam({ 
         limit, 
         page,
         companyId,
         itemId,
         isTeam,
         isExcludedCourses,
         searchedValue,
         isYourAvailableCourses,
         signal
      }) {
      let url = `api/v1/course?limit=${limit}&page=${page}&filter[company]=${companyId}`;

      if (searchedValue) {
         url += `&filter[search]=${searchedValue}`;
      }

      if(!isTeam && itemId && !isExcludedCourses) {
         url += `&filter[available_courses_of_user]=${itemId}`
      }

      //  get all courses apart from that user courses.
      if(!isTeam && itemId && isExcludedCourses) {
         url += `&filter[unavailable_courses_of_user]=${itemId}`
      }

      if(isTeam && itemId && !isExcludedCourses) {
         url += `&filter[available_courses_of_team]=${itemId}`
      }

      if(isTeam && itemId && isExcludedCourses) {
         url += `&filter[unavailable_courses_of_team]=${itemId}`
      }

      url += `&filter[status]=${COURSE_STATUS_ACTIVE}`;
      url += `&order=desc`;

      return super.get(url, null, signal);
   } 

   getAllCoursesOfUser({ 
         limit = 300, 
         page = 1,
         companyId,
         userId,
         serializationGroup
      }) {
      let url = `api/v1/course?limit=${limit}&page=${page}&filter[company]=${companyId}`;
      url += `&filter[available_courses_of_user]=${userId}`

      return super.get(url, null, null, serializationGroup);
   } 

   get100ActiveCoursesInFolder(companyId, folderId, signal) {
      let url = `api/v1/course?limit=${BIGGER_DATA_LIMIT}&page=${1}&filter[company]=${companyId}&filter[include_courses_in_folder]=${folderId}&filter[status]=${FOLDER_STATUS_ACTIVE}&filter[available_courses]=1&filter[get_courses_when_you_are_admin_as_student]=0`;
    
      return super.get(url, null, signal);
   }

   get100CoursesInFolder(companyId, folderId, signal) {
      let url = `api/v1/course?limit=${BIGGER_DATA_LIMIT}&page=${1}&filter[company]=${companyId}&filter[include_courses_in_folder]=${folderId}`;
    
      return super.get(url, null, signal);
   }

   getCoursesUsersInfo(data) {
      return super.post(`api/v1/course/get-course-info`, data);
   }

   getAllTags() {
        return super.get(`api/v1/course/get-all-tags`);
    } 

    getCourseByID(courseID, serializationGroup, languageHeader) {
        return super.get(`api/v1/course/${courseID}`, null, null, serializationGroup, languageHeader);
    }

    getYourCourseByID(courseID, serializationGroup, languageHeader) {
        return super.get(`api/v1/course/${courseID}/get-your-course`, null, null, serializationGroup, languageHeader);
    }
    
    getCompletedCourseStatus(courseID, userID) {
        return super.get(`api/v1/course/${courseID}/user/${userID}/is-completed`);
    }

    updateUsersProgressInCourse(courseID) {
        return super.put(`api/v1/course/${courseID}/update-user-progress`, {});
    }

   //  we get only 1 item and see totalCount in json.
    getUserOngoingCoursesCount(companyId, userId, signal) {
        return super.get(`api/v1/course?limit=1&page=1&filter[company]=${companyId}&filter[ongoing_courses]=${userId}&filter[status]=1`, null, signal);
    }

    getUserNotStartedCoursesCount(companyId, userId, signal) {
        return super.get(`api/v1/course?limit=1&page=1&filter[company]=${companyId}&filter[available_but_not_ongoing_courses]=${userId}&filter[status]=1`, null, signal);
    }

    getUserCompletedCoursesCount(companyId, userId, signal) {
        return super.get(`api/v1/course?limit=1&page=1&filter[company]=${companyId}&filter[completed_courses]=${userId}&filter[status]=1`, null, signal);
    }

    getUserAllAvailableCoursesCount(companyId, userId, signal) {
        return super.get(`api/v1/course?limit=1&page=1&filter[company]=${companyId}&filter[available_courses_of_user]=${userId}&filter[status]=1`, null, signal);
    }

    getYourCompletedCourses(serializationGroup) {
        return super.get("api/v1/course/get-your-completed-courses", null, null, serializationGroup);
    }

    createCourse(data) {
        return super.post("api/v1/course", data);
    }

    duplicateCourse(courseID, destinationBranchID) {
        return super.post(`api/v1/course/${courseID}/duplicate-to-new-branch/${destinationBranchID}`);
    }

    editCourseSetting(courseID, data) {
        return super.put(`api/v1/course/${courseID}`, data);
    }

    deleteCourseLanguageContent(courseID, locale) {
        return super.delete(`api/v1/course/${courseID}/delete-locale/${locale}`);
    }

    addAcceessOfFewCoursesToFewUsers(data) {
        return super.put(`api/v1/course/add-access-to-users`, data);
    }

    addAcceessOfFewCoursesToFewTeams(data) {
        return super.put(`api/v1/course/add-access-to-teams`, data);
    }

    deleteCourse(courseID) {
      return super.delete(`api/v1/course/${courseID}`);
    }

    getCourseImage(courseID) {
        return super.get(`api/v1/course/${courseID}/get-course-image`);
    }

    editCourseImage(courseID, data) {
        return super.post(`api/v1/course/${courseID}/change-course-image-name`, data);
    }

    deleteCourseImage(courseID) {
        return super.delete(`api/v1/course/${courseID}/delete-course-image`);
    }

    addUsersToCourse(courseID, data) {
        return super.post(`api/v1/course/${courseID}/add-many-users`, data);
    }

    editCourseCurators(courseID, data) {
        return super.put(`api/v1/course/${courseID}/curators`, data);
    }

    addOneUserToCourse(userID, courseID, data) {
        return super.put(`api/v1/user/${userID}/course/${courseID}`, data);
    }

    addTeamsToCourse(courseID, data) {
        return super.post(`api/v1/course/${courseID}/add-many-teams`, data);
    }

    getPresignedFilesUploadUrls(data) {
        return super.post(`api/v1/course/get-aws-s3-presigned-upload-urls`, data);
    }
}