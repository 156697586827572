import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import styles from "./styles.module.scss";
import Button from "../../Button/Button";
import { useNavigate } from "react-router-dom";
import { pathList } from "../../../routes/path";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const InfoModal = ({ onClose, isOpen, title, text, icon, maxHeight = "320px", maxWidth = "440px" }) => {
   const navigate = useNavigate();

   const t = useFindTranslationsByString();

   return (
      <ModalLayout
         onClose={onClose}
         isOpen={isOpen}
         maxHeight={maxHeight}
         maxWidth={maxWidth}
         withCross
      >
         <div className={styles.modal}>
            {icon && <img className={styles.icon} src={icon} alt="" />}
            
            <div className={styles.title}>
               {title}
            </div>
            <p className={styles.text}>
               {text}
            </p>
          
            <div className={styles.btns}>
               <Button title={t("Got it")} onClick={onClose} isBlack/>
            </div>
         </div>
      </ModalLayout>
   );
};

export default InfoModal;
