import React, { useState } from "react";

import shortLogo from "../../../assets/images/avatar.svg";

import styles from "./styles.module.scss";
import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";

const AvatarEditModal = ({ onClose, isActive, title, desc, avatar, setAvatar, renderedIconFile, setRenderedIconFile }) => {
   const [imageSize, setImageSize] = useState(60); // Initial size in percentage

   const handleImageSizeChange = (event) => {
     const newSize = parseInt(event.target.value);
     setImageSize(newSize);
   };
   
   return (
      <ModalLayout
         isOpen={isActive}
         maxHeight={"625px"}
         maxWidth={"460px"}
         onClose={onClose}
         withCross
      >
         <div className={styles.popup_upload}>
            <div className={styles.popup_upload_container}>
               <div className={styles.settings_logo_header}>
                  <span
                     className={`${styles.settings_box_icon} ${styles.settings_box_icon_logo}`}
                  />
                  <div className={styles.settings_logo_header_text}>
                     <p className={styles.settings_logo_title}>
                        {title}
                     </p>
                     <p className={styles.settings_logo_description}>
                        {desc}
                     </p>
                  </div>
               </div>
               <div className={styles.upload_image_box}>
                  <div className={styles.image_resize_box}>
                     <img 
                        src={renderedIconFile ? renderedIconFile : avatar} 
                        width={`${imageSize}%`}
                        height={`${imageSize}%`} 
                        alt="logo"
                     />
                  </div>
                  <div className={styles.upload_image_data}>
                     <div className={styles.upload_image_info}>
                        <span className={styles.upload_preview_wrapper}>
                           <img
                              src={renderedIconFile ? renderedIconFile : avatar}
                              width="24"
                              height="24"
                              alt="preview logo"
                           />
                        </span>
                        <div className={styles.upload_image_info_text}>
                           <p className={styles.upload_image_name}>
                              <CustomTooltip id={'avatar_logotype'} text={avatar?.name} limit={30}/> 
                           </p>
                           <p className={styles.upload_image_size}>
                              {(avatar?.size / 1024).toFixed(2)} KB
                           </p>
                        </div>
                     </div>
                     <div className={styles.resize_image_range}>
                        <label
                           className={styles.resize_image_range_label}
                           htmlFor="image_size"
                        >
                           {imageSize}%
                        </label>
                        <div className={styles.input_range_wrapper}>
                           <input
                              value={imageSize}
                              onChange={handleImageSizeChange}
                              className={styles.resize_image_range_input}
                              type="range"
                              id="image_size"
                              min="5"
                              max="100"
                           />
                           <span 
                              style={{width: `${imageSize}%`}} 
                               className={styles.purple_line}
                           ></span>
                        </div>
                        
                     </div>
                     <button onClick={()=> setRenderedIconFile(shortLogo)} className={styles.button_popup_close}>
                        X
                     </button>
                  </div>
               </div>

               <div className={styles.popup_button_grid}>
                  <button type="button" onClick={onClose} className={styles.button_popup_cancel}>
                     Cancel
                  </button>
                  <button type="button" onClick={onClose} className={styles.button_popup_save}>
                     Save changes
                  </button>
               </div>
            </div>
         </div>
      </ModalLayout>
   );
};

export default AvatarEditModal;
