import React, { useEffect, useState } from "react";
import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import styles from "./styles.module.scss";
import Button from "../../Button/Button";
import Input from "../../Input/Input";
import UserApi from "../../../utils/api/UserApi";
import { toast } from "react-toastify";
import ToastMsg from "../../ToastMsg/ToastMsg";
import Dropdown from "../../Dropdown/Dropdown";
import {
   DEPARTMENTS,
   POSITIONS,
} from "../../../constants/departmentsAndPositions";
import RenameModal from "../RenameModal/RenameModal";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const ChangeUserDataModal = ({ data, onClose, isOpen, refetchData }) => {
   const initialState = { name: "", surname: "", email: "" };
   const newDepartmentModal = useHandleModal();
   const newPositionModal = useHandleModal();

   const [user, setUser] = useState(initialState);
   const [selectedDepartment, setSelectedDepartment] = useState('');
   const [selectedPosition, setSelectedPosition] = useState('');

   const t = useFindTranslationsByString();

   const initUserData = () => {
      setUser(prev => ({
         name: data?.firstName || "",
         surname: data?.lastName || "",
         email: data?.email || ""
      }))
      setSelectedDepartment(data?.department);
      setSelectedPosition(data?.position);
   }

   const onOpenDepartmentModal = () => {
      newDepartmentModal.open();
      setSelectedDepartment("");
   };

   const onOpenPositionModal = () => {
      newPositionModal.open();
      setSelectedPosition("");
   };

   const onSelectDepartment = (department) => {
      setSelectedDepartment(department.name);
   };

   const onSelectPosition = (position) => {
      setSelectedPosition(position.name);
   };

   const closeModal = () => {
      onClose();
      initUserData();
   };

   const handleStudentInputChange = (field, value) => {
      const updatedUser = { ...user };
      updatedUser[field] = value;
      setUser(updatedUser);
   };

   const onSave = async () => {
      if(user.name?.length === 0) return toast(<ToastMsg text={"First name cannot be empty"} isError/>)

      const dataToSend = {
         ...(user.email !== data?.email && user.email?.length ? {new_email: user.email?.trim()} : {}),
         ...(user.name !== data?.firstName ? {new_first_name: user.name} : {}),
         ...(user.surname !== data?.lastName ? {new_last_name: user.surname} : {}),
         ...(selectedDepartment !== data?.department ? { new_department: selectedDepartment } : {}),
         ...(selectedPosition !== data?.position ? { new_position: selectedPosition } : {}),
      };

      const res = await new UserApi().updateUser(data?.id, dataToSend);

      if(res?.success?.data) {
         refetchData();
         toast(<ToastMsg text={res?.success?.message || 'Successfully updaated'} />)
         closeModal();
      }

      if(res?.error?.message) {
         return toast(<ToastMsg text={res?.error?.message} isError/>)
      }
   }

   useEffect(()=> {
    initUserData();
   }, [data])

   return (
      <ModalLayout
         onClose={closeModal}
         isOpen={isOpen}
         maxHeight={"535px"}
         maxWidth={"416px"}
         customBgColor={"#212224"}
         withCross
         visibleOverflow
      >
         <div className={styles.create_user_wrapper}>
            <div className={styles.title}>{t("Change user info")}</div>
            <div className={styles.inputs_wrapper}>
               <Input
                  value={user?.name}
                  placeholder={t("Name")}
                  onChangeValue={(e) =>
                     handleStudentInputChange("name", e.target.value)
                  }
               />
               <Input
                  value={user?.surname}
                  placeholder={t("Surname")}
                  onChangeValue={(e) =>
                     handleStudentInputChange("surname", e.target.value)
                  }
               />
               <Input
                  value={user?.email}
                  placeholder={t("Email")}
                  onChangeValue={(e) =>
                     handleStudentInputChange("email", e.target.value)
                  }
               />

               <Dropdown
                  data={DEPARTMENTS}
                  field={"name"}
                  value={selectedDepartment}
                  placeholder={t("Department * Optionally")}
                  onChange={onSelectDepartment}
                  createBtnText={t("Create new department")}
                  maxHeight={"220px"}
                  onOpenCreateModal={onOpenDepartmentModal}
               />

               <Dropdown
                  data={POSITIONS}
                  maxHeight={"180px"}
                  field={"name"}
                  value={selectedPosition}
                  placeholder={t("Position * Optionally")}
                  onChange={onSelectPosition}
                  createBtnText={t("Create new position")}
                  onOpenCreateModal={onOpenPositionModal}
               />
            </div>
            <div className={styles.btns}>
               <Button title={t("Cancel")} onClick={closeModal} isBlack/>
               <Button title={t("Confirm")} onClick={onSave} />
            </div>

            <RenameModal
               key={"New deparnment"}
               inputValue={selectedDepartment}
               setInputValue={setSelectedDepartment}
               onClose={newDepartmentModal.close}
               isOpen={newDepartmentModal.isActive}
               onConfirm={newDepartmentModal.close}
               title={t("Enter department name")}
            />
            <RenameModal
               key={"New position"}
               inputValue={selectedPosition}
               setInputValue={setSelectedPosition}
               onClose={newPositionModal.close}
               isOpen={newPositionModal.isActive}
               onConfirm={newPositionModal.close}
               title={t("Enter position name")}
            />
         </div>
      </ModalLayout>
   );
};

export default ChangeUserDataModal;
