import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import { useNavigate, Link } from "react-router-dom";
import arrowRight from "../../assets/images/arrow_right.svg";
import cn from "classnames";

const CoursesInfoLineBlock = ({ numberOfItems, link, icon, title, coursesText }) => {
   const navigate = useNavigate();

   return (
      <Link to={link} className={styles.block}>
         <div>
            <img src={icon} alt="" />
         </div>
         <div className={styles.middle}>
            <span>{title}</span>
            <p>{numberOfItems || 0} {coursesText}</p>
         </div>
         <img className={styles.arrow} src={arrowRight} alt="" />
      </Link>
   );
};

export default CoursesInfoLineBlock;
