import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { STATISTICS_TABS } from "../../../constants/statistics";

const StatisticsTabs = ({ activeTab, clickOnTab, t}) => {
   return (
      <div className={styles.subheader}>
         {STATISTICS_TABS.map((tab) => (
            <button
               key={tab.id}
               className={activeTab === tab.label ? styles.active : ""}
               onClick={() => clickOnTab(tab)}
            >
               {t?.returnTranslation(tab.name)}
            </button>
         ))}
      </div>
   );
};

export default StatisticsTabs;
