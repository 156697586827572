import React, { useCallback, useEffect, useMemo, useState } from "react";
import SelectPositionBlock from "../QuestionItem/QuestionItem";
import Loader from "../../../components/Loader/Loader";
import QuestionItem from "../QuestionItem/QuestionItem";
import LessonApi from "../../../utils/api/LessonApi";
import {
   catchErrorsAndShowSuccess,
   defineQuestionDefaultObj,
   showValidationErrors,
   LOADING_NOT_NEEDED,
   VIDEO_ANSWER_CODE,
   MULTIPLE_CHOICE_ANSWER_CODE
} from "../../../utils/questionsHelper";
import { toast } from "react-toastify";
import ToastMsg from "../../../components/ToastMsg/ToastMsg";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import tests from "../../../assets/images/icon_task1.svg";
import icon_hide_show_menu from "../../../assets/images/icon_hide_show_menu.svg";
import intro_icon from "../../../assets/images/introduction_icon.svg";
import saveIcon from '../../../assets/images/icon_save.svg';

import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import { useRef } from "react";
import cn from 'classnames';
import styles from "./styles.module.scss";
import { useSelector } from "react-redux";
import TextArea from "../../../components/TextArea/TextArea";
import Button from "../../../components/Button/Button";
import DOMPurify from "dompurify";
import { encodeSanitizedInputValue } from "../../../utils/coursesHelper";
import CourseApi from "../../../utils/api/CourseApi";
import { useParams } from "react-router-dom";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const QuestionsBlock = ({
   questions,
   setQuestions,
   questionsLoading,
   activeTest,
   onClickElement,
   lastDropped,
   handleInputsDataChange,
   questionsDataFromInputs,
   setQuestionsDataFromInputs,
   fetchQuestionsData,
   fetchModulesData,
   fetchDefaultModulesData,
   returnUpdatedQuestions,
   toggleLessonsSidebar,
   isLessonsSidebarOpen,
   setChangedLessonFlag,
   changedLessonFlag,
   isFieldEmpty,
   setIsFieldEmpty,
   editLanguage,
   defaultCourseLanguage,
   defaultQuestions,
   defaultModules,
   course
}) => {

   const params = useParams();
   const [wasReordered, setWasReordered] = useState(false);
   const [isRefetchLoading, setIsRefetchLoading] = useState(false);
   const [isLessonChanged, setIsLessonChanged] = useState(false);
   const [errorMsg, setErrorMsg] = useState('');
   const questionsWithoutDelete = questions?.filter(q => !q.hasOwnProperty('delete_question_id'));

   const t = useFindTranslationsByString();

   const [renameInput, setRenameInput] = useState(activeTest?.name || '');
   const [lessonIntro, setLessonIntro] = useState('');
   const [isRename, setIsRename] = useState(false);
   const lessonNameInputRef = useRef(null);

   const isSideBarOpen = useSelector(state => state.sidebar.isFixed);

   const updateQuestionsDisplaySequences = async (updatedQuestions) => {
      const reorderedQuestionsData = questions.map((item, index) => {
         // Set new sequences for each question
         return  {
            question_id: item?.id ? item.id : updatedQuestions?.find((q)=> q?.content === questionsDataFromInputs[index]?.content)?.id,
            new_display_sequence: index + 1
         }
      })

      const dataToSend = {
         questions: reorderedQuestionsData
      }

      const res = await new LessonApi().updateQuestionsSequence(dataToSend);
   }

   const updateAnswersDisplaySequences = async (updatedQuestions) => {
      let reorderedQuestionsData = [];

      questionsDataFromInputs?.forEach((question, questionIdx) => {
          reorderedQuestionsData = question?.answers?.map((item, index) => {
            // Set new sequences for each question
            return  {
               answer_id: typeof item?.id === 'number' ? item?.id : updatedQuestions?.[questionIdx]?.answers?.find((ans)=> ans?.content === item?.content)?.id,
               new_sequence: index + 1
            }
         })
      })

      const dataToSend = {
         answers: reorderedQuestionsData
      }

      const res = await new LessonApi().updateAnswersSequence(dataToSend);
   }

   const postQuestions = async () => {
      const transformedQuestionData = questionsDataFromInputs
         .map((questionItem, idx) => {
            const questionId = questions[idx]?.id;
            const isDelete = questions[idx]?.delete_question_id ? true : false;
            const propToAdd = isDelete ? "delete_question_id" : "question_id";

            // Create a copy of the questionItem object without chosenFileType
            const { chosenFileType, isShuffled, ...questionItemWithoutFileType } = questionItem;

            return {
               ...questionItemWithoutFileType,
               lesson_id: activeTest?.id,
               content: encodeSanitizedInputValue(questionItem?.content),
               description: encodeSanitizedInputValue(questionItem?.description),
               ...(questionItem?.type === VIDEO_ANSWER_CODE ? {title: encodeSanitizedInputValue(questionItem?.title)} : {}),
               [propToAdd]: questionId,
               type: questions[idx]?.type,
               default_language: defaultCourseLanguage,
               new_language: editLanguage?.code, 
               ...(questionItem?.sequence?.length ? { sequence: questionItem?.sequence } : {}),
               answers: questionItem?.answers?.map(
                  ({ id, matchingOption, question, isCorrect, userAnswers, sequence,defaultLanguage, ...rest }, idx) => ({
                     ...rest,
                     ...(typeof id === 'number' ? { answer_id: id } : {}),
                     ...(matchingOption !== undefined && matchingOption !== null ? 
                                          { matching_option: matchingOption } : {}),
                     is_correct: isCorrect ? 1 : 0,
                  })
               ),
            };
         })
         // delete newely added itmes from the array
         .filter((questionItem, idx) => questions[idx]?.delete_question_id !== null)

      const transformedWithoutDeleteItems = transformedQuestionData.filter(item => !item.hasOwnProperty('delete_question_id'));

      const validationError = showValidationErrors(transformedWithoutDeleteItems);

      if (validationError) {
         setIsFieldEmpty(true);
         setErrorMsg(validationError);
         return toast(<ToastMsg text={`${validationError} (${editLanguage?.name} version)`} isError />);
      }

      const res = await new LessonApi().processQuestions(transformedQuestionData);
      
      if (res) {
         catchErrorsAndShowSuccess(res, editLanguage?.name);
      }

      if (res?.success) {
         const updatedQuestions = await returnUpdatedQuestions();

         new CourseApi().updateUsersProgressInCourse(params?.id);
         await updateQuestionsDisplaySequences(updatedQuestions);
         await updateAnswersDisplaySequences(updatedQuestions);
         await fetchQuestionsData(LOADING_NOT_NEEDED);
      }

      if (res?.error?.message) {
         return toast(<ToastMsg text={res?.error?.message} isError />);
      }
   };

   const renameLesson = () => {
      setIsRename(true);
      lessonNameInputRef?.current?.focus();
      onCloseLessonOptions();
   }

   const updateLessonName = async () => {
      if(renameInput.length === 0) return;
      if(renameInput === activeTest?.name) return;

      const res = await new LessonApi().updateLesson(activeTest?.id, {new_name: renameInput.trim()});

      if(res?.success?.message) {
         toast(<ToastMsg text={`${res?.success?.message} (${editLanguage?.name} version)`} />);
         onClickElement(res?.success?.data?.lesson)
         fetchModulesData(editLanguage?.code);
      }

      if(res?.error?.message) {
         setRenameInput('');
         return toast(<ToastMsg text={res?.error?.message} isError/>);
      }
   }

   const showRenameInput = () => {
      setIsRename(true);
   }

   const onFocusOutName = () => {
      setIsRename(false);
      updateLessonName();
   }

   const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        setIsRename(false);
        updateLessonName();
      }
    };

    const findQuestionIndexById = (questions, questionId) => {
      return questions.findIndex(question => question.id === +questionId || question.uniqueId === questionId);
    }

   const onDragEnd = (result) => {
      const { source, destination, type } = result;
    
      // Check if the draggable was dropped outside a droppable
      if (!destination) return;
      if (
         source.droppableId === destination.droppableId &&
         source.index === destination.index
      ) return;

      setChangedLessonFlag("changed"); 

      // Means that we are dragging question blocks
      if(type === 'questions_group') {
         const reorderedQuestions = [...questions];
         const sourceIndex = source.index;
         const destinationIndex = destination.index;

         const [removedQuestion] = reorderedQuestions.splice(sourceIndex, 1); // remove element in which we wanted to change order
         reorderedQuestions.splice(destinationIndex, 0, removedQuestion); // add back the element with new index

         const isReordered = reorderedQuestions.some((question, index) => question.id !== questions[index].id);

         setWasReordered(isReordered)

         return setQuestions(reorderedQuestions);
      }

      if (type === 'answers_group') {
         const reorderedQuestions = [...questionsDataFromInputs]; 
         const questionIndex = findQuestionIndexById(questions, source.droppableId); 

         if (questionIndex !== -1) {
           const question = reorderedQuestions[questionIndex];
           const answers = [...question.answers];
       
           const sourceAnswerIndex = source.index;
           const destinationAnswerIndex = destination.index;

           const [removedAnswer] = answers.splice(sourceAnswerIndex, 1); // Remove the answer from the source position
           answers.splice(destinationAnswerIndex, 0, removedAnswer); // Insert the answer at the destination position
       
           question.answers = answers; 

           if (question.options) {
            question.answers = answers?.map((answer, idx) => ({
               ...answer,
               matchingOption: idx
            }));

            question.options = question.options?.map((opt, idx) => question.options[answers[idx].matchingOption]);
         }

           setQuestionsDataFromInputs(reorderedQuestions); // Update the state with the reordered questions
         }
       }
    };

    const changeLessonIntroduction = async () => {
      const res = await new LessonApi().updateLesson(activeTest?.id, {
         new_description: lessonIntro,
         new_language: editLanguage?.code
      });

      if(res?.success) {
         fetchModulesData(editLanguage?.code);
         fetchDefaultModulesData();
      }
    }

   const onSave = async () => {
      setIsRefetchLoading(true);
      await postQuestions();
      setIsRefetchLoading(false);
      changeLessonIntroduction();
      setChangedLessonFlag('still');
   };

   useEffect(()=> {
      if (activeTest?.description) {
         setLessonIntro(activeTest?.description);
      } else {
         setLessonIntro('');
      }
   }, [activeTest?.description])

   const allLessons = defaultModules.flatMap(module => module.lessons);
   const defaultTest = allLessons?.find(lesson => lesson?.id === activeTest?.id);

   const handleIntroChange = (e) => {
      setChangedLessonFlag('changed');
      setLessonIntro(e.target.value);
    }; 

   if (questionsLoading) {
      return (
         <>
         <div className={cn(
            styles.questions_subheader, 
            !isLessonsSidebarOpen ? styles.questions_subheader_wider : '',
            isSideBarOpen ? styles.questions_subheader_thin : '',
            isSideBarOpen && !isLessonsSidebarOpen ? styles.questions_subheader_wider_second : '',
         )}>
         <div className={styles.subheader_left}>
         {questions.length > 0 &&  
            <button onClick={toggleLessonsSidebar} className={cn(
                  styles.toggle_sidebar, 
               )}>
                    <img className={!isLessonsSidebarOpen ? styles.show_menu : ''} src={icon_hide_show_menu} alt="" /> 
               </button>
         }
    
            <div onDoubleClick={showRenameInput} className={styles.lesson_name}>
               <CustomTooltip id={activeTest.id} maxWidth={"500px"} text={activeTest?.name}  position="bottom" limit={33}/>

                  {isRename && <input 
                     key={activeTest?.id}
                     type="text" 
                     className={styles.name_input}
                     ref={lessonNameInputRef} 
                     onBlur={onFocusOutName} 
                     autoFocus={true} 
                     onKeyDown={handleKeyDown}
                     value={renameInput}
                     onChange={(e)=> setRenameInput(e.target.value)}
                  />}
            </div>
            <img src={tests} alt="" />
            <span>{questionsWithoutDelete.length > 0 ? questionsWithoutDelete.length : '0'}</span>
         </div>
      </div>
         <div className={styles.intro_wrapper}>
         <div className={styles.intro_header}> 
            <img src={intro_icon} alt="Intro icon" />
            <span>{t("Introduction")}</span>
         </div>
         <div className={cn(
                styles.intro_text_area,
               lessonIntro === defaultTest?.description && editLanguage?.code !== course?.defaultLanguage ? styles.prevInput : ''
            )}>
            <p>{t("Here you can provide comments on the task, make an introduction, provide hints")}</p>
            <TextArea
               isResize
               minRows={4}
               maxRows={15}
               placeholder={t("Enter the introduction * Optionally")}
               height={75}
               value={lessonIntro}
               onChangeValue={handleIntroChange}
            />
         </div>
      </div>
      <div className={styles.loader_wrapper}>
         <Loader size={'small'}/>
      </div>
   </>
       
      );
   }

   return (
      <DragDropContext onDragEnd={onDragEnd}>
            <div className={cn(
                  styles.questions_subheader, 
                  !isLessonsSidebarOpen ? styles.questions_subheader_wider : '',
                  isSideBarOpen ? styles.questions_subheader_thin : '',
                  isSideBarOpen && !isLessonsSidebarOpen ? styles.questions_subheader_wider_second : '',
               )}>
               <div className={styles.subheader_left}>
               {questions.length > 0 &&  
                  <button onClick={toggleLessonsSidebar} className={cn(
                        styles.toggle_sidebar, 
                     )}>
                          <img className={!isLessonsSidebarOpen ? styles.show_menu : ''} src={icon_hide_show_menu} alt="" /> 
                     </button>
               }
          
                  <div onDoubleClick={showRenameInput} className={styles.lesson_name}>
                           
                     <CustomTooltip id={activeTest.id} maxWidth={"500px"} text={activeTest?.name} position="bottom" isWidthMoreThanParent limit={33}/>

                        {isRename && <input 
                           key={activeTest?.id}
                           type="text" 
                           className={styles.name_input}
                           ref={lessonNameInputRef} 
                           onBlur={onFocusOutName} 
                           autoFocus={true} 
                           onKeyDown={handleKeyDown}
                           value={renameInput}
                           onChange={(e)=> setRenameInput(e.target.value)}
                        />}
                  </div>
                  <img src={tests} alt="" />
                  <span>{questionsWithoutDelete.length > 0 ? questionsWithoutDelete.length : '0'}</span>
               </div>
               
               {questions.length > 0 && (
                  <div className={styles.save_btn}>
                     <Button 
                        image={saveIcon}
                        title={t("Save questions")}
                        disabled={changedLessonFlag === 'still' || isRefetchLoading}
                        onClick={onSave}
                     />
                     {isRefetchLoading && 
                        <span>
                           <Loader size={'small'}/>
                        </span>
                     }
                  </div>
                  
               )}
            </div>

            <div className={styles.intro_wrapper}>
               <div className={styles.intro_header}> 
                  <img src={intro_icon} alt="Intro icon" />
                  <span>{t("Introduction")}</span>
               </div>
               <div className={cn(
                     styles.intro_text_area,
                    lessonIntro === defaultTest?.description && editLanguage?.code !== course?.defaultLanguage ? styles.prevInput : ''
                  )}>
                  <p>{t("Here you can provide comments on the task, make an introduction, provide hints")}</p>
                  <TextArea
                     isResize
                     minRows={4}
                     maxRows={15}
                     placeholder={t("Enter the introduction * Optionally")}
                     height={75}
                     value={lessonIntro}
                     onChangeValue={handleIntroChange}
                  />
               </div>
            </div>

         <Droppable droppableId="droppableQuestions" type="questions_group">
            {(provided, snapshot) => (
               <div {...provided.droppableProps} ref={provided.innerRef} className={styles.draggable_questions}>
                  {questions?.map((question, questionIndex) => {
                     return (
                        <Draggable
                           key={question.id ? question.id : question.uniqueId}
                           draggableId={`${question.id ? question.id : question.uniqueId}`}
                           index={questionIndex}
                        >
                           {(provided, snapshot) => (
                              <div
                                 id="additional_draggable"
                                 className={styles.draggable_questions}
                                 ref={provided.innerRef}
                                 {...provided.draggableProps}
                                 {...provided.dragHandleProps}
                              >
                                 <QuestionItem
                                    setQuestions={setQuestions}
                                    isDragging={snapshot.isDragging}
                                    handleInputsDataChange={handleInputsDataChange}
                                    question={question}
                                    questionsWithoutDelete={questionsWithoutDelete}
                                    questionIndex={questionIndex}
                                    setChangedLessonFlag={setChangedLessonFlag}
                                    setIsFieldEmpty={setIsFieldEmpty}
                                    isFieldEmpty={isFieldEmpty}
                                    defaultQuestion={defaultQuestions[questionIndex]}
                                    course={course}
                                    editLanguage={editLanguage}
                                 />
                              </div>
                           )}
                        </Draggable>
                     );
                  })}
                  {provided.placeholder}
               </div>
            )}
         </Droppable>
      </DragDropContext>
   );
};

export default QuestionsBlock;
