import React, { useEffect, useState } from "react";
import arrow from "../../../../assets/images/arrow-down.svg";
import lockWhite from "../../../../assets/images/lock_white.svg";
import moduleDoneImg from "../../../../assets/images/progress/module_done.svg";
import pauseImg from "../../../../assets/images/symbols/pause.svg";

import { toast } from "react-toastify";
import ModuleApi from "../../../../utils/api/ModuleApi";
import LessonApi from "../../../../utils/api/LessonApi";
import useOutsideClick from "../../../../hooks/dom/useOutsideClick";
import Input from "../../../../components/Input/Input";
import LessonItem from "../LessonItem/LessonItem";
import ToastMsg from "../../../../components/ToastMsg/ToastMsg";
import {
   LESSON_TYPE_WITHOUT_TESTING_NUMBER,
   LESSON_TYPE_WITH_TESTING_NUMBER,
} from "../../../../constants/courses";

import cn from "classnames";
import styles from "./styles.module.scss";
import { Draggable, Droppable } from "react-beautiful-dnd";
import DraggingArrows from "../../../../components/DraggingArrows/DraggingArrows";
import CustomTooltip from "../../../../components/CustomTooltip/CustomTooltip";
import ConfirmModal from "../../../../components/Modals/ConfirmModal/ConfirmModal";
import useHandleModal from "../../../../hooks/dom/useHandleModal";
import InfoModal from "../../../../components/Modals/InfoModal/InfoModal";
import useFindTranslationsByString from "../../../../hooks/useFindTranslationsByString";

const ModuleItem = ({
   module,
   activeModule,
   index,
   onClickModule,
   activeElement,
   modules,
   onClickElement,
   fetchData,
   questionsDataFromInputs,
   questions,
   isLinear,
   setOpenedModulesIds,
   openedModulesIds,
   isModuleCompleted,
   activeLessonRef,
   userStatusOnCoursePaused
}) => {
   const notAvailableModal = useHandleModal();

   const t = useFindTranslationsByString();

   const toggleModule = () => {
      if (!module) return;

      // if(!module?.lessons?.[0]?.completed && userStatusOnCoursePaused && module?.lessons?.length > 0) {
      //    notAvailableModal.open();
      //    return;
      // } 
      onClickModule(module.id);

      if(openedModulesIds.includes(module.id) && isModuleOpen) {
         setOpenedModulesIds(openedModulesIds.filter?.(id => id !== module.id));
      }
   };

   const isLinearModuleClick = () => {
      if((module?.lessons?.[0]?.unlocked) || ((module?.lessons?.[0]?.completed || module?.lessons?.[0]?.userTests?.length) && userStatusOnCoursePaused)) {
         toggleModule();
      } else {
         if(module?.lessons?.length > 0) notAvailableModal.open();
         if(!module?.lessons?.length) toggleModule();
      }
   } 

   useEffect(()=> {
      if(activeModule && isLinear) {
         isLinearModuleClick();
      }
   }, [isLinear])

   const isModuleOpen = openedModulesIds?.includes(module?.id);

   return (
      <div
         className={cn(
            styles.list_item,
            isModuleOpen ? styles.list_item_active : ""
         )}
         key={module.id}
      >
         <div
            onClick={isLinear ? isLinearModuleClick : toggleModule}
            className={cn(
               styles.list_item_header,
               isModuleOpen ? styles.list_item_header_active : ""
            )}
         >
            <span className={styles.list_item_header_title}>
               <div className={styles.module_number}>{index + 1}.</div>
               <span className={styles.module_name}>
                  <CustomTooltip
                     limit={isModuleCompleted ? 11 : 18}
                     text={module?.name}
                     delay={500}
                     id={`module_name_${module?.id}`}
                  />
               </span>
            </span>

            {isModuleCompleted && 
               <div className={styles.completedModule}>
                  <img src={moduleDoneImg} alt="Module done icon" />
                  <span></span>{t("Done")}</div>
            }
            <img
               className={cn(
                  isModuleOpen ? styles.arrowActive : "",
                  styles.arrow
               )}
               src={arrow}
               alt={""}
            />
         </div>

         {isModuleOpen && (
            <div className={styles.elements}>
               {!module?.lessons?.length && <p>No lessons yet</p>}
               {module?.lessons?.map((lesson, lessonIndex) => (
                  <LessonItem
                     userStatusOnCoursePaused={userStatusOnCoursePaused}
                     key={lesson?.id}
                     lessonIndex={lessonIndex}
                     moduleIndex={index}
                     onClickElement={onClickElement}
                     activeElement={activeElement}
                     lesson={lesson}
                     moduleId={module?.id}
                     fetchData={fetchData}
                     questions={questions}
                     questionsDataFromInputs={questionsDataFromInputs}
                     isLinear={isLinear}
                     notAvailableModal={notAvailableModal}
                     nextLesson={module?.lessons[lessonIndex + 1] ? module?.lessons[lessonIndex + 1] : modules[index + 1]?.lessons[0] || {}}
                     activeLessonRef={activeLessonRef}
                  />
               ))}
            </div>
         )}

         <InfoModal
            onClose={notAvailableModal.close}
            isOpen={notAvailableModal.isActive}
            maxHeight={userStatusOnCoursePaused ? '370px' : '330px'}
            title={userStatusOnCoursePaused ? t("The course was paused") : t("Access blocked")}
            text={userStatusOnCoursePaused ? t("You cannot continue learning, but completed lessons and finished tests are available. If there is a deadline, it will be suspended") : t("Complete the previous steps to unlock this stage")}
            icon={userStatusOnCoursePaused ? pauseImg : lockWhite}
         />
      </div>
   );
};

export default React.memo(ModuleItem);
