import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import useOutsideClick from "../../hooks/dom/useOutsideClick";
import { useNavigate } from "react-router-dom";
import AgencyApi from "../../utils/api/AgencyApi";
import { useSelector } from "react-redux";
import shortLogo from "../../assets/images/favicon.png";

import cn from 'classnames';
import CustomTooltip from "../CustomTooltip/CustomTooltip";

const AgencyCard = ({
   agency,
   lastElementRef,
   changeCurrentSuperAdminCompany,
   setIsMountedWithDelay,
   isMountedWithDelay,
   loading,
   isSpecialRole,
   t
}) => {
   const navigate = useNavigate();
   const companyName = useSelector((state) => state.sidebar.companyName);
   const companyId = useSelector((state) => state.sidebar.companyId);

   const [isOpen, setIsOpen] = useState(false);

   const dotsRef = useRef();
   const dropdownRef = useRef();

   const onClose = () => setIsOpen(false);
   const onToggle = () => setIsOpen(!isOpen);

   useOutsideClick(dropdownRef, onClose, dotsRef);

   useEffect(() => {
      const timeoutId = setTimeout(()=> {
         setIsMountedWithDelay(true)
      }, 2000)

      return () => {
         clearTimeout(timeoutId)
      };
   }, [agency]);

   const replaceEmptySpaces = (str) => {
      return str?.replace(' ', '_') || ''
   }

   const companyLogo = agency?.companyLogo?.iconLogo ? agency?.companyLogo?.iconLogo : shortLogo

   return (
      <li
         ref={lastElementRef}
         key={agency.id}
         className={`${styles.agency_card} ${
            styles[replaceEmptySpaces(agency.role)]
         } ${styles.defaultCartColor}`}
      >
         <div className={cn(
            styles.agency_logo_wrap, 
            +agency?.id === 6 ? styles.bigger_logo : '',
            +agency?.id === 1 ? styles.galaktika_logo : '',
         )}>
            {companyLogo && 
               <img
                  className={styles.agency_logo}
                  src={companyLogo}
                  width="24"
                  height="24"
                  alt="logo"
               />
            }
            
         </div>
         <div className={styles.agency_card_info}>
            <p className={styles.agency_name}>
               <CustomTooltip id={`${agency?.id}name`} text={agency.name} limit={15}/>
            </p>
            <p className={styles.agency_mail}>{agency.email}</p>
            <p
               className={`${styles.agency_role} ${
                  styles[replaceEmptySpaces(agency.role)]
               }`}
            >
               {agency?.role ? agency?.role  : `${checkRole}...`}
            </p>
         </div>

         <div className={styles.details}>
            <span className={styles.counter_courses}>{agency.courses}</span>
            <span className={styles.counter_profiles}>{agency.profiles}</span>
         </div>
     
         <button
            disabled={!isMountedWithDelay}
            onClick={() =>
               companyId !== agency?.id &&
               changeCurrentSuperAdminCompany(agency, companyLogo)
            }
            className={cn(
               styles.agency_card_status,
               +companyId !== agency?.id ? styles.change_link : '',
               'noselect'
            )}
         >
            {+companyId === agency?.id && isMountedWithDelay && t?.youAreOnCompany}
            {+companyId !== agency?.id && isMountedWithDelay && t?.switchCompany}
            {!isMountedWithDelay && `${t?.loading}...`}
         </button>

         {isSpecialRole && 
            <button
               ref={dotsRef}
               onClick={onToggle}
               className={`${styles.agency_card_button_menu} ${styles.button_more}`}
               type="button"
            >
               {t?.more}
            </button>
         }
      

         {isOpen && (
            <div ref={dropdownRef} className={styles.dropdown}>
               <p
                  className={styles.options}
                  onClick={() => navigate(`/companies/settings/${agency.id}`)}
               >
                  {t?.settings}
               </p>
            </div>
         )}
      </li>
   );
};

export default React.memo(AgencyCard);
