
const preLink = 'http://d3kpxdc2tckd1b.cloudfront.net';

// export const AVATAR_S3_LINK_BEFORE_FILENAME = `${preLink}/${process.env.REACT_APP_S3_CURRENT_FOLDER_NAME}/images/avatars/`;

// export const COMMENT_TESTS_S3_LINK_BEFORE_FILENAME = `${preLink}/${process.env.REACT_APP_S3_CURRENT_FOLDER_NAME}/files/comment_tests/`;

// export const COMPANY_ICON_S3_LINK_BEFORE_FILENAME = `${preLink}/${process.env.REACT_APP_S3_CURRENT_FOLDER_NAME}/images/company_icon_logos/`;

export const COURSE_ICON_S3_LINK_BEFORE_FILENAME = `${preLink}/${process.env.REACT_APP_S3_CURRENT_FOLDER_NAME}/images/course_images/`; 


export const HTTPS_REGEX = /https:/;