import { Droppable } from "react-beautiful-dnd";
import compareIcon from "../../../../assets/images/compare_icon.svg";
import {
   COMPARE_ANSWER_CODE,
   MULTIPLE_CHOICE_ANSWER_CODE,
   ONE_CHOICE_ANSWER_CODE,
   SEQUENCE_ANSWER_CODE,
   TRUE_FALSE_ANSWER_CODE,
} from "../../../../utils/questionsHelper";
import DraggingArrows from "../../../../components/DraggingArrows/DraggingArrows";

import styles from "./styles.module.scss";
import cn from "classnames";
import { useEffect } from "react";

const OptionsCompare = ({ type, item, answerIdx, inputsData }) => {

   return (
      <div key={answerIdx} className={styles.compare_wrapper}>
         <div
            className={cn(
               styles.answer,
               styles.compare_answer,
               styles.no_hover
            )}
         >
            <div className={cn(styles.answer_block)}>
               <div className={styles.compare_option}>
                  {inputsData?.options?.[answerIdx]}
               </div>
            </div>
         </div>
      </div>
   );
};

export default OptionsCompare;
