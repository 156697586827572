import React, {useEffect} from 'react';
import Modal from "react-modal";
import { customStyles, customStylesNoBlur } from "./custumModalStyles";
import cross from '../../assets/images/symbols/cross_grey.svg'
import styles from './styles.module.scss'
import cn from "classnames";

const ModalLayout = ({
                         isOpen,
                         onClose,
                         children,
                         maxWidth,
                         maxHeight,
                         withCross,
                         withScroll = false,
                         customPadding = null,
                         customBgColor,
                         visibleOverflow = false,
                         isSertificate = false,
                         black = true,
                         dFlex,
                         onClickToCloseDropdown,
                         noBlur
                     }) => {

    useEffect(() => {
        Modal.setAppElement('body')
    }, [])

    useEffect(() => {
      if (isOpen && !withScroll) {
        document.body.style.overflowY = 'hidden';
      } else {
        document.body.style.overflowY = 'auto';
      }
   
      // Cleanup function to remove the style when the component unmounts or modal is closed
      return () => {
        document.body.style.overflowY = 'auto';
      };
    }, [isOpen]);

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            style={{
                ...(noBlur ? customStylesNoBlur : customStyles), content: {
                    ...customStyles?.content,
                    maxWidth: maxWidth,
                    maxHeight: maxHeight,
                    backgroundColor: customBgColor ? customBgColor : '#28292C',
                    ...(withScroll ? { overflow: 'scroll' } : {}),
                    ...(visibleOverflow ? { overflow: 'visible' } : {}),
                    padding: customPadding ? customPadding : '40px',
                    height: '100%'
                }
            }}
        >
            <div onClick={()=> onClickToCloseDropdown ? onClickToCloseDropdown('') : {}} className={cn(styles.modal, dFlex ? styles.dFlex : '')}>
                {withCross && <div className={cn(styles.cross, customPadding && styles.cross_padding)}>
                    <img src={cross} alt={''} onClick={onClose}/>
                </div>}
                {children}
            </div>
        </Modal>
    );
};

export default ModalLayout;