import {
    USER_SET_INFO,
} from "../constants/user";
import {SET_INIT_STATE} from "../constants";

const initState = {
    info: null,
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case SET_INIT_STATE:
            return initState

        case USER_SET_INFO:
            return {
                ...state, ...{
                    info: action?.info,
                }
            }

        default:
            return state
    }
}
