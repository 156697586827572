import React, { useRef, useState } from "react";
import styles from "./styles.module.scss";
import cn from "classnames";
import CertificateTemplate from "../../CertificateTemplate/CertificateTemplate";
import EmptyBlock from "../../EmptyBlock/EmptyBlock";
import moment from "moment";
import Button from "../../Button/Button";
import pdfIcon from "../../../assets/images/pdf_icon.svg";
import pngIcon from "../../../assets/images/png_icon.svg";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Loader from "../../Loader/Loader";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const Certificates = ({
   certificates,
   orientation,
   certificateLoading,
   openCertificateModal,
   lastItemRef
}) => {
   const [loading, setLoading] = useState(false);
   const [currentTemplate, setCurrentTemplate] = useState({});
   const certificateRef = useRef(null);
   const t = useFindTranslationsByString();

   const downloadPdf = () => {
      setLoading(true);
      const pdf = new jsPDF({
         format: [
            certificateRef.current.offsetWidth,
            certificateRef.current.offsetHeight,
         ],
      });

      const pageWidth = pdf.internal.pageSize.width;
      const pageHeight = pdf.internal.pageSize.height;

      html2canvas(certificateRef.current).then((canvas) => {
         const imgData = canvas.toDataURL("image/png");
         pdf.addImage(imgData, "JPEG", 0, -3, pageWidth, pageHeight / 2);
         pdf.save("download.pdf");
         setLoading(false);
      });
   };

   const downloadPng = () => {
      setLoading(true);
      html2canvas(certificateRef.current, {
         width: certificateRef.current.offsetWidth,
      }).then((canvas) => {
         const imgData = canvas.toDataURL("image/png");
         const downloadLink = document.createElement("a");
         downloadLink.href = imgData;
         downloadLink.download = "download.png";
         downloadLink.click();
         setLoading(false);
      });
   };

   const handleMouseEnter = (item) => {
      setCurrentTemplate(item);
    };
  
    const handleMouseLeave = () => {
      setCurrentTemplate({});
    };

   if (orientation === "line") {
      return (
         <div className={cn(styles.lineOriented)}>
            {certificates?.map((item, index) => (
               <div
                  ref={lastItemRef && certificates?.length - 1 === index ? lastItemRef : null}
                  className={cn(styles.certificate_wrapper)}
                  key={`first${item.id}`}
               >
                  <div className={styles.certificate}>
                     <CertificateTemplate
                        onClick={() => openCertificateModal(item)}
                        size="5"
                        data={item}
                     />
                  </div>

                  <div className={styles.right_block}>
                     <div className={styles.first}>
                        <div className={styles.courseName}>
                           {item?.course?.name}
                        </div>
                        {!!item?.course?.tags?.length && (
                           <div className={styles.row}>
                              {item?.course?.tags?.map((item, key) => (
                                 <span key={key}>{item}</span>
                              ))}
                           </div>
                        )}
                        <div className={styles.date}>
                           <div className={styles.label}>{t('Date')}:</div>
                           <span>
                              {moment(item?.dateCompleted).format("DD/MM/YY")}
                           </span>
                        </div>
                     </div>

                     <div className={styles.second} onMouseEnter={() => handleMouseEnter(item)} onMouseLeave={handleMouseLeave}>
                        <Button
                           title={t("Download as PDF")}
                           image={pdfIcon}
                           onClick={downloadPdf}
                           isBlack
                           disabled={loading}
                        />
                        <Button
                           title={t("Download as PNG")}
                           image={pngIcon}
                           onClick={downloadPng}
                           isBlack
                           disabled={loading}
                        />
                     </div>
                  </div>
               </div>
            ))}

            {/* FOR PDF AND PNG */}
            {currentTemplate?.id && (
               <div className={styles.hide}>
                  <CertificateTemplate
                     size="15"
                     data={certificates.find(
                        (cer) => cer.id === currentTemplate?.id
                     )}
                     certificateRef={certificateRef}
                  />
               </div>
            )}

            {!certificateLoading && !certificates?.length && (
               <div className={styles.empty}>
                  <EmptyBlock key={"cert"} />
               </div>
            )}

            {loading && (
               <div className="default_loader_wrapper">
                  <Loader size={"small"} />
               </div>
            )}
         </div>
      );
   }

   return (
      <div className={cn(styles.certificatesWrapper)}>
         {certificates?.map((item, index) => (
            <div
               ref={lastItemRef && certificates?.length - 1 === index ? lastItemRef : null}
               className={cn(styles.certificate_item)}
               key={`first${item?.id}`}
            >
               <CertificateTemplate
                  onClick={() => openCertificateModal(item)}
                  size="5"
                  data={item}
               />
            </div>
         ))}
         {!certificateLoading && !certificates?.length && (
            <div  className={styles.empty}>
               <EmptyBlock key={"cert"} />
            </div>
         )}
      </div>
   );
};

export default Certificates;
