import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import useOutsideClick from "../../hooks/dom/useOutsideClick";
import Input from "../Input/Input";
import arrow from "../../assets/images/symbols/arrow.svg";
import plus from "../../assets/images/symbols/plus.svg";
import cross from "../../assets/images/symbols/cross_grey.svg";
import arrowWhite from "../../assets/images/symbols/arrow_white.svg";
import serchIcon from "../../assets/images/symbols/Search.svg";
import cn from "classnames";
import DatePicker from "react-datepicker";
import moment from "moment";
import useFindTranslations from "../../hooks/useFindTranlsations";

const CustomDropdown = ({
   data,
   placeholder,
   onChange,
   value,
   field = null,
   title = null,
   isWhiteBlack = false,
   searchNeeded,
   maxHeight,
   img,
   isWithDate,
   isWithDateRange,
   onChangeDate,
   dateFilter,
   noLeftRightPadding,
   frontTimeFilterValue,
   setFrontTimeFilterValue,
   maxFrontHeight40,
}) => {
   const [isOpen, setIsOpen] = useState(false);
   const [searchValue, setSearchValue] = useState("");
   const [dateValue, setDateValue] = useState("");
   const [selectedDate, setSelectedDate] = useState("");
   const [startDate, setStartDate] = useState("");
   const [endDate, setEndDate] = useState("");

   const t = useFindTranslations();

   const formatDate = (date) => {
      if (!date) {
         return "";
      }

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
   };

   const onChangeDateRange = (dates) => {
      const [start, end] = dates;
      const date = dates.map(formatDate);
      setFrontTimeFilterValue('')

      if(date[0] && date[1]) onChangeDateValue(`${date[0]} - ${date[1]}`)
      if(date[0] || date[1]) setFrontTimeFilterValue(`${date[0]} - ${date[1]}`)

      setStartDate(start);
      setEndDate(end);
   };

   const dropdownRef = useRef();
   const inputRef = useRef();
   const inputRefTop = useRef();

   const onClose = () => setIsOpen(false);
   const onToggle = () => setIsOpen(!isOpen);
   const onChangeValue = (val) => onChange(val);
   const onChangeDateValue = (val) => onChangeDate && onChangeDate(val);

   useOutsideClick(dropdownRef, onClose, inputRef);

   const searchHandler = (list) => {
      return list?.filter((item) =>
         searchValue?.length
            ? item?.name?.toLowerCase()?.includes(searchValue?.toLowerCase())
            : true
      );
   };

   const filteredData = searchHandler(data);

   useEffect(() => {
      if (!isOpen) setSearchValue("");
      if (isOpen) inputRefTop.current.focus();
   }, [isOpen]);

   return (
      <>
         <div
            className={cn(styles.dropdown_wrapper, maxFrontHeight40 ? styles.maxInputHeight : '')}
            onClick={onToggle}
            ref={inputRef}
         >
            {title && <p className={styles.title}>{t?.returnTranslation(title)}</p>}

            {img && (
               <img
                  className={cn(styles.search, styles.logo)}
                  src={img}
                  alt=""
               />
            )}

            <Input
               isDefaultColor
               placeholder={value?.name || frontTimeFilterValue ? frontTimeFilterValue : value || placeholder}
               ref={inputRefTop}
               customClass={cn(
                  isOpen ? styles.input_open : null,
                  styles.input,
                  !img ? styles.pl_0 : "",
                  noLeftRightPadding ? styles.noLeftRightPadding : "",
                  maxFrontHeight40 ? styles.noBorder : '',
               )}
               disabled={!searchNeeded}
               value={searchValue}
               onChangeValue={(e) => setSearchValue(e.target.value)}
               isWhiteBlack={isWhiteBlack}
            />

         {isWithDateRange && value?.length  > 0 && !isOpen && 
            <img className={cn(styles.cross, styles.crossFront)} onClick={(e)=>{
               onChangeDateValue('');
               e.stopPropagation();
               setStartDate('');
               setEndDate('');
               setFrontTimeFilterValue('')
            }} src={cross} alt="" />
         }

            <div className={styles.click_el}></div>

            <img
               src={isWhiteBlack ? arrowWhite : arrow}
               alt={""}
               className={cn(styles.arrow, isOpen && styles.arrow_open)}
            />

            {isOpen && (
               <div
                  style={{ maxHeight: maxHeight ? maxHeight : "300px" }}
                  className={cn(
                     styles.dropdown,
                     isWhiteBlack && styles.dropdown_isWhiteBlack
                  )}
               >

                  {value?.name === 'Select' && 
                     <img className={styles.crossTop} onClick={()=> onChange(null)} src={cross} alt="" />
                  }
                  {filteredData?.map((item, key) => {
                     return (
                        <div
                           key={key}
                           onClick={() => onChangeValue(item)}
                           className={cn(
                              styles.dropdown_item,
                              item?.id === value?.id &&
                                 styles.dropdown_item_active,
                                 isWithDateRange ? styles.noCapitalize : ''
                           )}
                        >
                           <div className={styles.user_info_wrapper}>
                              <div className={styles.right}>
                                 <p>{t?.returnTranslation(item?.name)}</p>
                              </div>
                           </div>
                        </div>
                     );
                  })}
                  {!data?.length && (
                     <div className={styles.dropdown_item}>{t?.thereAreNoData}</div>
                  )}

                  {isWithDate && (
                     <div
                        onClick={(e) => {
                           e.stopPropagation();
                        }}
                        className={cn(styles.dropdown_date_item)}
                     >
                        <p className={styles.date_option}>
                           {dateFilter || t?.selectDate}
                        </p>

                        <DatePicker
                           selected={selectedDate}
                           className={styles.date}
                           dateFormat="yyyy-MM-dd HH:mm:ss"
                           onChange={(date) => {
                              onChangeDateValue(
                                 moment(date).format("YYYY-MM-DD")
                              );
                              setSelectedDate(date)
                              onClose();
                           }}
                        />
                        {dateFilter && 
                           <img className={styles.cross} onClick={()=> {
                              onChangeDateValue('');
                           }} src={cross} alt="" />
                        }
                     </div>
                  )}
                  {isWithDateRange && (
                     <div
                        onClick={(e) => {
                           e.stopPropagation();
                        }}
                        className={cn(styles.dropdown_date_item)}
                     >
                        <p className={styles.date_option}>
                           {dateFilter || t?.selectDateRange}
                        </p>
                        <DatePicker
                           className={styles.date}
                           selected={startDate}
                           startDate={startDate}
                           endDate={endDate}
                           selectsRange
                           dateFormat="yyyy-MM-dd HH:mm:ss"
                           onChange={onChangeDateRange}
                           popperPlacement="auto-right"
                        />
                        
                        {dateFilter && 
                           <img className={styles.cross} onClick={()=>{
                              onChangeDateValue('');
                              setStartDate('');
                              setEndDate('');
                              setFrontTimeFilterValue('');
                           }} src={cross} alt="" />
                        }
                     </div>
                  )}
               </div>
            )}
         </div>
      </>
   );
};

export default CustomDropdown;
