import moment from "moment";
import CustomTooltip from "../../../../components/CustomTooltip/CustomTooltip";
import PieChart from "../../../../components/PieChart/PieChart";

import styles from "./styles.module.scss";
import cn from "classnames";
import { Link } from "react-router-dom";
import { parseIsoDurationStr } from "../../../../utils/coursesHelper";
import useFindTranslationsByString from "../../../../hooks/useFindTranslationsByString";

const LeftPanel = ({ chartData, isTheory, setIsTheory, testData }) => {
   const formattedDate = testData ? moment(testData.completedAt).format("DD.MM.YY HH:mm") : null;
   const timeSpent = parseIsoDurationStr(testData?.timeSpent || '');

   const t = useFindTranslationsByString();

   const theCourse = testData?.lesson?.module?.course;

   return (
      <div className={styles.left_panel}>
         <div className={styles.is_theory_btns}>
            <button
               className={cn(
                  styles.is_theory_btn,
                  !isTheory ? styles.active_theory : ""
               )}
               onClick={() => setIsTheory(false)}
            >
               {" "}
               {t("On check")}
            </button>
            <button
               className={cn(
                  styles.is_theory_btn,
                  isTheory ? styles.active_theory : ""
               )}
               onClick={() => setIsTheory(true)}
            >
               {" "}
               {t('Theory')}
            </button>
            <div className={styles.active_indicator}>
               {!isTheory ? t("On check") : t("Theory")}
            </div>
         </div>
         <div className={styles.chart_container}>
            <PieChart chartData={chartData} />
         </div>
       
         <div className={styles.test_info}>
            <div className={styles.test_info_item}>
               <span>{t("Course")}:</span>
               <Link to={`/courses/course/${theCourse?.id}/default_lang/${theCourse?.defaultLanguage || 'en_US'}/${theCourse?.availableLanguages?.length > 1}`}>
                  <CustomTooltip 
                     text={testData?.lesson?.module?.course?.name || ''} 
                     id={testData?.id}
                     limit={22}
                  />
               </Link>
            </div>
            <div className={styles.test_info_item}>
               <span>{t("Task")}:</span>
              
               <CustomTooltip 
                  text={testData?.lesson?.name || ''} 
                  id={testData?.lesson?.id}
                  maxWidth={'290px'}
                  limit={22}
               />
            </div>
            <div className={styles.test_info_item}>
               <span>{t("Attempt")}:</span>
               <div> {testData?.passAttemptsCounters?.length || 1} </div>
            </div>
            <div className={styles.test_info_item}>
               <span>{t('Run time')}:</span>
               <div>{timeSpent}</div>
            </div>
            <div className={styles.test_info_item}>
               <span>{t("Done")}:</span>
               <div>{formattedDate || ''}</div>
            </div>
         </div>
      </div>
   );
};

export default LeftPanel;
