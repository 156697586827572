import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import Button from "../../components/Button/Button";
import { useParams, useNavigate } from "react-router-dom";

import UsersPanel from "./UsersPanel/UsersPanel";
import StatisticsUserContent from "./StatisticsUserContent/StatisticsUserContent";
import { returnTemporaryLink } from "../../utils/usersHelper";
import UserApi from "../../utils/api/UserApi";
import useGetOneUser from "../../hooks/api/useGetOneUser";
import useGetAllUsersOfCompany from "../../hooks/api/useGetAllUsersOfCompany";
import { useSelector } from "react-redux";
import { USER_EXTRA_GROUP_TWO } from "../../utils/api/serialization_groups";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import CourseApi from "../../utils/api/CourseApi";
import StatisticsApi from "../../utils/api/StatisticsApi";
import useGetCoursesCount from "../../hooks/api/useGetCoursesCount";
import useGetYourCoursesInCompany from "../../hooks/api/useGetYourCoursesInCompany";
import useGetStatisticsUserCourses from "../../hooks/api/useGetStatisticsUserCourses";

const StatisticsUserInfoPage = () => {
   const companyId = useSelector((state) => state.sidebar.companyId);
   const isSideBarFixed = useSelector((state) => state.sidebar.isFixed);
   const params = useParams();
   const navigate = useNavigate();

   const abortController = new AbortController();
   const signal = abortController.signal;

   const { user: statisticsUser, setUser: setStatisticsUser } = useGetOneUser(
      params?.id
   );
   const { coursePassingInfo } = useGetCoursesCount(companyId, statisticsUser?.id);
   
   const [userIntegrationPercentage, setUserIntegrationPercentage] = useState(0);
   const [userScore, setUserScore] = useState(0);
   const [selectedDepartment, setSelectedDepartment] = useState('');
   const [selectedCourse, setSelectedCourse] = useState(null);
   const [triggerCFetch, setTriggerCFetch] = useState(false);

   const [orderBy, setOrderBy] = useState('progress');
   const [order, setOrder] = useState('desc');
   const [progressRange, setProgressRange] = useState('');

   const triggerUsersFetch = () => {
      if(selectedCourse || selectedDepartment.length) {
         setTriggerCFetch(!triggerCFetch);
      }
   }

   const onProgressConfirm = (range) => {
      setProgressRange(range);
   }

   const {
      setCurrentPage,
      userList,
      setUserList,
      searchedValue,
      totalUsers,
      setSearchedValue,
      isUsersLoading,
   } = useGetAllUsersOfCompany(
      companyId, null, null, 
      selectedDepartment && selectedDepartment !== 'All' ? selectedDepartment : null, null,
      selectedCourse?.id,
      USER_EXTRA_GROUP_TWO,
      triggerCFetch
   );

   const lastUserRef = useIntersectionObserver(() => {
      if (!isUsersLoading && userList?.length < totalUsers) {
         if (userList?.length === 0) {
            setCurrentPage(1);
         } else {
            setCurrentPage((prev) => prev + 1);
         }
      }
   }, [isUsersLoading, totalUsers]);

   const coursesTableManager = useGetStatisticsUserCourses(
      companyId, statisticsUser?.id, orderBy, order, progressRange, signal
   );

   const lastCourseTableRef = useIntersectionObserver(() => {
      if (!coursesTableManager.loading && coursesTableManager.data?.length < coursesTableManager.totalCount) {
         if (coursesTableManager?.data?.length === 0) {
            coursesTableManager.setPage(1);
         } else {
            coursesTableManager.setPage((prev) => prev + 1);
         }
      }
   }, [coursesTableManager.loading, coursesTableManager.totalCount]);

    //  all available to your courses
    const coursesManager = useGetYourCoursesInCompany(companyId);

    const lastAllCourseRef = useIntersectionObserver(
       () => {
         if (!coursesManager?.isCoursesLoading && coursesManager?.courseList?.length < coursesManager?.totalCourses) {
           if(coursesManager.coursesList?.length === 0) {
            coursesManager.setCurrentPage(1);
           } else {
            coursesManager.setCurrentPage((prev) => prev + 1);
           }
         }
       },
       [coursesManager.isCoursesLoading, coursesManager.totalCourses]
     );

   const getUserIntegrationStatistics = async () => {
      try {
         const res = await new StatisticsApi().getUserIntegrationStatistics(
            companyId,
            statisticsUser?.id,
            signal
         );

         if (res?.success?.data?.user) {
            setUserIntegrationPercentage(
               res?.success?.data?.user?.[0]?.integration_percentage
            );
         }
      } catch (error) {
         console.error("Error fetching user courses count:", error);
      }
   };

   const getUserScoreOverAllUserCourses = async () => {
      try {
         const res = await new StatisticsApi().getUserScoreStatistics(
            companyId,
            statisticsUser?.id,
            signal
         );

         if (res?.success?.data?.user) {
            setUserScore(
               res?.success?.data?.user?.[0]?.companyUserScore
            );
         }
      } catch (error) {
         console.error("Error fetching user courses count:", error);
      }
   };

   useEffect(() => {
      if (companyId && statisticsUser?.id) {
         getUserIntegrationStatistics();
         getUserScoreOverAllUserCourses();
      }

      return ()=> {
         abortController.abort();
       }
   }, [companyId, statisticsUser]);

   const changeActiveUser = (user) => {
      if(!user?.id) return;
      setStatisticsUser(user);
      navigate(`/statistics/user/${user?.id}`);
   };

   return (
      <MainLayout>
         <div className={styles.user_statistics}>
            <UsersPanel
               changeActiveUser={changeActiveUser}
               statisticsUser={statisticsUser}
               lastUserRef={lastUserRef}
               isUsersLoading={isUsersLoading}
               userList={userList}
               searchedValue={searchedValue}
               setSearchedValue={setSearchedValue}
               isSideBarFixed={isSideBarFixed}
               selectedDepartment={selectedDepartment}
               setSelectedDepartment={setSelectedDepartment}
               selectedCourse={selectedCourse}
               setSelectedCourse={setSelectedCourse}
               lastAllCourseRef={lastAllCourseRef}
               coursesManager={coursesManager}
               triggerUsersFetch={triggerUsersFetch}
            />
            <StatisticsUserContent
               statisticsUser={statisticsUser}
               isSideBarFixed={isSideBarFixed}
               userIntegrationPercentage={userIntegrationPercentage}
               coursePassingInfo={coursePassingInfo}
               userScore={userScore}
               coursesTableManager={coursesTableManager}
               lastUserCourseRef={lastCourseTableRef}
               orderBy={orderBy}
               setOrderBy={setOrderBy}
               order={order}
               setOrder={setOrder}
               onProgressConfirm={onProgressConfirm}
               progressRange={progressRange}
               userAvailableCoursesTotal={coursesManager?.totalCourses}
            />
         </div>
      </MainLayout>
   );
};

export default StatisticsUserInfoPage;
