import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import courseImg from "../../../assets/images/course_thumbnail.png";

import styles from "./styles.module.scss";
import cn from "classnames";
import { useParams } from "react-router-dom";

const renderCellContent = (column, row, maxWordsLength) => {
   if (column === "team_name") {
      if (row[column]?.length === 0) return "-";
      const arr = row[column]?.split(",");
      const text = arr?.map((name) => name?.trim())?.join(", ") || row[column];

      return (
         <div className={styles.few_items}>
            <CustomTooltip
               id={row[column]}
               limit={maxWordsLength}
               text={text}
               maxWidth="250px"
            />
         </div>
      );
   }
   if (column === "progress") {
      const rowEmpty = row[column] === "-";

      return (
         <div className={styles.integration_column_wrapper}>
            <span>{!rowEmpty ? row[column] : "0"}%</span>
            <div className={styles.line_wrapper}>
               <div
                  style={{
                     width: `${!rowEmpty ? row[column] : 0}%`,
                  }}
                  className={cn(styles.line, (+row[column]) === 100 ? styles.line_green : '')}
               ></div>
            </div>
         </div>
      );
   }

   if (column === "course_name") {
      return (
         <div>
            <img
               src={row?.courseImageUrl || courseImg}
               alt="Course Image"
               className={styles.courseImage}
            />
            <div className={styles.course_with_image}>
               <CustomTooltip
                  id={row[column]}
                  limit={maxWordsLength}
                  text={row[column]}
                  maxWidth="500px"
               />
            </div>
         </div>
      );
   } else {
      return (
         <CustomTooltip
            id={row[column]}
            limit={maxWordsLength}
            text={row[column]}
            maxWidth="500px"
         />
      );
   }
};

const StatisticsBodyItem = ({
   row,
   columns,
   index,
   onRowClick,
   tableFor,
   maxWordsLength = 20,
   rowClickable,
}) => {
   const params = useParams();

   const redirectToClickedEl = () => {
      if (row?.courseId) {
         window.open(
            `/courses/course/${row.courseId}/${params.id}`,
            "_blank"
         )
      }
   };

   return (
      <tr
         onClick={redirectToClickedEl}
         key={`row-${index}`}
         className={cn(
            index % 2 === 0 ? styles.rowOdd : "",
            tableFor === "teams" ? styles.link : "",
            rowClickable ? styles.clickable : ""
         )}
      >
         {columns.map((column, columnIndex) => (
            <td key={`cell-${index}-${columnIndex}`}>
               <div className={styles.cellContent}>
                  {renderCellContent(column, row, maxWordsLength)}
               </div>
            </td>
         ))}
      </tr>
   );
};

export default StatisticsBodyItem;
