import React, { useEffect, useRef, useState } from "react";
import { Chart } from "react-google-charts";
import styles from "./styles.module.scss";
import useFindTranslationsByString from "../../hooks/useFindTranslationsByString";

const options = {
   pieHole: 0.83,
   is3D: false,
   backgroundColor: "transparent",
   textStyle: {
      color: "#fff",
   },
   colors: ["#44DD65", "#0B88F8", "#3E3E41"],
   pieSliceBorderColor: "transparent",
   legend: "none",
   pieSliceText: "none",
   chartArea: { width: "100%", height: "100%" },
   pieSliceTextStyle: null,
   tooltip: {
      text: "value",
      fontSize: 16,
   },
   enableInteractivity: false,
   selectionMode: "none",
   animation: {
      startup: true,
      easing: "linear",
      duration: 1500,
   },
};

const PieChartStatistics = ({ chartData, score }) => {
   const t = useFindTranslationsByString();

   return (
      <div className={styles.chart_container}>
         <Chart
            chartType="PieChart"
            width="100%"
            height="100%"
            data={chartData}
            options={options}
         />

         {score && (
            <span className={styles.correct_percent}>
               <span>{(+score)?.toFixed() || "0"}</span>
               <span className={styles.gray}>{t("Average score")}</span>
            </span>
         )}
         {!score && (
            <span className={styles.correct_percent}>
               <span>{"0"}</span>
               <span className={styles.gray}>{t("Average score")}</span>
            </span>
         )}

      </div>
   );
};

export default PieChartStatistics;
