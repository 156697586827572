import React, { useEffect, useRef, useState, useTransition } from "react";
import MainLayout from "../../layouts/MainLayout/MainLayout";

import TableBlock from "./TableBlock/TableBlock";
import UsersSubheader from "./Subheaders/UsersSubheader";
import TeamsSubheader from "./Subheaders/TeamsSubheader";

import cn from 'classnames';
import styles from "./styles.module.scss";
import UserApi from "../../utils/api/UserApi";
import { ALL_STUDENTS_TABLE_HEADERS, BRANCH_ROLE_STUDENT, DEFAULT_DATA_LIMIT, PART_OF_MENTOR_NAME, ROLE_ID_ADMIN, ROLE_ID_GUEST, ROLE_ID_STUDENT, ROLE_ID_SUPER_ADMIN, TEAMS_HEADERS, USER_STATUS_ACTIVE, USER_STATUS_BANNED } from "../../constants/user";
import moment from "moment";
import Loader from "../../components/Loader/Loader";
import TeamApi from "../../utils/api/TeamApi";
import { useSelector } from "react-redux";
import useUserRole from "../../hooks/useUserRole";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import debounce from 'lodash/debounce';
import { DEFAULT_DEBOUNCE_DELAY } from "../../constants/statistics";
import { transformRoleText } from "../../utils/rolesHelper";
import { TEAM_EXTRA_GROUP, TEAM_EXTRA_GROUP_TABLE } from "../../utils/api/serialization_groups";
import useGetTeams from "../../hooks/api/useGetTeamsOfCompany";
import { sortRoles } from "../../utils/usersHelper";
import useFindTranslations from "../../hooks/useFindTranlsations";

const UsersPage = () => {
  const [activeTab, setActiveTab] = useState("students");

  const previousUsersRequest = useRef(null);

  const [searchedValue, setSearchedValue] = useState('');
  const [userList, setUserList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);
  const [isUsersLoading, setIsUsersLoading] = useState(false);

  const companyId = useSelector(state => state.sidebar.companyId);
  const currentCompanyName = useSelector(state => state.sidebar.companyName);

  const userInfo = useSelector(state => state?.user?.info);
  const { userRoleName } = useUserRole(companyId)

  const t = useFindTranslations();

   const mapTeams = (team => ({
      id: team?.id,
      department: team?.department || '-',
      position: team?.position ? team?.position : '-',
      team: team?.name,
      amount: team?.users?.length,
   }))

   const addUserToList = (newUser) => {
      const newUsersList = [newUser, ...userList];
      setTotalUsers(totalUsers + 1);
      setUserList(newUsersList);
    };

   const addUsersToList = (newUsers) => {
      const newUsersList = [...newUsers, ...userList];
      setTotalUsers(totalUsers + newUsers?.length);
      setUserList(newUsersList);
    };

    const fetchUsers = async (page, limit = null) => {
      setIsUsersLoading(true);

      const controller = new AbortController();
      previousUsersRequest.current = controller;
      const signal = controller.signal;
      
      const isStudents = activeTab === 'students';
      const isSpecialRoles = activeTab === 'roles';
      const isBlocked = activeTab === 'blocked';

      const res = await new UserApi().getUsersByRoles(page ? page : currentPage, limit ? limit : DEFAULT_DATA_LIMIT, companyId, isStudents, isSpecialRoles, isBlocked, searchedValue, signal);

      if(res?.success?.data) {
         if(page) {
            setUserList(res?.success?.data);
         } else {
            setUserList((prev)=> [...prev, ...res?.success?.data]);
         }

         setTotalUsers(res?.success?.totalCount)
      }

      if(res?.success?.data?.length === 0) {
         setCurrentPage(1);
      }

      if(res?.error?.message && page) {
         setUserList([]);
      }

      setIsUsersLoading(false);
   }

   const lastUserRef = useIntersectionObserver(
      () => {
        if (!isUsersLoading && userList?.length < totalUsers) {
          if(userList?.length === 0) {
            setCurrentPage(1);
          } else {
            setCurrentPage((prev) => prev + 1);
          }
        }
      },
      [isUsersLoading, totalUsers]
    );

   useEffect(()=> {
      if(currentPage > 1 && userList?.length <= totalUsers && !isUsersLoading) {
         fetchUsers();
      }
   }, [currentPage])

   const debouncedFetchUsers = debounce(()=> fetchUsers(1), DEFAULT_DEBOUNCE_DELAY); 

   useEffect(()=> {
      if(companyId && activeTab && activeTab !== 'teams') {
         setCurrentPage(1);
         debouncedFetchUsers();
      }

      return () => debouncedFetchUsers.cancel();
   }, [searchedValue, activeTab, companyId])

   const refetchUsers = () => {
      debouncedFetchUsers();
      setCurrentPage(1);
   };

   useEffect(()=> {
      setUserList([]);
      if(activeTab !== 'teams') setIsUsersLoading(true);
      if(activeTab === 'teams') setIsUsersLoading(false);
      if(previousUsersRequest?.current) {
         previousUsersRequest.current.abort();
      }  
      setSearchedValue('');
   }, [activeTab])


   const teamManager = useGetTeams({ companyId, userRoleName, serializationGroup: TEAM_EXTRA_GROUP_TABLE });

   const lastTeamRef = useIntersectionObserver(
      () => {
        if (!teamManager.isTeamsLoading && teamManager.teamsList?.length < teamManager.totalTeams) {
          if(teamManager.teamsList?.length === 0) {
            teamManager.setCurrentPage(1);
          } else {
            teamManager.setCurrentPage((prev) => prev + 1);
          }
        }
      },
      [teamManager.isTeamsLoading, teamManager.totalTeams]
    );

   useEffect(()=> {
      if(userRoleName?.includes(PART_OF_MENTOR_NAME)){
         setIsUsersLoading(false);
      } 
   }, [userRoleName])


  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

 useEffect(() => {
   if(userRoleName?.includes(PART_OF_MENTOR_NAME)) {
      setActiveTab('teams')
   }
 }, [userRoleName]);

 const mappedUserList = userList?.map(user => {
   const sortedRoles = user?.userBranchRoles
     ?.filter(role => role?.branch?.company?.id === +companyId)
     ?.sort(sortRoles);
 
   const studentRole = user?.userBranchRoles
     ?.find(role => role?.role?.id === ROLE_ID_STUDENT && role?.branch?.company?.id === +companyId)?.role;
 
   const role = activeTab === "students"
     ? studentRole
       ? transformRoleText(studentRole.name, currentCompanyName)
       : "-"
     : transformRoleText(
         (sortedRoles?.[0]?.role?.name) || "-", 
         currentCompanyName
       );

   const roleObj = activeTab === "students"
       ? user?.userBranchRoles
           ?.find(role => role?.role?.id === ROLE_ID_STUDENT && role?.branch?.company?.id === +companyId) || null
       : sortedRoles?.[0] || null;

   const isStudentRoleIsTheOnlyRoleInCompany = (studentRole && sortedRoles?.length === 1 && sortedRoles[0]?.role?.id === ROLE_ID_STUDENT) || (!sortedRoles?.length && activeTab === "students");
       
   return {
     id: user?.id,
     name: user?.firstName || user?.lastName ? `${user?.firstName || ''} ${user?.lastName || ''}` : '-',
     email: user?.email || '-',
     department: user?.department || '-',
     position: user?.position || "-",
     role,
     studentRoleIfFound: studentRole || null,
     roleObj,
     isStudentRoleIsTheOnlyRoleInCompany: isStudentRoleIsTheOnlyRoleInCompany ? 'yes' : 'no',
     was: user?.lastActivity ? moment(user?.lastActivity).format("MMM DD, YYYY h:mm a") : '-',
     status: user.status,
   };
 });

const mappedTeams = teamManager?.teamsList?.map(mapTeams)

 const generateCsvData = () => {
   if (activeTab === 'students' || activeTab === 'roles' || activeTab === 'blocked') {
     return [
       ALL_STUDENTS_TABLE_HEADERS,
       ...mappedUserList?.map(({ name, email, department, position, role, was }) => [
         name, email, department, position, role, was,
       ])
     ];
   } 
   return []
 };

 const generateCsvDataTeams = () => {
   return [
      TEAMS_HEADERS,
      ...mappedTeams?.map(({ department, position, team, amount }) => [
         team, department, position, amount
       ])
   ];
 };

  return (
    <MainLayout darkBg>
      <main className={styles.users_page}>
         <div className={styles.loader_wrapper}>
            {isUsersLoading && <Loader size={"small"} />}
         </div>
        <div className={styles.tab_navigation}>
         {!userRoleName?.includes(PART_OF_MENTOR_NAME) && 
            <>
               <button
                  className={cn(styles.tab, activeTab === "students" && styles.activeTab)}
                  onClick={() => handleTabChange("students")}
               >
                  {t?.students}
               </button>
               <button
                  className={cn(styles.tab, activeTab === "roles" && styles.activeTab)}
                  onClick={() => handleTabChange("roles")}
               >
                  {t?.roles}
               </button>
               <button
                  className={cn(styles.tab, activeTab === "teams" && styles.activeTab )}
                  onClick={() => handleTabChange("teams")}
               >
                  {t?.teams}
               </button>
               <button
                  className={cn(styles.tab, activeTab === "blocked" && styles.activeTab )}
                  onClick={() => handleTabChange("blocked")}
               >
                  {t?.blocked}
               </button>
            </>
         }

         {userRoleName?.includes(PART_OF_MENTOR_NAME) && 
            <button
               className={cn(styles.tab, activeTab === "teams" && styles.activeTab )}
               onClick={() => handleTabChange("teams")}
            >
               {t?.teams}
            </button>
         }
         
        </div>
      
        {activeTab === "students" && 
         <>
            <UsersSubheader
               key={'students'}
               setSearchedValue={setSearchedValue}
               searchedValue={searchedValue}
               refetchData={debouncedFetchUsers}
               addUserToList={addUsersToList}
               csvData={generateCsvData()}
               fetchUsers={fetchUsers}
               isDataLoading={isUsersLoading}
               t={t}
            />

            <TableBlock 
               key={'users_table'}
               tableFor={'students'}
               data={mappedUserList} 
               headers={ALL_STUDENTS_TABLE_HEADERS}
               refetchUsers={refetchUsers}
               loading={isUsersLoading}
               lastUserRef={lastUserRef}
               t={t}
            />
         </>
        }

        {activeTab === "blocked" &&
         <>
               <UsersSubheader
                  isBlocked
                  key={'students_blocked'}
                  setSearchedValue={setSearchedValue}
                  searchedValue={searchedValue}
                  refetchData={debouncedFetchUsers}
                  csvData={generateCsvData()}
                  fetchUsers={fetchUsers}
                  isDataLoading={isUsersLoading}
                  t={t}
               />
               <TableBlock 
                  key={'blocked_users_table'}
                  tableFor="blocked"
                  data={mappedUserList} 
                  headers={ALL_STUDENTS_TABLE_HEADERS}
                  refetchUsers={refetchUsers}
                  loading={isUsersLoading}
                  lastUserRef={lastUserRef}
                  t={t}
               />
         </>   
         }
         {activeTab === "roles" && 
            <>
               <UsersSubheader
                  key={'roles'}
                  isAdmins
                  setSearchedValue={setSearchedValue}
                  searchedValue={searchedValue}
                  refetchData={debouncedFetchUsers}
                  addUserToList={addUserToList}
                  csvData={generateCsvData()}
                  fetchUsers={fetchUsers}
                  isDataLoading={isUsersLoading}
                  t={t}
               />
               <TableBlock 
                  key={'users_table'}
                  tableFor={"roles"}
                  data={mappedUserList} 
                  headers={ALL_STUDENTS_TABLE_HEADERS}
                  refetchUsers={refetchUsers}
                  loading={isUsersLoading}
                  lastUserRef={lastUserRef}
                  t={t}
               />
            </>
         }
        {activeTab === "teams" && 
         <>
            <TeamsSubheader
               setSearchedValue={teamManager.setSearchedValue}
               searchedValue={teamManager.searchedValue}
               activeTeamsFilter={teamManager.activeTeamsFilter}
               setActiveTeamsFilter={teamManager.setActiveTeamsFilter}
               refetchTeams={teamManager?.refetchTeams}
               fetchTeams={teamManager?.fetchTeams}
               isDataLoading={teamManager?.isTeamsLoading}
               csvData={generateCsvDataTeams()}
               t={t}
            />
            <TableBlock 
               key={'teams_table'}
               data={mappedTeams} 
               headers={TEAMS_HEADERS}
               tableFor={"teams"}
               searchedValue={searchedValue} 
               loading={teamManager?.isTeamsLoading}
               lastUserRef={lastTeamRef}
               t={t}
            />
         </>
        }
      </main>
    </MainLayout>
  );
};

export default UsersPage;
