import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import TextArea from "../../../components/TextArea/TextArea";
import Dropdown from "../../../components/Dropdown/Dropdown";
import {
   DEPARTMENTS,
   POSITIONS,
} from "../../../constants/departmentsAndPositions";
import RenameModal from "../../../components/Modals/RenameModal/RenameModal";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const PositionBlock = ({
   setSelectedDepartment,
   selectedDepartment,
   setSelectedPosition,
   selectedPosition,
   onSelectDepartment,
   onSelectPosition
}) => {
   const newDepartmentModal = useHandleModal();
   const newPositionModal = useHandleModal();

   const t = useFindTranslationsByString();

   const onOpenDepartmentModal = () => {
      newDepartmentModal.open();
      setSelectedDepartment("");
   };

   const onOpenPositionModal = () => {
      newPositionModal.open();
      setSelectedPosition("");
   };

   return (
      <div className={styles.settings_box}>
         <div className={styles.block}>
            <div className={styles.setting_form_row}>
               <Dropdown
                  data={DEPARTMENTS}
                  field={"name"}
                  title={t('Department')}
                  value={selectedDepartment}
                  placeholder={t("Department * Optionally")}
                  onChange={onSelectDepartment}
                  createBtnText={t("Create new department")}
                  maxHeight={"220px"}
                  onOpenCreateModal={onOpenDepartmentModal}
               />
            </div>
            <div className={styles.setting_form_row}>
               <Dropdown
                  data={POSITIONS}
                  title={t('Position')}
                  maxHeight={"180px"}
                  field={"name"}
                  value={selectedPosition}
                  placeholder={t("Position * Optionally")}
                  onChange={onSelectPosition}
                  createBtnText={t("Create new position")}
                  onOpenCreateModal={onOpenPositionModal}
               />
            </div>

            <RenameModal
               key={"New deparnment"}
               inputValue={selectedDepartment}
               setInputValue={setSelectedDepartment}
               onClose={newDepartmentModal.close}
               isOpen={newDepartmentModal.isActive}
               onConfirm={newDepartmentModal.close}
               title={t("Enter department name")}
            />
            <RenameModal
               key={"New position"}
               inputValue={selectedPosition}
               setInputValue={setSelectedPosition}
               onClose={newPositionModal.close}
               isOpen={newPositionModal.isActive}
               onConfirm={newPositionModal.close}
               title={t("Enter position name")}
            />
         </div>
      </div>
   );
};

export default PositionBlock;
