import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import styles from "./styles.module.scss";
import Button from "../../Button/Button";
import { useNavigate } from "react-router-dom";
import { pathList } from "../../../routes/path";
import Input from "../../Input/Input";
import Dropdown from "../../Dropdown/Dropdown";
import { ROLE_ID_GUEST, ROLE_ID_STUDENT, ROLE_ID_SUPER_ADMIN } from "../../../constants/user";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import BranchApi from "../../../utils/api/BranchApi";
import Roles from "../../../utils/api/Roles";
import { transformRoleText } from "../../../utils/rolesHelper";
import Loader from "../../Loader/Loader";

const ChangeRoleModal = ({ onClose, onConfirm, isOpen, title, onSelectRole, selectedRole, isLoading, t }) => {
   const navigate = useNavigate();
   const [roles, setRoles] = useState([]);
   const [roleLoading, setRoleLoading] = useState(false);
   const [branch, setBranch] = useState([]);
   const companyId = useSelector(state => state.sidebar.companyId);
   const userId = useSelector((state) => state?.user?.info?.id);
   const currentBranchId = useSelector(state => state.sidebar.currentCompanyBranchId);
   const currentCompanyName = useSelector(state => state.sidebar.companyName);

   const getRoles = async () => {
      setRoleLoading(true);
      const res = await new Roles().getRoles(companyId);

      if (res?.success?.data) {
         const excludedRoleIds = [ROLE_ID_GUEST, ROLE_ID_SUPER_ADMIN];
         const filteredRoles = res?.success?.data?.filter(role => !excludedRoleIds?.includes(role?.id));

         setRoles([{name: 'student', id: ROLE_ID_STUDENT} ,...filteredRoles]);
      }
      setRoleLoading(false);
   };

   useEffect(() => {
       if(companyId && !roles?.length) {
          getRoles();
       }
    }, [companyId]);
 

   return (
      <ModalLayout
         onClose={onClose}
         isOpen={isOpen}
         maxHeight={"257px"}
         maxWidth={"416px"}
         withCross
         visibleOverflow
      >
         <div className={styles.confirm_wrapper}>
            <p className={styles.title}>{title}</p>
            <Dropdown
               searchNeeded
               data={roles.map(role => ({
                  ...role,
                  name: transformRoleText(role?.name, currentCompanyName)
                }))}
               field={'name'}
               placeholder={t?.chooseRole}
               value={selectedRole.name}
               onChange={onSelectRole}
               noTranslate
            />
            <div className={styles.btns}>
               <Button title={t?.cancel} onClick={onClose} isBlack />
               <Button disabled={isLoading} title={t?.confirm} onClick={()=> onConfirm(currentBranchId)} />
            </div>
            
            {isLoading && 
            <div className="default_loader_wrapper">
               <Loader size={"small"} />
            </div>
            }
         </div>
      </ModalLayout>
   );
};

export default ChangeRoleModal;
