import React, { useEffect, useRef, useState } from "react";
import fileIcon from "../../assets/images/file_icon.svg";
import rightErrow from "../../assets/images/arrow_right.svg";
import iconWrongRed from "../../assets/images/close.svg";
import partiallyCorrectIcon from "../../assets/images/partially_correct_icon.svg";
import correctImgGreen from "../../assets/images/correct_green.svg";
import reworkIcon from "../../assets/images/icon_rework.svg";

import CustomTooltip from "../CustomTooltip/CustomTooltip";

import styles from "./styles.module.scss";
import cn from "classnames";
import {
   TEST_STATUS_APPROVED,
   TEST_STATUS_ON_REWORK,
   TEST_STATUS_REJECTED,
} from "../../constants/tests";
import { returnTemporaryCommentsFileLinks } from "../../utils/questionsHelper";
import { useSelector } from "react-redux";
import useFindTranslationsByString from "../../hooks/useFindTranslationsByString";

const TestResultsBlock = ({
   score,
   commentInputValue,
   comment,
   originalWidth,
   status,
   courseId
}) => {
   const userId = useSelector(state => state?.user?.info?.id);
   const [commentLinks, setCommentLinks] = useState([]);

   const t = useFindTranslationsByString();
   
   const getFileExtFromName = (name) => {
      const parts = name?.split('.');
      const fileExtension = parts[parts.length - 1] || '';
      return fileExtension;
   };

   const removeExt = (name) => {
      return name?.split('.')?.slice(0, -1)?.join('.');;
   }

   const getCommentFileLinks = async () => {
      const links = await returnTemporaryCommentsFileLinks(userId, comment?.commentTestFiles, courseId);
      if(links?.length) {
         setCommentLinks(links);
      }
   }

   useEffect(()=> {
      if(comment?.commentTestFiles?.length && userId) {
         getCommentFileLinks();
      }
   }, [comment, userId]);

   return (
      <div
         className={cn(
            styles.complete_test_block,
            originalWidth ? styles.original_width : ""
         )}
      >
         <div className={styles.complete_title}>{t("Final result")}</div>
         <div className={styles.results_score}>
            <div className={styles.score_wrapper}>
               {" "}
               <span className={styles.score}>{score}%</span> {t("Your score")}
            </div>

            <div className={styles.complete_btns}>
               {status === TEST_STATUS_APPROVED && (
                  <button className={cn(styles.button_true, {})}>
                     <img src={correctImgGreen} alt="" />
                     <span>{t("Pass")}</span>
                  </button>
               )}
               {status === TEST_STATUS_ON_REWORK && (
                  <button className={cn(styles.button_half_right, {})}>
                     <img src={reworkIcon} alt="" />
                     <span>{t("On rework")}</span>
                  </button>
               )}
               {status === TEST_STATUS_REJECTED && (
                  <button className={cn(styles.button_false, {})}>
                     <img src={iconWrongRed} alt="" />
                     <span>{t("Failed")}</span>
                  </button>
               )}
            </div>
         </div>

         {commentInputValue && (
            <div className={styles.comment_block}>
               <h3>{t("Comments")}:</h3>
               <div>{commentInputValue}</div>
            </div>
         )}

         {comment?.comment && (
            <div className={styles.attached_files}>
               {comment?.commentTestFiles?.length > 0 && 
                 <h3>{t("Attached files")}: </h3>  
               }
               <div className={styles.files}>
                  {comment?.commentTestFiles?.map((commentFile) => (
                     <a
                        key={commentFile?.id}
                        target="_blank"
                        href={commentLinks?.filter(link => link?.fileName === commentFile?.filename)?.[0]?.fileUrl}
                        className={styles.file}
                     >
                        <div className={styles.flex_el}>
                           <img src={fileIcon} alt="" />
                           <span>
                           <CustomTooltip id={commentFile?.id} text={removeExt(commentFile?.filename)} limit={70}/>.<span className={styles.gray}>
                                  {getFileExtFromName(commentFile?.filename)}</span>
                           </span>
                        </div>
                        <div className={styles.flex_el}>
                           <span>{t("Open")}</span>
                           <img src={rightErrow} alt="" />
                        </div>
                     </a>
                  ))}
               </div>
            </div>
         )}
      </div>
   );
};

export default TestResultsBlock;
